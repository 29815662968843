import disableBrowserBackButton from 'disable-browser-back-navigation';
import 'jquery/dist/jquery';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Scrollbars } from 'react-custom-scrollbars';
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import { withRouter } from "react-router-dom";
import Spinner from 'react-spinner-material'; //Import Spinner
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { basicAuth } from './basicAuth';
import ReactExport from "react-data-export";
import { showSuccess, showAlert } from './services/ConfirmAlert';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class taskactive extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false   //Added by chandrani for custom error page
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }



  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false   //Added by chandrani for custom error page
    };
    this.state = {
      startDate: "",
      endDate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      Searchnum: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tskName: "",
      loading: false,
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      guId: "",
      smusnm: [],
      afmusnm: [],
      techusnm: [],
      role_id: ''

    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }


  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  onChange = e => {
    this.setState({ Search: e.target.value });
  }

  onChangenum = e => {
    this.setState({ Searchnum: e.target.value });
  }
  onChange1 = e => {
    this.setState({ searcheddesc: e.target.value });
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


 

  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  componentWillMount() {
    this.GetTasksOnload();
  }


  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render = () => {


    var that = this;

    const { Search } = this.state;
    const { Searchnum } = this.state;
    const { searcheddesc } = this.state;




    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {


      return (

        <div>

          <section id="container">
            <header className="header black-bg" >
              <div>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10} />
              </div>

              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">

                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" />
                  <b>{this.state.usrnm}</b>
                  <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3><i className="fa fa-angle-right"></i>User List
                {this.state.role_id == "300" || this.state.role_id == "508" ? <a className="pull-right btn btn-theme" onClick={this.usercreation.bind(this)}>+ Create User</a> : ''} </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>User  ID</b><br />
                          <input type="text" placeholder="Search  ID" className="form-control" id="Userid" onChange={this.onChangenum} autoComplete="off" />
                        </div>


                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>User  Name</b><br />
                          <input type="text" placeholder="Search  Name" className="form-control" id="taskname" onChange={this.onChange} autoComplete="off" />
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Role Description</b><br />
                          <input type="text" placeholder="Search Role Description" className="form-control" id="taskdesc" onChange={this.onChange1} autoComplete="off" />
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} />
                        </div>
                        <ExcelFile element={<button className="btn btn-theme" style={{ "margin-top": "15px" }} disabled={this.state.loading}> Download List</button>}>
                          <ExcelSheet data={this.state.data} name="User Details">
                            <ExcelColumn label="User Id" value="userId" />
                            <ExcelColumn label="Email Id" value="email" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Mobile Number" value="mobileNo" />
                            <ExcelColumn label="Role" value="role" />
                          </ExcelSheet>
                          <ExcelSheet data={this.state.techusnm} name="Technicain Details">
                            <ExcelColumn label="User Id" value="userId" />
                            <ExcelColumn label="Email Id" value="email" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Mobile Number" value="mobileNo" />
                            <ExcelColumn label="Role" value="role" />
                          </ExcelSheet>
                          <ExcelSheet data={this.state.smusnm} name="Store Manager Details">
                            <ExcelColumn label="User Id" value="userId" />
                            <ExcelColumn label="Email Id" value="email" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Mobile Number" value="mobileNo" />
                            <ExcelColumn label="Role" value="role" />
                          </ExcelSheet>
                          <ExcelSheet data={this.state.afmusnm} name="AFM Details">
                            <ExcelColumn label="User Id" value="userId" />
                            <ExcelColumn label="Email Id" value="email" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Mobile Number" value="mobileNo" />
                            <ExcelColumn label="Role" value="role" />
                          </ExcelSheet>
                        </ExcelFile>


                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                        <div className="mt">

                          <div className="tbl-holder">

                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>

                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">

                              <thead>

                                <tr>
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>Role Description</th>
                                  {this.state.role_id == "300" ? <th>Action</th> : ''}
                                </tr>
                              </thead>
                            </table>

                            <Scrollbars style={{ height: 296 }}>

                              <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                <tbody>


                                  {this.state.data.map(function (item, key) {

                                    if ((Searchnum !== "" || Search !== "" || (searcheddesc) != "All") &&
                                      (
                                        item.userId.toLowerCase().indexOf(Searchnum.toLowerCase()) ||
                                        item.name.toLowerCase().indexOf(Search.toLowerCase()) ||
                                        item.role.toLowerCase().indexOf(searcheddesc.toLowerCase()))
                                      === -1) {
                                      return null
                                    }


                                    return (

                                      <tr key={key}>
                                        <td data-th="Task Name">{item.userId}</td>
                                        <td data-th="Description">{item.name}</td>

                                        <td data-th="Start Date">{item.role}</td>
                                        {that.state.role_id == "300" ?
                                          <td data-th="Action">
                                            <a className="edit-icon"
                                              onClick={that.callupdatetaskpage.bind(that, item.userId, item.name, item.role)}> <i className="fa fa-pencil "></i> </a>
                                            <a className="delete-icon" onClick={that.deletetask.bind(that, item.userId)}> <i className="fa fa-trash-o "></i> </a>

                                            <span className="d-none">{item.taskId}</span>
                                          </td>

                                          : ''}
                                      </tr>
                                    )
                                  })}</tbody>

                              </table>
                            </Scrollbars>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </section>
          </section>

        </div>
      );
    }
  }


  GetTasksOnload(e) {



    this.setState({ loading: true })


    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ userid: this.props.location.state.userid })
      this.setState({ guId: this.props.location.state.guId, role_id: this.props.location.state.role_id })

      var Request1 = {
        "formatId": this.props.location.state.role_id,
        "guId": this.props.location.state.guId,
        "userId": this.props.location.state.userid
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('GetUsersList', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          if (DecryptedResponse1.responseCode === '200 OK') {

            this.setState({ data: DecryptedResponse1.userList });
            for (let f = 0; f < DecryptedResponse1.userList.length; f++) {
              if (DecryptedResponse1.userList[f].role == "Technician") {
                this.state.techusnm.push(DecryptedResponse1.userList[f])
              }
              else {
                if (DecryptedResponse1.userList[f].role == "AFM") {
                  this.state.afmusnm.push(DecryptedResponse1.userList[f])
                }
                else {
                  this.state.smusnm.push(DecryptedResponse1.userList[f])
                }
              }
            }

            this.setState({
              loading: false
            })

          }
          else {
            this.setState({ loading: false })
            showSuccess(DecryptedResponse1.responseMsg)

          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }

  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        // technicianId:this.props.location.state.technicianId

      }
    }
    );
  }


  EquipmentList =()=>{
    if(this.props.location.state.role_id=="505"||  this.props.location.state.role_id=="501" || this.props.location.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }

  deletetask(dusrid) {

    confirmAlert({
      message: 'Are you sure, you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var Request2 = {
              "userId": dusrid,
              "guId": this.props.location.state.guId,
            }
            var EncryptedRequest2 = Encrypt(Request2);
            fetch('/DeleteUser', {
              method: 'POST',
              body: EncryptedRequest2,
              headers: {
                guId: this.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

              }

            })
              .then((response) => response.text())
              .then((response) => {

                var DecryptedResponse2 = decryptData(response)
                if (DecryptedResponse2.responseCode === '200 OK') {
                  confirmAlert({
                    message: DecryptedResponse2.responseMsg,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { window.location.reload(true) }
                      },

                    ]
                  })

                  this.forceUpdate();
                }
                else {
                  showAlert(DecryptedResponse2.responseMsg)
                }
              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });

              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          }
        }
      ]
    })

  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }



  callupdatetaskpage(userId, name, role) {
    this.props.history.push({
      pathname: '/UserCreation',
      state: {
        values: userId,
        name: name,
        role: role,
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        usrnm: this.state.usrnm,
        header: 'Update User',
        guId: this.props.location.state.guId,
      }
    }
    );

  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }


  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId,
      }
    }
    );
  }
  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );

  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/UserCreation',
      state: {
        header: 'User Creation',
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  categorycreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }


  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId,
      }
    }
    );
  }





  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }
  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      }
    }
    );
  }
}
export default withRouter(taskactive);

