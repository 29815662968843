import disableBrowserBackButton from 'disable-browser-back-navigation';
import 'jquery/dist/jquery';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import Select from 'react-select';
import readXlsxFile from 'read-excel-file';
import { basicAuth } from './basicAuth';
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { showSuccess, showAlert } from './services/ConfirmAlert';


class TaskSummaryReport extends Component {

  state = {
    TaskDropdwn: false,
    userid: "",
    role_id: "",
    storeDetail: [],
    listQueAnsDetails: [],
    headers: [],
    sumheaders: [],
    answerList: [],
    taskSummaryList: [],
    taskName: '',
    taskDescr: '',
    hasError: false,   //Added by chandrani for custom error page
    stateName: '',
    sum1: [],
    statepercentage: '',
    pendingStores: '',
    isPendingVisible: false,
    storeList: [],
    templetId: "",
    imageArray: [],
    question: [],
    imgtaskid: "",
    searchQuestn: "",
    equipment: [],
    equipopt: [],
    equipmentId: [],
    reportcat: [],
    reportcatID: [],
    equipflag: "",
    reportcategory: "",
    reportcatvalueid: [],
    category: "",
    euipvalueid: [],
    category1: "",
    arrayequipid: [],
    reportidvalue: [],
    responseid: "",
    taskId: "report",
    taskidreport: "test",
    shwvalue: "",
    guId: "",
    showmonth: false,
    showequip: false,
    monthId: '',
    selectstate: [],
    selectedState: [],
    selectMonth: [],
    monthnessId: [],
    selectedMonth: [],
    sendState: [],
    stateFinalName: [],
    setFinalState: []
  }
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      usrnm: "",
      data: [],
      fromdata: [],
      titlename: [],
      taskId: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searchQuestn: "",
      loading: false,
      toggle: false,
      taskName: '',
      taskDescr: '',
      hasError: false,   //Added by chandrani for custom error page
      stateName: '',
      sum1: [],
      statepercentage: '',
      isPendingVisible: false,
      pendingStores: '',
      hammenu: true,
      appear: false,
      immodal: false,
      enimg: false,
      eddate: "",
      UpdatesDate: "",
      startdateupdateticker: "",
      storeList: [],
      templetId: "",
      imageArray: [],
      key: "",
      question: [],
      questionId: "",
      questionText: "",
      equipment: [],
      equipopt: [],
      equipmentId: [],
      reportcat: [],
      reportcatID: [],
      equipflag: "",
      reportcategory: "",
      reportcatvalueid: [],
      category: "",
      euipvalueid: [],
      category1: "",
      arrayequipid: [],
      reportidvalue: [],
      responseid: "",
      taskidreport: "test",
      shwvalue: "",
      guId: "",
      showmonth: false,
      showequip: false,
      monthId: "",
      selectstate: [],
      selectedState: [],
      selectMonth: [],
      monthnessId: [],
      selectedMonth: [],
      sendState: [],
      stateFinalName: [],
      setFinalState: []
    };
  
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  componentWillMount() {
    this.Reports()
  }

  _onAction(e) {

  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
 
  onChange = e => {
    this.setState({
      Search: e.target.value
    });

  }

  onChangequestion = e => {
    this.setState({
      searchQuestn: e.target.value
    });
  }


  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }



  
  callImageGallery = (questionId, questionText) => {
    this.props.history.push({
      pathname: '/ImageGallery',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        questionId: questionId,
        taskId: this.state.imgtaskid,
        questionText: questionText,
        guId: this.props.location.state.guId
      }
    }
    );
  }


 


  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );

  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  boxclose = () => {
    this.setState({
      appear: false,
      FileName: "",
      storeList: [],
    })

  }





  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }






  equipmentcategory = (e) => {
    if (e != null) {
      this.setState({ equipopt: e })

      let flagsym = []
      for (var i = 0; i < this.state.equipment.length; i++) {
        for (let k = 0; k < e.length; k++) {
          this.state.equipment[i].value.trim() === e[k].value.trim() ? flagsym.push(this.state.equipment[i].value) : this.setState({ euipvalueid: "" })
        }
      }

      this.state.euipvalueid = flagsym
      this.setState({ arrayequipid: this.state.euipvalueid })
      this.setState({ e: [] })

    }
  }

  changeMonth = (e) => {
    if (e != null) {
      this.setState({ selectedMonth: e.currentTarget.value })
      let flagMonth = []
      for (var i = 0; i < this.state.selectMonth.length; i++) {
        this.state.selectMonth[i].label.trim() === e.currentTarget.value.trim() ? flagMonth.push(this.state.selectMonth[i].id) : this.setState({ monthId: "" })
      }

      this.state.monthId = flagMonth
      this.setState({ monthnessId: this.state.monthId })
      this.setState({ e: [] })

    }
  }


  changeState = (e) => {
    if (e != null) {
      this.setState({ selectedState: e })
      let flagState = []
      for (var i = 0; i < this.state.selectstate.length; i++) {
        for (let k = 0; k < e.length; k++) {
          this.state.selectstate[i].value.trim() === e[k].value.trim() ? flagState.push(this.state.selectstate[i].label) : this.setState({ monthId: "" })
        }
      }
      this.state.stateFinalName = flagState

      this.setState({ setFinalState: this.state.stateFinalName })
      this.setState({ e: [] })

    }
  }



  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }
  render = () => {
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />

            </div>
            <header class="header black-bg">
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b> <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind}>Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Summary reports</h3>
                    </div>
                    <hr />
                    <div className="col-xs-22 col-sm-3 col-md-3 col-lg-3 mt-xs-10"> <b>Select Year</b><br />
                      <Select multiple class="form-control" isMulti multiValueRemove
                        onChange={this.equipmentcategory.bind(this)}
                        id="equipmentcategory123"
                        options={this.state.equipment}
                        value={this.state.equipopt}
                        theme={theme => ({
                          ...theme,

                          colors: {
                            primary25: 'white',
                            primary: 'rgb(255, 255, 255)',
                          },
                        })}
                      />
                    </div>



                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mt-xs-10"><b>Select Month</b><br />
                      <select class="form-control" onChange={this.changeMonth.bind(this)} id="selectMonth" >
                        <option id="select">Select Month</option>
                        {this.state.selectMonth.map((element) => (<option >{element.value}</option>))}
                      </select>
                    </div>

                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mt-xs-10"><b>Select State</b><br />
                      <Select multiple class="form-control" isMulti multiValueRemove
                        onChange={this.changeState.bind(this)}
                        id="selectState"
                        options={this.state.selectstate}
                        value={this.state.selectedState}
                        theme={theme => ({
                          ...theme,
                          colors: {
                            primary25: 'white',
                            primary: 'rgb(255, 255, 255)',
                          },
                        })}
                      />
                    </div>


                    <div>
                      <button class="btn btn-primary min-wid-90 mt-20" onClick={this.generatepdf.bind(this)}> Generate</button>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                      <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.TaskactiveBind.bind(this)}>Back</button>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>

        </div>
      );
    }
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  summaryReports =()=>{
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId

      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  EquipmentList =()=>{
    if(this.state.role_id=="505"||  this.state.role_id=="501" || this.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  generatepdf = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    if (this.state.monthnessId.length == "0") {
      this.setState({ loading: false })
      showSuccess('Please select month.')
    }
    else {


      if (this.state.setFinalState.length == "0") {
        this.setState({ loading: false })
        showSuccess('Please select state.')
      }


      else {

        var Request = {

          "userId": this.props.location.state.userid,
          "month": this.state.monthnessId.toString(),
          "state": this.state.setFinalState.toString(),
          "year": this.state.arrayequipid.toString()
        }


        var url = `/SummaryReport?key=${Encrypt(Request)}`;


        fetch(url,
          {
            method: 'GET',
            headers: {
              guId: this.props.location.state.guId,
              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

            }

          }

        ).then(data => {
          return data.blob();
        })
          .then((data) => {
            const a = document.createElement("a");
            const b = new File([data], '', { type: 'application/pdf' });

            a.href = URL.createObjectURL(b);
            a.hidden = true;
            a.download = "Summary Report";
            document.body.appendChild(a)
            a.click();
            a.parentNode.removeChild(a);
            this.setState({ loading: false })
          })

      }
    }

  }


  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  Reports() {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {

      this.setState({
        loading: true,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        guId: this.props.location.state.guId

      })



      fetch('/GetReportCategory', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            let temp = [];
            let temp1 = [];
            var equipflag = [];
            for (var i = 0; i < DecryptedResponse.categoryreportlist.length; i++) {
              temp.push(DecryptedResponse.categoryreportlist[i].reportCategoryid)
              temp1.push(DecryptedResponse.categoryreportlist[i].reportName)
              equipflag.push(DecryptedResponse.categoryreportlist[i].equipmentFlag)
            }
            this.setState({ reportcatID: temp });
            this.setState({ reportcat: temp1 });
            this.setState({ equipflag: equipflag })

          }

          else {
            showAlert(DecryptedResponse.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });


      fetch('/GetYearData', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            let temp = [];
            let temp1 = [];

            for (var i = 0; i < DecryptedResponse.yearList.length; i++) {
              temp.push(DecryptedResponse.yearList[i].isActive)
              temp1.push(DecryptedResponse.yearList[i].year)
              if (DecryptedResponse.yearList[i].isActive == "1") {
                var temp2 = {
                  label: "",
                  value: ""
                };

                temp2.label = DecryptedResponse.yearList[i].year
                temp2.value = DecryptedResponse.yearList[i].year
                this.state.equipment.push(temp2);
              }
              this.setState({ equipmentId: temp });

            }
          }

          else {
            showAlert(DecryptedResponse.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });

      let MonthName = [
        "January", "February ", "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

      for (let c = 0; c < MonthName.length; c++) {
        var monthlist = {
          id: "",
          label: "",
          value: ""
        };
        if (c >= 9) {
          monthlist.id = c + 1
        }
        else {
          monthlist.id = '0' + parseInt(c + 1)
        }
        monthlist.label = MonthName[c]
        monthlist.value = MonthName[c]
        this.state.selectMonth.push(monthlist)

      }


      var stateRequest = {
        "guId": this.props.location.state.guId,
        "userId": this.props.location.state.userid
      }
      var EncryptedRequest = Encrypt(stateRequest);


      fetch('/GetStateData', {
        method: 'POST',
        body: EncryptedRequest,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            for (var h = 0; h < DecryptedResponse.stateList.length; h++) {
              var statelist = {
                label: "",
                value: ""
              };
              statelist.label = DecryptedResponse.stateList[h]
              statelist.value = DecryptedResponse.stateList[h]
              this.state.selectstate.push(statelist)
            }


          }

          else {
            showAlert(DecryptedResponse.responseMsg)

          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }

  }


}

export default TaskSummaryReport;
