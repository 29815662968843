import React, { Component } from 'react';
import 'jquery/dist/jquery';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from 'react-global-configuration';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import { Scrollbars } from 'react-custom-scrollbars';
import readXlsxFile from 'read-excel-file';
import ReactExport from "react-data-export";
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import IdleTimer from 'react-idle-timer'// For Idle
import App from './App' // For Idel
import Redirect from 'react-router-dom/Redirect';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import { showSuccess } from './services/ConfirmAlert';

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class EquipmentList extends Component {

  state = {
    TaskDropdwn: false,
    userid: "",
    role_id: "",
    storeDetail: [],
    listQueAnsDetails: [],
    headers: [],
    sumheaders: [],
    answerList: [],
    taskSummaryList: [],
    taskName: '',
    taskDescr: '',
    hasError: false,   //Added by chandrani for custom error page
    stateName: '',
    sum1: [],
    statepercentage: '',
    pendingStores: '',
    isPendingVisible: false,
    storeList: [],
    templetId: "",
    imageArray: [],
    question: [],
    imgtaskid: "",
    searchQuestn: "",
    guId: "",
  }
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      displayMenu: false,
      usrnm: "",
      data: [],
      fromdata: [],
      titlename: [],
      taskId: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searchQuestn: "",
      loading: false,
      toggle: false,
      taskName: '',
      taskDescr: '',
      hasError: false,   //Added by chandrani for custom error page
      stateName: '',
      sum1: [],
      statepercentage: '',
      isPendingVisible: false,
      pendingStores: '',
      hammenu: true,
      immodal: false,
      enimg: false,
      eddate: "",
      UpdatesDate: "",
      startdateupdateticker: "",
      storeList: [],
      templetId: "",
      imageArray: [],
      key: "",
      question: [],
      questionId: "",
      questionText: "",
      guId: ""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  componentWillMount() {
    this.Reports()
  }

  _onAction(e) {

  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  handleChangeStart(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchSDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      startDate: date

    });
  }
  handleChangeEnd(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchedDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      endDate: date
    });
  }
  onChange = e => {
    this.setState({
      Search: e.target.value
    });

  }

  onChangequestion = e => {
    this.setState({
      searchQuestn: e.target.value
    });
  }


  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }







  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {
          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }



  callImageGallery = (questionId, questionText) => {
    this.props.history.push({
      pathname: '/ImageGallery',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        questionId: questionId,
        taskId: this.state.imgtaskid,
        questionText: questionText,
        guId: this.props.location.state.guId
      }
    }
    );
  }






  enlargeimage = (item) => {
    this.setState((prevState) => ({ enimg: !prevState.enimg, }))
    this.setState({ key: item })
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }




  imagemodalclose = () => {
    this.setState({
      immodal: false,

    })

  }



  enlargeimgclose = () => {
    this.setState({
      enimg: false,

    })

  }
  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  fileHandler = (event) => {
    this.setState({ storeList: [] });
    const input = document.getElementById('upload-storedata')
    readXlsxFile(input.files[0]).then((rows, files) => {
      var storedata = [];
      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i])
        for (let j = 0; j < storedata.length; j++) {
          var store =
          {
            storeNo: "",
            comment: "",

          }
          store.storeNo = storedata[j][0]
          store.comment = storedata[j][1]
        }
        {
          this.state.storeList.push(store)

        }
      }

      this.setState({ FileName: 'File Uploaded Successfully' });
    })

  }

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }


  modalclose = () => {
    this.setState({
      toggle: false,
    })
  }
  toggle(taskId, taskName, taskDescr) {
    this.props.history.push({
      pathname: '/Dashboard',
      state: {
        taskId: taskId,
        taskName: taskName,
        taskDescr: taskDescr,
        usrnm: this.props.location.state.usrnm,
        userid: this.props.location.state.userid,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId
      }
    });

  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  handleDateChangeRaws = (e) => {
    e.preventDefault();
  }

  handleDateChangeRawe = (e) => {
    e.preventDefault();
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }
  render = () => {
    const { searchQuestn } = this.state;
    var that = this;
    var imagemodal = [];
    var enlargeimg = [];





    enlargeimg.push(

      <div className="modal" role="dialog" style={this.state.enimg ? display : hide}>

        <div className="modal-dialog animate">
          <div className="modal-content" style={{ "width": "800px" }}>
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.enlargeimgclose}>X</a>
              <h4 className="modal-title">Enlarge Image</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal" style={{
                "margin-right": "10px", "overflow-y": "scroll",
                height: "500px"
              }}>
                <div className="form-group">
                  <h4 className="control-label">Monthly Activity - To be performed by the store	</h4>

                </div>
                <div>
                </div>
                <img src={this.state.key} />
              </div>
            </div>

          </div>
        </div>
      </div>
    )

    imagemodal.push(

      <div className="modal" role="dialog" style={this.state.immodal ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content" >
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.imagemodalclose}>X</a>
              <h4 className="modal-title">Image Gallery</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal" style={{ "margin-right": "5px", height: "400px", }}>
                <div className="form-group">

                  <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                    <input type="text" placeholder="Search Question" className="form-control" id="quesname" onChange={this.onChangequestion} autoComplete="off" />
                  </div>


                  <div>
                    <img src={require("./img/searchicon.png")} className="user-img" style={{ width: "25px", height: "25px" }} />
                  </div>


                  <div className="col-sm-10">
                    <div className="tbl-holder mt-20">
                      <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">

                        <thead>

                          <tr>
                            <th style={{ width: "3%" }}>Sr No</th>
                            <th>Question</th>
                          </tr>
                        </thead>
                      </table>

                      <Scrollbars style={{ height: 296 }}>

                        <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
                          <tbody>
                            {this.state.question.map(function (item, key) {
                              if (searchQuestn !== "" && item.questionText.toLowerCase().indexOf(searchQuestn.toLowerCase()) === -1) {
                                return null
                              }

                              return (
                                <tr key={key}>
                                  <td data-th="Sr No" style={{ width: "3%" }}>{key + 1}</td>
                                  <td data-th="Question">  <a href="javascript:void(0)" onClick={that.callImageGallery.bind(that, item.questionId, item.questionText)} className="black-text">  {item.questionText}</a></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )



    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const ReportDataSet = [
      {
        columns: this.state.headers,
        data: this.state.answerList
      }]


    const SummaryDataset = [
      {
        columns: this.state.sumheaders,
        data: this.state.taskSummaryList
      }
    ]

    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <div>
          {imagemodal}
          {enlargeimg}

          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header class="header black-bg">
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                {this.state.role_id == "506" ?
                  <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}({this.props.location.state.storeNo})</b> <b className="caret" /></a>
                  </li> :

                  <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b> <b className="caret" /></a>
                  </li>}

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind}>Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Equipment List</h3>
                    </div>
                    <hr />
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Equipment No</b><br />
                      <input type="number" placeholder="Search Equipment No" className="form-control" id="taskname" onChange={this.onChange} autoComplete="off" />
                    </div>




                    <div>
                      <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} />
                    </div>
                    <div class="clearfix"></div>

                    <div className="mt">

                      <div className="tbl-holder">
                        <div className="spin">
                          <Spinner visible={this.state.loading}
                            spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                        </div>
                        <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">

                          <thead>
                            <tr>
                              <th>Equipment No</th>
                              <th>Category Name</th>
                              <th>Description</th>

                            </tr>
                          </thead>
                        </table>
                        <Scrollbars style={{ height: 296 }}>
                          <table className="table table-striped table-advance table-hover table-bordered tbl-task ">
                            <tbody>

                              {this.state.data.map(function (item, key) {
                                if ((Search !== "") &&
                                  (item.equipmentNo.toLowerCase().indexOf(Search.toLowerCase())) === -1) {
                                  return null
                                }

                                return (

                                  <tr key={key}>
                                    <td data-th="Equipment No"><a href="javascript:void(0)" className="black-text" > {item.equipmentNo}</a></td>
                                    <td data-th="Category Name">{item.categoryName}</td>
                                    <td data-th="Description">{item.description}</td>


                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </Scrollbars>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </section>
          </section>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.TaskactiveBind.bind(this)}>Back</button>
          </div>
        </div>
      );
    }
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  previewpage(taskId, taskName, taskDescription, startDate, endDate, publishStatus, storeNo, technicianId, storeManagerStatus) {
    this.props.history.push({
      pathname: '/QuestionPreviewForCompletedTask',
      state: {
        tskId: taskId,
        tskName: taskName,
        tskDescr: taskDescription,
        strtDate: startDate,
        edDate: endDate,
        publishStatus: publishStatus,
        storeNo: this.props.location.state.storeNo,
        role_id: this.props.location.state.role_id,
        technicianId: technicianId,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeManagerStatus: storeManagerStatus,
        guId: this.props.location.state.guId



      }
    }
    );
  }


  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
        // technicianId:this.props.location.state.technicianId

      }
    }
    );
  }



  callpreviewquestionpage(tskId, tskName, tskDescr, strtDate, edDate) {
    this.props.history.push({
      pathname: '/TaskStoreList',
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        data: this.state.data,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );

  }

  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          data: this.state.data,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId
      }
    }
    );
  }



  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }




  Reports() {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {


      this.setState({
        loading: true,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        guId: this.props.location.state.guId

      })
      var Request1 = {
        "storeNo": this.props.location.state.storeNo,
        "guId": this.props.location.state.guId
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/GetStoreEquipments ', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ data: DecryptedResponse.equipmentList });
            this.setState({ loading: false });

          }
          else {
            this.setState({ loading: false });
            showSuccess(DecryptedResponse.responseMsg)
          }

        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });



    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }








}

export default EquipmentList;




