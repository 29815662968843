import addDays from "date-fns/addDays";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import 'jquery/dist/jquery';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import { withRouter } from "react-router-dom";
import Spinner from 'react-spinner-material'; //Import Spinner
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { basicAuth } from './basicAuth';
import { showAlert, showSuccess } from "./services/ConfirmAlert";

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
class Categorycreation extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false   //Added by chandrani for custom error page
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }


  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false,   //Added by chandrani for custom error page
      toggle: false,
    };
    this.state = {
      startDate: "",
      UpdatesDate: "",
      UpdateeDate: "",
      startdateticker: "",
      startdateupdateticker: "",
      enddateticker: "",
      enddateupdateticker: "",
      startdate: "",
      endDate: "",
      enddate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tickerId: "",
      tskName: "",
      loading: false,
      hasError: false,   //Added by chandrani for custom error page
      toggle: false,
      strtDate: "",
      edDate: "",
      hammenu: true,
      guId: "",
      checkcatval: false,
      check: '1',
      isActive: "",
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.handletickereupdate = this.handletickereupdate.bind(this);
    this.handleChangesticker = this.handleChangesticker.bind(this);
    this.handleChangeeticker = this.handleChangeeticker.bind(this);
    this.handleChangeStar = this.handleChangeStar.bind(this);
    this.handleChangend = this.handleChangend.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }


  handleChangeStart(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchSDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      startDate: date

    });
  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }


  handletickereupdate(dates) {
    this.setState({

      enddateupdateticker: dates,
      UpdateeDate: dates
    });
  }

  handleChangesticker(dates) {
    this.setState({

      startdateticker: dates

    });
  }

 

  handleChangeeticker(dated) {
    this.setState({
      enddateticker: dated
    });
  }




  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);

    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });
  }

  componentWillMount() {
    this.GetTasksOnload()
  }



  handleDateChangeRaws = (e) => {
    e.preventDefault();
  }


  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {

  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }

  createtclose = () => {
    document.getElementById("taskName").value = null
    this.setState({
      disappear: false,
      startdateticker: "",
      enddateticker: "",

    })

  }

  createmodal(taskName, taskDescr) {
    this.setState((prevState) => ({
      disappear: !prevState.disappear
    }));
    this.setState({
      taskName: taskName,
      taskDescr: taskDescr
    })

  }

  modalclose = (e) => {
    this.setState({
      toggle: false,
    })
  }




  UpdateTicker(tickerId, tickerText, isActive) {
    this.setState({
      tickerId: tickerId,
      tickerText: tickerText,
      toggle: !this.state.toggle,
      isActive: isActive
    })

    if (isActive === "InActive") {
      this.setState({ checkcatval: true })
      this.setState({ check: 0 })
    }
    else {
      this.setState({ checkcatval: false })
      this.setState({ check: 1 })
    }
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }
  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/UserCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }


  handleChangeStar(date) {
    this.setState({
      strtDate: date
    });
  }

  handleChangend(date) {
    this.setState({
      edDate: date
    });
  }
  render = () => {

    var modal = [];

    modal.push(
      <div className="modal" role="dialog" style={this.state.toggle ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff" }} onClick={this.modalclose}>X</a>
              <h4 className="modal-title">Update Category</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Category Id :</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" value={this.state.tickerId} onChange={(f) => this.setState({ tickerId: f.target.value })} id="tickerid" disabled></input>
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Category Desc :</h4>
                  <div className="col-sm-8">
                    <textarea className="form-control" value={this.state.tickerText} onChange={(f) => this.setState({ tickerText: f.target.value })} id="tickerdesc" maxlength="200">
                    </textarea>
                  </div>
                </div>

                <div className="form-group">
                  <h4 className="control-label col-sm-4">Delete Category :</h4>
                  <div className="col-sm-8 ques-boxcateg">

                    <input type="checkbox" onChange={this.handleChangeChk.bind(this)} id="chkboxcat" checked={this.state.checkcatval} />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.UpdateTask.bind(this)}
                    >Update</button>
                  </div>
                </div>

              </div>




            </div>
          </div>

        </div>
      </div>
    );

    var createt = [];
    createt.push(

      <div className="modal" role="dialog" style={this.state.disappear ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff" }} onClick={this.createtclose}>X</a>
              <h4 className="modal-title">Create Category</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Category Text :</h4>
                  <div className="col-sm-8">
                    <textarea class="form-control" id="taskName" autoComplete="off" maxlength="200"></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.CreateTicker.bind(this)}
                    >Create Category</button>
                  </div>
                </div>


              </div>



            </div>
          </div>

        </div>
      </div>

    );



    var that = this;

    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const { searcheddesc } = this.state;






    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {


      return (

        <div>
          <section id="container">
            <header className="header black-bg" >
              <div>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10} />
                {modal}
                {createt}
              </div>
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">

                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" />
                  <b>{this.state.usrnm}</b>
                  <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3><i className="fa fa-angle-right"></i>Category(s)
        <a className="pull-right btn btn-theme" onClick={this.createmodal.bind(this)}>+ Create Category</a> </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5"> <b>Category Text</b><br />
                          <input type="text" placeholder="Search Category Text" className="form-control" id="tasknames" onChange={this.onChange} autoComplete="off" />
                        </div>

                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Category Status</b><br />
                          <input type="text" placeholder="Search Category Status" className="form-control" id="taskdesc" onChange={this.onChange1} autoComplete="off" />
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="mt">

                          <div className="tbl-holder">

                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-ticker tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Category Text</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>
                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-ticker mob-tbl">
                                <tbody>
                                  {this.state.data.map(function (item, key) {

                                    if ((Search !== "" || (searcheddesc) != '') &&
                                      (item.categoryName.toLowerCase().indexOf(Search.toLowerCase()) ||
                                        item.isActive.toLowerCase().indexOf(searcheddesc.toLowerCase()))
                                      === -1) {
                                      return null
                                    }

                                    return (
                                      <tr key={key}>
                                        <td data-th="Ticker Text" className="black-text" > {item.categoryName}</td>
                                        <td data-th="Status">{item.isActive}</td>
                                        <td data-th="Action">
                                          <a className="edit-icon"
                                            onClick={that.UpdateTicker.bind(that, item.equipCategoryId, item.categoryName, item.isActive)}> <i className="fa fa-pencil "></i> </a>

                                          <span className="d-none">{item.equipCategoryId}</span>
                                        </td>


                                      </tr>
                                    )
                                  })}</tbody>

                              </table>
                            </Scrollbars>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </section>
          </section>

        </div>
      );
    }
  }
  onChange1 = e => {
    this.setState({ searcheddesc: e.target.value });
  }

  handleChangeChk = (e) => {

    if (document.getElementById("chkboxcat").checked == false) {
      this.setState({ check: 1 })
      this.setState({ checkcatval: false })
    }
    else {

      this.setState({ check: 0 })
      this.setState({ checkcatval: true })
    }

  }



  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }


  EquipmentList =()=>{
    if(this.props.location.state.role_id=="505"||  this.props.location.state.role_id=="501" || this.props.location.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }

  GetTasksOnload(e) {


    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({
        usrnm: this.props.location.state.usrnm,
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        loading: true,
        guId: this.props.location.state.guId,
      })


      var Request1 = {
        "formatId": this.props.location.state.role_id,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);

      fetch('/GetEquipmentCategory', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }


      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          if (DecryptedResponse1.responseCode == "200 OK") {
            this.setState({ data: DecryptedResponse1.equipcatlist });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ loading: false })

          }
          else {
            this.setState({ loading: false })
            showSuccess(DecryptedResponse1.responseMsg)

          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }


  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      }
    }
    );
  }



  CreateTicker = (e) => {
    e.preventDefault();
    if (!document.getElementById("taskName").value) {
      showAlert("Please enter required fields")
    }
    else {
      var Request1 = {
        "categoryName": document.getElementById("taskName").value,
        "guId": this.props.location.state.guId,
        "action": "1",
        "userId": this.props.location.state.userid

      }

      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/UpdateEquipmentCategory', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode == "200 OK") {
            { this.createtclose() }
            confirmAlert({
              title: 'Success',
              message: "Category created successfully.  ",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { window.location.reload(true) }
                },

              ]
            })
          }
          else {
            showAlert(DecryptedResponse.responseMsg)

          }

        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
  }

  deletetask(tskid) {

    confirmAlert({
      message: 'Are you sure, you want to delete this category?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var Request2 = {
              "tickerId": tskid,
              "guId": this.props.location.state.guId,
              "action": "3"
            }
            var EncryptedRequest2 = Encrypt(Request2);
            fetch('/DeleteTicker', {
              method: 'POST',
              body: EncryptedRequest2,
              headers: {
                guId: this.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

              }


            })
              .then((response) => response.text())
              .then((response) => {

                var DecryptedResponse2 = decryptData(response)
                if (DecryptedResponse2.responseCode == "200 OK") {
                  confirmAlert({
                    message: 'Ticker  Deleted Successfully.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { window.location.reload(true) }
                      },

                    ]
                  })

                  this.forceUpdate();
                }
                else {
                  showAlert(DecryptedResponse2.responseMsg)
                }
              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });

              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          }
        }
      ]
    })

  }




  callupdatetaskpage(tickerId, tskName, strtDate, edDate) {
    this.props.history.push({
      pathname: '/Updatetasks',
      state: {
        tickerId: tickerId,
        tskName: tskName,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat
      }
    }
    );

  }
  calladdquestionpage(tickerId, tskName, tskDescr, strtDate, edDate, publishStatus) {
    this.props.history.push({
      pathname: '/Addquestion',
      state: {
        tickerId: tickerId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId,
        //  userFormat: this.props.location.state.userFormat
      }
    }
    );

  }
  UpdateTask(e) {
    e.preventDefault();
    if (document.getElementById("tickerdesc").value === "") {
      showSuccess("Please enter required fields")
    }


    else {


      confirmAlert({
        message: 'Are you sure, you want to update the category?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              if (this.state.check == 0) {
                var Request1 = {

                  "equipCategoryId": document.getElementById("tickerid").value,
                  "categoryName": document.getElementById("tickerdesc").value,
                  "isActive": this.state.check,
                  "userId": this.props.location.state.userid,
                  "action": "3",
                  "guId": this.props.location.state.guId,
                }
              }
              else {
                var Request1 = {

                  "equipCategoryId": document.getElementById("tickerid").value,
                  "categoryName": document.getElementById("tickerdesc").value,
                  "isActive": this.state.check,
                  "userId": this.props.location.state.userid,
                  "action": "2",
                  "guId": this.props.location.state.guId,
                }
              }






              var EncryptedRequest1 = Encrypt(Request1);
              fetch('/UpdateEquipmentCategory', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                }

              })
                .then((response) => response.text())
                .then((response) => {

                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode == "200 OK") {

                    confirmAlert({
                      message: "Category updated successfully. ",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.modalclose(); window.location.reload(true) }
                        },

                      ]
                    })

                    this.forceUpdate();
                  }
                  else {
                    showAlert(DecryptedResponse.responseMsg)

                  }

                })
                .catch((error) => {
                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });

                });
            }
          },
          {
            label: 'No',
            onClick: () => {
              return false;
            }
          }
        ]
      })


    }
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat
      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat
      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat
      }
    }
    );
  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }

  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        // userFormat: this.props.location.state.userFormat
      }
    }
    );
  }
}
export default withRouter(Categorycreation);

