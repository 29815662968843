import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import { Scrollbars } from 'react-custom-scrollbars';
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import { th } from 'date-fns/esm/locale';
import AddQuestionChild from './AddquestionChild';
import disableBrowserBackButton from 'disable-browser-back-navigation';

import { basicAuth } from './basicAuth';
import { showAlert, showSuccess } from './services/ConfirmAlert';





const ParentComponent = props => (
  <div id="children-pane">
    {props.children}
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 mt-17">
      <button id="add-opt-btn" type="button" class="btn btn-primary" onClick={props.addChild}>+</button>
    </div>
  </div>
)





const ChildComponent = props => {
  return (
    <div style={{
      display: 'flex'
    }} class="col-lg-6 col-md-8 col-sm-8 col-xs-18 mt-17">
      <input type="text" class="form-control" placeholder={"Option " + props.number} onChange={props.OptChange}
        id={"Opts" + props.number} onKeyPress={props.OptStop} autoComplete="off" maxLength="50" />

      <div style={{ width: '24px' }}></div>
      <div style={{ display: 'flex' }}>
        <input
          type="checkbox"
          id={"optionExceptions" + props.number}
          checked={props.isChecked}
          onChange={() => props.toggleCheckboxes(props.number)}
        />
        <p>&nbsp;Is it an exception?</p>
      </div>
      {/* <input type='button' class="remove-icon" value='x'  /> */}
    </div >)
}


var child = new AddQuestionChild(this);
class Addquestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMenu: false,
      loaded: this.props.location.state,
      check: '1',
      checked: true,
      value1: "",
      value2: "",
      hasError: false   //Added by chandrani for custom error page

    };

    this.state = {
     
      tskId: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      numChildren: 0,
      TaskDropdwn: false,
      description: [],
      equipment: [],
      qtypeId: [],
      equipmentId: [],
      value: "",
      valueid: [],
      euipvalueid: [],
      question: '',
      option: '',
      QType: '',
      QuestionText: '',
      OptsValues: [],
      data: [],
      role_id: "",
      userid: "",
      usrnm: "",
      optns: [],
      Addoptbutton: false,
      loading: false,
      show: true,
      showed: false,
      isMandatory: "",
      check: '1',
      checked: true,
      value1: "",
      value2: "",
      status: true,
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      category: "Select Category",
      categoryName: "",
      equipCategId: "",
      addException: false,
      higherLimit: '',
      lowerLimit: '',
      isExcception: false,
      exceptionCheckboxes: 0,
      exceptionCheckbox: false,
      optionExceptions: []
    }



    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    /*this.showComments = this.showComments.bind(this);*/

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)


  }
  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  /*showComments() {
     this.setState({
       showComponent: !this.state.showComponent
     });
   } */


  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }



  inputChangedHandler = (param, evt) => {

    if (param === "min") {
      if (evt.target.value.length === 1) {
        const value1c = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
        this.setState({ numchange: true, value1: value1c });
        document.getElementById(param).value = value1c;
      }
      else {
        const value1c = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
        this.setState({ numchange: true, value1: value1c });
        document.getElementById(param).value = value1c;
      }
    }
    else if (param === "max") {
      if (evt.target.value.length === 1) {
        const value2c = (evt.target.validity.valid) ? evt.target.value : this.state.value2;
        this.setState({ numchange: true, value2: value2c });
        document.getElementById(param).value = this.state.value2;
      }
      else {
        const value2c = (evt.target.validity.valid) ? evt.target.value : evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true, value2: value2c });
        document.getElementById(param).value = value2c;
      }
    }
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
    console.log("State", this.state);

  }


  fullStop = (e) => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }


  inputChangedHandlertxt = (event) => {
    this.setState({
      question: event.target.value
    })
  }

  optionChangedHandler = (event) => {
    this.setState({
      option: event.target.value
    })
  }

  toggleExceptionCheckboxState = index => {
    this.setState(prevState => {
      const updatedOptionExceptions = [...prevState.optionExceptions];
      updatedOptionExceptions[index] = !updatedOptionExceptions[index];
      return {
        optionExceptions: updatedOptionExceptions,
      };
    });
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }



  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    console.log(EncryptedRequest1)

    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        console.log(`logout`, DecryptedResponse1)


        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          confirmAlert({
            message: DecryptedResponse1.responseMsg,
            buttons: [
              {
                label: 'Ok',
                // onClick: () => 
              },
            ]
          })

        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]
        });
      });


  }
  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,

        guId: this.props.location.state.guId
      }
    }
    );
  }

  handleChangeChk = (e) => {
    if (document.getElementById("chkbox").checked === false) {
      this.setState({ check: 0 })
      this.setState({ checked: false })
    }
    else {
      this.setState({ check: 1 })
      this.setState({ checked: true })
    }

  }




  onChanged(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ value: e.target.value })
    }
  }



  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    //console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }


  componentWillMount() {
    this.FetchQuestionsByTask();




  }

  componentDidUpdate() {

  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  _onIdle(e) {
    localStorage.clear();
    window.location.href = '/';
  }


  enter = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  }

  equipmentcategory = (e) => {

    this.setState({ category: e.target.value })

    let flagsym = []

    for (var i = 0; i < this.state.equipment.length; i++) {
      this.state.equipment[i].trim() == e.target.value.trim() ? flagsym.push(this.state.equipmentId[i]) : this.setState({ euipvalueid: "" })
    }
    this.setState({ euipvalueid: flagsym })

    console.log(`flAG`, this.state.euipvalueid)

  }


  changeqtype = (e) => {
    this.setState({ numChildren: 0, exceptionCheckboxes: 0 })
    this.setState({ value: e.target.value });
    // alert("select QType on select : "+ e.target.value)
    // alert("rlistid of on change: "+ this.state.qtypeId)
    let flag = []

    for (var i = 0; i < this.state.description.length; i++) {
      this.state.description[i] === e.target.value ? flag.push(this.state.qtypeId[i]) : this.setState({ valueid: "" })
    }
    this.setState({ valueid: flag })
    this.setState({ Addoptbutton: false })
    console.log(`oprion`, this.state.valueid)
    //alert("flag : "+ flag)
    if (flag == '1') {
      this.setState({ Addoptbutton: true });
      this.setState({ showed: false })
    }
    else {
      if (flag == '2') {
        this.setState({ Addoptbutton: true });
        this.setState({ showed: false })
      }
      else {

        if (flag == '4') {
          this.setState({ showed: true });

        }


        else {
          if (flag == '9') {

            this.setState({ Addoptbutton: true });
            this.setState({ showed: false })
          }
          else {

            if (flag == '10') {

              this.setState({ Addoptbutton: false });
              this.setState({ showed: false })

            }
            else {
              this.setState({ Addoptbutton: false });
              this.setState({ showed: false })
            }

          }

        }

      }

    }

  }





  render = () => {

    { console.log(`red`, this.state.category) }
    const { optionExceptions } = this.state;
    var that = this;
    const children = [];

    for (var i = 0; i < this.state.numChildren; i += 1) {
      children.push(<ChildComponent key={i} number={i} toggleCheckboxes={(index) => {
        this.setState(prevState => {
          const updatedOptionExceptions = [...prevState.optionExceptions];
          updatedOptionExceptions[index] = !updatedOptionExceptions[index];
          console.log("The update: ", updatedOptionExceptions)
          return {
            optionExceptions: updatedOptionExceptions,
          };
        });
      }} isChecked={this.state.optionExceptions[i]} />);
    };
    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <Router>

          <div>

            <section id="container">
              <header class="header black-bg">

                {/* <div class="sidebar-toggle-box">
      <div class="fa fa-bars tooltips" data-placement="right" data-original-title="Toggle Navigation"></div>
    </div> */}
                <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
                <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b><b className="caret" /></a>
                  </li>
                  <div>
                    <IdleTimer
                      ref={ref => { this.idleTimer = ref }}
                      element={document}
                      onActive={this.onActive}
                      onIdle={this.onIdle}
                      onAction={this.onAction}
                      debounce={250}
                      timeout={1000 * 60 * 10} />
                    {/* your app here */}
                  </div>
                  {this.state.displayMenu ?
                    (

                      <ul className="dropdown-menuuser-dd">
                        <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                        <li className="divider"></li>
                        <li><a href="#Log Out" onClick={this.logout} >Log Out</a></li>
                      </ul>
                    ) :
                    (
                      null
                    )
                  }


                </ul>

              </header>
              <aside>

                <div id="sidebar" className="nav-collapse">
                  {this.state.hammenu ?
                    <ul className="sidebar-menu" id="nav-accordion">
                      <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                        {this.state.TaskDropdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ?
                                <>
                                  <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                  <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                                </>
                                :
                                <>
                                  <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                  <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                  <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                                </>
                              }
                              {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                              {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                              {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                                <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                                : null}
                              {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                                <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                            </ul>
                          </div>

                          : null}
                      </li>
                      {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                        <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                          <span>Reports</span> </a>
                          {this.state.Taskreport ?
                            <div>
                              <ul class="sub" >
                                <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                                {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                              </ul>
                            </div> : null}
                        </li>
                        : null}
                      {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                        <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                          {this.state.Taskdwn ?
                            <div>
                              <ul class="sub" >
                                {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                                {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                                <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                              </ul>
                            </div> : null}
                        </li> : null}
                    </ul> : null}
                </div>
              </aside>
              <section id="main-content">
                <section class="wrapper">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                      {this.state.publishStatus === "Published" ? (
                        <div class="text-right mt-20">
                          <button class="btn btn-primary min-wid-90" onClick={this.reminder.bind(this)}>Send Reminder</button>
                        </div>) : (null)}
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <h3 class="mt-5"><i className="fa fa-angle-right"></i>{this.state.tskName}</h3>
                        <p>Description : {this.state.tskDescr}</p>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">Start Date : {this.state.strtDate}</div>
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">End Date : {this.state.edDate}</div>


                      <div class="clearfix"></div>
                      <hr />

                      <div class="clearfix">

                      </div>
                      {this.state.publishStatus == "New" ? (
                        <div class="ques-box">

                          <div >

                            <input type="checkbox" onChange={this.handleChangeChk.bind(this)} id="chkbox" checked={that.state.checked} /> Mandatory

                          </div>

                          <br />

                          <div>
                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12"  >
                              {this.state.publishStatus == "New" ? (
                                <select class="form-control" onChange={this.equipmentcategory.bind(this)} defaultValue={child.getCategory.bind(this)} id="equipment" >
                                  <option id="select">Select Category</option>
                                  {this.state.equipment.map((element) => (<option >{element}</option>))}
                                </select>) : (null)}
                            </div>
                            {this.state.category != "Select Category" ?
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"  >
                                {this.state.publishStatus == "New" ? (
                                  <select class="form-control" onChange={this.changeqtype.bind(this)} id="SelQType" value={this.state.value}>
                                    <option id="select" value="-1">Select Question Type</option>
                                    {this.state.description.map((element) => (<option>{element}</option>))}
                                  </select>) : (null)}
                              </div>

                              : (null)}
                          </div>
                          <br />
                          {this.state.category != "Select Category" ?

                            <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12 mt-cs-10">
                              {this.state.publishStatus === "New" ? (
                                <input type="text" class="form-control" placeholder="Enter Question" value={this.state.question} onChange={this.inputChangedHandlertxt.bind(this)} id="QText" autoComplete="off" maxLength="250" />
                              ) : (null)}

                            </div>
                            : (null)}
                          <br />
                          {/* Exception checkbox */}
                          {this.state.value === '' || this.state.value === 'Multiple Choice(Select Many)' || this.state.value === 'Text'  || this.state.value === 'Numeric' || this.state.value === 'Email-ID' || this.state.value === 'Date Question'|| this.state.value === 'Multiple Choice(Select One)' || this.state.value === 'Question with subquestion' || this.state.value === 'Yes/No' || this.state.value === 'Yes/No/NA' || this.state.value === 'File Upload' || this.state.value === 'Live Image Upload' ? (
                            <div>
                            </div>
                          ) : (
                            <div style={{ display: 'flex' }}><input type="checkbox" id="addExceptionchekbox" value={this.state.addException} onClick={(e) => {
                              this.setState({
                                addException: !this.state.addException,
                              });
                            }} /><p> &nbsp; Do you want to add exceptions</p>
                            </div>
                          )}

                          {this.state.Addoptbutton && this.state.value != -1 ? (
                            <div>
                              <ParentComponent addChild={this.onAddChild} OptStop={this.fullStop} toggleCheckboxes={(index) => {
                                this.setState(prevState => {
                                  const updatedOptionExceptions = [...prevState.optionExceptions];
                                  updatedOptionExceptions[index] = !updatedOptionExceptions[index];
                                  console.log("THe updated option", updatedOptionExceptions)
                                  return {
                                    optionExceptions: updatedOptionExceptions,
                                  };
                                });
                              }} OptChange={this.optionChangedHandler} >
                                {children}
                              </ParentComponent>
                            </div>
                          ) : (null)}
                          <br />

                          {/* If question type is text, then add limits textboxes */}
                          {/* {this.state.value === 'Multiple Choice(Select Many)' || this.state.value === 'Multiple Choice(Select One)' || this.state.value === 'Question with subquestion' || this.state.value === 'Yes/No' || this.state.value === 'Yes/No/NA' || this.state.value === 'File Upload' || this.state.value === 'Live Image Upload' ? (<></>) : this.state.addException ? (
                            <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12 mt-cs-10" style={{ display: 'flex', alignContent: 'space-between' }}>
                              <input type="text" class="form-control" placeholder="Lower Limit Values" value={this.state.lowerLimit} onChange={(e) => this.setState({ lowerLimit: e.target.value })} />
                              <div style={{ width: '60px' }}></div>
                              <input type="text" class="form-control" placeholder="Higher Limit Values" value={this.state.higherLimit} onChange={(e) => this.setState({ higherLimit: e.target.value })} />
                            </div>
                          ) : (<></>)} */}

                          {this.state.showed && this.state.value != -1
                            ?
                            (
                              <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mt-17">
                                <input type="text" class="form-control" placeholder="Min Length"
                                  onInput={this.inputChangedHandler.bind(this, "min")}
                                  id="min" pattern="[0-9]*" autoComplete="off" />
                                <input type="text" class="form-control" placeholder="Max Length"
                                  onInput={this.inputChangedHandler.bind(this, "max")}
                                  id="max" pattern="[0-9]*" autoComplete="off" />
                              </div>
                            ) : (null)
                          }
                        </div>
                      ) : (null)}
                      {this.state.publishStatus == "New" ? (
                        <div class="text-center"
                        >

                          <button class="btn btn-primary min-wid-90" onClick={this.CreateQuestions.bind(this)} onKeyPress={this.enter}>Add Question</button> </div>
                      ) : (null)}
                      <div class="clearfix"></div>
                      <div class="mt">
                        <div className="tbl-holder">

                          <div>
                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>


                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide" >
                              <thead>
                                <tr>
                                  <th>Category Name</th>
                                  <th>Question Type</th>
                                  <th>Question</th>
                                  <th>Options</th>
                                  <th>Exceptions</th>
                                  {/* <th>H-L</th> */}
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>
                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                <tbody>
                                  {this.state.data.map(function (item, key) {
                                     console.log("ITEM", item);
                                    return (
                                      <tr key={key}>
                                        <td data-th="Category Name">{item.categoryName}</td>

                                        <td data-th="Question Type"><span className="d-none">{item.questionId}</span><a className="black-text"> {item.questionTypeValue}</a></td>

                                        <td data-th="Question" id="item">{item.questionText}</td>
                                        <td data-th="Options"><ul class="opt-ans">{item.options.map((optele, index) => (<li key={index}>{optele}</li>))}</ul></td>
                                        <td data-th="Exceptions"><ul class="opt-ans">{item.exceptions.map((optele, index) => (<li key={index}>{optele}</li>))}</ul></td>
                                        {/* <td data-th="Higher Limit" id="item">{item.lowerLimit}-{item.higherLimit}</td> */}
                                        <td data-th="Action">

                                          {that.state.publishStatus == "New" ? (
                                            <a className="edit-icon" onClick={that.callupdatequestionpage.bind(that, item.equipCategId, item.categoryName, item.questionId, item.questionText, item.questionTypeValue, item.questiontype, item.minValue, item.maxValue, item.options, item.updatedBy)}> <i className="fa fa-pencil "></i> </a>
                                          ) : (null)}

                                          {that.state.publishStatus == "New" ? (
                                            <a className="delete-icon" value="Delete" onClick={that.DeleteQuestion.bind(that, item.questionId)} > <i className="fa fa-trash-o "></i> </a>
                                          ) : (null)}
                                        </td>
                                      </tr>
                                    )
                                  })}

                                </tbody>
                              </table>
                            </Scrollbars>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <div class="text-center mt ">
                          <button class="btn btn-primary min-wid-90 " onClick={this.TaskactiveBind.bind(this)}>Back</button>
                          {this.state.show ? (<button class="btn btn-primary min-wid-90 ml-10 " onClick={this.PreviewQuestion.bind(this)} >Preview</button>
                          ) : (null)}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>

        </Router >
      );
    }
  }
  onAddChild = () => {
    console.log("over here")

    this.setState(prevState => ({
      optionExceptions: [...prevState.optionExceptions, false],
      exceptionCheckboxes: this.state.exceptionCheckboxes + 1,
    }));
    this.setState({
      numChildren: this.state.numChildren + 1,
    });
  }



  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,

        guId: this.props.location.state.guId
      }
    }
    );
  }
  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }


  CreateQuestions(e) {


    console.log("The final state: ", this.state)
    if (document.getElementById("equipment").value == "Select Category") {
      showAlert("Please select category")

    }

    else {
      if (document.getElementById("SelQType").value == "-1") {
        showAlert("Please select option")

      }
      else {


        if (document.getElementById("QText").value === "") {
          showAlert("Please enter Question")

        }
        else {
          if ((this.state.valueid[0]) != '1' && (this.state.valueid[0]) != '2' && (this.state.valueid[0]) != '9' && (this.state.valueid[0]) != '4') {
            var Request = {
              "taskId": this.props.location.state.tskId,
              "userId": this.props.location.state.userid,
              "listQuestions": [
                {
                  "questionText": document.getElementById("QText").value,
                  "questiontype": this.state.valueid.toString(),
                  "minValue": "",
                  "maxValue": "",
                  "options": [""],
                  "optionExceptions": [""],
                  "isMandatory": this.state.check,
                  "equipCategId": this.state.euipvalueid.toString(),
                  "higherLimit": this.state.higherLimit,
                  "lowerLimit": this.state.lowerLimit,
                }
              ],
              "guId": this.props.location.state.guId
            }
            var EncryptedRequest = Encrypt(Request);
            fetch('/CreateQuestions', {
              method: 'POST',
              body: EncryptedRequest,
              headers: {
                guId: this.props.location.state.guId,

                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
              }
            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse = decryptData(response)
                if (DecryptedResponse.responseCode === '200 OK') {
                  this.setState({ question: "", value: -1, show: true })
                  child.setState({ category: this.state.category })
                  confirmAlert({
                    message: 'Question added successfully.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {
                          var Request4 = {
                            "taskId": this.props.location.state.tskId,
                            "guId": this.props.location.state.guId
                          }
                          var EncryptedRequest4 = Encrypt(Request4);
                          console.log(EncryptedRequest4)


                          fetch('/FetchQuestionsByTask', {
                            method: 'POST',
                            body: EncryptedRequest4,
                            headers: {
                              guId: this.props.location.state.guId,
                              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                            }


                          })
                            .then((response) => response.text())
                            .then((response) => {
                              var DecryptedResponse = decryptData(response)
                              if (DecryptedResponse.responseCode === '200 OK') {
                                this.setState({ data: DecryptedResponse.listQuestions });
                                this.setState({ loading: false });

                              }

                              else {
                                if (DecryptedResponse.responseCode === '400 BAD_REQUEST') {
                                  this.setState({ loading: false });

                                  confirmAlert({
                                    title: 'Alert !',
                                    message: "No question found",
                                    buttons: [
                                      {
                                        label: 'Ok',
                                        onClick: () => { this.setState({ show: false }) }
                                      },

                                    ]
                                  })

                                }
                                else {

                                  this.setState({ loading: false });


                                  confirmAlert({
                                    title: 'Alert !',
                                    message: DecryptedResponse.responseMsg,
                                    buttons: [
                                      {
                                        label: 'Ok',
                                        onClick: () => { this.setState({ show: false }) }
                                      },

                                    ]
                                  })


                                }
                              }


                            })
                            .catch((error) => {
                              confirmAlert({
                                title: 'Alert !',
                                message: "Session expired",
                                buttons: [
                                  {
                                    label: 'Ok',
                                    onClick: () => { this.logoutnormal() }
                                  },

                                ]


                              });

                            });
                        }

                      },

                    ]

                  })

                }

                else {
                  showAlert(DecryptedResponse.responseMsg)

                }
              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });
              });

          }
          else {
            if ((this.state.valueid[0]) == '4') {
              if (parseInt(document.getElementById("min").value) >= parseInt(document.getElementById("max").value)) {
                showSuccess('Minimum value cannot be greater than Maximum value .')

              }

              else {

                var Request1 = {
                  "taskId": this.props.location.state.tskId,
                  "userId": this.props.location.state.userid,
                  "listQuestions": [
                    {
                      "questionText": document.getElementById("QText").value,
                      "questiontype": this.state.valueid.toString(),
                      "minValue": document.getElementById("min").value,
                      "maxValue": document.getElementById("max").value,
                      "options": [""],
                      "isMandatory": this.state.check,
                      "equipCategId": this.state.euipvalueid.toString(),
                      "higherLimit": this.state.higherLimit,
                      "lowerLimit": this.state.lowerLimit,

                    }
                  ],
                  "guId": this.props.location.state.guId
                }
                var EncryptedRequest1 = Encrypt(Request1);
                fetch('/CreateQuestions', {
                  method: 'POST',
                  body: EncryptedRequest1,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })
                  .then((response) => response.text())
                  .then((response) => {

                    var DecryptedResponse = decryptData(response)
                    if (DecryptedResponse.responseCode === '200 OK') {
                      this.setState({ question: "", value: -1, show: true })

                      confirmAlert({
                        message: 'Question added successfully.',
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {


                              var Request4 = {
                                "taskId": this.props.location.state.tskId,
                                "guId": this.props.location.state.guId
                              }
                              var EncryptedRequest4 = Encrypt(Request4);
                              console.log(EncryptedRequest4)


                              fetch('/FetchQuestionsByTask', {
                                method: 'POST',
                                body: EncryptedRequest4,
                                headers: {
                                  guId: this.props.location.state.guId,
                                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                                }


                              })
                                .then((response) => response.text())
                                .then((response) => {
                                  var DecryptedResponse = decryptData(response)
                                  if (DecryptedResponse.responseCode === '200 OK') {
                                    this.setState({ data: DecryptedResponse.listQuestions });
                                    this.setState({ loading: false });

                                  }

                                  else {
                                    if (DecryptedResponse.responseCode === '400 BAD_REQUEST') {
                                      this.setState({ loading: false });

                                      confirmAlert({
                                        title: 'Alert !',
                                        message: "No question found",
                                        buttons: [
                                          {
                                            label: 'Ok',
                                            onClick: () => { this.setState({ show: false }) }
                                          },
                                        ]
                                      })
                                    }
                                    else {
                                      this.setState({ loading: false });
                                      showAlert(DecryptedResponse.responseMsg)
                                    }
                                  }
                                })
                                .catch((error) => {
                                  confirmAlert({
                                    title: 'Alert !',
                                    message: "Session expired",
                                    buttons: [
                                      {
                                        label: 'Ok',
                                        onClick: () => { this.logoutnormal() }
                                      },
                                    ]
                                  });
                                });
                            }
                          },
                        ]
                      })


                    }

                    else {
                      showAlert(DecryptedResponse.responseMsg)
                    }
                  })
                  .catch((error) => {
                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });
                  });
              }
            }
            else {
              if (this.state.numChildren === 0) {
                showAlert("Please create option.")
              }

              else {

                this.setState({
                  QType: document.getElementById("SelQType").value,
                  QuestionText: document.getElementById("QText").value,
                  OptsValues: document.getElementById("Opts" + 0).value,


                })

                let otval = [];
                let exceptionOptionsList = []
                for (var i = 0; i < this.state.numChildren; i++) {

                  if (document.getElementById("Opts" + i).value.indexOf(',') >= 0) {
                    if (document.getElementById("Opts" + i).value.includes(',')) {
                      let optValue = document.getElementById("Opts" + i).value.replace(/,/g, "");
                      otval.push(optValue);
                      // Exception list
                      if (this.state.optionExceptions[i])
                        exceptionOptionsList.push(optValue);
                    }
                  }
                  else {
                    let optValue = document.getElementById("Opts" + i).value;
                    otval.push(document.getElementById("Opts" + i).value);
                    this.setState({ OptsValues: otval });
                    // Exception list
                    if (this.state.optionExceptions[i])
                      exceptionOptionsList.push(optValue);
                  }
                };

                console.log("The exception options list: ", exceptionOptionsList)




                if (document.getElementById("Opts" + 0).value == '') {
                  showAlert("Options can not be blank")
                }
                else {
                  var Request2 = {
                    "taskId": this.props.location.state.tskId,
                    "userId": this.props.location.state.userid,
                    "listQuestions": [
                      {
                        "questionText": document.getElementById("QText").value,
                        "questiontype": this.state.valueid.toString(),
                        "minValue": "",
                        "maxValue": "",
                        "options": otval.filter(function (el) {
                          return el != "";
                        }),
                        "higherLimit": this.state.higherLimit,
                        "lowerLimit": this.state.lowerLimit,
                        "optionExpections": exceptionOptionsList,
                        "isMandatory": this.state.check,
                        "equipCategId": this.state.euipvalueid.toString(),
                      }
                    ],
                    "guId": this.props.location.state.guId
                  }
                  var EncryptedRequest2 = Encrypt(Request2);
                  fetch('/CreateQuestions', {
                    method: 'POST',
                    body: EncryptedRequest2,
                    headers: {
                      guId: this.props.location.state.guId,
                      'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                    }

                  })
                    .then((response) => response.text())
                    .then((response) => {
                      var DecryptedResponse = decryptData(response)

                      if (DecryptedResponse.responseCode === '200 OK') {
                        this.setState({ question: "", value: -1, show: true })

                        confirmAlert({
                          message: 'Question added successfully',
                          buttons: [
                            {
                              label: 'Ok',
                              onClick: () => {


                                var Request4 = {
                                  "taskId": this.props.location.state.tskId,
                                  "guId": this.props.location.state.guId
                                }
                                var EncryptedRequest4 = Encrypt(Request4);
                                console.log(EncryptedRequest4)


                                fetch('/FetchQuestionsByTask', {
                                  method: 'POST',
                                  body: EncryptedRequest4,
                                  headers: {
                                    guId: this.props.location.state.guId,
                                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                                  }


                                })
                                  .then((response) => response.text())
                                  .then((response) => {
                                    var DecryptedResponse = decryptData(response)
                                    console.log(`Adqustion on load `, DecryptedResponse)
                                    if (DecryptedResponse.responseCode === '200 OK') {
                                      this.setState({ data: DecryptedResponse.listQuestions });
                                      this.setState({ loading: false });

                                    }

                                    else {
                                      if (DecryptedResponse.responseCode === '400 BAD_REQUEST') {
                                        this.setState({ loading: false });

                                        confirmAlert({
                                          title: 'Alert !',
                                          message: "No question found",
                                          buttons: [
                                            {
                                              label: 'Ok',
                                              onClick: () => { this.setState({ show: false }) }
                                            },

                                          ]
                                        })

                                      }
                                      else {

                                        this.setState({ loading: false });
                                        confirmAlert({
                                          title: 'Alert !',
                                          message: DecryptedResponse.responseMsg,
                                          buttons: [
                                            {
                                              label: 'Ok',
                                              onClick: () => { this.setState({ show: false }) }
                                            },

                                          ]
                                        })


                                      }
                                    }


                                  })
                                  .catch((error) => {
                                    confirmAlert({
                                      title: 'Alert !',
                                      message: "Session expired",
                                      buttons: [
                                        {
                                          label: 'Ok',
                                          onClick: () => { this.logoutnormal() }
                                        },

                                      ]


                                    });
                                  });


                              }
                            },

                          ]
                        })
                      }

                      else {
                        showAlert(DecryptedResponse.responseMsg)

                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });


                }


              }


            }
          }
        }
      }
    }

  }


  reminder() {
    var Request = {
      "taskId": this.state.tskId,
      "userId": this.props.location.state.userid,
      "guId": this.props.location.state.guId
    }
    var EncryptedRequest = Encrypt(Request);

    fetch('/ReminderMail', {
      method: 'POST',
      body: EncryptedRequest,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }
    })
      .then((response) => response.text())
      .then((response) => {

        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode === '200 OK') {
          confirmAlert({
            message: 'Reminder send successfully. Store will receive notification mail within upcoming 3 hours .',
            buttons: [
              {
                label: 'Ok',
                onClick: () => window.location.reload(true)
              },

            ]
          })

        }
        else {
          showAlert(DecryptedResponse.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });


      });

  }




  DeleteQuestion(questionId) {

    confirmAlert({
      message: 'Are you sure, you want to delete this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var Request3 = {
              "questionId": questionId,
              "userId": this.props.location.state.userid,
              "guId": this.props.location.state.guId
            }
            var EncryptedRequest3 = Encrypt(Request3);
            fetch('/DeleteQuestion', {
              method: 'POST',
              body: EncryptedRequest3,
              headers: {
                guId: this.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
              }

            })
              .then((response) => response.text())
              .then((response) => {

                var DecryptedResponse = decryptData(response)
                if (DecryptedResponse.responseCode === '200 OK') {
                  confirmAlert({
                    message: 'Question deleted successfully.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => window.location.reload(true)
                      },

                    ]
                  })

                }
                else {
                  showAlert(DecryptedResponse.responseMsg)
                }
              })
              .catch((error) => {

                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });


              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          }
        }
      ]
    })

  }





  FetchQuestionsByTask(e) {

    if (this.props.location.state) {
      this.setState({ tskName: this.props.location.state.tskName });
      this.setState({ tskDescr: this.props.location.state.tskDescr });
      this.setState({ strtDate: this.props.location.state.strtDate });
      this.setState({ edDate: this.props.location.state.edDate });
      this.setState({ tskId: this.props.location.state.tskId });
      this.setState({ role_id: this.props.location.state.role_id });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ publishStatus: this.props.location.state.publishStatus });
      this.setState({ category: this.state.category })

      var Request4 = {
        "taskId": this.props.location.state.tskId,
        "guId": this.props.location.state.guId
      }
      var EncryptedRequest4 = Encrypt(Request4);


      fetch('/FetchQuestionsByTask', {
        method: 'POST',
        body: EncryptedRequest4,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }


      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ data: DecryptedResponse.listQuestions });
            this.setState({ loading: false });

          }

          else {
            if (DecryptedResponse.responseCode === '400 BAD_REQUEST') {
              this.setState({ loading: false });

              confirmAlert({
                title: 'Alert !',
                message: "No question found",
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => { this.setState({ show: false }) }
                  },

                ]
              })

            }
            else {

              this.setState({ loading: false });


              confirmAlert({
                title: 'Alert !',
                message: DecryptedResponse.responseMsg,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => { this.setState({ show: false }) }
                  },

                ]
              })


            }
          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });


      fetch('/GetQuestionTypes', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ queDesc: DecryptedResponse.lstQTypes });
            let temp = [];
            let temp1 = [];
            for (var i = 0; i < DecryptedResponse.lstQTypes.length; i++) {
              temp.push(DecryptedResponse.lstQTypes[i].qtypeId)
              temp1.push(DecryptedResponse.lstQTypes[i].description)
            }
            this.setState({ qtypeId: temp });
            this.setState({ description: temp1 });

          }

          else {
            showAlert(DecryptedResponse.responseMsg)

          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });


        });



      fetch('/GetEquipmentCategoryMaster', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            let temp = [];
            let temp1 = [];
            for (var i = 0; i < DecryptedResponse.equipcatlist.length; i++) {
              temp.push(DecryptedResponse.equipcatlist[i].equipCategoryId)
              temp1.push(DecryptedResponse.equipcatlist[i].categoryName)
            }
            this.setState({ equipmentId: temp });
            this.setState({ equipment: temp1 });

          }

          else {
            showAlert(DecryptedResponse.responseMsg)

          }
        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });




    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }



  callupdatequestionpage(equipCategId, categoryName, questionId, questionText, questionTypeValue, questiontype, minValue, maxValue, options, updatedBy) {
    this.props.history.push({
      pathname: '/Updatequestion',
      state: {
        equipCategId: equipCategId,
        categoryName: categoryName,//categoryid
        questionId: questionId,
        questionText: questionText,
        questiontype: questiontype,
        questionTypeValue: questionTypeValue,
        minValue: minValue,
        maxValue: maxValue,
        options: options,
        updatedBy: updatedBy,
        userid: this.props.location.state.userid,
        role_id: this.state.role_id,
        tskName: this.state.tskName,
        tskId: this.state.tskId,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.state.usrnm,
        publishStatus: this.state.publishStatus,
        guId: this.props.location.state.guId
      }
    }
    );

  }


  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  PreviewQuestion = () => {
    this.props.history.push({
      pathname: '/Questionpreview',
      state: {
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        userid: this.state.userid,
        publishStatus: this.state.publishStatus,
        isMandatory: this.state.check,
        guId: this.props.location.state.guId


      }
    }
    );
  }
}
export default withRouter(Addquestion);