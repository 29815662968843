import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import Login from './Login';
import Taskactive from './Taskactive';
import Addquestion from './Addquestion';
import Createtasks from './Createtasks';
import Questionpreview from './Questionpreview';
import Reports from './Reports';
import CompletedTaskList from './CompletedTaskList';
import Updatetasks from './Updatetasks';
import Updatequestion from './Updatequestion';
import QuestionPreviewForCompletedTask from './QuestionPreviewForCompletedTask';
import Changepassword from './Changepassword';
import config from 'react-global-configuration';
import configuration from './config';
import CreateHo from './CreateHo';
import Storecreation from './Storecreation';
import Templates from './Templates';
import Tiker from './Tiker';
import Addtemplate from './Addtemplate';
import UpdateTemplates from './UpdateTemplates';
import QuestionPreviewFortemplate from './QuestionPreviewFortemplate';
import UserCreation from './UserCreation';
import UserList from './UserList';
import ImageGallery from './ImageGallery';
import QuestionPreviewLevel1 from './QuestionPreviewLevel1';
import ErrorBoundary from "./ErrorBoundary";
import TaskStoreList from './TaskStoreList';
import Archive from './Archive';
import Categorycreation from './Categorycreation';
import TaskSummaryReport from './TaskSummaryReport';
import Scanning from './Scanning';
import Preview from './Preview';
import QuestionPreviewForEquipmentTask from './QuestionPreviewForEquipmentTask';
import EquipmentList from './EquipmentList';
import Newstore from './Newstore';
import TaskApprovalLevelTwo from './TaskApprovalLevelTwo';
import TaskApprovalLevelThree from './TaskApprovalLevelThree';
import './css/style.css';
import './css/style-responsive.css';
import './lib/bootstrap/css/bootstrap.min.css';
import './lib/font-awesome/css/font-awesome.css';

config.set(configuration);
class App extends Component {

  render() {
    return (
      <div>
        <ErrorBoundary>
          <BrowserRouter >
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/Addquestion" component={Addquestion} />
              <Route path="/Questionpreview" component={Questionpreview} />
              <Route path="/Reports" component={Reports} />
              <Route path="/Taskactive" component={Taskactive} />
              <Route path="/Createtasks" component={Createtasks} />
              <Route path="/Updatetasks" component={Updatetasks} />
              <Route path="/Updatequestion" component={Updatequestion} />
              <Route path="/Changepassword" component={Changepassword} />
              <Route path="/CompletedTaskList" component={CompletedTaskList} />
              <Route path="/QuestionPreviewForCompletedTask" component={QuestionPreviewForCompletedTask} />
              <Route path="/CreateHo" component={CreateHo} />
              <Route path="/Storecreation" component={Storecreation} />
              <Route path="/Templates" component={Templates} />
              <Route path="/Tiker" component={Tiker} />
              <Route path="/Addtemplate" component={Addtemplate} />
              <Route path="/UpdateTemplates" component={UpdateTemplates} />
              <Route path="/QuestionPreviewFortemplate" component={QuestionPreviewFortemplate} />
              <Route path="/UserCreation" component={UserCreation} />
              <Route path="/UserList" component={UserList} />
              <Route path="/ImageGallery" component={ImageGallery} />
              <Route path="/QuestionPreviewLevel1" component={QuestionPreviewLevel1} />
              <Route path="/TaskStoreList" component={TaskStoreList} />
              <Route path="/Archive" component={Archive} />
              <Route path="/Categorycreation" component={Categorycreation} />
              <Route path="/TaskSummaryReport" component={TaskSummaryReport} />
              <Route path="/Scanning" component={Scanning} />
              <Route path="/Preview" component={Preview} />
              <Route path="/QuestionPreviewForEquipmentTask" component={QuestionPreviewForEquipmentTask} />
              <Route path="/EquipmentList" component={EquipmentList} />
              <Route path="/Newstore" component={Newstore} />
              <Route path="/LevelTwoApproval" component={TaskApprovalLevelTwo} />
              <Route path="/LevelThreeApproval" component={TaskApprovalLevelThree} />
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
      </div>
    );
  }
}

export default withRouter(App);