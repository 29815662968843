import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Spinner from 'react-spinner-material';//Import Spinner
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import Resizer from 'react-image-file-resizer';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import disableBrowserBackButton from 'disable-browser-back-navigation';
import _ from 'underscore'
import { Scrollbars } from 'react-custom-scrollbars';

import { basicAuth } from './basicAuth';
import { showAlert, showSuccess } from './services/ConfirmAlert';

class Preview extends Component {

  state = {
    TaskDropdwn: false,
    data: [],
    taskId: "",
    usrnm: "",
    tempquesid: "",
    quesid: [],
    quesidmail: [],
    quesidcs9: [],
    ans: [],
    imgquesflag: "",
    fileval: "",
    fileuploaded: [],
    nooffileques: 0,
    userId: "",
    fstring: "",

    answers: [],
    ansobj: {
      taskId: "",
      userId: "",
      questionId: "",
      answer: ""
    },
    radioObj: [],
    jsonObj: [],
    arrrefs: [],
    arrrefscs9: [],
    arrchk: [],
    checked: "",
    status: "",
    file: "",
    disabled: "",
    value1: "",
    defval: "",
    numchange: false,
    arrid1: [],
    arrid2: [],
    arrid3: [],
    arrid4: [],
    arrid5: [],
    arrid6: [],
    arrid7: [],
    arrid8: [],
    arrid9: [],
    arrid10: [],
    objwithans: [],
    nummaxflag: 1,
    numeleid: [],
    numminval: [],
    mand: [],
    mandid: [],
    abc: "",
    hasError: false,   //Added by chandrani for custom error page
    comment: "",
    categoryName: [],
    smapprove: "",
    checkbox: true,
    checkbox1: true,

    questionidpush: [],
    chkquespush: [],
    equipmentrequest: [],
    euipcatidentity: [],
    tskName: "",
    tskDescr: "",
    strtDate: "",
    edDate: "",
    instReadFlag: "",
    guId: "",
    browser: false,
    role_id: "",
    latitude: "",
    longitude: "",
    Question: [],
    getBarcodeData: '',
    showscanner: false,
    getbuttondisable: false,
    showEquipArray: [],
    buttondis: "",
    loading: false
  }

  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);

    var _ = require('lodash')

    this.state = {
      // checked:false,
      selectedOption: '',
      data: [],
      displayMenu: false,
      tempquesid: "",
      quesid: [],
      quesidmail: [],
      quesidcs9: [],
      ans: [],
      answers: [],
      fileval: "",
      fileuploaded: [],
      taskId: "",
      userId: "",
      storeNo: "",
      loading: false,
      fstring: "",
      abc: "",
      visiblity: true,
      startDate: new Date(),
      ansobj: {
        taskId: "",
        userId: "",
        questionId: "",
        answer: ""
      },

      radioObj: [],
      jsonObj: [],
      arrrefs: [],
      arrrefscs9: [],
      arrchk: [],
      imgquesflag: "",
      value1: "",
      numchange: false,
      defval: "",
      status: "",
      file: "",
      nooffileques: 0,
      arrid1: [],
      arrid2: [],
      arrid3: [],
      arrid4: [],
      arrid5: [],
      arrid6: [],
      arrid7: [],
      arrid8: [],
      arrid9: [],
      arrid10: [],
      objwithans: [],
      nummaxflag: 1,
      numeleid: [],
      numminval: [],
      mand: [],
      mandid: [],
      abc: "",
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      comment: "",
      categoryName: [],
      smapprove: "",
      checkbox: true,
      questionidpush: [],
      chkquespush: [],
      equipmentrequest: [],
      euipcatidentity: [],
      checkbox1: true,
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",

      usrnm: "",
      instReadFlag: "",
      guId: "",
      browser: false,
      role_id: "",
      latitude: "",
      longitude: "",
      Question: [],
      getBarcodeData: '',
      showscanner: false,
      getbuttondisable: false,
      showEquipArray: [],
      buttondis: "",


    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)



  }
  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }



  componentDidMount() {

    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const lat = JSON.stringify(position.coords.latitude);
          const long = JSON.stringify(position.coords.longitude);
          console.log("The location: ", lat, long)
          this.setState({ latitude: lat + "," + long });
        },
        error => '',
        { enableHighAccuracy: true }
      );
    } else {
      showAlert('Either the location is not enabled or the browser is outdated. Please take necessary steps.')
    }


    console.log(`lat`, this.state.latitude)
    // console.log(`lont`,this.state.longitude)

  }


  handleChangeRadioNew = (param, equipmentNo, event) => {
    console.log(`def`, equipmentNo)
    var flag = false;
    if (this.state.quesid.length > 0) {

      for (let i = 0; i < this.state.quesid.length; i++) {
        const element = this.state.quesid[i];
        if (element === param) {
          for (let j = 0; j < this.state.radioObj.length; j++) {
            const element1 = this.state.radioObj[j];
            if (element1.questionId === param) {
              this.state.radioObj[j].answer = event.currentTarget.value;
              flag = true;
            }
          }
        }
      }
      if (!flag) {
        var newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: param,
          answer: event.currentTarget.value,
          equipmentNo: equipmentNo
        };
        console.log(`hello ran`, newans)
        this.state.radioObj.push(newans);
        this.state.quesid.push(param)
      }
    }
    else {
      this.state.quesid.push(param)
      this.state.ansobj.questionId = param;
      this.state.ansobj.answer = event.currentTarget.value;
      this.state.radioObj.push(this.state.ansobj)
    }

  }

  handleChangeCheck = (param, equipmentNo, event) => {

    let _ = require('underscore')
    var cleanedqid = [];
    this.state.quesid.forEach(function (itm) {
      var unique = true;
      cleanedqid.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) cleanedqid.push(itm);
    });

    var flag = false;
    if (cleanedqid.length > 0) {

      for (let i = 0; i < cleanedqid.length; i++) {
        const element = cleanedqid[i];
        if (element === param) {
          for (let j = 0; j < this.state.radioObj.length; j++) {
            const element1 = this.state.radioObj[j];
            if (element1.questionId === param) {
              if (this.state.radioObj[j].answer === undefined) {

              }
              else {
                if (this.state.radioObj[j].answer.includes(event.currentTarget.value)) {
                  if (this.state.radioObj[j].answer.includes(event.currentTarget.value + ",")) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace(event.currentTarget.value + ",", "")
                  }
                  else if (this.state.radioObj[j].answer.includes("," + event.currentTarget.value)) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace("," + event.currentTarget.value, "")
                  }
                  else if (this.state.radioObj[j].answer.includes(event.currentTarget.value)) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace(event.currentTarget.value, "")
                  }
                }
                else {
                  if (this.state.radioObj[j].answer === "") {
                    this.state.radioObj[j].answer = event.currentTarget.value;
                  }
                  else {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer + "," + event.currentTarget.value;
                  }
                }
                flag = true;
              }
            }
          }
        }
      }
      if (!flag) {
        var newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: param,
          answer: event.currentTarget.value,
          equipmentNo: equipmentNo
        };
        this.state.radioObj.push(newans);
        this.state.quesid.push(param)
      }
    }
    else {
      this.state.quesid.push(param)
      this.state.ansobj.questionId = param;
      this.state.ansobj.answer = event.currentTarget.value;
      this.state.radioObj.push(this.state.ansobj)
    }

  }



  browserCheck() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    ) {
      this.setState({ browser: true });
    }
    console.log("browser", this.state.browser);
  }





  handleChangeNum = (param, param2, evt) => {
    //this.setState({nummaxflag : this.state.nummaxflag+1});
    if (param2 === undefined) {
      if (evt.target.value.length === 1) {
        const value1 = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
      else {
        const value1 = (evt.target.validity.valid) ? evt.target.value : evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
    }
    else {
      if (document.getElementById(param).value.length <= param2) {
        if (evt.target.value.length === 1) {
          const value1 = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
          this.setState({ numchange: true });
          document.getElementById(param).value = value1;
        }
        else {
          const value1 = (evt.target.validity.valid) ? evt.target.value : evt.target.value.substring(0, evt.target.value.length - 1);
          this.setState({ numchange: true });
          document.getElementById(param).value = value1;
        }
      }
      else {
        const value1 = evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
    }
  }

  downloadPDF() {

    var dlnk = document.getElementById('dwnldLnk');
    dlnk.href = this.state.file;
    dlnk.click();

  }

  openIns() {


    if (this.state.instReadFlag == "0") {
      var Request1 = {
        "taskId": this.props.location.state.tskId,
        "storeId": this.props.location.state.storeNo,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/InstReadFlag ', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            var string = this.state.file;
            var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
            var iframe = "<iframe width='100%' height='100%' src='" + "/Images/" + this.state.file + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
          }
          else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.responseMsg,
              buttons: [
                {
                  label: 'Ok',

                },

              ]
            })
          }



        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });
    }

    else {


      var string = this.state.file;
      var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
      var iframe = "<iframe width='100%' height='100%' src='" + "/Images/" + this.state.file + "'></iframe>"
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    }
  }
  DownloadIns = () => {
    if (this.state.instReadFlag == "0") {
      var Request1 = {
        "taskId": this.props.location.state.tskId,
        "storeId": this.props.location.state.storeNo,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/InstReadFlag ', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            document.getElementById('FileDnld').click();
          }
          else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.responseMsg,
              buttons: [
                {
                  label: 'Ok',
                  // onClick: () =>
                },

              ]
            })
          }



        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
          // console.error(error);
        });
    }

    else {

      document.getElementById('FileDnld').click()
    }

  }




  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  openPrf = (param, equipmentNum, evt) => {
    var cleanedans = [];
    console.log(`radioobj`, this.state.radioObj)
    cleanedans = this.state.radioObj;
    evt.preventDefault();
    var bool = false;
    var countimg = 0;
    if (cleanedans.length > 0) {
      for (let index = 0; index < cleanedans.length; index++) {
        const element = cleanedans[index];
        console.log(`element`, element)
        console.log(`Ìmage `, element.imageArray)
        // && element.equipmentNo === equipmentNum
        if (element.questionId == param && element.equipmentNo == equipmentNum) {
          bool = true;
          if (element.imageArray.length > 0) {
            for (let k = 0; k < element.imageArray.length; k++) {

              if (element.imageArray[k] == null || element.imageArray[k] == "") {
                countimg = countimg + 1
                if (countimg === 3) {
                  bool = false;
                  break
                }
              }
              else {
                var iframe = "<iframe width='100%' height='100%' src='" + element.imageArray[k] + "'></iframe>"
                var x = window.open();
                x.document.open();
                x.document.write(iframe);
                x.document.close();
              }
            }
          }
          else {
            confirmAlert({
              message: "No File uploaded yet",
              buttons: [
                {
                  label: 'OK',
                },

              ]
            })
          }
        }

      }
      if (!bool) {
        confirmAlert({
          message: "No File uploaded yet",
          buttons: [
            {
              label: 'OK',
            },

          ]
        })
      }
    }
    else {
      confirmAlert({
        message: "No File uploaded yet",
        buttons: [
          {
            label: 'OK',
          },

        ]
      })
    }
  }




  handleFileSelect = (param, evt) => {
    console.log(`evt`, param)
    console.log(`evt`, evt)
    // this.setState({radioObj:[]})
    console.log(`radioobj`, this.state.radioObj)
    var that1 = this;
    var files = document.getElementById(param).files;
    console.log(`file`, evt.target.files[0])
    var newans = {
      taskId: that1.props.location.state.tskId,
      userId: that1.props.location.state.userid,
      questionId: param,
      imageArray: []
    };


    if (files.length > 3) {
      confirmAlert({
        title: 'Alert !',
        message: "Maximum three file can be uploaded.",
        buttons: [
          {
            label: 'Ok',
          },

        ]
      })
    }

    else {
      for (let k = 0; k < files.length; k++) {


        if (files[k].type == "image/gif"
          || files[k].type == "image/jpg"
          || files[k].type == "image/jpeg"
          || files[k].type == "image/png"
          || files[k].type == "application/msword"
          || files[k].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          || files[k].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          || files[k].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          || files[k].type == "application/vnd.ms-excel"
          || files[k].type == "application/pdf"
          || files[k].type == "application/vnd.ms-powerpoint"
          || files[k].type == "text/plain"
        ) {
          if (files[k].type == "image/jpeg" || files[k].type == "image/png") {
            let _ = require('underscore')
            var cleanedqid = [];
            this.state.quesid.forEach(function (itm) {
              var unique = true;
              cleanedqid.forEach(function (itm2) {
                if (_.isEqual(itm, itm2)) unique = false;
              });
              if (unique) cleanedqid.push(itm);
            });
            var flag = false;
            if (cleanedqid.length > 0) {
              for (let i = 0; i < cleanedqid.length; i++) {
                const element = cleanedqid[i];
                if (element === param) {
                  for (let j = 0; j < that1.state.radioObj.length; j++) {
                    const element1 = that1.state.radioObj[j];
                    if (element1.questionId === param) {
                      if (files.length > 0) {
                        if (that1.state.radioObj[j].imageArray === undefined) {

                        }
                        else {

                          flag = true;
                          var reader = new FileReader();
                          reader.readAsDataURL(files[k]);
                          reader.onload = function () {
                            Resizer.imageFileResizer(
                              files[k],
                              500,
                              500,
                              'JPEG',
                              100,
                              0,
                              uri => {
                                that1.state.fileuploaded.push(param + "" + uri)
                                newans.imageArray.push(uri)
                              },
                              'base64'
                            );


                          };
                          reader.onerror = function (error) {
                          };

                        }
                        that1.state.imgquesflag = param;
                        that1.state.fileval = param + "File Upload successful";
                        document.getElementById("proof" + param).value = "File Upload successful"
                      }
                    }
                  }
                }
              }
              if (!flag) {
                if (files.length > 0) {

                  that1.state.quesid.push(param)
                  if (files.length > 0) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = function () {
                      Resizer.imageFileResizer(
                        files[k],
                        500,
                        500,
                        'JPEG',
                        100,
                        0,
                        uri => {
                          that1.state.fileuploaded.push(param + "" + uri)
                          newans.imageArray.push(uri)
                        },
                        'base64'
                      );

                    };

                  }


                  that1.state.imgquesflag = param;
                  that1.state.fileval = param + "File Upload successful";
                  document.getElementById("proof" + param).value = "File Upload successful";

                }
              }
            }
            else {
              //
              var files = document.getElementById(param).files;
              var temp;
              var that1 = this;

              if (files.length > 0) {

                var reader = new FileReader();
                reader.readAsDataURL(files[k]);
                reader.onload = function () {
                  newans.imageArray.push(reader.result);
                  that1.state.fileuploaded.push(param + "" + reader.result)

                };



              }





              that1.state.radioObj.push(newans);
              that1.state.imgquesflag = param;
              that1.state.fileval = param + "File Upload successful";
              that1.state.quesid.push(param);
              document.getElementById("proof" + param).value = "File Upload successful"
            }

          }
          else {
            var that1 = this;
            var files = document.getElementById(param).files;
            let _ = require('underscore')
            var cleanedqid = [];
            this.state.quesid.forEach(function (itm) {
              var unique = true;
              cleanedqid.forEach(function (itm2) {
                if (_.isEqual(itm, itm2)) unique = false;
              });
              if (unique) cleanedqid.push(itm);
            });
            var flag = false;
            if (cleanedqid.length > 0) {
              for (let i = 0; i < cleanedqid.length; i++) {
                const element = cleanedqid[i];
                if (element === param) {
                  for (let j = 0; j < that1.state.radioObj.length; j++) {
                    const element1 = that1.state.radioObj[j];
                    if (element1.questionId === param) {
                      if (files.length > 0) {
                        if (that1.state.radioObj[j].imageArray === undefined) {

                        }
                        else {

                          flag = true;
                          var reader = new FileReader();
                          console.log(`file`, files)
                          reader.readAsDataURL(files[k]);
                          reader.onload = function (evt) {
                            newans.imageArray.push(evt.target.result)
                            that1.state.fileuploaded.push(param + "" + reader.result)
                          };
                          reader.onerror = function (error) {
                          };

                        }
                        that1.state.imgquesflag = param;
                        that1.state.fileval = param + "File Upload successful";
                        document.getElementById("proof" + param).value = "File Upload successful"
                      }
                    }
                  }
                }
              }
              if (!flag) {
                if (files.length > 0) {

                  that1.state.quesid.push(param)
                  if (files.length > 0) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = function () {
                      newans.imageArray.push(reader.result);
                      that1.state.fileuploaded.push(param + "" + reader.result)

                    };
                    reader.onerror = function (error) {
                    };
                  }

                  that1.state.radioObj.push(newans);
                  that1.state.imgquesflag = param;
                  that1.state.fileval = param + "File Upload successful";
                  document.getElementById("proof" + param).value = "File Upload successful";
                }
              }
            }
            else {
              //
              var files = document.getElementById(param).files;
              var temp;
              var that1 = this;

              if (files.length > 0) {

                var reader = new FileReader();
                reader.readAsDataURL(files[k]);
                reader.onload = function () {
                  newans.imageArray.push(reader.result);
                  that1.state.fileuploaded.push(param + "" + reader.result)

                };
                reader.onerror = function (error) {
                };


              }

              that1.state.radioObj.push(newans);
              that1.state.imgquesflag = param;
              that1.state.fileval = param + "File Upload successful";
              that1.state.quesid.push(param);
              document.getElementById("proof" + param).value = "File Upload successful"
            }


          }
        }
        else {
          this.setState({ instructionUpload: '' });
          confirmAlert({
            title: 'Alert !',
            message: "Unsupported File Format.",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.setState({ instructionUpload: "" }); }
              },

            ]
          })
        }
      }
      console.log(`newans`, newans)
      console.log(`handle`, this.state.radioObj)
      if (this.state.radioObj.length == 0) {
        //  this.setState({radioObj:[newans]})
        this.state.radioObj = [newans]
      }
      else {
        for (let z = 0; z < this.state.radioObj.length; z++) {

          if (param == this.state.radioObj[z].questionId) {
            this.state.radioObj[z] = newans

          }
        }

      }

    }
  }


  componentWillMount() {

    this.fetchQuestionPaper()
    this.browserCheck()
    this.fetchFullPaper("preview")
  }

  writestate = (param, res) => {
    this.state.fileuploaded.push(param + "" + res);
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  logout = () => {
    if (this.props.location.state.Isroffice == true) {

      this.props.history.push({
        pathname: '/QuestionPreviewLevel1',
        state: {
          usrnm: ''
        }


      });
      localStorage.clear();
      document.getElementById("rofficelogin").click();


    }
    else {
      var Request1 = {
        "guId": this.props.location.state.guId,
        "userId": this.props.location.state.userid
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/LogOut', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          console.log(`logout`, DecryptedResponse1)


          if (DecryptedResponse1.responseCode === '200 OK') {

            localStorage.clear();
            this.props.history.replace('/');
            disableBrowserBackButton();

          }
          else {
            this.setState({ loading: false })
            showSuccess(DecryptedResponse1.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });



        });




    }
  }



  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }


  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  handleOnChange(e) {
    this.setState({ selectedOption: e.target.value });
  }




  handleNotapplicableCheck(listQuestions, e) {
    console.log(` this.state.chkquespush`, e.currentTarget.checked)
    this.setState({ checkbox: !this.state.checkbox })

    console.log(`check`, listQuestions);
    if (e.currentTarget.checked == true) {
      for (let y = 0; y < listQuestions[0].newQuest[0].length; y++) {
        console.log(`cbdjcb`, listQuestions[0].newQuest[0][y])
        this.state.questionidpush.push(listQuestions[0].newQuest[0][y].questionId)
        this.state.equipmentrequest.push(listQuestions[0].newQuest[0][y].equipCategId)


      }
    }
    else {
      for (let n = 0; n < this.state.questionidpush.length; n++) {
        for (let t = 0; t < listQuestions[0].newQuest[0].length; t++) {
          if (this.state.questionidpush[n] == listQuestions[0].newQuest[0][t].questionId) {
            this.state.questionidpush.splice(n, 1)


          }

        }
      }


      for (let g = 0; g < this.state.equipmentrequest.length; g++) {
        for (let b = 0; b < listQuestions[0].newQuest[0].length; b++) {
          if (this.state.equipmentrequest[g] == listQuestions[0].newQuest[0][b].equipCategId) {
            this.state.equipmentrequest.splice(g, 1)
          }
        }
      }


    }
    this.state.equipmentrequest = [...new Set(this.state.equipmentrequest)]
    // this.state.questionidpush=[...new Set(this.state.questionidpush)]
    console.log(`this.state`, this.state.questionidpush)
    console.log(`equipment`, this.state.equipmentrequest)

  }


  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }


  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  // openReview = () => {
  //   this.setState((prevState) => ({
  //     reviewAFM: !prevState.reviewAFM,

  //   }));
  // }


  // closeReview = () => {
  //   this.setState({ reviewAFM: false });
  // }





  render = () => {

    var that = this;
    // var imagemodal = [];
    // var imageClick = []

    // imageClick.push(
    //   <div className="imgalign"><a class="mr-50" onClick={this.openReview.bind(this)}><img class='mr-10' style={{ height: '40px' }} src={require("./img/comment.png")}></img><b>Feedback</b></a></div>
    // )

    // imagemodal.push(

    //   <div className="modal" role="dialog" style={this.state.reviewAFM ? display : hide}>
    //     <div className="modal-dialog animate">
    //       <div className="modal-content" >
    //         <div className="modal-header">
    //           <a className="close" style={{ color: "#fff", }} onClick={this.closeReview}>X</a>
    //           <h4 className="modal-title">FeedBack</h4>
    //         </div>
    //         <div className="modal-body">
    //           <div className="form-horizontal" style={{ "margin-right": "5px", height: "400px", }}>
    //             <div className="form-group">

    //               <div className="col-sm-10">
    //                 <div className="tbl-holder mt-20">
    //                   <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">

    //                     <thead>

    //                       <tr>
    //                         <th style={{ width: "3%" }}>Sr No</th>
    //                         <th>Question</th>
    //                         <th style={{ width: "8%" }}>Feedback</th>
    //                       </tr>
    //                     </thead>
    //                   </table>

    //                   <Scrollbars style={{ height: 296 }}>

    //                     <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
    //                       <tbody>


    //                         {this.state.Question.map(function (item, key) {
    //                           return (
    //                             <tr key={key}>
    //                               <td data-th="Sr No" style={{ width: "3%" }}>{key + 1}</td>
    //                               <td data-th="Question">  <a href="javascript:void(0)" className="black-text">  {item.surveyQuest}</a></td>
    //                               <td data-th="Feedback" style={{ width: "8%" }}>  <a href="javascript:void(0)" className="black-text">  {item.surveyanswer}</a></td>

    //                             </tr>
    //                           )
    //                         })}
    //                       </tbody>
    //                     </table>
    //                   </Scrollbars>
    //                 </div>



    //               </div>

    //             </div>


    //           </div>
    //         </div>

    //       </div>
    //     </div>
    //   </div>
    // )




    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <div>
          {/* {imagemodal} */}
          <a href={config.get('RofficeLoginURL')} id="rofficelogin" hidden></a>
          <div className="spin">
            <Spinner visible={this.state.loading}
              spinnerColor={"rgba(0, 0, 0, 0.3)"} />
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header class="header black-bg">

              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo">
                <img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b> ({this.state.storeNo})<b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3><i className="fa fa-angle-right"></i>{this.state.tskName}</h3>
                      <p>Description : {this.state.tskDescr}</p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" >Start Date : {this.state.strtDate} </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">End Date : {this.state.edDate}</div>
                    {this.state.file ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" style={{ float: "right", marginTop: "10px" }}>
                        <a onClick={this.openIns.bind(this)}>View Instruction</a>/
                        <a download="instructions" onClick={this.DownloadIns.bind(this)}>Download Instructions</a>
                        <a download="instructions" href={"/Images/" + this.state.file} id="FileDnld" hidden />


                      </div>
                      :
                      null}
                    {this.state.comment ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 mt-20" style={{ "font-weight": "bold" }}>Store Manager :<span style={{ "color": "red" }}>  {this.state.comment}</span></div>
                      : null}

                    {this.state.afmcomment ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 mt-20" style={{ "font-weight": "bold" }}>AFM :<span style={{ "color": "red" }}>  {this.state.afmcomment}</span></div>
                      : null
                    }
                    <div class="clearfix"></div>

                    <hr />

                    {/* {(this.state.role_id == "505" || this.state.role_id == "501") && this.state.status == "Submitted" ?
                      <div>{imageClick}</div>
                      : ''} */}
                    {this.state.loading = false}

                    <ul class="ques">
                      {that.state.data ?
                        (that.state.data.map(function (xitem, xkey) {
                          return (
                            <Accordion allowZeroExpanded >
                              <AccordionItem >
                                <div key={xkey}>

                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <b id="accordionopen" style={{ textAlign: "center" }}>Category Name :{xitem.categoryName}({that.state.data[xkey].listQuestions.length})</b>
                                      {/* <div style={{ "float": "right" }}>
                                        <input type="checkbox"
                                          {...that.state.status === "Submitted" ?
                                            that.state.disabled = "disabled"
                                            :
                                            that.state.disabled = ""
                                          }
                                          defaultChecked={that.state.euipcatidentity.includes(xitem.equipCategId) ? true : false}
                                          onChange={that.handleNotapplicableCheck.bind(that, xitem.listQuestions)}
                                          disabled={that.state.disabled} /><span style={{ "color": "red" }}><b>Not Applicable</b></span>
                                      </div> */}
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {that.state.data[xkey].listQuestions.map(function (yitem, ykey) {
                                      return (
                                        <Accordion allowZeroExpanded >
                                          <AccordionItem >
                                            <div key={ykey}>
                                              <AccordionItemHeading>
                                                <AccordionItemButton>
                                                  <b>Equipment Number : {yitem.equipmentNum}({that.state.data[xkey].listQuestions[ykey].newQuest[0].length})</b>
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                {that.state.data[xkey].listQuestions[ykey].newQuest[0].map(function (item, key) {

                                                  switch (item.questionType) {

                                                    case "1":
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            {item.options.map((optele, index) => (<span class="nameloc">
                                                              <input type="radio"
                                                                class="custom-control-input" id={item.questionId + index} name={item.questionId + yitem.equipmentNum}
                                                                value={optele}
                                                                {...that.state.arrid1.push(item.questionId + index)}
                                                                {...item.answer === optele ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}
                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + index}>{optele}</label>
                                                            </span>
                                                            )
                                                            )}
                                                          </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "2":

                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            {item.options.map((optele, index) => (
                                                              <span class="nameloc">
                                                                <input type="checkbox" class="custom-control-input" id={item.questionId + index} name={item.questionId + index}
                                                                  value={optele}
                                                                  {...that.state.arrid2.push(item.questionId + index)}
                                                                  {...item.answer === undefined ?
                                                                    that.state.checked = false :
                                                                    {
                                                                      ...item.answer.includes(optele) ?
                                                                        that.state.checked = true
                                                                        :
                                                                        that.state.checked = false
                                                                    }
                                                                  }
                                                                  defaultChecked={that.state.checked}
                                                                  {...that.state.status === "Submitted" ?
                                                                    that.state.disabled = "disabled"
                                                                    :
                                                                    that.state.disabled = ""
                                                                  }
                                                                  disabled={that.state.disabled}

                                                                  onChange={that.handleChangeCheck.bind(that, item.questionId, yitem.equipmentNum)}
                                                                />
                                                                <label style={{ fontWeight: "normal" }} for={item.questionId + index}>{optele}</label>
                                                              </span>
                                                            ))}

                                                          </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "3":
                                                      that.state.arrrefs.push("qtype3" + item.questionId)
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0" >
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} /></p>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                              <textarea type="text" class="form-control" placeholder="Some Text"
                                                                value={that.state.value}
                                                                defaultValue={item.answer}
                                                                {...that.state.arrid3.push("qtype3" + item.questionId)}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                ref={"qtype3" + item.questionId}
                                                                id={"qtype3" + item.questionId}
                                                              />
                                                            </div>
                                                            <div class="clearfix">
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "4":
                                                      that.state.arrrefs.push("qtype3" + item.questionId)
                                                      that.state.numeleid.push("qtype3" + item.questionId)
                                                      that.state.numminval.push(item.minValue)
                                                      if (that.state.value1 === "") {
                                                        if (!that.state.numchange) {
                                                          //that.state.value1 = item.answer
                                                        }

                                                      }
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0" >
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                              <that.Astrik isMandatory={item.isMandatory} /></p>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                              <input type="tel" class="form-control" placeholder="Number Only"
                                                                defaultValue={item.answer}
                                                                {...that.state.arrid4.push("qtype3" + item.questionId)}

                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                pattern="^-?[0-9]\d*\.?\d*$" onInput={that.handleChangeNum.bind(that, "qtype3" + item.questionId, item.maxValue)}

                                                                ref={"qtype3" + item.questionId}
                                                                id={"qtype3" + item.questionId}
                                                              />

                                                            </div>
                                                            <div class="clearfix">
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "5":
                                                      that.state.arrrefs.push("qtype3" + item.questionId);
                                                      that.state.quesidmail.push("qtype3" + item.questionId)
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0" >
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                              <input type="email" class="form-control" placeholder="Email-ID"
                                                                value={that.state.value}
                                                                defaultValue={item.answer}
                                                                ref={"qtype3" + item.questionId}
                                                                id={"qtype3" + item.questionId}
                                                                {...that.state.arrid5.push("qtype3" + item.questionId)}

                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                              />
                                                            </div>
                                                            <div class="clearfix">
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "6":
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            <span class="ml-10">
                                                              <input type="radio" class="custom-control-input" id={item.questionId + 'A' + yitem.equipmentNum} name={item.questionId + yitem.equipmentNum}
                                                                value="Yes"
                                                                {...that.state.arrid6.push(item.questionId + 1)}

                                                                {...item.answer === "Yes" ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + 'A' + yitem.equipmentNum}>Yes</label>
                                                            </span>
                                                            <span>
                                                              <input type="radio" class="custom-control-input" id={item.questionId + 'B' + yitem.equipmentNum} name={item.questionId + yitem.equipmentNum}
                                                                value="No"
                                                                {...that.state.arrid6.push(item.questionId + 2)}

                                                                {...item.answer === "No" ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + 'B' + yitem.equipmentNum}>No</label>
                                                            </span> </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "7":
                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                              <that.Astrik isMandatory={item.isMandatory} /></p>
                                                            <span class="ml-10">
                                                              <input type="radio" class="custom-control-input" id={item.questionId + 'D' + yitem.equipmentNum} name={item.questionId + yitem.equipmentNum}
                                                                value="Yes"
                                                                {...that.state.arrid7.push(item.questionId + 1)}

                                                                {...item.answer === "Yes" ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + 'D' + yitem.equipmentNum}>Yes</label>
                                                            </span> <span>
                                                              <input type="radio" class="custom-control-input" id={item.questionId + 'E' + yitem.equipmentNum} name={item.questionId + yitem.equipmentNum}
                                                                value="No"
                                                                {...that.state.arrid7.push(item.questionId + 2)}

                                                                {...item.answer === "No" ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + 'E' + yitem.equipmentNum}>No</label>
                                                            </span> <span>
                                                              <input type="radio" class="custom-control-input" id={item.questionId + 'F' + yitem.equipmentNum} name={item.questionId + yitem.equipmentNum}
                                                                value="NA"
                                                                {...that.state.arrid7.push(item.questionId + 3)}

                                                                {...item.answer === "NA" ?
                                                                  that.state.checked = true
                                                                  :
                                                                  that.state.checked = false
                                                                }
                                                                defaultChecked={that.state.checked}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                onChange={that.handleChangeRadioNew.bind(that, item.questionId, yitem.equipmentNum)}
                                                              />
                                                              <label style={{ fontWeight: "normal" }} for={item.questionId + 'F' + yitem.equipmentNum}>NA</label>
                                                            </span> </div>
                                                        </li>
                                                      );
                                                      break;
                                                    case "8":
                                                      var valup = "Upload File"
                                                      var bool = false;
                                                      if (that.state.fileval.includes(item.questionId, 0)) {
                                                        var elelen = that.state.fileval.length;
                                                        valup = that.state.fileval.substring(item.questionId.length, elelen);
                                                        bool = true
                                                      }

                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            <div class="clearfix">
                                                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                                <input id={"proof" + item.questionId + yitem.equipmentNum} type="text" class="form-control input-upload"
                                                                  {...bool === true ?
                                                                    valup = valup
                                                                    :
                                                                    { ...item.imageArray.length > 0 ? valup = "one file uploaded" : valup = "upload file" }

                                                                  }
                                                                  value={valup}
                                                                  {...that.state.arrid8.push("proof" + item.questionId)}
                                                                  {...that.state.status === "Submitted" ?
                                                                    that.state.disabled = "disabled"
                                                                    :
                                                                    that.state.disabled = ""
                                                                  }
                                                                  disabled={that.state.disabled}
                                                                  accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf"
                                                                />
                                                                <span class="btn-upload" style={{ padding: "7px 15px" }}
                                                                  {...that.state.status === "Submitted" ?
                                                                    that.state.disabled = "disabled"
                                                                    :
                                                                    that.state.disabled = ""
                                                                  }
                                                                  disabled={that.state.disabled}

                                                                >Upload</span>
                                                                <input id={item.questionId} type="file" name="proof-data" class="input-hidden"
                                                                  {...that.state.status === "Submitted" ?
                                                                    that.state.disabled = "disabled"
                                                                    :
                                                                    that.state.disabled = ""
                                                                  }
                                                                  disabled={that.state.disabled}
                                                                  {...that.state.arrid8.push(item.questionId)}

                                                                  onChange={that.handleFileSelect.bind(that, item.questionId)}
                                                                  accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf" multiple="multiple" />

                                                                <div class="clearfix"></div>

                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0" >
                                                                  <a onClick={that.openPrf.bind(that, item.questionId, yitem.equipmentNum)}>View file</a>
                                                                </div>
                                                                <div class="clearfix"></div>
                                                              </div>
                                                            </div>
                                                            <div class="clearfix">
                                                            </div>
                                                          </div>
                                                        </li>

                                                      );
                                                      break;
                                                    case "9":
                                                      that.state.quesidcs9.push(item.questionId);
                                                      var ansarr = ""
                                                      if (item.answer === undefined || item.answer === null || item.answer === "") {

                                                      }
                                                      else if (item.answer != null || item.answer != "" || item.answer != undefined) {
                                                        ansarr = item.answer.split(",")
                                                      }

                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            {item.options.map((optele, index) => (
                                                              <span class="ml-0 pl-0">
                                                                <p for={item.questionId + index}><b>{optele}</b></p>

                                                                <input type="text" class="form-control"
                                                                  value={that.state.value}
                                                                  onKeyPress={that.fullstop}
                                                                  {...ansarr.length > 0 ?
                                                                    (that.state.defval = ansarr[index])
                                                                    :
                                                                    (that.state.defval = "")
                                                                  }

                                                                  defaultValue={that.state.defval}
                                                                  {...that.state.status === "Submitted" ?
                                                                    (that.state.disabled = "disabled")
                                                                    :
                                                                    (that.state.disabled = "")
                                                                  }
                                                                  disabled={that.state.disabled}

                                                                  ref={item.questionId + index}
                                                                  id={item.questionId + index}
                                                                  {...that.state.arrid9.push(item.questionId + index)}

                                                                  {...that.state.arrrefscs9.push(item.questionId + index)}
                                                                />

                                                                <div class="clearfix">
                                                                </div>
                                                              </span>
                                                            )
                                                            )}
                                                          </div>
                                                        </li>
                                                      );
                                                      break;

                                                    case "10":
                                                      that.state.arrrefs.push("qtype3" + item.questionId)
                                                      return (


                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0" >
                                                            <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                              <that.Astrik isMandatory={item.isMandatory} />
                                                            </p>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                              <input type="date" className="form-control form-control-inline input-medium default-date-picker"
                                                                size="16" dateFormat="dd-MMM-YYYY" data-date-inline-picker="true"
                                                                value={that.state.value}
                                                                defaultValue={item.answer}
                                                                {...that.state.arrid10.push("qtype3" + item.questionId)}
                                                                {...that.state.status === "Submitted" ?
                                                                  that.state.disabled = "disabled"
                                                                  :
                                                                  that.state.disabled = ""
                                                                }
                                                                disabled={that.state.disabled}

                                                                ref={"qtype3" + item.questionId}
                                                                id={"qtype3" + item.questionId}
                                                              />
                                                            </div>
                                                            <div class="clearfix">
                                                            </div>
                                                          </div>
                                                        </li>

                                                      );

                                                      break;
                                                    case "11":
                                                      that.state.quesid.push(item.questionId);
                                                      var valup = "Upload File";
                                                      var bool = false;
                                                      if (
                                                        that.state.fileval.includes(
                                                          item.questionId,
                                                          0
                                                        )
                                                      ) {
                                                        var elelen = that.state.fileval.length;
                                                        valup = that.state.fileval.substring(
                                                          item.questionId.length,
                                                          elelen
                                                        );
                                                        bool = true;
                                                      }

                                                      return (
                                                        <li key={key}>
                                                          <div class="custom-selection ml-0 pl-0">
                                                            <p style={{ fontWeight: "bold" }}>
                                                              {item.questionText}
                                                              <that.Astrik
                                                                isMandatory={item.isMandatory}
                                                              />
                                                            </p>
                                                            <div class="clearfix">
                                                              {that.state.browser === true ? (
                                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                                                  <input
                                                                    id={"proof" + item.questionId}
                                                                    type="text"
                                                                    class="form-control input-upload"
                                                                    {...(bool === true
                                                                      ? (valup = valup)
                                                                      : {
                                                                        ...(item.imageArray.length >
                                                                          0
                                                                          ? (valup =
                                                                            "one image uploaded")
                                                                          : (valup =
                                                                            "upload live image"))
                                                                      })}
                                                                    value={valup}
                                                                    {...that.state.arrid8.push(
                                                                      "proof" + item.questionId
                                                                    )}
                                                                    {...(that.state.status === "Submitted"
                                                                      ? (that.state.disabled =
                                                                        "disabled")
                                                                      : (that.state.disabled = ""))}
                                                                    disabled={that.state.disabled}
                                                                    accept="image/*"
                                                                  />

                                                                  <span
                                                                    class="btn-upload"
                                                                    style={{ padding: "7px 15px" }}
                                                                    {...(that.state.status === "Submitted"
                                                                      ? (that.state.disabled =
                                                                        "disabled")
                                                                      : (that.state.disabled = ""))}
                                                                    disabled={that.state.disabled}
                                                                  >
                                                                    Upload
                                                                  </span>
                                                                  <input
                                                                    id={item.questionId}
                                                                    type="file"
                                                                    name="proof-data"
                                                                    class="input-hidden"
                                                                    {...(that.state.status === "Submitted"
                                                                      ? (that.state.disabled =
                                                                        "disabled")
                                                                      : (that.state.disabled = ""))}
                                                                    disabled={that.state.disabled}
                                                                    {...that.state.arrid8.push(
                                                                      item.questionId
                                                                    )}
                                                                    onChange={that.handleFileSelect.bind(
                                                                      that,
                                                                      item.questionId
                                                                    )}
                                                                    capture="camera"
                                                                    accept="image/*"
                                                                    multiple="multiple"
                                                                  />

                                                                  <div class="clearfix"></div>

                                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
                                                                    <a onClick={that.openPrf.bind(that, item.questionId, yitem.equipmentNum)}>View file</a>
                                                                  </div>

                                                                  <div class="clearfix"></div>
                                                                </div>
                                                              ) : (
                                                                <h5>Use Mobile Browser to Upload</h5>
                                                              )}
                                                            </div>
                                                            <div class="clearfix"></div>
                                                          </div>
                                                        </li>
                                                      );
                                                      break;

                                                    default:
                                                      showSuccess("Error Displaying Preview.")
                                                      break;
                                                  }
                                                })}</AccordionItemPanel>
                                            </div></AccordionItem></Accordion>)
                                    })}</AccordionItemPanel>
                                </div></AccordionItem>
                            </Accordion>
                          )
                        })
                        ) : (
                          null)

                      }</ul>
                    <div class="text-center mt">



                      {this.state.data.length > 0 ?
                        <button className="btn btn-primary min-wid-90 ml-10"
                          {...that.state.buttondis === "Submitted" ?
                            that.state.disabled = "disabled"
                            :
                            that.state.disabled = ""
                          }
                          disabled={that.state.disabled}
                          onClick={that.newsavequestion.bind(that)} id="savebtn" >
                          {/* onClick={that.savequestion.bind(that)} id="savebtn" > */}
                          Submit
                        </button> : ''}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  }



  fullstop = (e) => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.props.location.state.data,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  fetchQuestionPaper = () => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== "") {
      this.setState({
        loading: true,
        taskId: this.props.location.state.tskId,
        userId: this.props.location.state.userid,
        storeNo: this.props.location.state.storeNo,
        usrnm: this.props.location.state.usrnm,
        tskName: this.props.location.state.tskName,
        tskDescr: this.props.location.state.tskDescr,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        guId: this.props.location.state.guId,
        role_id: this.props.location.state.role_id

      })
      this.state.ansobj.taskId = this.props.location.state.tskId
      this.state.ansobj.userId = this.props.location.state.userid

    }
    else {

      this.props.history.push({
        pathname: '/',
      });

    }
  }

  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  fetchFullPaper = (params) => {
    if (params === "preview") {
      var Request = {
        "taskId": this.props.location.state.tskId,
        "storeNo": this.props.location.state.storeNo,
        "userId": this.props.location.state.userid,
        "guId": this.props.location.state.guId,
      }
      this.setState({ data: '' })
      this.setState({ categoryName: [] })
      this.getTechDetail(Request)

    }
  }


  getTechDetail = (Request) => {
    var EncryptedRequest = Encrypt(Request);
    fetch('/GetTaskPreview', {
      method: 'POST',
      body: EncryptedRequest,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),



      }

    })
      .then((response) => response.text())
      .then((response) => {

        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode == '200 OK') {
          console.log(`response of fetchQuestionPaper `, DecryptedResponse.categoryDetails)
          for (let s = 0; s < DecryptedResponse.categoryDetails.length; s++) {
            var query = {
              categoryName: "",
              equipCategId: "",
              listQuestions: []
            }
            query.categoryName = DecryptedResponse.categoryDetails[s].categoryName
            query.equipCategId = DecryptedResponse.categoryDetails[s].categoryId

            var totln = Object.entries(DecryptedResponse.categoryDetails[s].equpTaskQuestMap).length;
            console.log(`length`, totln)
            for (let r = 0; r < totln; r++) {

              var newRequest = {
                equipmentNum: "",
                newQuest: []
              }
              newRequest.equipmentNum = Object.entries(DecryptedResponse.categoryDetails[s].equpTaskQuestMap)[r][0]
              newRequest.newQuest.push(Object.entries(DecryptedResponse.categoryDetails[s].equpTaskQuestMap)[r][1])
              query.listQuestions.push(newRequest)


            }

            this.state.categoryName.push(query)

          }
          console.log(`hello karn`, this.state.categoryName)
          this.setState({ getbuttondisable: true })


          // if (DecryptedResponse.taskDetails.feedback !== "" && DecryptedResponse.taskDetails.feedback != undefined) {
          //   var questionFeedbackList = ["Quality & Effectiveness of Soft Services (HK and PC)", "Quality of Repair and Maintenance of FM equipment's at the Stores / Sites (MEPC and CAMC)",
          //     "FM team displays a sense of urgency in addressing issues and faults", "Accessibility & availability of FM team members in emergencies",
          //     "Effectiveness of Store Connect /FM Self Audit Programs in Store/Supplying Sites", "How would you rate the overall performance of FM services?", "Comment"]

          //   var answerfeedback = []

          //   answerfeedback = DecryptedResponse.taskDetails.feedback.split(',')
          //   answerfeedback.push(DecryptedResponse.taskDetails.managerComment)

          //   for (let y = 0; y < questionFeedbackList.length; y++) {
          //     var feedbacksurvey = {
          //       surveyQuest: "",
          //       surveyanswer: ""
          //     }
          //     feedbacksurvey.surveyQuest = questionFeedbackList[y]
          //     feedbacksurvey.surveyanswer = answerfeedback[y]
          //     this.state.Question.push(feedbacksurvey)
          //   }
          // }
          this.setState({ euipcatidentity: DecryptedResponse.equipcategoryID })
          this.setState({ data: this.state.categoryName });

          this.setState({ status: DecryptedResponse.taskDetails.status });
          this.setState({ instReadFlag: DecryptedResponse.taskDetails.instReadFlag })
          if (DecryptedResponse.equipcategoryID[0] != "") {
            this.setState({ equipmentrequest: DecryptedResponse.equipcategoryID })
          }

          var that = this
          for (let i = 0; i < that.state.categoryName.length; i++) {
            for (let mdidi = 0; mdidi < that.state.categoryName[i].listQuestions[0].newQuest[0].length; mdidi++) {
              if (that.state.categoryName[i].listQuestions[0].newQuest[0][mdidi].isMandatory === "1") {
                this.state.mandid.push(that.state.categoryName[i].listQuestions[0].newQuest[0][mdidi].questionId)
              }
            }
          }
          console.log(`hello mandid`, this.state.mandid)



          for (let y = 0; y < DecryptedResponse.equipcategoryID.length; y++) {
            for (let j = 0; j < that.state.categoryName.length; j++) {
              for (let d = 0; d < that.state.categoryName[j].listQuestions[0].newQuest[0].length; d++) {
                if (DecryptedResponse.equipcategoryID[y] == that.state.categoryName[j].listQuestions[0].newQuest[0][d].equipCategId) {
                  this.state.questionidpush.push(that.state.categoryName[j].listQuestions[0].newQuest[0][d].questionId)
                }
              }
            }
          }
          this.setState({ smapprove: DecryptedResponse.taskDetails.managerApproval })
          if (this.state.smapprove !== "Approved" && this.state.smapprove === "Pending") {
            this.setState({ status: "Submitted" })
            this.setState({ buttondis: "Draft" })
          }
          else {
            this.setState({ buttondis: DecryptedResponse.taskDetails.status })
            this.setState({ status: "Submitted" })
          }

          if (DecryptedResponse.taskDetails.imageInstructionName != " ") {
            this.setState({ file: DecryptedResponse.taskDetails.imageInstructionName });
          }
          this.setState({ comment: DecryptedResponse.taskDetails.managerComment })
          this.setState({ afmcomment: DecryptedResponse.taskDetails.afmComment })
          this.state.radioObj = [];

          var newans = "";
          var isimgq = "false";
          if (DecryptedResponse.categoryDetails.length > 0) {
            for (let pair = 0; pair < that.state.categoryName.length; pair++) {
              for (let hrd = 0; hrd < that.state.categoryName[pair].listQuestions.length; hrd++) {
                for (let index = 0; index < that.state.categoryName[pair].listQuestions[hrd].newQuest[0].length; index++) {
                  var one = that.state.categoryName[pair].listQuestions[hrd].newQuest[0][index];
                  var two = that.state.categoryName[pair].listQuestions[hrd]
                  console.log(`find `, two)
                  this.state.quesid.push(one.questionId);
                  if (one.questionType === "8" || one.questionType === "11") {
                    if (one.imageArray === "") {
                      this.state.fileuploaded = [];
                      this.setState({ fileval: "Upload file" })

                    }
                    else {
                      this.state.fileuploaded.push(one.questionId + one.imageArray);
                      this.setState({ fileval: "One file attached" });
                      isimgq = "true";
                    }

                    if (isimgq === "false") {
                      this.state.fileuploaded = [];
                      this.setState({ fileval: "Upload file" })
                    }
                    if (one.imageArray === "") {
                      this.setState({ fileval: "Upload file" })
                    } else {
                      if (one.imageArray === "" || one.imageArray === null || one.imageArray === undefined) {
                        newans = {
                          taskId: this.props.location.state.tskId,
                          userId: this.props.location.state.userid,
                          questionId: one.questionId,
                          imageArray: "",
                          equipmentNo: two.equipmentNum
                        };
                      } else {
                        newans = {
                          taskId: this.props.location.state.tskId,
                          userId: this.props.location.state.userid,
                          questionId: one.questionId,
                          imageArray: one.imageArray,
                          equipmentNo: two.equipmentNum
                        };
                      }
                      this.state.radioObj.push(newans);
                      console.log(`ans1`, this.state.radioObj)
                    }
                  }
                  else {
                    if (one.answer === "" || one.answer === null || one.answer === undefined) {


                      newans = {
                        taskId: this.props.location.state.tskId,
                        userId: this.props.location.state.userid,
                        questionId: one.questionId,
                        answer: '',
                        equipmentNo: two.equipmentNum
                      };
                    }
                    else {
                      newans = {
                        taskId: this.props.location.state.tskId,
                        userId: this.props.location.state.userid,
                        questionId: one.questionId,
                        answer: one.answer,
                        equipmentNo: two.equipmentNum
                      };
                    }
                    this.state.radioObj.push(newans);
                    console.log(`ans1`, this.state.radioObj)

                  }
                }
              }
            }
            document.getElementById("accordionopen").click();
          }

        }

        else {
          showAlert(DecryptedResponse.responseMsg)

          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        this.setState({ visiblity: false })

        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });
        // console.error(error);
      });
  }





  Astrik = (props) => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red" > *</font>;
    }
    return null;
  }


  newsavequestion = () => {
    if (this.state.fileval === "One file attached" || this.state.fileval === "File Upload successful") {
      this.state.nooffileques = this.state.nooffileques + 1;
    }



    var value = this.state.latitude
    if (this.state.latitude != "" && this.state.latitude != '' && this.state.latitude != undefined && this.state.latitude != null && value.includes(".")) {
      confirmAlert({
        message: 'Are you sure you want to submit?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {

              var Request2 = {
                "status": "Submitted",
                "storeNo": this.props.location.state.storeNo,
                // "userAnswerList": this.state.radioObj,
                "userFormatId": this.props.location.state.role_id,
                "equipcategoryID": this.state.equipmentrequest,
                "guId": this.props.location.state.guId,
                "latLong": this.state.latitude,
                "taskId": this.props.location.state.tskId,
                "userId": this.props.location.state.userid,
              }

              var EncryptedRequest2 = Encrypt(Request2);
              console.log(`save`, EncryptedRequest2)
              fetch('/TaskFinalSubmit', {
                method: 'POST',
                body: EncryptedRequest2,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


                }

              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode === '00') {
                    showSuccess("Answers has been submitted successfully.")

                    this.props.history.push({
                      pathname: '/Taskactive',
                      state: {
                        userid: this.props.location.state.userid,
                        usrnm: this.props.location.state.usrnm,
                        role_id: this.props.location.state.role_id,
                        data: this.props.location.state.data,
                        storeNo: this.props.location.state.storeNo,
                        guId: this.props.location.state.guId

                      }
                    }
                    );
                  }

                  else {
                    if (DecryptedResponse.equipList !== undefined || DecryptedResponse.equipList.length > 0) {
                      var newarrayt = [];
                      for (let equipist = 0; equipist < DecryptedResponse.equipList.length; equipist++) {
                        newarrayt.push(DecryptedResponse.equipList[equipist].equipmentNo)
                      }
                      this.setState({ showEquipArray: [...new Set(newarrayt)] })
                      showSuccess(DecryptedResponse.responseMsg + " " + "for Equipment Number :-" + " " + this.state.showEquipArray.toString())
                    }

                    else {
                      showAlert(DecryptedResponse.responseMsg)

                    }


                  }
                })
                .catch((error) => {

                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });

                });
            }
          },
          {
            label: 'No',
            onClick: () => {
              //  cleaned = null;

              this.state.nooffileques = 0;

              return false;
            }
          }
        ]
      });
    }
    else {
      showAlert("Unable to access your location.")

    }

  }

  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }
}
export default Preview;
