import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import { basicAuth } from './basicAuth';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { showSuccess } from './services/ConfirmAlert';


class QuestionPreviewFortemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      loaded: this.props.location.state,
      hasError: false   //Added by chandrani for custom error page



    };

    this.state = {
      TaskDropdwn: false,
      data: [],
      taskId: "",
      usrnm: "",
      role_id: "",
      quesid: [],
      ans: [],
      userid: "",
      tskId: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      loading: false,
      publish: true,
      publishStatus: "New",
      isMandatory: "",
      hasError: false,  //Added by chandrani for custom error page
      hammenu: true,
      categoryName: [],
      showaccordian: true,
      guId: ""
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }






  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }

  _onAction(e) {
    // console.log('user did something', e)
  }

  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }
  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }



  handleChange(param, event) {
    this.state.ans.push(event.currentTarget.value);
    this.state.quesid.push(param);


    confirmAlert({
      title: 'Alert',
      message: "quesid: " + param,
      buttons: [
        {
          label: 'Ok',
          // onClick: () => 
        },

      ]
    })


    confirmAlert({
      title: 'Alert',
      message: "ans: " + event.currentTarget.value,
      buttons: [
        {
          label: 'Ok',
          // onClick: () => 
        },

      ]
    })


  }


  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  componentWillMount() {
    this.fetchQuestionPaper()
  }


  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  EquipmentList =()=>{
    if(this.state.role_id=="505"||  this.state.role_id=="501" || this.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }

  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  render = () => {
    var that = this
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <div>
          <Spinner visible={this.state.loading}
            spinnerColor={"rgba(0, 0, 0, 0.3)"} />
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header class="header black-bg">
              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b><b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }


              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3><i className="fa fa-angle-right"></i>{this.state.tskName}</h3>
                      <p>Description : {this.state.tskDescr}</p>
                    </div>
                   <div class="clearfix"></div>
                    <hr />

                    <ul class="ques">
                      {this.state.data.map(function (xitem, xkey) {


                        return (
                          <Accordion allowZeroExpanded>
                            <AccordionItem  >
                              <div key={xkey}>

                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    <b id="accordionopen" style={{ textAlign: "center" }}>Category Name :{xitem.categoryName}({xitem.listQuestions.length})</b>
                                    <div style={{ "float": "right" }}>
                                      <input type="checkbox"
                                        disabled /><span style={{ "color": "red" }}><b>Not Applicable</b></span>
                                    </div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  {that.state.data[xkey].listQuestions.map(function (item, key) {
                                    switch (item.questiontype) {


                                      case "1":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              {item.options.map((optele, index) => (
                                                <span class="nameloc">
                                                  <input type="radio"
                                                    class="custom-control-input" id={item.questionId + index} name="MCS1"
                                                    value={optele}
                                                    onChange={that.handleChange.bind(that, item.questionId)}
                                                    disabled="disabled"
                                                  />
                                                  <label class="custom-control-label" for={item.questionId + index}>{optele}</label>
                                                </span>
                                              )
                                              )}
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "2":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0 ">
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              {item.options.map((optele) => (
                                                <span class="nameloc ">
                                                  <input type="checkbox" class="custom-control-input " id="customCheck-1" name="ques-2" disabled="disabled" />
                                                  <label class="custom-control-label" for="customCheck-1">{optele}</label>
                                                </span>
                                              ))}

                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "3":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0" >
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                <textarea type="text" disabled="disabled" class="form-control" placeholder="Some Text" />
                                              </div>
                                              <div class="clearfix">
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "4":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0" >
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                <input type="text" disabled="disabled" class="form-control" placeholder="Number Only" />
                                              </div>
                                              <div class="clearfix">
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "5":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0" >
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                <input type="email" disabled="disabled" class="form-control" placeholder="Email-ID" />
                                              </div>
                                              <div class="clearfix">
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "6":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <span class="mlr-10">
                                                <input type="radio" class="custom-control-input" id="customRadio1-11" name="ques-1" disabled="disabled" />
                                                <label class="custom-control-label" for="customRadio1-11">Yes</label>
                                              </span> <span>
                                                <input type="radio" class="custom-control-input" id="customRadio2-22" name="ques-1" disabled="disabled" />
                                                <label class="custom-control-label" for="customRadio2-22">No</label>
                                              </span> </div>
                                          </li>
                                        );
                                        break;
                                      case "7":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <span class="mlr-10">
                                                <input type="radio" class="custom-control-input" id="customRadio1-1" name="ques-1" disabled="disabled" />
                                                <label class="custom-control-label" for="customRadio1-1">Yes</label>
                                              </span> <span>
                                                <input type="radio" class="custom-control-input" id="customRadio2-2" name="ques-1" disabled="disabled" />
                                                <label class="custom-control-label" for="customRadio2-2">No</label>
                                              </span> <span>
                                                <input type="radio" class="custom-control-input" id="customRadio3-3" name="ques-1" disabled="disabled" />
                                                <label class="custom-control-label" for="customRadio3-3">NA</label>
                                              </span> </div>
                                          </li>
                                        );
                                        break;
                                      case "8":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">
                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                              <div class="clearfix">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                  <input id="store-list" type="text" class="form-control input-upload" value="Upload Image" disabled="disabled" />
                                                  <span class="btn-upload" style={{ padding: "7px 15px" }}>Upload</span>
                                                  <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" disabled="disabled" />
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "9":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">

                                              <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>

                                              {item.options.map((optele, index) => (
                                                <span className="pl-0 ml-0">

                                                  <p for={item.questionId + index}><b>{optele}</b></p>
                                                  <input type="text" disabled="disabled" class="form-control col-lg-1 col-md-1 col-sm-1 col-xs-4 pl-0  " />

                                                </span>
                                              )
                                              )}
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "10":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0" >
                                              <p>{item.questionText} <that.Astrik isMandatory={item.isMandatory} /></p>
                                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                <input type="date" disabled="disabled" class="form-control" />
                                              </div>
                                              <div class="clearfix">
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;
                                      case "11":
                                        return (
                                          <li key={key}>
                                            <div class="custom-selection ml-0 pl-0">
                                              <p>
                                                {item.questionText}
                                                <that.Astrik
                                                  isMandatory={item.isMandatory}
                                                />
                                              </p>
                                              <div class="clearfix">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                                  <input
                                                    id="store-list"
                                                    type="text"
                                                    class="form-control input-upload"
                                                    value="Upload Live Image"
                                                    disabled="disabled"
                                                  />
                                                  <span
                                                    class="btn-upload"
                                                    style={{ padding: "7px 15px" }}
                                                  >
                                                    Upload
                                        </span>
                                                  <input
                                                    id="upload-storedata"
                                                    type="file"
                                                    name="Store-list-data"
                                                    class="input-hidden"
                                                    disabled="disabled"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                        break;

                                      default: confirmAlert({
                                        title: 'Alert !',
                                        message: "Error displaying preview.",
                                        buttons: [
                                          {
                                            label: 'Ok',
                                            // onClick: () => 
                                          },

                                        ]
                                      })
                                        break;
                                    }
                                  })}</AccordionItemPanel>
                              </div>

                            </AccordionItem>
                          </Accordion>
                        )
                      })}
                    </ul>
                    <div class="clearfix"></div>
                    <div class="text-center mt">
                      <a onClick={this.addquestionbind.bind(this)} class="btn btn-primary min-wid-90 ">Back</a>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>

      );
    }
  }


  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Astrik = (props) => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red" > *</font>;
    }
    return null;
  }


  fetchQuestionPaper = () => {
    this.setState({ loading: true })
    this.setState({ showaccordian: true })
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      if (this.props.location.state) {
        this.setState({
          role_id: this.props.location.state.role_id,
          usrnm: this.props.location.state.usrnm,
          userid: this.props.location.state.userid,
          tskId: this.props.location.state.tskId,
          tskName: this.props.location.state.tskName,
          tskDescr: this.props.location.state.tskDescr,
          strtDate: this.props.location.state.strtDate,
          edDate: this.props.location.state.edDate,
          publishStatus: this.props.location.state.publishStatus,
          isMandatory: this.props.location.state.check,
          guId: this.props.location.state.guId

        });


        var Request = {
          "taskId": this.props.location.state.tskId,
          "guId": this.props.location.state.guId,
        }
        var EncryptedRequest = Encrypt(Request);
        fetch('/FetchQuestionsTempByTask', {
          method: 'POST',
          body: EncryptedRequest,
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)
            if (DecryptedResponse.responseCode === '200 OK') {
              var category = []
              for (let k = 0; k < DecryptedResponse.listQuestions.length; k++) {
                category.push(DecryptedResponse.listQuestions[k].categoryName)
              }

              var item = [...new Set(category)]
              for (let s = 0; s < item.length; s++) {
                var query = {
                  categoryName: "",
                  listQuestions: []
                }
                query.categoryName = item[s]
                for (let g = 0; g < DecryptedResponse.listQuestions.length; g++) {
                  if (DecryptedResponse.listQuestions[g].categoryName === item[s]) {
                    query.listQuestions.push(DecryptedResponse.listQuestions[g])

                  }

                }
                this.state.categoryName.push(query)


              }




              this.setState({ data: this.state.categoryName });
              this.setState({ loading: false })
            }

            else {

            }
          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });
          });
      }
      else {
        this.props.history.push({
          pathname: '/',
        });
      }
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  addquestionbind = () => {
    this.props.history.push({
      pathname: '/Addtemplate',
      state: {
        role_id: this.state.role_id,
        userid: this.state.userid,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        tskDescr: this.state.tskDescr,
        strtDate: this.state.strtDate,
        edDate: this.state.edDate,
        usrnm: this.state.usrnm,
        publishStatus: this.state.publishStatus,
        guId: this.props.location.state.guId
      }
    }
    );

  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

}
export default QuestionPreviewFortemplate;