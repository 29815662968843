import React, { Component } from 'react';
import './css/style.css';
import './css/style-responsive.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import readXlsxFile from 'read-excel-file';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import config from 'react-global-configuration';
import { showSuccess, showAlert } from './services/ConfirmAlert';




class UserCreation extends Component {
  state = {
    value: "",
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    formatId: "",
    formerMasterList: [],
    roleMasterList: [],
    rformatlist: [],
    rformatlisted: [],
    rformatId: [],
    rrole: [],
    lstformat: [],
    formatDesc: [],
    formarMasterList: [],
    roleMasterList1: [],
    storeList: [],
    values: '',
    mobile: '',
    hasError: false, //Added by chandrani for custom error page
    userid: "",
    guId: "",
    showAction: false,
    countforexist: 0,
    countfornonexist: 0,
    deccountforexist: 0,
    deccountfornonexist: 0,
    catTypeID: [],
    taskTypeOpt: [],
    taskTypeId: []
  }

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      username: "",
      valueid: [],
      role_id: [],
      firstName: "",
      FileName: "",
      middleName: "",
      lastName: "",
      StoreList: [],
      email: "",
      formatId: null,
      userId: "",
      formerMasterList: [],
      roleMasterList: [],
      rformatlist: [],
      rformatlisted: [],
      rformatId: [],
      rrole: [],
      rlistid: [],
      lstformat: [],
      storeList: [],
      displayMenu: false,
      values: '',
      mobile: '',
      hasError: false,  //Added by chandrani for custom error page
      userid: "",
      hammenu: true,
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      mobNo: "",
      guId: "",
      userList: [],
      showAction: false,
      countforexist: 0,
      countfornonexist: 0,
      deccountforexist: 0,
      deccountfornonexist: 0,
      catTypeID: [],
      taskTypeOpt: [],
      taskTypeId: []
    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {
          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }
  handleSetList([]) {
    this.setState({
      formarMasterList: []
    });
  }


  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }


  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }


  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.roleId,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  UserchangeId = (e) => {

    const re = /^[a-zA-Z0-9]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ values: e.target.value })
    }
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }
  MobilechangeId = (e) => {

    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value })
    }
  }

  formatSelection = (e) => {
    this.setState({ value: e.target.value });

    let flag = [];

    for (var i = 0; i < this.state.rformatlist.length; i++) {

      this.state.rformatlist[i] === e.target.value ?
        flag.push(this.state.rformatId[i]) : this.setState({ valueid: "" });
    }
    this.setState({ valueid: flag });
  }

  fileHandler = (event) => {
    this.setState({ userList: [] });
    const input = document.getElementById('upload-storedata')

    readXlsxFile(input.files[0]).then((rows, files) => {

      var storedata = [];
      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i])
        for (let j = 0; j < storedata.length; j++) {
          var store =
          {

            userId: "",
            email: "",
            firstName: "",
            middleName: "",
            lastName: "",
          }
          store.userId = storedata[j][0]
          store.email = storedata[j][1]
          store.firstName = storedata[j][2]
          store.middleName = storedata[j][3]
          store.lastName = storedata[j][4]

        }
        {
          this.state.userList.push(store)
        }
      }

      this.setState({

        FileName: 'File Uploaded Successfully',
        showAction: true
      });
    })

  }

  componentWillMount() {
    this.GetFormatMaster()
  }


  componentDidCatch() {
    this.setState({ hasError: true })
  }
  render = () => {
    var that = this;
    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <Router>
          <div >
            <div >

              <section id="container">
                <header class="header black-bg">
                  <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

                  <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>

                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" />
                      <b>{this.state.usrnm}</b>
                      <b className="caret" /></a>
                    </li>

                    {this.state.displayMenu ?
                      (

                        <ul className="dropdown-menuuser-dd">
                          <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                          <li className="divider"></li>
                          <li><a onClick={this.logout} >Log Out</a></li>
                        </ul>
                      ) :
                      (
                        null
                      )
                    }
                  </ul>


                </header>
                <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">

                      <h3><i class="fa fa-angle-right"></i>{this.state.header}</h3>
                      <hr />
                      <div class="row mt">
                        {this.state.header == 'User Creation' ?

                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">


                            <label>Upload File</label>
                            <div class="upload-holder">

                              <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />

                              <span class="btn-upload" style={{ padding: "7px 25px" }}>Upload</span>
                              <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />


                            </div>
                            <span class="help-block">Sample File <a href={require("./doc/UserCreation.xlsx")}>UserCreation.xls</a></span>
                          </div>

                          : null}

                        {this.state.header == 'User Creation' && this.state.showAction ?
                          <>
                            <div class="col-lg-2 col-md-4 col-sm-5 col-xs-12">
                              <label>Select User Type</label><span class="required-mark"  >*</span>
                              <br />


                              <select class="form-control" id="SelQType1"
                                onChange={this.formatSelection.bind(this)} >
                                <option>Select</option>
                                {
                                  this.state.formerMasterList.map(function (item, key) {
                                    return (<option  >{item.roleDescription}</option>)
                                  })
                                }
                              </select>
                            </div>
                          </>
                          : null}




                        {this.state.header != 'User Creation' ?
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                            <label>User Id</label><span class="required-mark">*</span>
                            <input type="text" class="form-control" placeholder="Enter UserId" input value={this.state.values} id="UserId" onChange={this.UserchangeId} maxlength="8" autoComplete="off" disabled></input>
                            <br />
                          </div>
                          : null}
                        {this.state.header != 'User Creation' ?
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                            <label>Email ID</label><span class="required-mark">*</span>
                            <input type="email" class="form-control" value={this.state.email} placeholder="Enter EmailId" id="Admin_EmailId" maxlength="40" autoComplete="off" onChange={(f) => this.setState({ email: f.target.value })} ></input>
                            <br />
                          </div> : null}


                        <div class="clearfix"></div>
                        {this.state.header != 'User Creation' ?
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>First Name</label><span class="required-mark">*</span>
                            <input type="FirstName" class="form-control" placeholder="Enter First Name" id="FirstName" maxlength="30" autoComplete="off" value={this.state.firstName} onChange={(f) => this.setState({ firstName: f.target.value })}></input><br />
                          </div> : null}
                        {this.state.header != 'User Creation' ?
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <label>Middle Name</label>
                            <input type="MiddleName" class="form-control" placeholder="Enter Middle Name" id="MiddleName" maxlength="30" autoComplete="off" value={this.state.middleName} onChange={(f) => this.setState({ middleName: f.target.value })}></input>
                            <br />
                          </div> : null}

                        {this.state.header != 'User Creation' ?
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                              <label>Last Name</label><span class="required-mark">*</span>
                              <br />
                              <input type="LastName" class="form-control" placeholder="Enter Last Name" id="LastName" maxlength="30" autoComplete="off" value={this.state.lastName} onChange={(f) => this.setState({ lastName: f.target.value })}></input>
                            </div>
                            <div>
                              <br />

                            </div>
                          </div>
                          : null}
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <div class="text-center mt ">
                            {this.state.header == 'Update User' ?
                              <button class="btn btn-primary min-wid-90 " onClick={this.UpdateUser.bind(this)}  >Update</button>
                              : this.state.showAction ?
                                <button class="btn btn-primary min-wid-90 " onClick={this.CreateHOUser.bind(this)} >Submit</button>
                                : null}
                            <button class="btn btn-primary min-wid-90 ml-20 "
                              onClick={this.userlist.bind(this)}
                            >Back</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          </div>
        </Router>
      );
    }
  }

  Taskactive() {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.roleId,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }


  EquipmentList = () => {
    if (this.props.location.state.role_id == "505" || this.props.location.state.role_id == "501" || this.props.location.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  UpdateUser = (e) => {
    var pattern = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\_\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    var re = /^[a-zA-Z]+$/;

    var UserId = document.getElementById("UserId").value
    var Admin_EmailId = document.getElementById("Admin_EmailId").value;
    var FirstName = document.getElementById("FirstName").value;
    var MiddleName = document.getElementById("MiddleName").value;
    var LastName = document.getElementById("LastName").value;


    if (!pattern.test(Admin_EmailId)) {
      showSuccess("Please enter valid email.")
      return false;
    }



    else if (!document.getElementById("FirstName").value || !document.getElementById("LastName").value || !document.getElementById("Admin_EmailId").value || !document.getElementById("UserId").value) {
      showSuccess("Please enter required fields")

    }


    else if (!re.test(FirstName) || !re.test(LastName)) {
      showSuccess("Special characters & numbers are not allowed.")
    }

    else {
      confirmAlert({
        message: "Are you sure, you want to submit?.",
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Request1 = {
                "userId": document.getElementById("UserId").value,
                "firstName": document.getElementById("FirstName").value,
                "middleName": document.getElementById("MiddleName").value,
                "lastName": document.getElementById("LastName").value,
                "email": document.getElementById("Admin_EmailId").value,
                "guId": this.props.location.state.guId,


              }
              var EncryptedRequest1 = Encrypt(Request1);
              fetch('/UpdateUser', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                }

              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode === '200 OK') {
                    confirmAlert({
                      message: 'User details updated successfully',
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.userlist() }
                        },

                      ]
                    })
                    this.setState({ userId: DecryptedResponse.userId });


                  }
                  else {
                    showAlert(DecryptedResponse.responseMsg)

                    this.setState({ loading: false })
                  }

                })
                .catch((error) => {
                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });

                });

            }
          },
          {
            label: 'No',
            onClick: () => { return (false); }
          }

        ]
      })




    }
  }








  CreateHOUser = (e) => {


    if (document.getElementById("SelQType1").value == "Select") {
      showAlert("Please select format type.")
    }
    else {
      var that = this;
      let isValid = true;
      this.state.userList.map(function (item, key) {
        if (
          item.userId == undefined ||
          item.email == undefined ||
          item.firstName == undefined ||
          item.lastName == undefined

        ) {
          isValid = false;
          showAlert("Please upload the correct excel")
        }
        else {

          isValid = true;
        }
      })
      var request;
      if (isValid) {
          request = {
            "userList": that.state.userList,
            "roleId": that.state.valueid[0],
            "guId": that.props.location.state.guId
          }
        var EncryptedRequest = Encrypt(request);

        fetch('/CreateUser', {
          method: 'POST',
          body: EncryptedRequest,
          headers: {
            guId: that.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(that.props.location.state.userid),
          }
        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)

            if (DecryptedResponse.responseCode === "200 OK"
            ) {
              confirmAlert({
                title: 'Success',
                message: "Users added successfully"
                  + " " +
                  (DecryptedResponse.existUserList.length > 0 ? "Following user already exist:" + DecryptedResponse.existUserList : "") + " " +
                  (DecryptedResponse.errorUserList.length > 0 ? "Following user does not exist:" + DecryptedResponse.errorUserList : "")
                ,
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => { window.location.reload(true) }
                  },

                ]
              })
            }
            else {
              confirmAlert({

                message: DecryptedResponse.responseMsg
                  + " " +
                  (DecryptedResponse.existUserList.length > 0 ? "Following user already exist:" + DecryptedResponse.existUserList : "") + " " +
                  (DecryptedResponse.errorUserList.length > 0 ? "Following user does not exist:" + DecryptedResponse.errorUserList : "")
                ,
                buttons: [
                  {
                    label: 'Ok',

                  },

                ]
              })



            }

          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });

          });
      }




    }



  }
  GetFormatMaster = (e) => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {

      this.setState({ userid: this.props.location.state.userid })
      this.setState({ usrnm: this.props.location.state.usrnm })
      this.setState({ roleId: this.props.location.state.role_id })
      this.setState({ header: this.props.location.state.header })
      this.setState({ guId: this.props.location.state.guId })
      this.setState({ role_id: this.props.location.state.role_id })

      var Request1 = {
        "guid": this.props.location.state.guId,
        "userId": this.props.location.state.userid
      }
      var EncryptedRequest1 = Encrypt(Request1);

      fetch('/GetTaskTypeCategory', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)




          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ catTypeID: DecryptedResponse.TaskCategoryDetails });
            let tcat = [];
            let tcat1 = [];
            for (var p = 0; p < DecryptedResponse.TaskCategoryDetails.length; p++) {
              tcat.push(DecryptedResponse.TaskCategoryDetails[p].categoryName)
              tcat1.push(DecryptedResponse.TaskCategoryDetails[p].taskCatagoryId)
            }
            this.setState({ taskTypeOpt: tcat });
            this.setState({ taskTypeId: tcat1 });

          }
          else {
            showSuccess(DecryptedResponse.errorMsg)

          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });

      fetch('/GetRolesDesc', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.getRoleDescList != undefined) {
            this.setState({ formerMasterList: DecryptedResponse.getRoleDescList });
            if (this.state.roleId == "501") {
              for (let k = 0; k < this.state.formerMasterList.length; k++) {
                if (this.state.formerMasterList[k].roleId == "501") {
                  this.state.formerMasterList.splice(k, 1)
                }
              }
            }
            this.setState({ roleMasterList: DecryptedResponse.roleMasterList });


            let list = [];
            let list1 = [];
            let list2 = [];
            let list3 = [];


            for (var i = 0; i < DecryptedResponse.getRoleDescList.length; i++) {

              if (this.state.roleId == "501") {
                if (DecryptedResponse.getRoleDescList[i].roleId != "501") {
                  list.push(DecryptedResponse.getRoleDescList[i].roleDescription);
                  list1.push(DecryptedResponse.getRoleDescList[i].roleId);
                }

              }

              else {
                list.push(DecryptedResponse.getRoleDescList[i].roleDescription);
                list1.push(DecryptedResponse.getRoleDescList[i].roleId);
              }
            }
            this.setState({ rformatlist: list });
            this.setState({ rformatId: list1 });


          }
          else {
            showAlert(DecryptedResponse.responseMsg)

          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });


      if (this.props.location.state.header == 'Update User') {
        this.setState({ values: this.props.location.state.values })
        var Request1 = {
          "userId": this.props.location.state.values,
          "guId": this.props.location.state.guId,
        }
        var EncryptedRequest1 = Encrypt(Request1);
        fetch('/GetUser', {
          method: 'POST',
          body: EncryptedRequest1,
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse1 = decryptData(response)
            if (DecryptedResponse1.responseCode === '200 OK') {

              this.setState({ userid: DecryptedResponse1.userId });
              this.setState({ email: DecryptedResponse1.email });
              this.setState({ firstName: DecryptedResponse1.firstName });
              this.setState({ middleName: DecryptedResponse1.middleName });
              this.setState({ lastName: DecryptedResponse1.lastName });
              this.setState({ loading: false })

            }
            else {
              this.setState({ loading: false })
              showSuccess(DecryptedResponse1.responseMsg)
            }
          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });

          });
      }
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }


  email = (e) => {
    e.preventDefault();

  }

  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        // technicianId:this.props.location.state.technicianId

      }
    }
    );
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }



  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId,

      }
    }
    );
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );

  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId,
      }
    }
    );
  }




  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

}

export default withRouter(UserCreation);

