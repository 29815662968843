
import 'jquery/dist/jquery';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { withRouter } from "react-router-dom";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import { basicAuth } from './basicAuth';
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { showSuccess } from './services/ConfirmAlert';

class Barcode
    extends Component {
    state = {
        TaskDropdwn: false,
        hasError: false   //Added by chandrani for custom error page
    }
    showTaskDropdwnHandler = () => {
        this.setState({
            TaskDropdwn: !(this.state.TaskDropdwn)
        })
    }

    componentDidMount() {
        if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
    }



    constructor(props) {
        super(props);
        this.state = {
            displayMenu: false,
            hasError: false   //Added by chandrani for custom error page
        };
        this.state = {
            startDate: "",
            endDate: "",
            resptaskname: [],
            respstartdate: [],
            respenddate: [],
            resptaskdesc: [],
            data: [],
            usrnm: "",
            userid: "",
            Search: "",
            searchSDate: "",
            searchedDate: "",
            searcheddesc: "",
            tskId: "",
            tskName: "",
            loading: false,
            hasError: false,   //Added by chandrani for custom error page
            hammenu: true,
            storeName: "",
            storeNo: "",
            technicianId: "",
            role_id: "",
            guId: "",
            categoryName: "",
            categoryId: "",
            getBarcodeData: 'No data'

        };

    }


  

    onChange1 = e => {
        this.setState({ searcheddesc: e.target.value });
    }

   


    componentDidCatch() {
        this.setState({ hasError: true })
    }


    GetTasksOnload(e) {
        this.setState({ loading: true })
        if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
            this.setState({
                usrnm: this.props.location.state.usrnm ,
                userid: this.props.location.state.userid,
                tskId: this.props.location.state.tskId,
                tskName: this.props.location.state.tskName,
                role_id: this.props.location.state.role_id,
                guId: this.props.location.state.guId,
                categoryName: this.props.location.state.categoryName,
                categoryId: this.props.location.state.categoryId
            })


            var Request1 = {
                "equipmentNo": document.getElementById("equipNum").value,
                "storeNo": this.props.location.state.storeNo,
                "guId": this.props.location.state.guId,
            }
            var EncryptedRequest1 = Encrypt(Request1);
            fetch('/GetStoreEquipmentDetails', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                }

            })
                .then((response) => response.text())
                .then((response) => {
                    var DecryptedResponse1 = decryptData(response)
                    if (DecryptedResponse1.responseCode === '200 OK') {
                        this.setState({ data: DecryptedResponse1.equipmentDetails });
                        this.setState({ loading: false   })

                    }
                    else {
                        this.setState({ loading: false })
                        showSuccess(DecryptedResponse1.responseMsg)
                    }
                })
                .catch((error) => {
                    confirmAlert({
                        title: 'Alert !',
                        message: "Session expired",
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => { this.logoutnormal() }
                            },

                        ]


                    });

                });
        }
        else {
            this.props.history.push({
                pathname: '/',
            });
        }
    }






    render = () => {
        if (this.state.hasError) {
            return (
                <div><h2>Error occurred!! please contact administrator</h2></div>
            )
        } else {


            return (
                <div>
                <section id="container">
                  <section id="main-content">
                            <section className="wrapper">
                                <div className="mt">
                                    <div className="row mt">
                                        
                                        <div className="col-md-12">
                                            
                                                    <BarcodeScannerComponent
                                                        width={"100%"}
                                                        height={500}
                                                        onUpdate={(err, result) => {
                                                            console.log(`result`, result)

                                                            if (result)
                                                               { this.setState({ getBarcodeData: result.text })
                                                                this.props.onClose(result.text)}
                                                            else{
                                                                this.setState({ getBarcodeData: 'no data' })
                                                            }

                                                        }}

                                                    />
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </section>
                    </section>

                </div>
            );
        }
    }













}
export default withRouter(Barcode);

