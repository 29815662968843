import axios from 'axios';
class Service {

  getRestClient(guId,basic) {
   
      this.serviceInstance =  axios.create({
        baseURL: '/InsertInstructions',
        timeout: 50000,
        headers: {
            'Content-Type': 'text/plain',
            guId:guId,
           'Authorization': basic
          },
      });
    return this.serviceInstance;
  }
}

export default (new Service());