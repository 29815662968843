import $ from "jquery";
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import config from 'react-global-configuration';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { basicAuth } from './basicAuth';
import { showSuccess } from "./services/ConfirmAlert";

class Login extends Component {
  handleKeyPress(target) {
    if (target.charCode == 13) { document.getElementById("loginbtn").click() }

  }
  submit = () => {
   
    confirmAlert({
      message: 'Please enter required fields.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => { this.login.bind(this) }
         
        },

      ]
    })
     };
  constructor(props) {
    super(props);

    this.state = {
      userName: null,
      formatDesc: null,
      roleId: null,
      success: false,
      responseJson: null,
      usrname:"",
      password:"" ,
      hasError: false,   //Added by chandrani for custom error page
      guId:"",
      browserSessionId:"",
    };
    this.state = { value: '' };
    this.onChange = this.onChange.bind(this)
  }

  state = {
    userName: null,
    formatDesc: null,
    roleId: null,
    success: false,
    responseJson: null,
    hasError: false,   //Added by chandrani for custom error page
    guId:"",
    browserSessionId:"",
  }


  onChange(e) {

    const re = /^[a-zA-Z0-9]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ value: e.target.value })
    }

  }
  
  //Added by chandrani for custom error page
  componentDidCatch(){
    this.setState({hasError : true})
  }

  componentDidMount(){
    this.GenerateCaptcha()
  }

  render = () => {
    //Added by chandrani for custom error page
    if(this.state.hasError){
      return(
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    }else{
    return (
      <Router>
        <div id="login-page" className="back-image" style={{ height: "100vh" }}>
      
          <div className="container">
            <form className="form-login animate" action="index.html" autocomplete="off">
              <h2 className="form-login-heading">sign in now</h2>
              <div className="login-wrap">
                <label>User ID</label>
                <input ref="usrname" type="text" input value={this.state.value} id="userLogin" onChange={this.onChange} className="form-control" placeholder="User ID" maxLength="18" autofocus="true" onKeyPress={this.handleKeyPress}
                  readonly
                  onfocus="this.removeAttribute('readonly');" autoComplete="off"/>
                <br />
                <label>Password</label>
                <input ref="password"  type="password" className="form-control" placeholder="Password" id="userPassword" maxLength="20" onKeyPress={this.handleKeyPress} readonly
                  onfocus="this.removeAttribute('readonly');" autoComplete="off" />
                <br />

                <div class="row">
                    <div class="col-xs-4">
                      <label className="ml-5 d-inline m-1 float-left">
                        Captcha
                      </label>
                    </div>
                    <div className="col-xs-4">
                      <output
                        readOnly
                        style={{ marginLeft: "22px" }}
                        position="center"
                        id="txtCaptcha"
                        onClick={this.OnCopyFunction.bind(this)}
                        className="ml-5 d-inline m-1 float-center"
                      />
                    </div>
                    <div className="col-xs-4 float-sm-right">
                      <a
                        onClick={this.GenerateCaptcha.bind(this)}
                        id="btnrefresh"
                      >
                        <div className="col-mr-0">
                          <img
                            style={{ height: "30px", marginLeft: "40px" }}
                            src={require("./img/refresh.png")}
                          ></img>
                        </div>
                      </a>
                    </div>
                  </div>

                  <br />
                  <input
                    ref="captcha"
                    type="text"
                    className="form-control"
                    id="txtCompare"
                    placeholder="Captcha"
                    onKeyPress={this.handleKeyPress}
                    onClick={this.OnCopyFunction.bind(this)}
                  />
                  <br />




                <a className="btn btn-theme btn-block" onClick={this.login.bind(this)} id="loginbtn" ><i className="fa fa-lock mr-10"/>SIGN IN</a>
               
                  <div style={{"text-align":"end"}}>
                  <a
                 onClick={this.forgotPass.bind(this)}
                  id="loginbtn" >Forgot Password ? </a>
                </div>


              </div>
            </form>
          </div>
        </div>
      </Router>
    );
  }
  }



  forgotPass = e => {
    if(!document.getElementById("userLogin").value){

      showSuccess("Please enter User Id")
     
    }
     else if(!document.getElementById("txtCompare").value)
    {
       showSuccess("Please enter  captcha.")
    }
    else 
    {
      confirmAlert({
        message: "Are you Sure you want to reset the Password ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => 
            this.resetPass()
            
          },
          {
            label: "No",
          }
        ]
      });
    }
  }

  resetPass = () => {
    this.setState({loading:true})
        var Request = {
      "userId": this.refs.usrname.value,
      "captcha" :document.getElementById("txtCompare").value,
      "browserSessionId":this.state.browserSessionId
    };
    var EncryptedRequest = Encrypt(Request);

    fetch("/PasswordReset", {
      method: "POST",
      headers: {
        Authorization: "Basic " + basicAuth(this.refs.usrname.value),
        userId: this.refs.usrname.value
      },
      body: EncryptedRequest
    })
    .then(response => response.text())
    .then(response => {
      var DecryptedResponse = decryptData(response);
      console.log(`reset`, DecryptedResponse)
      if (DecryptedResponse.responseCode === "200 OK") { 
        this.setState({loading:false})
       document.getElementById("txtCaptcha").value = DecryptedResponse.captcha
       this.setState({ browserSessionId: DecryptedResponse.browserSessionId })
         confirmAlert({
          message: "New temporary password generated & sent to the enrolled mail ID",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.login.bind(this);
              }
            }
          ]
        }); 
        document.getElementById("txtCompare").value = ""
        document.getElementById("userLogin").value = ""
        document.getElementById("userPassword").value= ""
      } else {
        this.setState({loading:false})
        document.getElementById("txtCaptcha").value = DecryptedResponse.captcha
        this.setState({ browserSessionId: DecryptedResponse.browserSessionId })
        document.getElementById("txtCompare").value = ""
        document.getElementById("userLogin").value = ""
        document.getElementById("userPassword").value= ""
        confirmAlert({
          message: DecryptedResponse.responseMsg,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.login.bind(this);
              }
            }
          ]
        });
      }
    })
    .catch(error => {
      this.setState({loading:false})

      confirmAlert({
        title: "Alert !",
        message: "Password Reset Unsuccessful!",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              this.login.bind(this);
            }
          }
        ]
      });
      // console.error(error);
    });
  }



  login = (e) => {
    if (!document.getElementById("userLogin").value || !document.getElementById("userPassword").value) {
   
      this.submit();
      return (false);
    }
      e.preventDefault();
    var Request= {
      "userId": this.refs.usrname.value,
      "password": this.refs.password.value,
      "captcha" :document.getElementById("txtCompare").value,
      "browserSessionId":this.state.browserSessionId
    }
    var EncryptedRequest=Encrypt(Request);
   
    fetch('/Login', {
      method: 'POST',

      headers: {
      'Authorization': 'Basic ' + basicAuth(this.refs.usrname.value),
      'userId':this.refs.usrname.value
      },
      body: EncryptedRequest

    })
     
      .then((response) => response.text())
      .then((response) => {

        var DecryptedResponse=decryptData(response)

        if (DecryptedResponse.responseCode === '200 OK') {
          this.setState({ userName: DecryptedResponse.name})
          this.setState({ roleId: DecryptedResponse.roleId });
          this.setState({ passwordFlag: DecryptedResponse.passwordFlag });
          this.setState({ success: true });
          this.setState({ responseJson: DecryptedResponse });
          this.setState({guId: DecryptedResponse.guId})

          if(DecryptedResponse.passwordFlag==='1'){
            
            this.props.history.push({
              pathname: '/Changepassword',
              state: {
                userid: this.refs.usrname.value,
                passwordFlag: DecryptedResponse.passwordFlag,
                usrnm:  DecryptedResponse.name,
                guId:DecryptedResponse.guId        
              }
            }
            );


          }else
          if(DecryptedResponse.roleId==='100'){
            this.props.history.push({
              pathname: '/CreateHo',
              state: {
                 userid: this.refs.usrname.value,
                username:  DecryptedResponse.name,
                guId: DecryptedResponse.guId}
              })

          } else if(DecryptedResponse.passwordFlag=='0'){
            this.props.history.push({
              pathname: '/Taskactive',
              state: {
                role_id: DecryptedResponse.roleId,
                usrnm: DecryptedResponse.name,
                userName: this.userName,
                userid: this.refs.usrname.value,
                storeNo:DecryptedResponse.storeNo,
                guId: DecryptedResponse.guId
              
              }
            });


          }

          
        }
        else {
        //  this.GenerateCaptcha()
          confirmAlert({
            title: 'Alert !!',
            message:  DecryptedResponse.responseMsg,
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.login.bind(this) }
              },

            ]
          })
          document.getElementById("txtCaptcha").value = DecryptedResponse.captcha
          this.setState({ browserSessionId: DecryptedResponse.browserSessionId })
          this.refs.usrname.value = "";
          this.state.value="";
          this.refs.password.value = "";
          this.refs.captcha.value = "";
        }

      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "User authorization error",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.login.bind(this) }
            },

          ]
        })
      
      });    
  
  
  }

   GenerateCaptcha() {
      fetch('/CreateToken', {
      method: 'GET',
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode == "200 OK") {
           document.getElementById("txtCaptcha").value = DecryptedResponse.captcha;
        
          this.setState({ browserSessionId: DecryptedResponse.browserSessionId })
          this.setState({ loading: false })
        
        }
        else {
          showSuccess(DecryptedResponse.responseMsg)

        }
      })
      .catch((error) => {
        this.setState({ loading: false })
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
            //  onClick: () => { this.logoutnormal() }
            },

          ]


        });



      })
       
      } 

  
      OnCopyFunction() {
            $("#txtCompare").bind("paste", function(e) {
              e.preventDefault();
            });
            $("#txtCaptcha").bind("copy", function(e) {
              e.preventDefault();
            });
      }
  

}
export default withRouter(Login);