import disableBrowserBackButton from 'disable-browser-back-navigation';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import IdleTimer from 'react-idle-timer'; // For Idle
import Pagination from "react-js-pagination";
import Spinner from 'react-spinner-material'; //Import Spinner
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { basicAuth } from './basicAuth';
import { showSuccess } from './services/ConfirmAlert';
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
class ImageGallery extends Component {
  state = {
    fromdata: [],
    sum1: [],
    headers: [],
    answerList: [],
    BarChartArray: [],
    percentage: "",
    draft: "",
    newTask: "",
    save: "",
    total: "",
    loading: false,
    imageArray: [],
    enimg: false,
    taskId: "",
    storeNo: [],
    showimagemsg: false,
    forwardpage: 0,
    backwardpage: 0,
    currentPage: 1,
    pagenumber: "",
    todosPerPage: 20,
    imagecount: "",
    store: "",
    imagepdfid: "test",
    questionText: "",
    guId: ""
  }






  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.fetchImages();

  }
  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  _onAction(e) {

  }



  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  enlargeimgclose = () => {
    this.setState({
      enimg: false,

    })

  }


  enlargeimage = (item, storeNo) => {
    this.setState((prevState) => ({ enimg: !prevState.enimg, }))
    this.setState({ key: item })
    this.setState({ store: storeNo })
  }


  handlePageChange = (pagenumber) => {
    this.state.currentPage = pagenumber
    this.setState({ loading: true })
    this.fetchImages()
  }




  render = () => {
    var that = this;
    var enlargeimg = [];

    /* pagination
    const { todosPerPage } = this.state;
      
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(this.state.imagecount/todosPerPage); i++) {
        pageNumbers.push(i);
      }
      
      const renderPageNumbers = pageNumbers.map(number => {
        return (
        
          <li  style={{"list-style-type": "none"}} 
            key={number}
            id={number}
            onClick={this.handleClick.bind(this)}
          >
            {number}
          </li>
        );
      });
*/

    enlargeimg.push(

      <div className="modal" role="dialog" style={this.state.enimg ? display : hide}>

        <div className="modal-dialog animate">
          <div className="modal-content ig-modal">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.enlargeimgclose}>X</a>
              <h4 className="modal-title">Store No: {this.state.store}</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal" style={{ "margin-right": "10px" }}>
                <div className="form-group">
                </div>
                <div>
                </div>


                <iframe src={this.state.key} style={{ height: "500px", width: "100%" }} />

              </div>
            </div>

          </div>
        </div>
      </div>
    )

    return (

      <div>


        {enlargeimg}
        <div className="spin">
        <Spinner visible={this.state.loading}
          spinnerColor={"rgba(0, 0, 0, 0.3)"} />
          </div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10} />
        <div className="modal-header" style={{ 'border-radius': '0px' }}>
          <a className="close" style={{ "font-weight": "bold", color: "#fff" }} onClick={this.Reportsbind}>X</a>

          <a> <button class="btn btn-primary min-wid-90 mr-10 " style={{ float: 'right' }}
            onClick={this.downloadimagepdf.bind(this)} > Download </button></a>
          <h4 className="modal-title" style={{ 'text-align': 'center' }}>IFMS Image Gallery</h4>

        </div>
        <div className="modal-body">
          <h1 className="m-5">{that.state.questionText}</h1>

          {this.state.showimagemsg == true ?
            <h4 style={{ "margin-left": "10px" }}>Store has not uploaded images yet.</h4>
            :
            <div className="imgholder">
              {that.state.imageArray.map(function (item, key) {
                return (
                  <div style={{
                    "width": "220px", margin: "10px", border: "1px solid #ddd", padding: "5px",
                    "border-radius": "4px"
                  }} >
                    <a onClick={that.enlargeimage.bind(that, item.image, item.storeNo,item.equipmentNo)}>
                      <img src={item.image} style={{ "width": "210px", height: "100px", "padding-right": "10px" }} />
                    </a>

                    <span><b>Store no :{item.storeNo}</b></span>
                    <div className="clearfix"></div>
                 {item.equipmentNo ?  <span><b>Equipment no:{item.equipmentNo}</b></span>:''}
                  </div>
                )
              })}


            </div>}
        </div>


        {this.state.showimagemsg == false ?
          <ul className="pagein">
            <div>
              <Pagination
                activePage={this.state.currentPage}
                itemsCountPerPage={20}
                totalItemsCount={this.state.imagecount}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            </div>
          </ul>
          : null}
      </div>


    )
  }

  fetchImages = () => {

    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({
        imageArray: [],
        questionText: this.props.location.state.questionText,
        guId: this.props.location.state.guId
      })

      var request = {
        "questionId": this.props.location.state.questionId,
        "taskId": this.props.location.state.taskId,
        "buttonNo": (this.state.currentPage * 20) - 20,
        "guId": this.props.location.state.guId,
      }

      var EncryptedRequest = Encrypt(request);
      fetch('/GetImages', {
        method: 'POST',
        body: EncryptedRequest,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }


      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode == "200 OK") {

            this.setState({ imagecount: DecryptedResponse.imageCount })

            for (let k = 0; k < DecryptedResponse.imageList.length; k++) {
              this.state.imageArray.push(DecryptedResponse.imageList[k])


            }
            this.setState({ loading: false })
            if (DecryptedResponse.imageList.length == '0' || DecryptedResponse.imageList == undefined) {
              this.setState({ showimagemsg: true })
            }
            else {
              this.setState({ showimagemsg: false })
              /// For conversion of images to  pdf
              //   var doc = new jsPDF();

              // for(let  p=0;p<DecryptedResponse.imageList.length;p++){
              //   if(DecryptedResponse.imageList[p].image.substring(5,10)=="image"){
              //   doc.addImage(DecryptedResponse.imageList[p].image, 35, 25);
              //   doc.text(DecryptedResponse.imageList[p].storeNo, 35, 25);
              //   doc.addPage();

              //   }

              // }
              // doc.save("text.pdf")

            }
          }
          else {
            this.setState({ loading: false, showimagemsg: true })
            showSuccess(DecryptedResponse.responseMsg)

          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });



        })
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }


  downloadimagepdf = () => {
    this.setState({ loading: true })
    var request = {
      "questionId": this.props.location.state.questionId,
      "taskId": this.props.location.state.taskId,
      "buttonNo": "0",
      guId: this.props.location.state.guId,
    }


    var url = `/GetAllImages?key=${Encrypt(request)}`;


    fetch(url,
      {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }


      }

    ).then(data => {
      return data.blob();
    })
      .then((data) => {
        const a = document.createElement("a");

        const b = new File([data], '', { type: "application/pdf" });

        a.href = URL.createObjectURL(b);
        a.hidden = true;
        a.download = this.props.location.state.taskId;
        document.body.appendChild(a)
        a.click();
        a.parentNode.removeChild(a);
        this.setState({ loading: false })
      })









  }



}


export default ImageGallery;
