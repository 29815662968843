import addDays from "date-fns/addDays";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ReactExport from "react-data-export";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import Spinner from 'react-spinner-material'; //Import Spinner
import readXlsxFile from 'read-excel-file';
import { basicAuth } from './basicAuth';
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import FormDataService from './services';
import { showAlert, showSuccess } from './services/ConfirmAlert';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Updatetasks extends Component {
  state = {
    TaskDropdwn: false,
    displayMenu: false,
    hasError: false,   //Added by chandrani for custom error page
    loading:false
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn),
      
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      tskDescr: "",
      FileName: "",
      tskName: "",
      taskRoleId:[],
      strtDate: new Date(),
      edDate: new Date(),
      StoreList: "StoreList",
      showAction: false,
      usrnm: "",
      userid: "",
      roleId: "",
      loaded: this.props.location.state,
      taskStores: [],
      hasError: false,   //Added by chandrani for custom error page
      hammenu:true,
      loading:false,
      guId:"",
      tskId:""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.CheckProps.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)


  }

  _onAction(e) {
    //console.log('user did something', e)
  }
 
  _onActive(e) {
   // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
 _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
          disableBrowserBackButton();
  }

componentWillMount(){
  this.SetUpdateTask()
}

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  componentDidMount() {
    if (window.innerWidth<=768)
    {this.setState({hammenu:false})}
 }


 logoutnormal=()=>{
      
  localStorage.clear();
  this.props.history.replace('/');
  disableBrowserBackButton();
}








logout=()=>{
    var Request1= { 
     "guId":this.props.location.state.guId,
    "userId":this.props.location.state.userid 
  }
      var EncryptedRequest1 = Encrypt(Request1);    
    fetch('/LogOut', {
    method: 'POST',
    body: EncryptedRequest1,
    headers: {
      guId:this.props.location.state.guId,
      'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
    }
    
    })
    .then((response) => response.text())
    .then((response) => {
      var DecryptedResponse1=decryptData(response)    
      if (DecryptedResponse1.responseCode=== '200 OK') {
       
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
    
      }
      else {
        this.setState({loading:false})
        showSuccess(DecryptedResponse1.responseMsg)
    
      }
    })
    .catch((error) => {
      confirmAlert({
        title: 'Alert !',
        message: "Session expired",
        buttons: [
          {
            label: 'Ok',
            onClick: () => {this.logoutnormal()}
          },

        ]

       
      });
      
    });
  

 }

  handleChangeStart(date) {
    this.setState({
      strtDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      edDate: date
    });
  }


  submit = () => {
    confirmAlert({
      message: 'Are you sure, you want to update the task?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { this.UpdateTask.bind(this) }
        },
        {
          label: 'No',
          onClick: () => { this.forceUpdate(); }
        }
      ]
    })
  };


  fileHandler = (event) => {
    const input = document.getElementById('upload-storedata')
    var exits=[]
    readXlsxFile(input.files[0]).then((rows, files) => {
      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        exits.push(element[0])
      }
      this.setState({
        StoreList: exits,
        FileName: 'File Uploaded Successfully',
        showAction: true
      });
    })
    
  }

  //Added by chandrani for custom error page


  InstructionHandler = async (e) => {
    try {
      let files = e.target.files;
      if (files[0].type == "image/gif"
        || files[0].type == "image/jpg"
        || files[0].type == "image/jpeg"
        || files[0].type == "image/png"
        || files[0].type == "application/msword"
        || files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        || files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || files[0].type == "application/vnd.ms-excel"
        || files[0].type == "application/pdf"
        || files[0].type == "application/vnd.ms-powerpoint"
        || files[0].type == "text/plain"
      )

      { 
        var Request1 = {
          "taskId":this.props.location.state.tskId,
          "guId":this.props.location.state.guId
        }

        var EncryptedRequest1 = Encrypt(Request1);
        this.setState({loading:true})
       const response= await FormDataService.uploadFormData(files[0],EncryptedRequest1,this.props.location.state.guId,'Basic ' + basicAuth(this.props.location.state.userid));
        var DecryptedResponse=decryptData(response.data)
        if(DecryptedResponse.responseCode =='00'){
        this.setState({ instructionUpload: 'File Uploaded Successfully',loading:false });
         showSuccess(DecryptedResponse.responseMsg)

      }
      else{
        this.setState({ instructionUpload: '' ,loading:false });
        confirmAlert({
          message:DecryptedResponse.responseMsg,
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.setState({ instructionUpload: "" }); }
            },
  
          ]
        })
      }

      }
      else {
        this.setState({ instructionUpload: '' ,loading:false });
        confirmAlert({
          title: 'Alert !',
          message: "Unsupported File Format.",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.setState({ instructionUpload: "" }); }
            },
  
          ]
        })
      }
    } catch (error) {
       this.setState({instructionUpload: '',loading:false })
      showSuccess( "File not saved.")
      


    }


  }







  componentDidCatch(){
    this.setState({hasError : true})
  }

  userlist=()=>{
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,

      }
    }
    );
  }
  tiker=()=>{

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      }
    }
    );

  }


  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


usercreation=()=>{
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      }
    }
    );
  }


  categorycreation=()=>{
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      }
    }
    );
  }

  
  archive=()=>{
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo:this.props.location.state.storeNo,
        guId:this.props.location.state.guId,
      }
    }
    );
  }

  render = () => {
    //Added by chandrani for custom error page
    if(this.state.hasError){
      return(
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    }else{
    return (
      <Router>
        {this.state.loaded ?
          <div>
            <section id="container">
            <div>
            <Spinner visible={this.state.loading}
            spinnerColor={"rgba(0, 0, 0, 0.3)"} />
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10} />
      </div>
              <header class="header black-bg">
               
              <a className="mob-show" onClick={()=>this.setState({hammenu:!this.state.hammenu})}><i className="fa fa-tasks hammenu" style={{"margin-top":"30px"}}></i></a>

                <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" /><b>{this.state.usrnm}</b><b className="caret" /></a>
                  </li>

                  {this.state.displayMenu ?
                    (

                      <ul className="dropdown-menuuser-dd">
                        <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                        <li className="divider"></li>
                        <li><a href="#Log Out" onClick={this.logout} >Log Out</a></li>
                      </ul>
                    ) :
                    (
                      null
                    )
                  }


                </ul>
              </header>
              <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
              <section id="main-content">
                <section class="wrapper">
                  <div class="mt">
                    <h3><i class="fa fa-angle-right"></i>Update Task</h3>
                    <hr />
                    <div class="row mt"
                      onLoad={this.CheckProps.bind(this)}
                    >
                      <form method="post">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <label>Task ID</label>
                          <br />
                          <input ref="taskid" type="text" className="form-control" size="16" placeholder={this.state.tskId} id="taskID" disabled="disabled" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Task Name</label>
                          <br />
                          <input ref="taskname" type="text" class="form-control" onChange={(f) => this.setState({ tskName: f.target.value })} value={this.state.tskName} id="taskName" autoComplete="off" maxlength="250"/>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 " >
                          <label >Start Date</label>
                          <br />
                          <DatePicker ref="startdate" className="form-control form-control-inline input-medium default-date-picker "
                            size="16" type="text" value={this.state.strtDate} dateFormat="dd-MMM-yyyy HH:mm" id="startDate"
                            selected={this.state.strtDate} showTimeSelect
                            timeIntervals={30}  
                            minDate={new Date()}
                            onChange={this.handleChangeStart}
                          />
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 ">
                          <label>End Date</label>
                          <br />
                          <DatePicker ref="enddate" className="form-control form-control-inline input-medium default-date-picker"
                            size="16" type="text" dateFormat="dd-MMM-yyyy HH:mm" id="endDate"
                            onChange={this.handleChangeEnd} minDate={this.state.strtDate}  showTimeSelect
                            timeIntervals={30}   
                            maxDate={addDays(new Date(), 365)}
                            selected={this.state.edDate}
                          />
                        </div>

                      
                        <div class="clearfix"></div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-20">
                          <label>Task Description</label>
                          <br />
                          <textarea ref="taskdescr" onChange={(e) => this.setState({ tskDescr: e.target.value })} type="text" class="form-control" value={this.state.tskDescr} id="taskDesc" maxLength="250"></textarea>
                        </div>
                        <div class="clearfix"></div>
                        <div class="mt-20">
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">

                            <label>Store / Individual (Optional)</label><br />
                            <div class="upload-holder">
                              <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                              <span class="btn-upload">Upload</span>
                              <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} value=''   accept=".xlsx,.xls" />
                            </div>
                            <span class="help-block">Sample File <a href={require("./doc/sample.xlsx")}>Sample.xls</a></span>
                            <span>Existing store / individual data </span>
                            <ExcelFile element={<a> Download</a>}>
                              <ExcelSheet data={this.state.taskStores} name="Store Details">
                                <ExcelColumn label="Assigned To" value="storeNo" />
                                <ExcelColumn label="Name" value="storeName" />
                              </ExcelSheet>
                            </ExcelFile>

                          </div>
                          {this.state.showAction ?
                            <div id="showaction" className="mh-100 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                              <div class="">
                                <label>Action on selected stores</label>
                                <select class="form-control"
                                  id="SelActType">
                                  <option>Add</option>
                                  <option>Delete</option>
                                </select>

                              </div>
                            </div> : null}

                            <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Instructions (Optional)
                        
                          </label>
                          <br />
                          <div class="upload-holder">
                            <input id="instructions" type="text" class="form-control input-upload" placeholder="Upload Instructions" value={this.state.instructionUpload} />
                            <span class="btn-upload">Upload</span>
                            <input id="upload-instructions" type="file" name="Instruction-data" class="input-hidden" onChange={(e) => this.InstructionHandler(e)}  value='' accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf" />

                          </div>
                        
                        </div> 


                        </div>
                       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <button class="btn btn-primary min-wid-90 mt-17" onClick={this.UpdateTask.bind(this)}>Update</button>
                          <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.BackPage.bind(this)}>Cancel</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
          : this.props.history.push({
            pathname: '/',
          })
        }

      </Router>
    );
  }
  }
  SetUpdateTask = () => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
    this.setState({
      tskDescr: this.props.location.state.tskDescr,
      tskName: this.props.location.state.tskName,
      strtDate: this.props.location.state.strtDate,
      edDate: this.props.location.state.edDate,
      roleId: this.props.location.state.role_id,
      userid: this.props.location.state.userid,
      usrnm: this.props.location.state.usrnm,
      guId:this.props.location.state.guId,
      tskId: this.props.location.state.tskId
    });
    this.state.taskRoleId.push(this.props.location.state.taskRoleId)

    var Request=  { "taskId": this.props.location.state.tskId,
    "guId":this.props.location.state.guId, }
    var EncryptedRequest=Encrypt(Request);
    fetch('/GetTaskStores', {
      method: 'POST',
      body: EncryptedRequest,
      headers: {
    guId:this.props.location.state.guId,
  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
  
      }
    })
      .then((response) => response.text())
      .then((response) => {

        var DecryptedResponse=decryptData(response)
        if (DecryptedResponse.responseCode === "200 OK") {
          this.setState({ taskStores: DecryptedResponse.taskStoreList });
        }
        else {
             showAlert(DecryptedResponse.responseMsg)
         
        }

      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => {this.logoutnormal()}
            },
  
          ]
  
         
        });
        
      });
  }
else{
  
    this.props.history.push({
      pathname: '/',
    });
 
}


}



BackPage=(e)=>{
  e.preventDefault();
  confirmAlert({
    message: 'Are you sure, you want to cancel?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {   this.props.history.push({
          pathname: '/Taskactive',
          state: {
            userid: this.state.userid,
            usrnm: this.state.usrnm,
            role_id: this.state.roleId,
            guId:this.props.location.state.guId,
          }
        }
        );}
      },
      {
        label: 'No',
        onClick: () => {return false}
      }
    ]
  });
}



  UpdateTask(e) {
    e.preventDefault();
    if (document.getElementById("taskName").value=="" || document.getElementById("taskDesc").value=="") {
     showSuccess("Please enter required fields")
   
    }

    else{
    var vstartdate = new Date(document.getElementById("startDate").value);
      var venddate = new Date(document.getElementById("endDate").value);
      if(venddate < vstartdate){
    showSuccess( "End date cannot be earlier than start date")
      
      }
   

else{

    if (this.state.showAction == false) {
      confirmAlert({
        message: 'Are you sure, you want to update the task?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Request1= {
                "taskId": this.props.location.state.tskId,
                "taskDesc": this.refs.taskdescr.value,
                "startDate": document.getElementById("startDate").value,
                "endDate": document.getElementById("endDate").value,
                "createdBy": this.props.location.state.userid,
                "taskName": document.getElementById("taskName").value,
                "action": "",
                "storeId": [],
                "guId":this.props.location.state.guId,
              //  "roleId": this.state.taskRoleId
              }
              var EncryptedRequest1=Encrypt(Request1);
              fetch('/UpdateTask', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId:this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
            
                }
              })
                .then((response) => response.text())
                .then((response) => {

                  var DecryptedResponse=decryptData(response)
                  if (DecryptedResponse.responseCode === "200 OK") {

                    confirmAlert({
                      message: "Task updated successfully. ",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => {
                            this.props.history.push({
                              pathname: '/Taskactive',
                              state: {
                                userid: this.state.userid,
                                usrnm: this.state.usrnm,
                                role_id: this.state.roleId,
                                guId:this.props.location.state.guId,
                              }
                            }
                            );
                          }
                        },

                      ]
                    })
                    this.forceUpdate();
                  }
                  else {
                     showAlert(DecryptedResponse.responseMsg)
                   
                  }

                })
                .catch((error) => {
                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {this.logoutnormal()}
                      },
            
                    ]
            
                   
                  });
                  
                });
            }
          },
          {
            label: 'No',
            onClick: () => {
              return false;
            }
          }
        ]
      })
    }
    else {

      var Request2=   {
        "taskId": this.props.location.state.tskId,
        "taskDesc": this.refs.taskdescr.value,
        "startDate": document.getElementById("startDate").value,
        "endDate": document.getElementById("endDate").value,
        "createdBy": this.props.location.state.userid,
        "taskName": document.getElementById("taskName").value,
        "action": document.getElementById("SelActType").value,
        "storeId": this.state.StoreList,
        "guId":this.props.location.state.guId,
     //   "roleId": this.state.taskRoleId
      }
      var EncryptedRequest2=Encrypt(Request2);


      fetch('/UpdateTask', {
        method: 'POST',
        body: EncryptedRequest2,
        headers: {
          guId:this.props.location.state.guId,
    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
    
        }
      })
        .then((response) => response.text())
        .then((response) => {

          var DecryptedResponse=decryptData(response)         
          if (DecryptedResponse.responseCode === "200 OK") {
            confirmAlert({
              message: "Task updated successfully.", 
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => {
                    this.props.history.push({
                      pathname: '/Taskactive',
                      state: {
                        userid: this.state.userid,
                        usrnm: this.state.usrnm,
                        role_id: this.state.roleId,
                        guId:this.props.location.state.guId,
                      
                      }
                    }
                    );
                  }
                },

              ]
            })
            this.forceUpdate();
          }
          else {
      showAlert(DecryptedResponse.responseMsg)
          }

        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => {this.logoutnormal()}
              },
    
            ]
    
           
          });
          
        });

    }

  } }
  }
  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo, 
        guId:this.props.location.state.guId,
      }
    }
    );
  }
  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.roleId,
        guId:this.props.location.state.guId,
      }
    }
    );
  }
 
  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  CheckProps = () => {
    if (this.props.location.state) {
    }
    else {

      this.props.history.push({
        pathname: '/',
      });
    }
  }
  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      
      }
    }
    );
  }

  EquipmentList =()=>{
    if(this.props.location.state.role_id=="505"||  this.props.location.state.role_id=="501" || this.props.location.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }
  
showTaskReportHandler=()=>{
  this.setState({
    Taskreport: !(this.state.Taskreport)
  })
}



summaryReports =()=>{
  this.props.history.push({
    pathname: '/TaskSummaryReport',
    state: {
      userid: this.props.location.state.userid,
      usrnm: this.state.usrnm,
      role_id: this.props.location.state.role_id,
      guId:this.props.location.state.guId

    }
  }
  );
}

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId:this.props.location.state.guId,
      }
    }
    );
  }



  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId:this.props.location.state.guId,
      }
    }
    );
  }

}

export default withRouter(Updatetasks);