import React, { Component } from 'react';
import 'jquery/dist/jquery';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from 'react-global-configuration';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import { Scrollbars } from 'react-custom-scrollbars';
import readXlsxFile from 'read-excel-file';
import ReactExport from "react-data-export";
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import IdleTimer from 'react-idle-timer'// For Idle
import App from './App' // For Idel
import Select from 'react-select';
import $ from "jquery";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import { showSuccess, showAlert } from './services/ConfirmAlert';
import MySelect from "./MySelect.jsx";
import addDays from "date-fns/addDays";


const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class Reports extends Component {

  state = {

    TaskDropdwn: false,
    userid: "",
    role_id: "",
    storeDetail: [],
    listQueAnsDetails: [],
    headers: [],
    sumheaders: [],
    answerList: [],
    taskSummaryList: [],
    taskName: '',
    taskDescr: '',
    hasError: false,   //Added by chandrani for custom error page
    stateName: '',
    sum1: [],
    statepercentage: '',
    pendingStores: '',
    isPendingVisible: false,
    storeList: [],
    templetId: "",
    imageArray: [],
    question: [],
    imgtaskid: "",
    searchQuestn: "",
    equipment: [],
    equipopt: [],
    equipmentId: [],
    reportcat: [],
    reportcatID: [],
    equipflag: "",
    reportcategory: "",
    reportcatvalueid: [],
    category: "",
    euipvalueid: [],
    category1: "",
    arrayequipid: [],
    reportidvalue: [],
    responseid: "",
    taskId: "report",
    taskidreport: "test",
    shwvalue: "",
    guId: "",
    showmonth: false,
    showequip: false,
    monthId: '',
    selectstate: [],
    selectedState: [],
     
    selectMonth: [],
    monthnessId: [],
    selectedMonth: [],
    sendState: [],
    stateFinalName: [],
    setFinalState: []
  }
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      startDate: "",
      endDate: "",
      displayMenu: false,
      usrnm: "",
      data: [],
      fromdata: [],
      titlename: [],
      taskId: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searchQuestn: "",
      loading: false,
      toggle: false,
      taskName: '',
      taskDescr: '',
      hasError: false,   //Added by chandrani for custom error page
      stateName: '',
      sum1: [],
      statepercentage: '',
      isPendingVisible: false,
      pendingStores: '',
      hammenu: true,
      immodal: false,
      eddate: "",
      UpdatesDate: "",
      startdateupdateticker: "",
      storeList: [],
      templetId: "",
      imageArray: [],
      key: "",
      question: [],
      questionId: "",
      questionText: "",
      equipment: [],
      equipopt: [],
      equipmentId: [],
      reportcat: [],
      reportcatID: [],
      equipflag: "",
      reportcategory: "",
      reportcatvalueid: [],
      category: "",
      euipvalueid: [],
      category1: "",
      arrayequipid: [],
      reportidvalue: [],
      responseid: "",
      taskidreport: "test",
      shwvalue: "",
      guId: "",
      showmonth: false,
      showequip: false,
      monthId: "",
      selectstate: [],
      selectedState: [],
      selectMonth: [],
      monthnessId: [],
      selectedMonth: [],
      sendState: [],
      stateFinalName: [],
      setFinalState: [],
      isDropdownOpen: false,
      taskIdOfReport: props.taskId,
      lastGenRepDate: "",
      lastGenExceptionDate:"",
      loading: false,
      reportUrl: "",
      exceptionReportUrl:"",
      reportStatus: "",
      reportDetails:"",
      reportStatusMessage: "",
      reportStatusExceptionMessage:""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.confirmAlertRef = React.createRef();
    this.dataAndSummaryReport = this.dataAndSummaryReport.bind(this);
    this.dataExceptionReport = this.dataExceptionReport.bind(this);
  }

  toggleDropdown = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  componentWillMount() {

    this.Reports();

  }

  _onAction(e) {

  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  handleChangeStart(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchSDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      startDate: date

    });
  }
  handleChangeEnd(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchedDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      endDate: date
    });
  }
  onChange = e => {
    this.setState({
      Search: e.target.value
    });

  }

  onChangequestion = e => {
    this.setState({
      searchQuestn: e.target.value
    });
  }


  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }



  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }




  callImageGallery = (questionId, questionText) => {
    this.props.history.push({
      pathname: '/ImageGallery',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        questionId: questionId,
        taskId: this.state.imgtaskid,
        questionText: questionText,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  imagemodalopen = (taskId) => {

    { this.setState((prevState) => ({ immodal: !prevState.immodal, })) }

    var Request1 = {
      "taskId": taskId,
      "guId": this.props.location.state.guId,
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/GetImageQuestions', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === "200 OK") {
          this.setState({ question: DecryptedResponse1.questionList });
          this.setState({ imgtaskid: taskId })
          this.setState({ loading: false })

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)

        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !', 
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }



  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );

  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  imagemodalclose = () => {
    this.setState({
      immodal: false,

    })

  }

  fileHandler = (event) => {
    this.setState({ storeList: [] });
    const input = document.getElementById('upload-storedata')
    readXlsxFile(input.files[0]).then((rows, files) => {
      var storedata = [];
      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i])
        for (let j = 0; j < storedata.length; j++) {
          var store =
          {
            storeNo: "",
            comment: "",

          }
          store.storeNo = storedata[j][0]
          store.comment = storedata[j][1]

        }

        {
          this.state.storeList.push(store)

        }
      }

      this.setState({

        FileName: 'File Uploaded Successfully',
      });
    })

  }

  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }



  /* handleReportStartDate(dates) {
     this.setState({ startDateForReport: dates });
     this.setState({ UpdatesDateForReport: dates });
     this.setState({endDateForReport:''})
   }
 
   
   handleReportEndDate(dates){
     this.setState({ endDateForReport: dates });
     this.setState({ UpdatesDateForReportEd: dates });
   
   }*/






  /*duplicate(taskName, taskDescr, taskId, edDate) {
    this.setState(prevState => ({
      appear: !prevState.appear
    }));
    this.setState({
      taskName: taskName,
      taskDescr: taskDescr,
      templetId: taskId,
      edDate: edDate,
      UpdatesDate: edDate
    });
  }


  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleReportStartDate(dates) {
    this.setState({ startDateForReport: dates });
    this.setState({ UpdatesDateForReport: dates });
    this.setState({endDateForReport:''})
  }

  handleReportEndDate(dates){
    this.setState({ endDateForReport: dates });
    this.setState({ UpdatesDateForReportEd: dates });
  
  }*/

  /*  handleReportStartDate(dates) {
      this.setState({ startDateForReport: dates });
      this.setState({ UpdatesDateForReport: dates });
      this.setState({endDateForReport:''})
    }
  
    handleReportEndDate(dates){
      this.setState({ endDateForReport: dates });
      this.setState({ UpdatesDateForReportEd: dates });
    }*/


  fetchReportStatus = (taskId) => {

    
    // this.setState({ loading: true });
    console.log("fetchReportTaskId",taskId);
    if (this.state.reportcatvalueid[0] == undefined) {
      this.setState({ loading: false })
      showSuccess('Please select report category.')
    }


    else {
      if (this.state.reportcatvalueid[0] == "1" && this.state.arrayequipid.length == "0") {
        this.setState({ loading: false })
        showSuccess('Please select equipment category.')
      }
      else {

   
    var Request1 = {
      "taskId": taskId,
      "userId": this.props.location.state.userid,
      "roleId": this.props.location.state.role_id,
      "equipCategId": this.state.arrayequipid.toString(),
      "reportId": this.state.reportidvalue.toString(),
      "reportType":this.state.reportcategory ==="Task By Lifecycle" ? "LIFECYCLE" : "NORMAL" 
    };

    console.log("request1", Request1)
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/refreshReportDetails', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response);
        console.log("Fetch Response", DecryptedResponse);
        {
          DecryptedResponse.flag === "N"
            ? this.setState({ lastGenRepDate: "Report not generated" })
            : this.setState({
                lastGenRepDate: DecryptedResponse.dateTime             
              });
        }

        this.setState({ reportStatus: DecryptedResponse.flag });
        // this.setState({
        //   reportStatusMessage:
        //   <b style={{ color: 'red' }}> Please first generate the Report and then downloaded it.</b>,
        // });
       
          this.setState({
            reportStatusMessage:
            <b style={{ color: 'red' }}> Please first generate the Report and then download it.</b>,
          });
      
        this.confirmAlertRef.onClose();
        this.confirmAlertRef = null;
        this.setState({ loading: false });
        this.dataAndSummaryReport(taskId);
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.logoutnormal();
              },
            },
          ],
        });
      });
  };
}
  }

  fetchReportException = (taskId) => {
    
     this.setState({ loading: true });
    if (this.state.reportcatvalueid[0] == undefined) {
      this.setState({ loading: false })
      showSuccess('Please select report category.')
    }


    else {
      if (this.state.reportcatvalueid[0] == "1" && this.state.arrayequipid.length == "0") {
        this.setState({ loading: false })
        showSuccess('Please select equipment category.')
      }
      else {
    
    var Request1 = {
      "taskId": taskId,
      "userId": this.props.location.state.userid,
      "roleId": this.props.location.state.role_id,
      "equipCategId": this.state.arrayequipid.toString(),
      "reportId": this.state.reportidvalue.toString(),
      "reportType": "EXCEPTIONS"
    };

    console.log("request1", Request1)
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/refreshReportDetails', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response);
        console.log("Fetch Response", DecryptedResponse);
        {
          DecryptedResponse.flag === "N"
            ? this.setState({ lastGenExceptionDate: "Report not generated" })
            : this.setState({
              lastGenExceptionDate: DecryptedResponse.dateTime             
              });
        }

        this.setState({ reportDetails: DecryptedResponse.flag });
        this.setState({
          reportStatusExceptionMessage:
          <b style={{ color: 'red' }}>Please first generate the Report and then downloaded it.</b>,
        });
        this.confirmAlertRef.onClose();
        this.confirmAlertRef = null;
        this.setState({ loading: false });
        this.dataExceptionReport(taskId);
      })
      .catch((error) => {
        confirmAlert({
          title: "Alert !",
          message: "Session expired",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.props.logoutnormal();
              },
            },
          ],
        });
      });
  };
}
  }

  

  downloadReport = (taskId) => { 
   
    const a = document.createElement("a");
    a.href = this.state.reportUrl
    console.log("needtocheckdata",taskId);
    a.download = taskId;
    a.hidden = true;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
    this.setState({
      reportStatusMessage: "",
    });
    this.confirmAlertRef.onClose();
    this.confirmAlertRef = null;
    this.setState({ reportStatus: "" });
  };

  downloadReportException = (taskId) => { 
    
    const a = document.createElement("a");
    a.href = this.state.exceptionReportUrl
    a.download = taskId;
    a.hidden = true;
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
    this.setState({
      reportStatusExceptionMessage: "",
    });
    this.confirmAlertRef.onClose();
    this.confirmAlertRef = null;
    this.setState({ reportDetails: "" });
  };
  

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }


  modalclose = () => {
    this.setState({
      toggle: false,
    })
  }
  toggle(taskId, taskName, taskDescr) {
    this.props.history.push({
      pathname: '/Dashboard',
      state: {
        taskId: taskId,
        taskName: taskName,
        taskDescr: taskDescr,
        usrnm: this.props.location.state.usrnm,
        userid: this.props.location.state.userid,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId
      }
    });

  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }



  handleDateChangeRaws = (e) => {
    e.preventDefault();
  }

  handleDateChangeRawe = (e) => {
    e.preventDefault();
  }



  reportcategorychange = (e) => {
    this.setState({ equipopt: [] })
    this.setState({ reportcategory: e.target.value })
    let flagsym = []
    let flag123 = []
    for (var i = 0; i < this.state.reportcat.length; i++) {
      this.state.reportcat[i].trim() == e.target.value.trim() ? flagsym.push(this.state.equipflag[i]) : this.setState({ reportcatvalueid: "" })
      this.state.reportcat[i].trim() == e.target.value.trim() ? flag123.push(this.state.reportcatID[i]) : this.setState({ reportcatvalueid: "" })


    }
    this.setState({ reportcatvalueid: flagsym })
    this.setState({ reportidvalue: flag123 })
    if (flag123 == "2") {
      this.setState({ showequip: true })
      this.setState({ showmonth: false })
    }
    else {
      if (flag123 == "4") {
        this.setState({ showmonth: true })
        this.setState({ showequip: false })
      }
      else {
        this.setState({ showmonth: false, showequip: false })
      }
    }

  }

  equipmentcategory = (e) => {
    console.log(`e`, e)
    if (e != null) {
      this.setState({ equipopt: e })

      let flagsym = []
      for (var i = 0; i < this.state.equipment.length; i++) {
        for (let k = 0; k < e.length; k++) {
          this.state.equipment[i].value.trim() === e[k].value.trim() ? flagsym.push(this.state.equipmentId[i]) : this.setState({ euipvalueid: "" })
        }
      }

      this.state.euipvalueid = flagsym

      this.setState({ arrayequipid: this.state.euipvalueid })
      this.setState({ e: [] })

    }
  }



  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  dataAndSummaryReport = (taskId) => {  
  
    console.log("vvv",taskId);

    confirmAlert({
      customUI: ({ onClose }) => {
        this.confirmAlertRef = { onClose };
        return (
          <>
            {this.state.loading ? (
              <div className="overlay-box">
                <Spinner
                  visible={this.state.loading}
                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                />
              </div>
            ) : (
              <div
                className="react-confirm-alert-body"
                style={{ width: "575px" }}
              >
                <button
                  onClick={() => {
                    this.confirmAlertRef.onClose();
                    this.confirmAlertRef = null;
                    this.setState({ reportStatus: "" });
                  }}
                  type="button"
                  class="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <h1>Alert !</h1>
                <p>Last generated : {this.state.lastGenRepDate} </p>

                {this.state.lastGenRepDate = ""}
                <p> Do you want to Generate the Report ?</p>
                <button
                  className="btn btn-primary btn-prev-report"
                  onClick={this.genrate.bind(this,taskId)}
                >
                  Generate Report
                </button>

                <button
                  className="btn btn-primary"
                  style={{ marginRight: "60px", width: "115px",marginLeft:'50px' }}
                  disabled={
                    !(
                      this.state.reportStatus === "Y" ||
                      this.state.lastGenRepDate
                    ) || this.state.lastGenRepDate === "Report not generated"
                  }
                   onClick={() => {                                
                  //   if (this.state.reportStatus === "Y") {
                  //     console.log("checkkk", this.state.reportStatus);
                  //     confirmAlert({
                  //       title: 'Alert!',
                  //       message: "Please first generate the report and then download",
                  //     });
                       this.downloadReport(taskId);
                    // }
                //     else {                  
                //  console.log("data");
                //  }
                 }}
                  // onClick={this.downloadReport.bind(this,taskId)}
                >
                  Download
                </button>

                <button
                  className="btn btn-primary"
                  onClick={
                    this.fetchReportStatus.bind(this,taskId)
                  }
                  style={{ width: "115px" }}
                >
                  Refresh
                </button>
                {this.state.reportStatusMessage !== "" && (
                  <div style={{ marginTop: "20px", whiteSpace: "pre-line" }}>
                    <p>{this.state.reportStatusMessage}</p>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    });
  };

  dataExceptionReport = (taskId) => {  
     
    confirmAlert({
      customUI: ({ onClose }) => {
        this.confirmAlertRef = { onClose };
        return (
          <>
            {this.state.loading ? (
              <div className="overlay-box">
                <Spinner
                  visible={this.state.loading}
                  spinnerColor={"rgba(0, 0, 0, 0.3)"}
                />
              </div>
            ) : (
              <div
                className="react-confirm-alert-body"
                style={{ width: "575px" }}
              >
                <button
                  onClick={() => {
                    this.confirmAlertRef.onClose();
                    this.confirmAlertRef = null;
                    this.setState({ reportDetails: "" });
                  }}
                  type="button"
                  class="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <h1>Alert !</h1>             
                <p>Last generated : {this.state.lastGenExceptionDate} </p>
           
                {this.state.lastGenExceptionDate = ""} 
                <p> Do you want to Generate the Report ?</p>
                <button
                  className="btn btn-primary btn-prev-report"
                  onClick={this.getDownload.bind(this,taskId)}
                >
                  Generate Report
                </button>

                <button
                  className="btn btn-primary"
                  style={{ marginRight: "60px", width: "115px",marginLeft:'50px' }}
                  disabled={
                    !(
                      this.state.reportDetails === "Y" ||
                    this.state.lastGenExceptionDate
                  ) || this.state.lastGenExceptionDate === "Report not generated"
                  }
                  onClick={() => {
                   // this.downloadReport(taskId);
                   this.downloadReportException(taskId)
                  }}
                  // onClick={this.downloadReport.bind(this,taskId)}
                >
                  Download
                </button>

                <button
                  className="btn btn-primary"
                  onClick={
                    // this.fetchReportStatus.bind(this,taskId)
                    this.fetchReportException.bind(this,taskId)
                  }
                  style={{ width: "115px" }}
                >
                  Refresh
                </button>
                {this.state.reportStatusExceptionMessage !== "" && (
                  <div style={{ marginTop: "20px", whiteSpace: "pre-line" }}>
                    <p>{this.state.reportStatusExceptionMessage}</p>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    });
  };



  render = () => {
   
    const { searchQuestn } = this.state;
    
    console.log("data",this.state.taskId);
   console.log("apidata",this.state.data);


    var that = this;
    var imagemodal = [];



    imagemodal.push(

      <div className="modal" role="dialog" style={this.state.immodal ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content" >
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.imagemodalclose}>X</a>
              <h4 className="modal-title">Image Gallery</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal" style={{ "margin-right": "5px", height: "400px", }}>
                <div className="form-group">

                  <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                    <input type="text" placeholder="Search Question" className="form-control" id="quesname" onChange={this.onChangequestion} autoComplete="off" />
                  </div>


                  <div>
                    <img src={require("./img/searchicon.png")} className="user-img" style={{ width: "25px", height: "25px" }} />
                    
                  </div>


                  <div className="col-sm-10">
                    <div className="tbl-holder mt-20">
                      <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">

                        <thead>

                          <tr>
                            <th style={{ width: "3%" }}>Sr No</th>
                            <th>Question</th>
                          </tr>
                        </thead>
                      </table>

                      <Scrollbars style={{ height: 296 }}>

                        <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
                          <tbody>


                            {this.state.question.map(function (item, key) {
                              if (searchQuestn !== "" && item.questionText.toLowerCase().indexOf(searchQuestn.toLowerCase()) === -1) {
                                return null
                              }

                              return (
                                <tr key={key}>


                                  <td data-th="Sr No" style={{ width: "3%" }}>{key + 1}</td>
                                  <td data-th="Question">  <a href="javascript:void(0)" onClick={that.callImageGallery.bind(that, item.questionId, item.questionText)} className="black-text">  {item.questionText}</a></td>


                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Scrollbars>
                    </div>



                  </div>

                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    )



    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const ReportDataSet = [
      {
        columns: this.state.headers,
        data: this.state.answerList
      }]


    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <div>
          {imagemodal}

          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 50} />
            </div>
            <header class="header black-bg">
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b> <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind}>Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>

            <section id="main-content">
              <section className="wrapper">
                <div className="mt">

                  <h3>Reports</h3>



                  <hr />

                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">



                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Task Id</b><br />
                          <input type="number" placeholder="Search Task Id" className="form-control" id="taskname" onChange={this.onChange} autoComplete="off" />
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"><b> Report Category</b><br />
                          <select class="form-control"
                            onChange={this.reportcategorychange.bind(this)}
                            id="reportcategory" >
                            <option id="select">Select Category</option>
                            {this.state.reportcat.map((element) => (<option>{element}</option>))}
                          </select>

                        </div>

                        {this.state.showequip ?

                          <div className="col-xs-22 col-sm-3 col-md-3 col-lg-3 mt-xs-10"> <b>Equipment Category</b><br />

                            <MySelect
                              options={this.state.equipment}
                              isMulti
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              /*  components={animatedComponents} */
                              onChange={this.equipmentcategory.bind(this)}
                              allowSelectAll={true}
                              value={this.state.equipopt}
                              id="equipmentcategory123"
                            />

                          </div>
                          : ''}




                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                          <b>Start Date</b>
                          <DatePicker
                            className="form-control" type="text" placeholderText="Select Start Date"
                            /* value={this.state.UpdatesDateForReport}*/
                            selected={this.state.startDate}
                            /*onChange={this.handleReportStartDate}*/
                            onChange={this.handleChangeStart}
                            /*id="streprtdate"*/
                            id="startDate"
                            onChangeRaw={this.handleDateChangeRaw}
                            // maxDate={addDays(this.state.UpdatesDateForReportEd, 90)}
                            dateFormat="dd-MMM-yyyy"
                            /* selected={this.state.startDateForReport}*/


                            autoComplete="off"

                          />
                          <br />





                        </div>


                        <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 mt-xs-10 w-sm-110">
                          <b>End Date</b>
                          <DatePicker
                            className="form-control" type="text" placeholderText="Select End Date"
                            /*value={this.state.UpdatesDateForReportEd}*/
                            selected={this.state.endDate}
                            /*onChange={this.handleReportEndDate}*/
                            onChange={this.handleChangeEnd}
                            /*id="edreprtdate"*/
                            id="endDate"
                            onChangeRaw={this.handleDateChangeRaw}
                            minDate={this.state.UpdatesDateForReport}
                            dateFormat="dd-MMM-yyyy"
                            /* selected={this.state.endDateForReport}*/
                            autoComplete="off"
                            maxDate={addDays(this.state.UpdatesDateForReport, 90)}

                          />
                          <br />

                        </div>





                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">

                          <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} onClick={this.GetReportTask.bind(this)} />
                          
                        </div>

                        {/* <button class="btn btn-primary min-wid-90 ml-10" onClick={that.getDownload.bind(that)}>Report Download</button> */}


                        <div class="clearfix"></div>

                        <div className="mt">
                        </div>
                        <div className="tbl-holder">
                          <div className="spin">
                            <Spinner visible={this.state.loading}
                              spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                          </div>
                          <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">

                            <thead>
                              <tr>
                                <th>Task Id</th>
                                <th>Task Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th class="gnt-report">Report</th>
                              </tr>
                            </thead>
                          </table>
                          <Scrollbars style={{ height: 296 }}>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-task ">
                              <tbody>

                                {this.state.data.map(function (item, key) {
                                  // if ((Search !== "" || (searchSDate) !== "" || (searchedDate) !== "") &&
                                  //   (item.taskId.toLowerCase().indexOf(Search.toLowerCase()) ||
                                  //     item.startDate.toLowerCase().indexOf(searchSDate.toLowerCase()) ||
                                  //     item.endDate.toLowerCase().indexOf(searchedDate.toLowerCase())) === -1) {
                                  //   return null
                                  // }

                                  return (
                                    <tr key={key}>
                                      <td data-th="Task Name">{item.taskId}</td>
                                      <td data-th="Description">{item.taskName}</td>
                                      <td data-th="Start Date">{item.startDate}</td>
                                      <td data-th="End Date">{item.endDate}</td>
                                      <td data-th="Report" class="gnt-report">
                                        {/* <button class="btn btn-primary min-wid-90 mt-57 ml-10"
                                          onClick={that.genrate.bind(that,item.taskId)}> Generate </button> */}
                                         
                                  {/* <button class="btn btn-primary min-wid-90 mt-57 ml-10"
                                          onClick={that.genrate.bind(that,item.taskId)}> Generate </button>          */}

                                         <button  class="btn btn-primary btn-sm"
                                         onClick={that.dataAndSummaryReport.bind(that,item.taskId)}
                                         > General Report </button> 


                                         {(item.taskCategoryId === "6" || item.taskCategoryId === "7" || item.taskCategoryId === "8") && (

                                              // <button class="btn btn-primary min-wid-90 ml-10" onClick={that.getDownload.bind(that, item.taskId,item.reportType)}>Report Download</button>  
                                              <button class="btn btn-primary btn-sm min-wid-90 ml-10" onClick={that.dataExceptionReport.bind(that, item.taskId,item.reportType)}>Exception Report</button>
                                            )}
                          

                                        {item.imageFlag == '1' ?
                                          <a class="ml-10" onClick={that.imagemodalopen.bind(that, item.taskId)}>
                                            <img style={{ height: '30px' }} src={require("./img/gallery.png")}></img>
                                          </a>
                                          :
                                          <a class="ml-10" style={{ cursor: "default" }}>
                                            <img style={{ height: '30px', visibility: "hidden" }} src={require("./img/gallery.png")}></img>
                                          </a>
                                        }
                                        <span className="d-none">{item.taskId}</span>

                                     
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </Scrollbars>
                        </div>
                      </div>

                      <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12" hidden >
                        <ExcelFile element={<button class="btn btn-primary min-wid-90 mt-17" ref="ReportDownload"> Download</button>}>
                          <ExcelSheet dataSet={ReportDataSet} name="Reports" />
                        </ExcelFile>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </section>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.TaskactiveBind.bind(this)}>Back</button>
          </div>
        </div>

      );
    }
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("-");
  }



  GetReportTask = () => {
    var Request1 = {
      userId: this.props.location.state.userid,
      roleId: this.props.location.state.role_id,
      startDate: this.convert(this.state.startDate),
      endDate: this.convert(this.state.endDate),
      guId: this.props.location.state.guId
    }
    console.log("request1", Request1)
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/GetReportTaskList', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })

      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {
          this.setState({ data: DecryptedResponse1.reporttasklist });

          this.setState({ loading: false });
          console.log("response", DecryptedResponse1.reporttasklist)
        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }




  genrate = (taskId, reportType, e) => {
     console.log("TaskId", taskId)
   //this.dataAndSummaryReport("", "", "");   
    this.setState({ loading: true })

    if (this.state.reportcatvalueid[0] == undefined) {
      this.setState({ loading: false })
      showSuccess('Please select report category.')
    }


    else {
      if (this.state.reportcatvalueid[0] == "1" && this.state.arrayequipid.length == "0") {
        this.setState({ loading: false })
        showSuccess('Please select equipment category.')
      }
      else {


        var Request = {
          "taskId": taskId,
          "userId": this.props.location.state.userid,
          "roleId": this.props.location.state.role_id,
          "equipCategId": this.state.arrayequipid.toString(),
          "reportId": this.state.reportidvalue.toString(),
          "reportType": "8"
        }

        console.log("request1", Request)
        
        // var url = `/GetReportDetails?key=${Encrypt(Request)}`;


        fetch(`/GetReportDetails?key=${Encrypt(Request)}`,
          {
            method: 'GET',
            headers: {
              guId: this.props.location.state.guId,
              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

            }

          }

        ).then(data => {
          console.log("CHECK-FFF")
          return data.blob();
        })
          .then((data) => {
           
            console.log("CHECK-FFFKKK", data)
            const a = document.createElement("a");

            const b = new File([data], 'Test.xlsx', {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            // a.href = URL.createObjectURL(b);
            // a.hidden = true;
            //  a.download = taskId;
            // document.body.appendChild(a)
            // a.click();
            // a.parentNode.removeChild(a);
            // this.setState({ loading: false })
       //   })
                 const blobUrl = URL.createObjectURL(b);
                  this.setState({ reportUrl: blobUrl });
                  this.setState({
                    reportStatusMessage:
                      "Report Generation is in progress.\n1) Click on refresh to fetch the report.\n2) Click on download",
                  });
                  this.confirmAlertRef.onClose();
                  this.confirmAlertRef = null;
                  this.setState({
                    loading: false,
                  });
                  console.log("TT",taskId);
                   this.dataAndSummaryReport(taskId);
                })   
                .catch(error => {
                  console.error("Error generating report:", error);
                  this.setState({ loading: false });
                  showSuccess('Error generating report. Please try again.');
              });            
       }
      
     }  
  }

 


  getDownload = (taskId, reportType, e) => {
    
    this.setState({ loading: true })

    if (this.state.reportcatvalueid[0] == undefined) {
      this.setState({ loading: false })
      showSuccess('Please select report category.')
    }


    else {
      if (this.state.reportcatvalueid[0] == "1" && this.state.arrayequipid.length == "0") {
        this.setState({ loading: false })
        showSuccess('Please select equipment category.')
      }
      else {


   

        var Request = {
          "taskId": taskId,
          "userId": this.props.location.state.userid,
          "roleId": this.props.location.state.role_id,
          "equipCategId": this.state.arrayequipid.toString(),
          "reportId": this.state.reportidvalue.toString(),
          "reportType": "8"
        }
       
        console.log("request1", Request)
       

        var url = `/GetReportDetailsExceptions?key=${Encrypt(Request)}`;
        //var url = `/GetReportDetailsExceptions=TX3TAb8GfH5XXE7IPBpbVyIygRUQFqsnPnvyyCQmnv4ubJOHQ6Um7ME6RsIFo6RhOvhkXFmspHx6eWk8RC%2B4kd2BxrISV1IjpSdOVIItEpzs2Rzus6aaMX6CPV7RIMmPqtGzAD%2FW0A%2F%2FbwuOqXoyG0W0YGwOKy%2FqswrFxCVpbmzDMpvP5u7cp18FonPLnjhQEve1Qfc8b8Mx4zzQ%2FF6ZOw753cn22LkdcYb00yqwaOTSbF2FCf5Rq3jUTsRDd6bdwHmQ9wf7J8j2ReMsO9QH%2Fx3jTYZAdwlwtHhg6rH1QBzNw58Dc0Cri6OM3mh3qi%2Bka%2BBt83b00mmLkyi%2FKm1dIFmHp0yDUVWRQnQGxZeBCYfLBUu421ZAbZ5m%2FK4lG%2FIF3N4%2F%2FAxPirSFLGakKNQUjShjXOfXcQxMTWqpHaToSd0QlJoE4iP0P7L%2BsUCVOkw3Be0pxsCLincuOTcdo0JpcLvE3dm0UZOJg4bWq6hGVZuh2eLl2wX9FxSNUCSYZWEyW%2BdkeK7gByfqqzw7bMkJFj9ojk3sg9634nLikNMme23XIDk%2FKR76%2FhhqAkhgdOsrNszoK0%2F3vagjKH67VoV1l87MJ1csSuqg%2BxbAvEqsqkqI5WlN1tcQx4W6gGk9km8Yb86NF%2BR0qtyMd8dypBEXAoSkkBvR0lsK85Bn1Ghw5VPkCnmLi8YbX6ewpkvXmGIZG1efNHJBuMkh%2FvI7bQfE7DVxV77uX4k797or1Yqjq%2B3bKDwwcit0QEzvxyRwSnJl7IcOKU1KpjKoNa5q%2FIu6D%2FI5LeDdcdb5MKInbqnFZSIQaup%2FZ9CQ8Q5cHku0mU9hOkJ5v3Vbz2LDM9Abz9WkPez3LXmKrNb1MSxS7OgkrCVOlErrNGdpPkuTj5MnreVDCSA3BBBSGzgCEdo6kmDqdDkf%2Fs8c6mVvORjlRCe3KmE9PtmRw4DeecIi%2BhWMHx74czciQ7CecNHleNlO9XsDGY7B%2FcYzb%2BJr8JCGPEINFAs57n7QoK%2B%2F0WdjYOVX048Y3pGCg9T7Yiu1aROTKn%2BgogNyjzlE46oluU9xYbNNC3s7yWSe5Qtp5bE5STOrUZ1m1nPXOltlKndqbsUB3ylsEBMJ8w%2BL1s2T3%2FimyMWW1bwCQ6rfn4qUk5P2ssAwT10AArh0GMVcPcahFKd0YNpq93IMlGgtjNptbbBEEr24p32VoQGQflTVrTdJaBDlx%2F1%2BW671Qm9e2q9oza%2B79fqEnG%2ByldPx8jbWQQfhj%2FVy7oThFQQOrMmboQvJ9ZnbuKdmk%2BXZd%2FAgV%2BFi2VUOU8vt3Bvb56xvjElLz7Bre%2BhNODiBk%2BkG364UArrU%2FC2RhY9774fot0jSsvCAxR0CL45bcQ%3D%3D`;

        fetch(url,
          {
            method: 'GET',
            headers: {
              guId: this.props.location.state.guId,
              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

            }

          }

        ).then(data => {
          console.log('CHECK-A',data)
          return data.blob();

        })
          .then((data) => {
            console.log("CHECK-B",data);
            const a = document.createElement("a");

            const b = new File([data], '', {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

          //   a.href = URL.createObjectURL(b);
          //   a.hidden = true;
          //   a.download = taskId ;
          //   document.body.appendChild(a)
          //   a.click();
          //   a.parentNode.removeChild(a);
          //   this.setState({ loading: false })
          // })
          const exceptionBlobUrl = URL.createObjectURL(b);
          this.setState({ exceptionReportUrl: exceptionBlobUrl });
          this.setState({
            reportStatusExceptionMessage:
              "Report Generation is in progress.\n1) Click on refresh to fetch the report.\n2) Click on download",
          });
          this.confirmAlertRef.onClose();
          this.confirmAlertRef = null;
          this.setState({
            loading: false,
          });
          console.log("TT",taskId);
           this.dataExceptionReport(taskId);
        })   
        .catch(error => {
          console.error("Error generating report:", error);
          this.setState({ loading: false });
          showSuccess('Error generating report. Please try again.');
      });            
      }
    }
  }
  
  


















  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  Reports() {     
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {

      this.setState({
        loading: true,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        guId: this.props.location.state.guId

      })

      var Request1 = {
        "userId": this.props.location.state.userid,
        "roleId": this.props.location.state.role_id,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/GetReportTaskList', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ data: DecryptedResponse.reporttasklist });
            this.setState({ loading: false });

          }
          else {
            this.setState({ loading: false });
            showSuccess(DecryptedResponse.responseMsg)

          }

        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
         
        });





      fetch('/GetReportCategory', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            let temp = [];
            let temp1 = [];
            var equipflag = [];
            for (var i = 0; i < DecryptedResponse.categoryreportlist.length; i++) {
              temp.push(DecryptedResponse.categoryreportlist[i].reportCategoryid)
              temp1.push(DecryptedResponse.categoryreportlist[i].reportName)
              equipflag.push(DecryptedResponse.categoryreportlist[i].equipmentFlag)
            }
            this.setState({ reportcatID: temp });
            this.setState({ reportcat: temp1 });
            this.setState({ equipflag: equipflag })

          }

          else {
            showAlert(DecryptedResponse.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });


      fetch('/GetEquipmentCategory', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            let temp = [];
            let temp1 = [];

            for (var i = 0; i < DecryptedResponse.equipcatlist.length; i++) {
              temp.push(DecryptedResponse.equipcatlist[i].equipCategoryId)

              temp1.push(DecryptedResponse.equipcatlist[i].categoryName)

              var temp2 = {
                label: "",
                value: ""
              };

              temp2.label = DecryptedResponse.equipcatlist[i].categoryName
              temp2.value = DecryptedResponse.equipcatlist[i].categoryName
              this.state.equipment.push(temp2);
            }
            this.setState({ equipmentId: temp });


          }

          else {
            showAlert(DecryptedResponse.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });

    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }

  }


}

/*ReportTask = () => {
  var that = this;
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  var date =
    new Date().getDate() +
    "-" +
    monthNames[new Date().getMonth()] +
    "-" +
    new Date().getFullYear();

  var systemtime =
    date + " " + new Date().getHours() + ":" + new Date().getMinutes();
  var windowtime = document.getElementById("extdate").value;
}
*/





export default Reports;
