import disableBrowserBackButton from 'disable-browser-back-navigation';
import React, { Component } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import IdleTimer from 'react-idle-timer'; // For Idle
import Spinner from 'react-spinner-material'; //Import Spinner
import { basicAuth } from './basicAuth';
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { showAlert, showSuccess } from './services/ConfirmAlert';


const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

class QuestionPreviewForCompletedTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      loaded: this.props.location.state,
      taskStores: [],
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      file: "",
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false,   //Added by chandrani for custom error page
      viewdate: true,
      questionid: "",
      rformatlist: [],
      rformatId: [],
      formerMasterList: [],
      valueid: [],
      value: "",
      reasonText: "", status: "",
      data: [],
      categoryName: [],
      managerApproval: "",
      afmApproval: "",
      euipcatidentity: [],
      rejectQuestid: [],
      sendquestion: [],
      storeNo: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      guId: "",
      TaskcategoryId: "",
      TaskcategoryName: "",
      storeManagerComment: "",
      AFMComment: "",
      zonalStatus: "",
      fvalues: [],
      taskDetails: ''
    };

    this.state = {
      TaskDropdwn: false,
      data: [],
      taskId: "",
      usrnm: "",
      role_id: "",
      quesid: [],
      ans: [],
      loading: false,
      taskStores: [],
      file: "",
      fileval: "Upload file",
      fileuploaded: [],
      nooffileques: 0,
      filestr: "",
      fileupmsg: "",
      storenumber: "",
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      datapresent: false,
      startdateupdateticker: "",
      UpdatesDate: "",
      storeval: "",
      questionid: "",
      rformatlist: [],
      rformatId: [],
      formerMasterList: [],
      valueid: [],
      value: "",
      reasonText: "",
      status: "",
      categoryName: [],
      managerApproval: "",
      afmApproval: "",
      euipcatidentity: [],
      rejectQuestid: [],
      sendquestion: [],
      storeNo: "",
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      guId: "",
      TaskcategoryId: "",
      TaskcategoryName: "",
      storeManagerComment: "",
      AFMComment: "",
      zonalStatus: "",
      fvalues: [],
      taskDetails: ''

    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handletickersupdate = this.handletickersupdate.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)

  }

  openproof = (param, evt) => {
    evt.preventDefault();
    console.log(`fileuploaded`, this.state.fileuploaded)
    var string;
    var qlen = param.length;
    if (this.state.fileuploaded.length > 0) {
      for (let index = 0; index < this.state.fileuploaded.length; index++) {
        const element = this.state.fileuploaded[index];
        console.log(`element`, element)
        if (element.includes(param, 0)) {
          var elelen = element.length;
          string = element.substring(qlen, elelen);
          console.log(`string`, string)
          if (string == "null") {

          }
          else {
            var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
          }
        }
        else {

        }

      }
    }
    else {
      showAlert("No files uploaded yet")


    }

  }

  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    console.log(EncryptedRequest1)

    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)

        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)


        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }



  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }
  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  toggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,

    }));

  }


  feedback = () => {
    this.setState((prevState) => ({
      reviewofSM: !prevState.reviewofSM
    }))
  }

  reject = () => {
    this.setState((prevState) => ({
      togglereject: !prevState.togglereject,

    }));

  }

  modalclose = () => {
    this.setState({
      toggle: false,
    })
  }


  feedbackclose = () => {
    this.setState({
      reviewofSM: false
    })
  }

  modalclosereject = () => {
    this.setState({
      togglereject: false,
    })
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }
  dropdownselect = (e) => {
    this.setState({
      datapresent: false,
      data: [],
      storeval: e.label
    })
  }

  componentWillMount() {

    this.fetchQuestionPaper()

  }


  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }


  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          tskId: this.state.tskId,
          tskName: this.state.tskName,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          data: this.state.data,
          tskId: this.state.tskId,
          tskName: this.state.tskName,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }


  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  handlequestioncheck = (questionides, e) => {
    console.log(`questionides`, questionides)
    console.log(`e`, e.currentTarget.checked)
    var newEqReq = {
      questionId: "",
      equipmentNo: "",
    }
    if (e.currentTarget.checked) {
      newEqReq.questionId = questionides
      this.state.sendquestion.push(newEqReq)
    }
    else {
      for (let g = 0; g < this.state.sendquestion.length; g++) {
        if (this.state.sendquestion[g].questionId == questionides)
          this.state.sendquestion.splice(g, 1)
      }
    }
    console.log(`this.state.sendquestion`, this.state.sendquestion)
  }

  // handlequestiontext = (questionides, e) => {
  //   console.log(`questionides`, questionides)
  //   console.log(`e`, e.currentTarget.text)
  //   var newEqReq = {
  //     questionId: "",
  //     equipmentNo: "",
  //   }
  //   if (e.currentTarget.text) {
  //     newEqReq.questionId = questionides
  //     this.state.sendquestion.push(newEqReq)
  //   }
  //   else {
  //     for (let g = 0; g < this.state.sendquestion.length; g++) {
  //       if (this.state.sendquestion[g].questionId == questionides)
  //         this.state.sendquestion.splice(g, 1)
  //     }
  //   }
  //   console.log(`this.state.sendquestion`, this.state.sendquestion)
  // }







  handletickersupdate(dates) {
    this.setState({ startdateupdateticker: dates });
    this.setState({ UpdatesDate: dates });
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }



  reviewSMhandler = (key, e, params) => {
    this.state.fvalues[key] = e.target.value
    console.log(`e.target`, this.state.fvalues)

  }

  render = () => {
    var that = this
    var ReassignTask = []
    var RejectTask = []
    var Comment = []
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    Comment.push(
      <div className="imgalign"><a class="mr-50" onClick={this.feedback.bind(this)}><img class='mr-10' style={{ height: '40px' }} src={require("./img/comment.png")}></img><b>Comment</b></a></div>
    )


    ReassignTask.push(
      <div className="imgalign"><a class="mr-50" onClick={this.toggle.bind(this)}><img class='mr-10' style={{ height: '40px' }} src={require("./img/right.jpg")}></img><b>Approve Task</b></a></div>
    )


    RejectTask.push(
      <div className="imgalign"><a class="mr-50" onClick={this.reject.bind(this)}><img class='mr-10' style={{ height: '30px' }} src={require("./img/wrong.png")}></img><b>Reject Task</b></a></div>
    )

    var modal = [];
    var modal1 = [];
    var modal2 = [];
    var that = this;

    modal.push(
      <div className="modal" role="dialog" style={this.state.toggle ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.modalclose}>X</a>
              <h4 className="modal-title">Approve</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">
<h5  style={{"color":"red"}}>Below Task is completed by FM representative.</h5>
                  <br />
                  <h4 className="control-label col-sm-4">Comment</h4>
                  <div className="col-sm-8">
                    <textarea type="text" class="form-control" id="comment" autoComplete="off" maxLength="200"></textarea>
                  </div>
                  <br />
                  <h4 className="control-label col-sm-4">&nbsp;</h4>


                 
                  { this.state.role_id == "506" || this.state.role_id == "508" ?
                    <div className="col-sm-8">
                      <button class="btn btn-primary min-wid-90 mt-17"
                        onClick={this.ReopenTask}

                      >Approve</button>
                    </div> : <div className="col-sm-8">
                      <button class="btn btn-primary min-wid-90 mt-17"
                        onClick={this.ReopenAFMTask}

                      >Approve</button>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );






    modal1.push(
      <div className="modal" role="dialog" style={this.state.togglereject ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.modalclosereject}>X</a>
              <h4 className="modal-title">Reject</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal">
                <div className="form-group">



                  <h4 className="control-label col-sm-4">Reason</h4>

                  <div className="col-sm-8" style={{ marginTop: "15px" }}>
                    <select class="form-control" id="SelQType1"
                      onChange={this.formatSelection.bind(this)}

                    >
                      <option>Select</option>


                      {that.state.formerMasterList.map(function (item, key) {
                        return (
                          <option>{item.reasonText}</option>

                        )
                      })}

                    </select>
                  </div>


                  <div className="clearfix">
                    <h5 className="control-label col-sm-4">&nbsp;</h5>

                  </div>
                  <h4 className="control-label col-sm-4">Comment</h4>
                  <div className="col-sm-8">
                    <textarea type="text" class="form-control" id="commented" autoComplete="off" maxLength="200"></textarea>
                  </div>
                  <br />




                  <h4 className="control-label col-sm-4">&nbsp;</h4>


                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.RejectTask}

                    >Reject</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );


    modal2.push(
      <div className="modal" role="dialog" style={this.state.reviewofSM ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.feedbackclose}>X</a>
              <h4 className="modal-title">Comment</h4>
            </div>
            <div className="modal-body  box-scroll">
              <div className="form-horizontal">
                <div className="form-group">

                  <br />
                  <div className="custom-selection ml-0 pl-0">
                    <h4>1) Quality & Effectiveness of Soft Services (HK and PC)<font color="red">*</font></h4>

                    <input type="radio" class="custom-control-input" id="1" value="Excellent" onChange={this.reviewSMhandler.bind(this, 0)} name="A1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="1" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="2" value="Good" onChange={this.reviewSMhandler.bind(this, 0)} name="A1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="2" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="3" value="Poor" onChange={this.reviewSMhandler.bind(this, 0)} name="A1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="3" style={{ "margin-right": "10px" }}>Poor</label>


                    <h4 >2) Quality of Repair and Maintenance of FM equipment's at the Stores / Sites (MEPC and CAMC)<font color="red">*</font></h4>

                    <input type="radio" class="custom-control-input" id="4" value="Excellent" onChange={this.reviewSMhandler.bind(this, 1)} name="B1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="4" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="5" value="Good" onChange={this.reviewSMhandler.bind(this, 1)} name="B1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="5" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="6" value="Poor" onChange={this.reviewSMhandler.bind(this, 1)} name="B1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="6" style={{ "margin-right": "10px" }}>Poor</label>

                    <h4>3) FM team displays a sense of urgency in addressing issues and faults<font color="red">*</font></h4>
                    <input type="radio" class="custom-control-input" id="7" value="Excellent" onChange={this.reviewSMhandler.bind(this, 2)} name="C1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="7" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="8" value="Good" onChange={this.reviewSMhandler.bind(this, 2)} name="C1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="8" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="9" value="Poor" onChange={this.reviewSMhandler.bind(this, 2)} name="C1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="9" style={{ "margin-right": "10px" }}>Poor</label>

                    <h4>4) Accessibility & availability of FM team members in emergencies<font color="red">*</font></h4>
                    <input type="radio" class="custom-control-input" id="10" value="Excellent" onChange={this.reviewSMhandler.bind(this, 3)} name="D1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="10" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="11" value="Good" onChange={this.reviewSMhandler.bind(this, 3)} name="D1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="11" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="12" value="Poor" onChange={this.reviewSMhandler.bind(this, 3)} name="D1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="12" style={{ "margin-right": "10px" }}>Poor</label>

                    <h4>5) Effectiveness of "Store Connect" /"FM Self Audit"  Programs in Store/Supplying Sites<font color="red">*</font></h4>
                    <input type="radio" class="custom-control-input" id="13" value="Excellent" onChange={this.reviewSMhandler.bind(this, 4)} name="E1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="13" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="14" value="Good" onChange={this.reviewSMhandler.bind(this, 4)} name="E1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="14" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="15" value="Poor" onChange={this.reviewSMhandler.bind(this, 4)} name="E1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="15" style={{ "margin-right": "10px" }}>Poor</label>

                    <h4>6) How would you rate the overall performance of FM services?<font color="red">*</font></h4>
                    <input type="radio" class="custom-control-input" id="16" value="Excellent" onChange={this.reviewSMhandler.bind(this, 5)} name="F1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="16" style={{ "margin-right": "10px" }}>Excellent</label>
                    <input type="radio" class="custom-control-input" id="17" value="Good" onChange={this.reviewSMhandler.bind(this, 5)} name="F1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="17" style={{ "margin-right": "10px" }}>Good</label>
                    <input type="radio" class="custom-control-input" id="18" value="Poor" onChange={this.reviewSMhandler.bind(this, 5)} name="F1" style={{ "margin-left": "5px", "margin-right": "2px" }} /><label for="18" style={{ "margin-right": "10px" }}>Poor</label>

                    <h4 >7) Comment <font color="red">*</font></h4>

                    <div className="col-sm-8">
                      <textarea type="text" class="form-control" id="commentforaudit" autoComplete="off" maxLength="200"></textarea>
                    </div>
                    <br />
                    <h4 className="control-label col-sm-4">&nbsp;</h4>


                  </div>



                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.Surveyopen}

                    >Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );




    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <div>
          {modal}
          {modal1}
          {modal2}

          <div className="spin">
            <Spinner visible={this.state.loading}
              spinnerColor={"rgba(0, 0, 0, 0.3)"} />
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>



            <header class="header black-bg">

              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
              <a onClick={this.TaskactiveBind.bind(this)} class="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right">
                <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}({this.state.storeNo})</b> <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
               <h5 style={{"color":"red"}}>Exception captured by FM representative are marked in RED color for further action by FM </h5>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3><i className="fa fa-angle-right"></i>{this.state.tskName}</h3>
                      <p>Description : {this.state.tskDescr}</p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6" >Start Date : {this.state.strtDate} </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">End Date : {this.state.edDate}</div>
                    <div class="clearfix"></div>
                    <br />
                    <div>                   {this.state.managerApproval == "Rejected" ?

                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6" style={{ "font-weight": "bold" }}>Reason : <span style={{ "color": "red" }}> {this.state.reasonText}</span>
                      </div>
                      : null}
                    </div>

                    {this.state.role_id == "300" ?
                      <div>
                        {this.state.storeManagerComment ? <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6" style={{ "font-weight": "bold" }}>Level 1 Comment : <span style={{ "color": "red" }}> {this.state.storeManagerComment}</span></div> : ''}
                        {this.state.AFMComment ? <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6" style={{ "font-weight": "bold" }}>Level 2 Comment : <span style={{ "color": "red" }}> {this.state.AFMComment}</span></div> : ''}

                      </div> : null}
                    <hr />


                    <br />

                    <br />

                    {this.state.managerApproval === 'Pending' && this.state.afmApproval == 'Submitted' ?
                      <div>
                        {Comment}
                      </div>
                      : ''}

                    {(this.props.location.state.level1Status === 'Submitted' && this.props.location.state.levelNo === '2') || (this.props.location.state.level2Approval === 'Approved' && this.props.location.state.levelNo === '3') ?
                      <div>
                        {ReassignTask}

                        {RejectTask}

                      </div> : null}



                    {this.state.loading = false}


                    <ul class="ques">
                      {this.state.data ?
                        (
                          this.state.data.map(function (xitem, xkey) {
                            return (
                              <Accordion allowZeroExpanded>
                                <AccordionItem>
                                  <div key={xkey}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton  >
                                        <b id="accordionopen" style={{ textAlign: "center" }}>Category Name :{xitem.categoryName}({xitem.listQuestions.length})</b>

                                        <div style={{ "float": "right" }}>
                                          <input type="checkbox"
                                            defaultChecked={that.state.euipcatidentity.includes(xitem.equipCategId) ? true : false}
                                            disabled /><span style={{ "color": "red" }}><b>Not Applicable</b></span>
                                        </div>


                                        
                                       
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>


                                      {that.state.data[xkey].listQuestions.map(function (item, key) {


                                        switch (item.questionType) {

                                          case "1":

                                            return (

                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0">
                                          
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                          
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      />
                                                      
                                                      
                                                      {/* <input type="text"
                                                        defaultSelected={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestiontext.bind(that, item.questionId)}
                                          
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      /> */}
                                                      
                                              
                                              
                                              
                                                       
                                                      
                                                      
                                                      
                                                      
                                                      
                                                      <span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}






                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  {item.options.map((optele, index) => (
                                                    
                                                    <span class="nameloc">
                                                
                                                      <input type="radio"
                                                        class="custom-control-input" id={item.questionId + index}
                                                        name={item.questionId}
                                                        value={optele}
                                                        {...item.answer === optele ?
                                                          that.state.checked = true
                                                          :
                                                          that.state.checked = false
                                                        }
                                                        checked={that.state.checked}
                                                        defaultChecked={that.state.checked}
                                                        disabled="disabled"
                                                      />
                                                     
                                                      {item.exceptions ? (
                                                        <label class="custom-control-label" style={{ color: item.exceptions.includes(optele) ? 'red' : 'black' }} for={item.questionId + index}>{optele}</label>
                                                      ) : (
                                                        <label class="custom-control-label" for={item.questionId + index}>{optele}</label>
                                                      )}

                                                    </span>
                                                  )
                                                  )}
                                                </div>
                                              </li>


                                            );
                                            break;
                                          case "2":
                                            return (
                                              <li key={key}>

                                                <div class="custom-selection ml-0 pl-0">

                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}

                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}

                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}


                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  {item.options.map((optele) => (
                                                    <span class="nameloc" >
                                                      <input type="checkbox" class="custom-control-input" id="customCheck-1"
                                                        name={item.questionId}
                                                        disabled="disabled"
                                                        {...item.answer !== undefined ?
                                                          {
                                                            ...item.answer.includes(optele) ?
                                                              that.state.checked = true
                                                              :
                                                              that.state.checked = false
                                                          } : null}
                                                        checked={that.state.checked}
                                                        defaultChecked={that.state.checked}
                                                      />
                                                      {item.exceptions ? (
                                                        <label class="custom-control-label" style={{ color: item.exceptions.includes(optele) ? 'red' : 'black' }} for="customCheck-1">{optele}</label>
                                                      ) : (
                                                        <label class="custom-control-label" for="customCheck-1">{optele}</label>
                                                      )}

                                                    </span>
                                                  ))}

                                                </div>
                                              </li>
                                            );
                                            break;
                                          case "3":
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0" >

                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}


                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}


                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input type="text" disabled="disabled" class="form-control" placeholder="Some Text"
                                                      name={item.questionId}
                                                      defaultValue={item.answer}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            );
                                            break;
                                          case "4":
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0" >
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}


                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}



                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input type="text" disabled="disabled" class="form-control" placeholder="Number Only"
                                                      name={item.questionId}
                                                      defaultValue={item.answer}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            );
                                            break;
                                          case "5":
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0" >

                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}

                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}

                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}


                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input type="email" disabled="disabled" class="form-control" placeholder="Email-ID"
                                                      name={item.questionId}
                                                      defaultValue={item.answer}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            );
                                            break;
                                          case "6":
                                            var vchky = "";
                                            var vchkn = "";
                                            if (item.answer === "Yes") {
                                              vchky = true;
                                              vchkn = false;
                                            }
                                            else if (item.answer === "No") {
                                              vchky = false;
                                              vchkn = true;
                                            }

                                            return (
                                              <li key={key}>
                                                <div class="custom-selection  ml-0 pl-0">
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}


                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}
                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <span class="ml-10">
                                                    <input type="radio" class="custom-control-input" id="customRadio1-11"
                                                      name={item.questionId}
                                                      disabled="disabled"
                                                      checked={vchky}
                                                      value="Yes"

                                                    />
                                                    <label class="custom-control-label" for="customRadio1-11">Yes</label>
                                                  </span> <span>
                                                    <input type="radio" class="custom-control-input" id="customRadio2-22" name="ques-1"
                                                      // name={item.questionId}
                                                      checked={vchkn}
                                                      disabled="disabled"
                                                      value="No"

                                                    />
                                                    <label class="custom-control-label" for="customRadio2-22">No</label>
                                                  </span> </div>
                                              </li>
                                            );
                                            break;
                                          case "7":
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0">
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}


                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}




                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <span class="ml-10">
                                                    <input type="radio" class="custom-control-input" id="customRadio1-1"
                                                      name={item.questionId}
                                                      disabled="disabled"
                                                      {...item.answer === "Yes" ?
                                                        that.state.checked = true
                                                        :
                                                        that.state.checked = false
                                                      }
                                                      value="Yes"
                                                      checked={that.state.checked}
                                                    />
                                                    <label class="custom-control-label" for="customRadio1-1">Yes</label>
                                                  </span> <span>
                                                    <input type="radio" class="custom-control-input" id="customRadio2-2"
                                                      name={item.questionId}
                                                      disabled="disabled"
                                                      {...item.answer === "No" ?
                                                        that.state.checked = true
                                                        :
                                                        that.state.checked = false
                                                      }
                                                      checked={that.state.checked}
                                                    />
                                                    <label class="custom-control-label" for="customRadio2-2">No</label>
                                                  </span> <span>
                                                    <input type="radio" class="custom-control-input" id="customRadio3-3"
                                                      name={item.questionId}
                                                      disabled="disabled"
                                                      {...item.answer === "NA" ?
                                                        that.state.checked = true
                                                        :
                                                        that.state.checked = false
                                                      }
                                                      checked={that.state.checked}

                                                    />
                                                    <label class="custom-control-label" for="customRadio3-3">NA</label>
                                                  </span> </div>
                                              </li>
                                            );
                                            break;
                                          case "8":

                                            var filestr = "";

                                            var array = [];
                                            var hrf = "";
                                            var disble = "";
                                            if (item.imageArray.length > 0) {
                                              that.state.fileupmsg = "File Uploaded"
                                            }
                                            else {
                                              that.state.fileupmsg = "Upload file"
                                            }
                                            if (that.state.fileuploaded.length > 0) {
                                              for (let index = 0; index < that.state.fileuploaded.length; index++) {
                                                const element = that.state.fileuploaded[index];

                                                if (element.includes(item.questionId, 0)) {
                                                  var elelen = element.length;
                                                  filestr = element.substring(item.questionId.length, elelen);
                                                  if (filestr != "null") {
                                                    array.push(filestr)
                                                  }

                                                  that.state.fileval = "File uploaded";
                                                }
                                              }
                                            }
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0">
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}




                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input id="store-list" type="text" class="form-control input-upload" value={that.state.fileupmsg} disabled="disabled" />
                                                    <span class="btn-upload" style={{ padding: "7px 15px" }}>Upload</span>
                                                    <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" disabled="disabled" />
                                                  </div>
                                                  <div class="clearfix"></div>
                                                  <a href={that.state.fileuploaded} onClick={that.openproof.bind(that, item.questionId)}>View Answer</a>
                                                  /
                                                  {filestr.length > 0 ?
                                                    <div>
                                                      {array.map((image, index) => (
                                                        <div>
                                                          <a download={item.questionId + "_" + that.state.storenumber} href={image}>Download Answer({index + 1}) </a><br />
                                                        </div>)

                                                      )}       </div>
                                                    :
                                                    <a download={item.questionId + "_" + that.state.storenumber} >Download Answer</a>
                                                  }

                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            )

                                            break;

                                          case "9":
                                            var ansarr;
                                            if (item.answer === undefined || item.answer === null || item.answer === "") {
                                              ansarr = "";
                                            }
                                            else {
                                              ansarr = item.answer.split(",")
                                            }

                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0">

                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}



                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /> </p>
                                                  {item.options.map((optele, index) => (
                                                    <span class="ml-0 pl-0" >
                                                      {item.exceptions ? (
                                                        <p style={{ color: item.exceptions.includes(optele) ? 'red' : 'black' }} for={item.questionId + index}><b>{optele}</b></p>
                                                      ) : (
                                                        <p for={item.questionId + index}><b>{optele}</b></p>
                                                      )}
                                                      <p style={{ color: item.exceptions.includes(optele) ? 'red' : 'black' }} for={item.questionId + index}><b>{optele}</b></p>
                                                      <input type="text" disabled="disabled" class="form-control col-lg-1 col-md-1 col-sm-1 col-xs-4 "
                                                        {...ansarr.length > 0 ?
                                                          that.state.defval = ansarr[index]
                                                          :
                                                          that.state.defval = ""
                                                        }

                                                        defaultValue={that.state.defval}
                                                        name={item.questionId}
                                                      />
                                                    </span>
                                                  )
                                                  )}
                                                </div>
                                              </li>
                                            );
                                            break;

                                          case "10":
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0" >
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}



                                                  <p>{item.questionText}<that.Astrik isMandatory={item.isMandatory} /></p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input type="date" disabled="disabled" class="form-control"
                                                      defaultValue={item.answer}
                                                      name={item.questionId}
                                                    />
                                                  </div>
                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            );

                                            break;

                                          case "11":
                                            var filestr = "";

                                            var array = [];
                                            var hrf = "";
                                            var disble = "";
                                            if (item.imageArray.length > 0) {
                                              that.state.fileupmsg = "File Uploaded";
                                            } else {
                                              that.state.fileupmsg = "Upload file";
                                            }
                                            if (that.state.fileuploaded.length > 0) {
                                              for (
                                                let index = 0;
                                                index < that.state.fileuploaded.length;
                                                index++
                                              ) {
                                                const element =
                                                  that.state.fileuploaded[index];

                                                if (element.includes(item.questionId, 0)) {
                                                  var elelen = element.length;
                                                  filestr = element.substring(
                                                    item.questionId.length,
                                                    elelen
                                                  );
                                                  if (filestr != "null") {
                                                    array.push(filestr);
                                                  }

                                                  that.state.fileval = "File uploaded";
                                                }
                                              }
                                            }
                                            return (
                                              <li key={key}>
                                                <div class="custom-selection ml-0 pl-0">
                                                  {that.props.location.state.role_id == "505" && (that.state.managerApproval === "Approved" && that.state.euipcatidentity.includes(xitem.equipCategId) ? false : true) ?
                                                    <div style={{ "float": "right" }}>
                                                      <input type="checkbox"
                                                        defaultChecked={that.state.rejectQuestid.includes(item.questionId) ? true : false}
                                                        onChange={that.handlequestioncheck.bind(that, item.questionId)}
                                                        {
                                                        ...that.state.afmApproval == "Approved" ?
                                                          that.state.disabled = "disabled"
                                                          :
                                                          that.state.disabled = ""
                                                        }

                                                        disabled={that.state.disabled}
                                                      /><span style={{ "color": "red" }}><b>Reject</b></span>
                                                    </div>

                                                    : null}
                                                  <p>
                                                    {item.questionText}
                                                    <that.Astrik
                                                      isMandatory={item.isMandatory}
                                                    />
                                                  </p>
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                                    <input
                                                      id="store-list"
                                                      type="text"
                                                      class="form-control input-upload"
                                                      value={that.state.fileupmsg}
                                                      disabled="disabled"
                                                    />
                                                    <span
                                                      class="btn-upload"
                                                      style={{ padding: "7px 15px" }}
                                                    >
                                                      Upload
                                                    </span>
                                                    <input
                                                      id="upload-storedata"
                                                      type="file"
                                                      name="Store-list-data"
                                                      class="input-hidden"
                                                      disabled="disabled"
                                                    />
                                                  </div>
                                                  <div class="clearfix"></div>
                                                  <a
                                                    href={that.state.fileuploaded}
                                                    onClick={that.openproof.bind(
                                                      that,
                                                      item.questionId
                                                    )}
                                                  >
                                                    View Answer
                                                  </a>
                                                  /
                                                  {filestr.length > 0 ? (
                                                    <div>
                                                      {array.map((image, index) => (
                                                        <div>
                                                          <a
                                                            download={
                                                              item.questionId +
                                                              "_" +
                                                              that.state.storenumber
                                                            }
                                                            href={image}
                                                          >
                                                            Download Answer({index + 1}){" "}
                                                          </a>
                                                          <br />
                                                        </div>
                                                      ))}{" "}
                                                    </div>
                                                  ) : (
                                                    <a
                                                      download={
                                                        item.questionId +
                                                        "_" +
                                                        that.state.storenumber
                                                      }
                                                    >
                                                      Download Answer
                                                    </a>
                                                  )}
                                                </div>
                                                <div class="clearfix"></div>
                                              </li>
                                            );

                                            break;

                                          default:
                                            showAlert("Error displaying preview.")

                                            break;
                                        }
                                      })}</AccordionItemPanel>
                                  </div></AccordionItem>
                              </Accordion>
                            )
                          })
                        ) : (
                          null)

                      }</ul>
                    <br />

                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>

      );
    }
  }


  formatSelection = (e) => {
    this.setState({ value: e.target.value });

    let flag = []

    for (var i = 0; i < this.state.rformatlist.length; i++) {

      this.state.rformatlist[i].trim() === e.target.value ?
        flag.push(this.state.rformatId[i]) : this.setState({ valueid: "" })
    }
    this.setState({ valueid: flag })
  }

  fetchQuestionPaper = (e) => {

    this.setState({ loading: true })
    this.state.fileuploaded = [];
    this.setState({ fileval: "Upload file" })
    this.setState({ data: [] });


    fetch('/RejectionReason', {
      method: 'GET',
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }
    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode === '200 OK') {
          this.setState({ formerMasterList: DecryptedResponse.list });

          let list = [];
          let list1 = [];



          for (var i = 0; i < DecryptedResponse.list.length; i++) {
            list.push(DecryptedResponse.list[i].reasonId);
            list1.push(DecryptedResponse.list[i].reasonText);

          }
          this.setState({ rformatlist: list1 });
          this.setState({ rformatId: list });



        }
        else {
          showAlert(DecryptedResponse.errorMsg)

        }


      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });
      });

    if (this.props.location.state) {
      this.setState({
        role_id: this.props.location.state.role_id,
        usrnm: this.props.location.state.usrnm,
        UpdatesDate: this.props.location.state.edDate,
        storeNo: this.props.location.state.storeNo,
        tskName: this.props.location.state.tskName,
        tskDescr: this.props.location.state.tskDescr,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        guId: this.props.location.state.guId,
        TaskcategoryName: this.props.location.state.categoryName,
        TaskcategoryId: this.props.location.state.categoryId,
        zonalStatus: this.props.location.state.zonalStatus
      });

      this.setState({ storenumber: this.state.storeval });

      if (this.props.location.state.levelNo == "2" || this.props.location.state.levelNo == "3") {
        this.FetchAnsAdmin()
      }

      else {
        if (this.props.location.state.role_id !== "505") {
          this.fetchAFMQuestionPaper()
        }
        else {

          var Request1 = {
            "taskId": this.props.location.state.tskId,
            "storeNo": this.props.location.state.storeNo,
            "userId": this.props.location.state.technicianId,
            "guId": this.props.location.state.guId,
            // "equipmentNo" :"",

          }
          var EncryptedRequest1 = Encrypt(Request1);
          console.log(Request1)


          fetch('/GetTechDetails', {
            method: 'POST',
            body: EncryptedRequest1,
            headers: {
              guId: this.props.location.state.guId,
              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
            }

          })
            .then((response) => response.text())
            .then((response) => {
              var DecryptedResponse = decryptData(response)
              if (DecryptedResponse.responseCode === '200 OK' && DecryptedResponse.taskQuestionsList != undefined) {

                var category = []
                for (let k = 0; k < DecryptedResponse.taskQuestionsList.length; k++) {
                  category.push(DecryptedResponse.taskQuestionsList[k].equipCategoryName)
                }

                var item = [...new Set(category)]

                for (let s = 0; s < item.length; s++) {
                  var query = {
                    categoryName: "",
                    equipCategId: "",
                    listQuestions: [],

                  }
                  query.categoryName = item[s]
                  for (let g = 0; g < DecryptedResponse.taskQuestionsList.length; g++) {
                    if (DecryptedResponse.taskQuestionsList[g].equipCategoryName === item[s]) {
                      query.listQuestions.push(DecryptedResponse.taskQuestionsList[g])
                      query.equipCategId = DecryptedResponse.taskQuestionsList[g].equipCategId

                    }

                  }
                  console.log(`listquestion`, query)
                  this.state.categoryName.push(query)


                }



                console.log(`category`, this.state.categoryName)



                this.setState({ euipcatidentity: DecryptedResponse.equipcategoryID })



                this.setState({ data: this.state.categoryName });
                this.setState({ status: DecryptedResponse.taskDetails.status });
                if (DecryptedResponse.equipcategoryID[0] != "") {
                  this.setState({ equipmentrequest: DecryptedResponse.equipcategoryID })
                }



                this.state.datapresent = true;
                //this.setState({ file: responseJson.task.taskFile });
                var isimgq = "false";
                if (DecryptedResponse.taskQuestionsList.length > 0) {
                  for (let index = 0; index < DecryptedResponse.taskQuestionsList.length; index++) {
                    const one = DecryptedResponse.taskQuestionsList[index];
                    if (one.questionType === "8" || one.questionType === "11") {
                      if (one.imageArray === "") {
                        this.state.fileuploaded = [];
                        this.setState({ fileval: "Upload file" })

                      }
                      else {
                        for (let k = 0; k < one.imageArray.length; k++) { this.state.fileuploaded.push(one.questionId + one.imageArray[k]); }
                        console.log(`fileuploaded `, this.state.fileuploaded)
                        this.setState({ file: one.imageArray });
                        this.setState({ fileval: "One file attached" });
                        isimgq = "true";
                      }
                    }
                    if (isimgq === "false") {
                      this.state.fileuploaded = [];
                      this.setState({ fileval: "Upload file" })
                    }

                  }

                  document.getElementById("accordionopen").click();
                }
                this.setState({ loading: false })

              }

              else {
                this.setState({ loading: false })
                showAlert("No answers to display")
                this.setState({ datapresent: false })
              }
            })
            .catch((error) => {
              confirmAlert({
                title: 'Alert !',
                message: "Session expired",
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => { this.logoutnormal() }
                  },

                ]


              });
            });



        }
      }

    }

    else {
      this.props.history.push({
        pathname: '/',
      });
    }

  }





  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  Astrik = (props) => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red" > *</font>;
    }
    return null;
  }



  fetchAFMQuestionPaper = (e) => {

    this.setState({ loading: true })
    this.state.fileuploaded = [];
    this.setState({ fileval: "Upload file" })
    this.setState({ data: [] });

    if (this.props.location.state) {
      this.setState({
        role_id: this.props.location.state.role_id,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      });


      this.setState({ storenumber: this.state.storeval });

      var Request1 = {
        "taskId": this.props.location.state.tskId,
        "storeNo": this.props.location.state.storeNo,
        "userId": this.props.location.state.userid,
        "guId": this.props.location.state.guId,

      }
      var EncryptedRequest1 = Encrypt(Request1);
      console.log(Request1)


      fetch('/GetAfmTechDetails', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK' && DecryptedResponse.taskQuestionsList != undefined) {
            var category = []
            for (let k = 0; k < DecryptedResponse.taskQuestionsList.length; k++) {
              category.push(DecryptedResponse.taskQuestionsList[k].equipCategoryName)
            }

            var item = [...new Set(category)]
            console.log(`Equip`, item)

            for (let s = 0; s < item.length; s++) {
              var query = {
                categoryName: "",
                equipCategId: "",
                listQuestions: [],

              }
              query.categoryName = item[s]
              for (let g = 0; g < DecryptedResponse.taskQuestionsList.length; g++) {
                if (DecryptedResponse.taskQuestionsList[g].equipCategoryName === item[s]) {
                  query.listQuestions.push(DecryptedResponse.taskQuestionsList[g])
                  query.equipCategId = DecryptedResponse.taskQuestionsList[g].equipCategId

                }

              }
              console.log(`listquestion`, query)
              this.state.categoryName.push(query)


            }



            console.log(`category`, this.state.categoryName)




            this.setState({ euipcatidentity: DecryptedResponse.equipcategoryID })
            this.setState({ rejectQuestid: DecryptedResponse.rejectQuestID })
            this.setState({ data: this.state.categoryName });
            this.setState({ reasonText: DecryptedResponse.reasonText })

            this.setState({ managerApproval: DecryptedResponse.taskDetails.managerApproval })
            if (DecryptedResponse.taskDetails.afmApproval == "Approved" || DecryptedResponse.taskDetails.afmApproval == "Approved with Exception") {
              this.setState({ afmApproval: "Approved" })
            }



            this.state.datapresent = true;
            //this.setState({ file: responseJson.task.taskFile });
            var isimgq = "false";
            if (DecryptedResponse.taskQuestionsList.length > 0) {
              for (let index = 0; index < DecryptedResponse.taskQuestionsList.length; index++) {
                const one = DecryptedResponse.taskQuestionsList[index];
                if (one.questionType === "8" || one.questionType === "11") {
                  if (one.imageArray === "") {
                    this.state.fileuploaded = [];
                    this.setState({ fileval: "Upload file" })

                  }
                  else {
                    for (let k = 0; k < one.imageArray.length; k++) { this.state.fileuploaded.push(one.questionId + one.imageArray[k]); }
                    console.log(`fileuploaded `, this.state.fileuploaded)
                    this.setState({ file: one.imageArray });
                    this.setState({ fileval: "One file attached" });
                    isimgq = "true";
                  }
                }
                if (isimgq === "false") {
                  this.state.fileuploaded = [];
                  this.setState({ fileval: "Upload file" })
                }
              } document.getElementById("accordionopen").click();

            }
            this.setState({ loading: false })
          }

          else {
            this.setState({ loading: false })
            showAlert("No answers to display")
            this.setState({ datapresent: false })
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });



      fetch('/RejectionReason', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {

            console.log(`role`, DecryptedResponse)
            this.setState({ formerMasterList: DecryptedResponse.list });
            //this.setState({roleMasterList:DecryptedResponse.roleMasterList});

            let list = [];
            let list1 = [];



            for (var i = 0; i < DecryptedResponse.list.length; i++) {
              list.push(DecryptedResponse.list[i].reasonId);
              list1.push(DecryptedResponse.list[i].reasonText);

            }
            this.setState({ rformatlist: list1 });
            this.setState({ rformatId: list });



          }
          else {
            showAlert(DecryptedResponse.errorMsg)

          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        })
    }

    else {
      this.props.history.push({
        pathname: '/',
      });
    }

  }


  FetchAnsAdmin = () => {


    var Request1 = {
      "taskId": this.props.location.state.tskId,
      "storeNo": this.props.location.state.storeNo,
      "userId": this.props.location.state.technicianId,
      "guId": this.props.location.state.guId,

    }
    var EncryptedRequest1 = Encrypt(Request1);
    console.log(Request1)


    fetch('/GetHoTaskDetails', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode === '200 OK' && DecryptedResponse.taskQuestionsList != undefined) {

          var category = []
          for (let k = 0; k < DecryptedResponse.taskQuestionsList.length; k++) {
            category.push(DecryptedResponse.taskQuestionsList[k].equipCategoryName)
          }

          var item = [...new Set(category)]

          for (let s = 0; s < item.length; s++) {
            var query = {
              categoryName: "",
              equipCategId: "",
              listQuestions: []
            }
            query.categoryName = item[s]
            for (let g = 0; g < DecryptedResponse.taskQuestionsList.length; g++) {
              if (DecryptedResponse.taskQuestionsList[g].equipCategoryName === item[s]) {
                query.listQuestions.push(DecryptedResponse.taskQuestionsList[g])
                query.equipCategId = DecryptedResponse.taskQuestionsList[g].equipCategId

              }

            }
            console.log(`listquestion`, query)
            this.state.categoryName.push(query)


          }



          console.log(`category`, this.state.categoryName)




          this.setState({ euipcatidentity: DecryptedResponse.equipcategoryID })


          this.setState({ data: this.state.categoryName });
          this.setState({ storeManagerComment: DecryptedResponse.taskDetails.managerComment })
          this.setState({ AFMComment: DecryptedResponse.taskDetails.afmComment })
          // this.setState({ status: DecryptedResponse.taskDetails.status });

          this.state.datapresent = true;
          //this.setState({ file: responseJson.task.taskFile });
          var isimgq = "false";
          if (DecryptedResponse.taskQuestionsList.length > 0) {
            for (let index = 0; index < DecryptedResponse.taskQuestionsList.length; index++) {
              const one = DecryptedResponse.taskQuestionsList[index];
              if (one.questionType === "8" || one.questionType === "11") {
                if (one.imageArray === "") {
                  this.state.fileuploaded = [];
                  this.setState({ fileval: "Upload file" })

                }
                else {
                  for (let k = 0; k < one.imageArray.length; k++) { this.state.fileuploaded.push(one.questionId + one.imageArray[k]); }
                  console.log(`fileuploaded `, this.state.fileuploaded)
                  this.setState({ file: one.imageArray });
                  this.setState({ fileval: "One file attached" });
                  isimgq = "true";
                }
              }
              if (isimgq === "false") {
                this.state.fileuploaded = [];
                this.setState({ fileval: "Upload file" })
              }

            } document.getElementById("accordionopen").click();
          }
          this.setState({ loading: false })
        }

        else {
          this.setState({ loading: false })
          showAlert("No answers to display")

          this.setState({ datapresent: false })
        }
      })
      .catch((error) => {
        console.log(`err`, error)
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });
      });
  }






  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }

  Surveyopen = () => {


    if (!document.getElementById("commentforaudit").value) {
      showSuccess('Please enter comments.')

    }
    else {
      if (this.state.fvalues.filter(e => e != '').length == 6) {
        confirmAlert({
          message: 'Are you sure, you want to submit?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var Request1 = {
                  "managerId": this.props.location.state.userid,
                  "taskId": this.props.location.state.tskId,
                  "storeNo": this.props.location.state.storeNo,
                  "comments": document.getElementById("commentforaudit").value,
                  "status": "Approved",
                  "guId": this.props.location.state.guId,
                  "levelNo": this.props.location.state.levelNo,
                  "feedback": this.state.fvalues.toString()
                }
                var EncryptedRequest1 = Encrypt(Request1);
                console.log(EncryptedRequest1)
                fetch('/InsertManagerReview ', {
                  method: 'POST',
                  body: EncryptedRequest1,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })
                  .then((response) => response.text())
                  .then((response) => {
                    var DecryptedResponse = decryptData(response)
                    if (DecryptedResponse.responseCode === '200 OK') {

                      confirmAlert({
                        message: "Task approved successfully.",
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                              this.modalclose();
                              this.TaskactiveBind()
                            }
                          },

                        ]
                      })
                    }
                    else {
                      this.setState({ loading: false });
                      showSuccess(DecryptedResponse.responseMsg)

                    }



                  })
                  .catch((error) => {

                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });
                    // console.error(error);
                  });
              }
            },
            {
              label: 'No',
              onClick: () => { return false }
            }
          ]
        });



      }
      else {
        showSuccess("Please complete the survey")

      }


    }


  }




  ReopenTask = () => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    if (!document.getElementById("comment").value) {
      showSuccess('Please enter comments.')

    }
    else {

      confirmAlert({
        message: 'Are you sure, you want to approve task?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Request1 = {
                "managerId": this.props.location.state.userid,
                "taskId": this.props.location.state.tskId,
                "storeNo": this.props.location.state.storeNo,
                "comments": document.getElementById("comment").value,
                "status": "Approved",
                "guId": this.props.location.state.guId,
                "levelNo": this.props.location.state.levelNo
              }
              var EncryptedRequest1 = Encrypt(Request1);
              console.log(EncryptedRequest1)
              fetch('/InsertManagerReview ', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                }

              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode === '200 OK') {

                    confirmAlert({
                      message: "Task approved successfully.",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => {
                            this.modalclose();
                            this.TaskactiveBind()
                          }
                        },

                      ]
                    })
                  }
                  else {
                    this.setState({ loading: false });
                    showSuccess(DecryptedResponse.responseMsg)

                  }



                })
                .catch((error) => {

                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });
                  // console.error(error);
                });
            }
          },
          {
            label: 'No',
            onClick: () => { return false }
          }
        ]
      });


    }

  }





  ReopenAFMTask = () => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    if (!document.getElementById("comment").value) {
      showSuccess('Please enter comments.')
    }
    else {


      confirmAlert({
        message: 'Are you sure, you want to approve task?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Request1 = {
                "afmId": this.props.location.state.userid,
                "taskId": this.props.location.state.tskId,
                "storeNo": this.props.location.state.storeNo,
                "comments": document.getElementById("comment").value,
                "status": "Approved",
                "rejectQId": this.state.sendquestion,
                "guId": this.props.location.state.guId,
                //"extendedDate":document.getElementById("extend").value
              }
              var EncryptedRequest1 = Encrypt(Request1);
              console.log(EncryptedRequest1)
              fetch('/InsertAfmReview  ', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                }

              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode === '200 OK') {

                    confirmAlert({
                      message: "Task approved successfully.",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => {
                            this.modalclose();
                            this.TaskactiveBind()
                          }
                        },

                      ]
                    })
                  }
                  else {
                    this.setState({ loading: false });
                    showSuccess(DecryptedResponse.responseMsg)

                  }



                })
                .catch((error) => {

                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });
                  // console.error(error);
                });
            }
          },
          {
            label: 'No',
            onClick: () => { return false }
          }
        ]
      });


    }


  }





  RejectTask = () => {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    if (!document.getElementById("commented").value) {
      showSuccess('Please enter comments.')

    }
    else {
      if (document.getElementById("SelQType1").value == "Select") {
        showAlert("Please select reason .")

      }
      else {
        confirmAlert({
          message: 'Are you sure, you want to reject task?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var Request1 = {
                  "managerId": this.props.location.state.userid,
                  "taskId": this.props.location.state.tskId,
                  "storeNo": this.props.location.state.storeNo,
                  "comments": document.getElementById("commented").value,
                  "rejectionReasonId": this.state.valueid[0],
                  "status": "Rejected",
                  "guId": this.props.location.state.guId,
                  "levelNo": this.props.location.state.levelNo
                }
                var EncryptedRequest1 = Encrypt(Request1);
                console.log(EncryptedRequest1)
                fetch('/InsertManagerReview ', {
                  method: 'POST',
                  body: EncryptedRequest1,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })
                  .then((response) => response.text())
                  .then((response) => {
                    var DecryptedResponse = decryptData(response)
                    if (DecryptedResponse.responseCode === '200 OK') {

                      confirmAlert({
                        message: "Task rejected successfully.",
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                              this.modalclosereject();
                              this.TaskactiveBind()
                            }
                          },

                        ]
                      })
                    }
                    else {
                      this.setState({ loading: false });
                      showSuccess(DecryptedResponse.responseMsg)

                    }



                  })
                  .catch((error) => {

                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });
                    // console.error(error);
                  });
              }
            },
            {
              label: 'No',
              onClick: () => { return false }
            }
          ]
        });


      }
    }
  }



  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

}
export default QuestionPreviewForCompletedTask;