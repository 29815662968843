import disableBrowserBackButton from 'disable-browser-back-navigation';
import 'jquery/dist/jquery';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import { basicAuth } from './basicAuth';
import './css/react-confirm-alert.css'; // Import css 
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import { showSuccess } from './services/ConfirmAlert';



class Changepassword extends Component {
  state = {
    TaskDropdwn: false,
    userid: "",
    role_id: "",
    passwordFlag: "",
    hasError: false   //Added by chandrani for custom error page
  }
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      AddtaskOpt: false,
      usrnm: "",
      passwordFlag: "",
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }
 








  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  Changepassword(event) {
    event.preventDefault();
    var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&]).{6,10}/;
    var cnew = document.getElementById("cnew").value;
    var newpwd = document.getElementById("newpwd").value;


    if (!re.test(cnew) || !re.test(newpwd) || (!document.getElementById("old").value)) 
    {
     showSuccess('Password should contain atleast least one uppercase letter, one lowercase letter, one number and one special character & password should not be blank.')
    }
     else {
      if (document.getElementById("newpwd").value == document.getElementById("cnew").value) {
        confirmAlert({
          message: 'Are you sure, you want to update your password ?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var Request = {
                  "userId": this.props.location.state.userid,
                  "password": document.getElementById("old").value,
                  "newpswd": document.getElementById("cnew").value,
                  "guId": this.props.location.state.guId
                }
                var EncryptedRequest = Encrypt(Request);
                fetch('/ChangePswd', {
                  method: 'POST',
                  body: EncryptedRequest,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })

                  .then((response) => response.text())
                  .then((response) => {
                    var DecryptedResponse = decryptData(response)
                    if (DecryptedResponse.responseCode === '200 OK') {
                      confirmAlert({
                        message: 'Password changed successfully.',
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => { window.location.href = '/' }
                          },

                        ]
                      })

                    }
                    else {
                      confirmAlert({
                        title: 'Alert !',
                        message: DecryptedResponse.responseMsg,
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => { window.location.reload(this.Changepassword.bind(this)); }
                          },

                        ]
                      })
                    }
                  })
                  .catch((error) => {
                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });
                  });
              },

            },
            {
              label: 'No',
              onClick: () => { window.location.reload(this.Changepassword.bind(this)); }
            }]
        })
      }
      else {
        confirmAlert({
          message: 'Password does not match.',
          buttons: [
            {
              label: 'Ok',
              onClick: () => { window.location.reload(this.Changepassword.bind(this)); }
            },

          ]
        })


      }
    }
  }

  pagebind = () => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      if (this.props.location.state.passwordFlag === '1') {
        this.setState({ AddtaskOpt: false });
      } else {
        this.setState({ AddtaskOpt: true });
      }

      this.setState({
        usrnm: this.props.location.state.usrnm,
        role_id: this.state.roleId,
        userid: this.props.location.state.userid,

      })
    }
    else {

      this.props.history.push({
        pathname: '/',
      });
    }



  }


  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  componentWillMount() {
    this.pagebind()
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {

    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }


  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  tiker = () => {
    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );

  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }


  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }


  render = () => {
    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header class="header black-bg">
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b><b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }


              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h3>Change Password</h3>
                    </div>
                    <div class="clearfix"></div>
                    <hr />
                    <form className="form-horizontal" autoComplete="off">
                      <div className="col-lg-8 col-md-8 col-sm-10 col-xs-12">
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">User ID</label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input type="text" class="form-control" value={this.state.userid} id="taskName" maxlength="100" autoComplete="off" disabled={true} />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">Old Password</label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input type="password" class="form-control" placeholder="Enter Old Password" id="old" maxlength="100" autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">New Password</label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input type="password" class="form-control" placeholder="Enter New Password" id="newpwd" maxlength="20" autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12">Confirm Password</label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <input type="password" class="form-control " placeholder="Retype New Password" id="cnew" maxlength="20" autoComplete="off" />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label col-lg-3 col-md-4 col-sm-4 col-xs-12"> </label>
                          <div class="col-lg-9 col-md-8 col-sm-8 col-xs-12">
                            <button class="btn btn-primary min-wid-90" onClick={this.Changepassword.bind(this)}>Save</button>
                            {this.state.AddtaskOpt ? (
                              <button class="btn btn-primary min-wid-90 ml-10" onClick={this.BackPage.bind(this)}>Cancel</button>
                            ) : (
                                <button class="btn btn-primary min-wid-90 ml-10" onClick={this.BackPages.bind(this)}>Cancel</button>
                              )}
                          </div>
                        </div>

                      </div>
                    </form>

                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  }
  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId
      }
    }
    );
  }


  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  EquipmentList =()=>{
    if(this.state.role_id=="505"||  this.state.role_id=="501" || this.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          tskId: this.state.tskId,
          tskName: this.state.tskName,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.state.usrnm,
          role_id: this.props.location.state.role_id,
          data: this.state.data,
          tskId: this.state.tskId,
          tskName: this.state.tskName,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }



  BackPage = (e) => {
    e.preventDefault();
    confirmAlert({
      message: 'Are you sure, you want to cancel?  ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.history.push({
              pathname: '/Taskactive',
              state: {
                userid: this.state.userid,
                usrnm: this.props.location.state.usrnm,
                role_id: this.props.location.state.role_id,
                guId: this.props.location.state.guId
              }
            }
            );
          }
        },
        {
          label: 'No',
          onClick: () => { return false }
        }
      ]
    });

  }

  BackPages = (e) => {

    e.preventDefault();

    confirmAlert({
      message: 'Are you sure, you want to cancel?  ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.clear();
            window.location.href = '/';
          }
        },
        {
          label: 'No',
          onClick: () => { return false }
        }
      ]
    });

  }



  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }
}
export default Changepassword;
