import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
//import readXlsxFile from 'read-excel-file';
import { withRouter } from "react-router-dom";
import { Encrypt, decryptData } from './Encryption-Decrypytion'


import IdleTimer from 'react-idle-timer'// For Idle
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import { basicAuth } from './basicAuth';
import { BrowserRouter as Router, } from 'react-router-dom';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { showAlert, showSuccess } from './services/ConfirmAlert';



const ChildComponent = props =>

  <div >

    <input type="text" class="form-control" placeholder={"Option " + props.number}
      onChange={props.OptChange}

      id={"uptd" + props.number} autocomplete="off" />
  </div>;

class Updatetask extends Component {

  state = {
    TaskDropdwn: false,
    endDate: "",
    startDate: "",
    updatedopt: [],
    option: '',
    numChildren: 0,
    Addoptbutton: false,
    hasError: false   //Added by chandrani for custom error page

  }

  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn),

    })
  }
  optionChangedHandler = (event) => {
    this.setState({

      option: event.target.value
    })
  }




  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false   //Added by chandrani for custom error page
    };
    this.state = {
      value: '',
      startDate: "",
      endDate: "",
      StoreList: "StoreList",
      FileName: "",
      loaded: this.props.location.state,
      questionText: "",
      opt: [],
      numChildren: "",
      tskName: "",
      tskId: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",
      usrnm: "",
      addClickbutton: false,
      show: true,
      publishStatus: "New",
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      guId: ""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleoptchange = this.handleoptchange.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)


  }

  handleoptchange(param, event) {


    this.state.opt[param] = event.target.value;
    this.setState({

    })
  }

  FetchQuestionFromTaskActive() {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      if (this.props.location.state.questiontype == '1' || this.props.location.state.questiontype == '2' || this.props.location.state.questiontype == '9') {
        this.setState({ addClickbutton: true });
      } else {
        this.setState({ addClickbutton: false });
      }
      this.setState({
        optlength: this.props.location.state.options,
        opt: this.props.location.state.options,
        tskId: this.props.location.state.tskId,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        tskName: this.props.location.state.tskName,
        tskDescr: this.props.location.state.tskDescr,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        usrnm: this.props.location.state.usrnm,
        publishStatus: this.props.location.state.publishStatus,
        questionText: this.props.location.state.questionText,
        numChildren: this.props.location.state.options.length,
        guId: this.props.location.state.guId,
      })
    }

    else {
      this.props.history.push({
        pathname: '/',
      });
    }
  }

  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //  console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }



  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)


        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  removeClick(index) {
    let opt = [...this.state.opt];
    opt.splice(index, 1);
    this.setState({ opt });





  }
  componentWillMount() {
    this.FetchQuestionFromTaskActive()
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );

  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }


  addClick() {
    this.setState(prevState => ({ opt: [...prevState.opt, ''] }))
    this.setState({ show: true })
  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render = () => {

    var that = this;
    const children = [];

    for (var i = 0; i < this.state.numChildren; i += 1) {
      children.push(<ChildComponent key={i} number={i} />);

    };
    var i = 1;


    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <Router>
          {this.state.loaded ?

            <div>
              <section id="container">
                <div>
                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 10} />
                </div>
                <header class="header black-bg">
                  <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

                  <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b><b className="caret" /></a>
                    </li>

                    {this.state.displayMenu ?
                      (

                        <ul className="dropdown-menuuser-dd">
                          <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                          <li className="divider"></li>
                          <li><a href="#Log Out" onClick={this.logout} >Log Out</a></li>
                        </ul>
                      ) :
                      (
                        null
                      )
                    }

                  </ul>
                </header>
                <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">
                      <h3><i class="fa fa-angle-right"></i>Update Question</h3>
                      <hr />
                      <div class="row mt">
                        <form method="post">
                          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                            <label>Equipment Category </label>
                            <br />
                            <input ref="catid" type="text" className="form-control" size="25" placeholder={this.props.location.state.categoryName} id="catID" disabled="disabled" />
                          </div>



                          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <label>Question Type</label>
                            <br />
                            <input ref="taskid" type="text" className="form-control" size="25" placeholder={this.props.location.state.questionTypeValue} id="taskID" disabled="disabled" />
                          </div>



                          <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">

                            <label>Question</label>
                            <br />
                            <input ref="taskname" onChange={(e) => this.setState({ questionText: e.target.value })} type="text" class="form-control" value={this.state.questionText} id="taskName" autoComplete="off" />
                          </div>

                          <div id="Remove" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            {this.state.addClickbutton ? (

                              <label>Options</label>
                            ) : (null)}


                            {this.state.opt.map((optele, index) => (
                              <div class="d-flex flex-wrap flex-row justify-content-between mb-10">

                                <input ref="taskname" onChange={that.handleoptchange.bind(that, index)}
                                  type="text" placeholder="option" class="form-control fbasis-94"
                                  value={this.state.opt[index]} onKeyPress={this.fullstop}
                                  maxLength="255"
                                  id={"uptd"} autoComplete="off" />

                                <input type='button' class="remove-icon" value='x' onClick={this.removeClick.bind(this, index)} />

                              </div>
                            )

                            )}



                          </div>


                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            {this.state.addClickbutton ? (


                              <input type='button' class="btn btn-primary min-wid-90  mt-17" value='Add More' onClick={this.addClick.bind(this)} />

                            ) : (null)}

                            {this.state.show ? (


                              <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.UpdateQuestion.bind(this)}>Update</button>) : (null)}
                            <button class="btn btn-primary min-wid-90 ml-10 mt-17" onClick={this.addquestionbind.bind(this)}>Cancel</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
            : this.props.history.push({
              pathname: '/',
            })
          }
        </Router>
      );
    }
  }
  onAddChild = () => {
    this.setState({
      numChildren: this.state.numChildren + 1,

    });
  }



  fullstop = (e) => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }

  }
  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      }
    }
    );
  }


  addquestionbind = (e) => {
    e.preventDefault();

    confirmAlert({
      message: 'Are you sure, you want to cancel?  ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.history.push({
              pathname: '/Addquestion',
              state: {

                role_id: this.state.role_id,
                userid: this.state.userid,
                tskId: this.state.tskId,
                tskName: this.state.tskName,
                tskDescr: this.state.tskDescr,
                strtDate: this.state.strtDate,
                edDate: this.state.edDate,
                usrnm: this.state.usrnm,
                publishStatus: this.state.publishStatus,
                guId: this.props.location.state.guId,
              }
            }
            )
          }
        },
        {
          label: 'No',
          onClick: () => { return false }
        }
      ]
    })

  }


  UpdateQuestion(e) {
    e.preventDefault();
    console.log(`update`, this.state.opt);

    if (this.state.opt.toString().length >= 255) {
      showAlert("Total Option length cannot be greater than 255 ")

    }
    else {
      let otval = [];
      if (document.getElementById("uptd") !== null) {
        for (var i = 0; i <= this.state.numChildren; i++) {

          otval.push(document.getElementById("uptd").value);

        };
      }
      if (document.getElementById("taskName").value === "") {
        showAlert("Question Cannot be Empty")
      }
      else {
        if (this.state.opt.length === 0 && this.state.addClickbutton === true) {
          showAlert("Options Cannot be Empty")


          this.setState({ show: false })
        }

        else {

          e.preventDefault();
          confirmAlert({
            message: 'Are you sure,you want to update question?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  var Request = {
                    "userId": this.props.location.state.userid,
                    "taskId": this.props.location.state.tskId,
                    "listQuestions": [{
                      "questionId": this.props.location.state.questionId,
                      "equipCategId": this.props.location.state.equipCategId,
                      "questionText": document.getElementById("taskName").value,
                      "questiontype": this.props.location.state.questiontype,
                      "minValue": "",
                      "maxValue": "",
                      "options": this.state.opt.filter(function (el) {
                        return el != "";
                      }),
                    }],
                    "guId": this.props.location.state.guId,
                  }
                  var EncryptedRequest = Encrypt(Request);
                  fetch('/UpdateQuestions', {
                    method: 'POST',
                    body: EncryptedRequest,
                    headers: {
                      guId: this.props.location.state.guId,
                      'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                    }

                  })
                    .then((response) => response.text())
                    .then((response) => {
                      var DecryptedResponse = decryptData(response)
                      if (DecryptedResponse.responseCode === "200 OK") {
                        confirmAlert({
                          message: 'Question updated successfully.',
                          buttons: [
                            {
                              label: 'Ok',
                              onClick: () => {
                                this.props.history.push({
                                  pathname: '/Addquestion',
                                  state: {
                                    role_id: this.state.role_id,
                                    userid: this.state.userid,
                                    tskId: this.state.tskId,
                                    tskName: this.state.tskName,
                                    tskDescr: this.state.tskDescr,
                                    strtDate: this.state.strtDate,
                                    edDate: this.state.edDate,
                                    usrnm: this.state.usrnm,
                                    publishStatus: this.state.publishStatus,
                                    guId: this.props.location.state.guId,
                                  }
                                }
                                );
                              }
                            },

                          ]
                        })

                      }
                      else {
                        showAlert(DecryptedResponse.responseMsg)


                      }

                    })
                    .catch((error) => {
                      confirmAlert({
                        title: 'Alert !',
                        message: "Session expired",
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => { this.logoutnormal() }
                          },

                        ]


                      });

                    });
                },

              },
              {
                label: 'No',
                onClick: () => { return false }
              }
            ]
          })


        }
      }
    }

  }


  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

  EquipmentList =()=>{
    if(this.state.role_id=="505"||  this.state.role_id=="501" || this.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,

      }
    }
    );
  }



  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
      }
    }
    );
  }


  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId,
      }
    }
    );
  }

}

export default withRouter(Updatetask);
