import React, { Component } from 'react';
import 'jquery/dist/jquery';
import { withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from 'react-custom-scrollbars';
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import disableBrowserBackButton from 'disable-browser-back-navigation';

import { basicAuth } from './basicAuth';
import { showSuccess } from './services/ConfirmAlert';




class CompletedTaskList extends Component {
  state = {
    TaskDropdwn: false,

  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }




  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
      hasError: false   //Added by chandrani for custom error page
    };
    this.state = {
      startDate: "",
      endDate: "",
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      taskList: [],
      value: "",
      usrnm: "",
      userid: "",
      Search: "",
      taskId: "",
      createdBy: "",
      searchSDate: "",
      searchEDate: "",
      previewData: "",
      role_id: "",
      searchedDate: "",
      loading: false,
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      guId: ""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)




  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  onUsernameChange = (username) => {
    this.setState({
      username: username
    });
  }

  handleChangeStart(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchSDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      startDate: date

    });
  }
  handleChangeEnd(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchEDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      endDate: date
    });
  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }








  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }
  handleTaskList([]) {
    this.setState({
      taskList: []
    });
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  _onAction(e) {
    // console.log('user did something', e)
  }

  _onActive(e) {
    //console.log('user is active', e)
    //  console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  componentWillMount() {
    this.GetCompletedTask()
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });


  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );

  }
  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  render = () => {
    var that = this;
    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;

    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <div>

          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header className="header black-bg" >

              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right">
                <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" /><b>{this.state.usrnm}</b><b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }


              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3><i className="fa fa-angle-right"></i>Task Answers
         </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Task Id</b><br />
                          <input type="number" placeholder="Search Task Id" className="form-control" id="taskname" onChange={this.onChange} autoComplete="off" />
                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mt-xs-10"> <b>Start Date</b><br />
                          <DatePicker autoComplete="off" className="form-control form-control-inline input-medium default-date-picker"
                            size="16" type="text" placeholderText="Select Start Date " dateFormat="dd-MMM-yyyy" id="startDate"
                            selected={this.state.startDate}
                            onChange={this.handleChangeStart}
                            onChangeRaw={this.handleDateChangeRaw}
                          />

                        </div>
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mt-xs-10"><b> End Date</b><br />
                          <DatePicker autoComplete="off" className="form-control form-control-inline input-medium default-date-picker"
                            size="16" type="text" placeholderText="Select End Date" dateFormat="dd-MMM-yyyy" id="endDate"
                            selected={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            onChangeRaw={this.handleDateChangeRaw}
                          />
                        </div>
                        <div>
                          <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} />
                        </div>
                        <div className="clearfix"></div>
                        <div className="mt">

                          <div className="tbl-holder">
                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">

                              <thead>
                                <tr>
                                  <th>Task Id</th>
                                  <th>Task Name</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                </tr>
                              </thead>
                            </table>
                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task">
                                <tbody>
                                  {this.state.data.map(function (item, key) {
                                    if ((Search !== "" || (searchSDate) !== "" || (searchedDate) !== "") &&
                                      (item.taskId.toLowerCase().indexOf(Search.toLowerCase()) ||
                                        item.startDate.toLowerCase().indexOf(searchSDate.toLowerCase()) ||
                                        item.endDate.toLowerCase().indexOf(searchedDate.toLowerCase())) === -1) {
                                      return null
                                    }
                                    return (

                                      <tr key={key}>

                                        <td data-th="Task ID"><a onClick={that.callpreviewquestionpage.bind(that, item.taskId, item.taskName, item.taskDescription, item.startDate, item.endDate,item.categoryId)} className="black-text" style={{ cursor: "pointer" }}> {item.taskId}</a></td>
                                        <td data-th="Task Name">{item.taskName}</td>
                                        <td data-th="Start Date">{item.startDate}</td>
                                        <td data-th="End Date">{item.endDate}
                                          <span className="d-none">{item.taskId}</span>
                                        </td>

                                      </tr>
                                    )
                                  })}</tbody>

                              </table>
                            </Scrollbars>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </section>
          </section>

        </div>
      );
    }
  }





  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  GetCompletedTask(e) {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({ loading: true });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ role_id: this.props.location.state.role_id })
      this.setState({ guId: this.props.location.state.guId })
      fetch('/GetTaskList', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ data: DecryptedResponse.tasklist });
            this.setState({ loading: false })
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ role_id: this.props.location.state.role_id });


          }
          else {
            this.setState({ loading: false });
            showSuccess(DecryptedResponse.responseMsg)

          }

        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });

    }
    else {

      this.props.history.push({
        pathname: '/',
      });

    }
  }









  callpreviewquestionpage(tskId, tskName, tskDescr, strtDate, edDate,categoryId) {
    this.props.history.push({
      pathname: '/TaskStoreList',
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        data: this.state.data,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,
        categoryId:categoryId

      }
    }
    );

  }

  ChangepasswordBind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        data: this.state.data,
        usrnm: this.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {

        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId

      }
    }
    );
  }
  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  EquipmentList =()=>{
    if(this.state.role_id=="505"||  this.state.role_id=="501" || this.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }


}
export default withRouter(CompletedTaskList);