import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import Spinner from 'react-spinner-material';//Import Spinner
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import Resizer from 'react-image-file-resizer';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import disableBrowserBackButton from 'disable-browser-back-navigation';
import _ from 'underscore'
import { Scrollbars } from 'react-custom-scrollbars';

import { basicAuth } from './basicAuth';
import Scanning from './Scanning';
import { showSuccess, showAlert } from './services/ConfirmAlert';
const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};
class QuestionPreviewLevel1 extends Component {

  state = {
    TaskDropdwn: false,
    data: [],
    taskId: "",
    usrnm: "",
    tempquesid: "",
    quesid: [],
    quesidmail: [],
    quesidcs9: [],
    equipidcs9: [],
    qforsub: [],
    ans: [],
    imgquesflag: "",
    fileval: "",
    fileuploaded: [],
    nooffileques: 0,
    taskId: "",
    userId: "",
    fstring: "",

    answers: [],
    ansobj: {
      taskId: "",
      userId: "",
      questionId: "",
      answer: ""
    },
    radioObj: [],
    jsonObj: [],
    arrrefs: [],
    arreqnum: [],
    arrrefscs9: [],
    arrchk: [],
    checked: "",
    status: "",
    file: "",
    disabled: "",
    value1: "",
    defval: "",
    numchange: false,
    arrid1: [],
    arrid2: [],
    arrid3: [],
    arrid4: [],
    arrid5: [],
    arrid6: [],
    arrid7: [],
    arrid8: [],
    arrid9: [],
    arrid10: [],
    objwithans: [],
    nummaxflag: 1,
    numeleid: [],
    numminval: [],
    mand: [],
    mandid: [],
    abc: "",
    hasError: false,   //Added by chandrani for custom error page
    comment: "",
    categoryName: [],
    smapprove: "",
    checkbox: true,
    checkbox1: true,

    questionidpush: [],
    chkquespush: [],
    equipmentrequest: [],
    euipcatidentity: [],
    tskName: "",
    tskDescr: "",
    strtDate: "",
    edDate: "",
    instReadFlag: "",
    guId: "",
    browser: false,
    role_id: "",
    latitude: "",
    longitude: "",
    Question: [],
    getBarcodeData: '',
    showscanner: false,
    getbuttondisable: false,
    categoryId: "",
    equipFlag: "",
  }

  constructor(props) {
    super(props);

    var _ = require('lodash')

    this.state = {
      selectedOption: '',
      data: [],
      displayMenu: false,
      tempquesid: "",
      quesid: [],
      quesidmail: [],
      quesidcs9: [],
      equipidcs9: [],
      qforsub: [],
      ans: [],
      answers: [],
      fileval: "",
      fileuploaded: [],
      taskId: "",
      userId: "",
      storeNo: "",
      loading: false,
      fstring: "",
      abc: "",
      visiblity: true,
      startDate: new Date(),
      ansobj: {
        taskId: "",
        userId: "",
        questionId: "",
        answer: ""
      },

      radioObj: [],
      jsonObj: [],
      arrrefs: [],
      arreqnum: [],
      arrqtypenum: [],
      arrrefscs9: [],
      arrchk: [],
      imgquesflag: "",
      value1: "",
      numchange: false,
      defval: "",
      status: "",
      file: "",
      nooffileques: 0,
      arrid1: [],
      arrid2: [],
      arrid3: [],
      arrid4: [],
      arrid5: [],
      arrid6: [],
      arrid7: [],
      arrid8: [],
      arrid9: [],
      arrid10: [],
      objwithans: [],
      nummaxflag: 1,
      numeleid: [],
      numminval: [],
      mand: [],
      mandid: [],
      abc: "",
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      comment: "",
      categoryName: [],
      smapprove: "",
      checkbox: true,
      questionidpush: [],
      chkquespush: [],
      equipmentrequest: [],
      euipcatidentity: [],
      checkbox1: true,
      tskName: "",
      tskDescr: "",
      strtDate: "",
      edDate: "",

      usrnm: "",
      instReadFlag: "",
      guId: "",
      browser: false,
      role_id: "",
      latitude: "",
      longitude: "",
      Question: [],
      getBarcodeData: '',
      showscanner: false,
      getbuttondisable: false,
      categoryId: "",
      equipFlag: ""

    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)



  }
  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }



  componentDidMount() {

    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const lat = JSON.stringify(position.coords.latitude);
          const long = JSON.stringify(position.coords.longitude);
          console.log('The location: ', lat, long)
          this.setState({ latitude: lat + "," + long });
        },
        error => '',
        { enableHighAccuracy: true }
      );
    } else {
      showAlert(
        "Either the location is off, or the device broswer is outdated. Please take necessary steps."
      )
    }



  }


  handleChangeRadioNew = (param, equipmentNo, qtype, event) => {

    var flag = false;
    if (this.state.quesid.length > 0) {

      for (let i = 0; i < this.state.quesid.length; i++) {
        const element = this.state.quesid[i];
        if (element === param) {
          for (let j = 0; j < this.state.radioObj.length; j++) {
            const element1 = this.state.radioObj[j];
            if (element1.questionId === param) {
              this.state.radioObj[j].answer = event.currentTarget.value;
              flag = true;
            }
          }
        }
      }
      if (!flag) {
        var newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: param,
          answer: event.currentTarget.value,
          equipmentNo: equipmentNo,
          qType: qtype
        };
        this.state.radioObj.push(newans);
        this.state.quesid.push(param)
      }
    }
    else {
      this.state.quesid.push(param)
      this.state.ansobj.questionId = param;
      this.state.ansobj.answer = event.currentTarget.value;
      this.state.radioObj.push(this.state.ansobj)
    }

  }

  handleChangeCheck = (param, equipmentNo, qType, event) => {

    let _ = require('underscore')
    var cleanedqid = [];
    this.state.quesid.forEach(function (itm) {
      var unique = true;
      cleanedqid.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) cleanedqid.push(itm);
    });

    var flag = false;
    if (cleanedqid.length > 0) {

      for (let i = 0; i < cleanedqid.length; i++) {
        const element = cleanedqid[i];
        if (element === param) {
          for (let j = 0; j < this.state.radioObj.length; j++) {
            const element1 = this.state.radioObj[j];
            if (element1.questionId === param) {
              if (this.state.radioObj[j].answer === undefined) {

              }
              else {
                if (this.state.radioObj[j].answer.includes(event.currentTarget.value)) {
                  if (this.state.radioObj[j].answer.includes(event.currentTarget.value + ",")) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace(event.currentTarget.value + ",", "")
                  }
                  else if (this.state.radioObj[j].answer.includes("," + event.currentTarget.value)) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace("," + event.currentTarget.value, "")
                  }
                  else if (this.state.radioObj[j].answer.includes(event.currentTarget.value)) {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer.replace(event.currentTarget.value, "")
                  }
                }
                else {
                  if (this.state.radioObj[j].answer === "") {
                    this.state.radioObj[j].answer = event.currentTarget.value;
                  }
                  else {
                    this.state.radioObj[j].answer = this.state.radioObj[j].answer + "," + event.currentTarget.value;
                  }
                }
                flag = true;
              }
            }
          }
        }
      }
      if (!flag) {
        var newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: param,
          answer: event.currentTarget.value,
          equipmentNo: equipmentNo,
          qType: qType
        };
        this.state.radioObj.push(newans);
        this.state.quesid.push(param)
      }
    }
    else {
      this.state.quesid.push(param)
      this.state.ansobj.questionId = param;
      this.state.ansobj.answer = event.currentTarget.value;
      this.state.radioObj.push(this.state.ansobj)
    }

  }



  browserCheck() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i)
    ) {
      this.setState({ browser: true });
    }
  }





  handleChangeNum = (param, param2, evt) => {
    if (param2 === undefined) {
      if (evt.target.value.length === 1) {
        const value1 = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
      else {
        const value1 = (evt.target.validity.valid) ? evt.target.value : evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
    }
    else {
      if (document.getElementById(param).value.length <= param2) {
        if (evt.target.value.length === 1) {
          const value1 = (evt.target.validity.valid) ? evt.target.value : this.state.value1;
          this.setState({ numchange: true });
          document.getElementById(param).value = value1;
        }
        else {
          const value1 = (evt.target.validity.valid) ? evt.target.value : evt.target.value.substring(0, evt.target.value.length - 1);
          this.setState({ numchange: true });
          document.getElementById(param).value = value1;
        }
      }
      else {
        const value1 = evt.target.value.substring(0, evt.target.value.length - 1);
        this.setState({ numchange: true });
        document.getElementById(param).value = value1;
      }
    }
  }

  downloadPDF() {

    var dlnk = document.getElementById('dwnldLnk');
    dlnk.href = this.state.file;
    dlnk.click();

  }

  openIns() {


    if (this.state.instReadFlag == "0") {
      var Request1 = {
        "taskId": this.props.location.state.tskId,
        "storeId": this.props.location.state.storeNo,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/InstReadFlag ', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            var string = this.state.file;
            var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
            var iframe = "<iframe width='100%' height='100%' src='" + "/Images/" + this.state.file + "'></iframe>"
            var x = window.open();
            x.document.open();
            x.document.write(iframe);
            x.document.close();
          }
          else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.responseMsg,
              buttons: [
                {
                  label: 'Ok',

                },

              ]
            })
          }



        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });
    }

    else {


      var string = this.state.file;
      var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
      var iframe = "<iframe width='100%' height='100%' src='" + "/Images/" + this.state.file + "'></iframe>"
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    }
  }
  DownloadIns = () => {
    if (this.state.instReadFlag == "0") {
      var Request1 = {
        "taskId": this.props.location.state.tskId,
        "storeId": this.props.location.state.storeNo,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/InstReadFlag ', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            document.getElementById('FileDnld').click();
          }
          else {
            this.setState({ loading: false });
            confirmAlert({
              message: DecryptedResponse.responseMsg,
              buttons: [
                {
                  label: 'Ok',
                  // onClick: () =>
                },

              ]
            })
          }



        })
        .catch((error) => {

          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });
        });
    }

    else {

      document.getElementById('FileDnld').click()
    }

  }




  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  openPrf = (param, evt) => {

    let _ = require('underscore')
    var cleanedans = [];
    console.log(`radioobj`, this.state.radioObj)
    cleanedans = this.state.radioObj;

    evt.preventDefault();
    var bool = false;
    if (cleanedans.length > 0) {
      for (let index = 0; index < cleanedans.length; index++) {
        const element = cleanedans[index];
        console.log(`element`, element)
        console.log(`Ìmage `, element.imageArray)

        if (element.questionId === param) {
          bool = true;
          if (element.imageArray.length > 0) {
            for (let k = 0; k < element.imageArray.length; k++) {

              if (element.imageArray[k] == null || element.imageArray[k] == "") {

              }
              else {
                var iframe = "<iframe width='100%' height='100%' src='" + element.imageArray[k] + "'></iframe>"
                var x = window.open();
                x.document.open();
                x.document.write(iframe);
                x.document.close();
              }
            }
          }
          else {
            confirmAlert({
              message: "No File uploaded yet",
              buttons: [
                {
                  label: 'OK',
                },

              ]
            })
          }
        }

      }
      if (!bool) {
        confirmAlert({
          message: "No File uploaded yet",
          buttons: [
            {
              label: 'OK',
            },

          ]
        })
      }
    }
    else {
      confirmAlert({
        message: "No File uploaded yet",
        buttons: [
          {
            label: 'OK',
          },

        ]
      })
    }
  }






  handleFileSelect = (param, questionType, equipmentNo, evt) => {
    console.log(`evt`, param)
    console.log(`evt`, evt)
    console.log(`radioobj`, this.state.radioObj)
    var that1 = this;
    var files = document.getElementById(param).files;
    console.log(`file`, evt.target.files[0])
    var newans = {
      taskId: that1.props.location.state.tskId,
      userId: that1.props.location.state.userid,
      questionId: param,
      imageArray: [],
      qType: questionType,
      equipmentNo: equipmentNo
    };


    if (files.length > 3) {
      showAlert("Maximum three file can be uploaded.")

    }

    else {
      for (let k = 0; k < files.length; k++) {


        if (files[k].type == "image/gif"
          || files[k].type == "image/jpg"
          || files[k].type == "image/jpeg"
          || files[k].type == "image/png"
          || files[k].type == "application/msword"
          || files[k].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          || files[k].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          || files[k].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          || files[k].type == "application/vnd.ms-excel"
          || files[k].type == "application/pdf"
          || files[k].type == "application/vnd.ms-powerpoint"
          || files[k].type == "text/plain"
        ) {
          if (files[k].type == "image/jpeg" || files[k].type == "image/png") {
            let _ = require('underscore')
            var cleanedqid = [];
            this.state.quesid.forEach(function (itm) {
              var unique = true;
              cleanedqid.forEach(function (itm2) {
                if (_.isEqual(itm, itm2)) unique = false;
              });
              if (unique) cleanedqid.push(itm);
            });
            var flag = false;
            if (cleanedqid.length > 0) {
              for (let i = 0; i < cleanedqid.length; i++) {
                const element = cleanedqid[i];
                if (element === param) {
                  for (let j = 0; j < that1.state.radioObj.length; j++) {
                    const element1 = that1.state.radioObj[j];
                    if (element1.questionId === param) {
                      if (files.length > 0) {
                        if (that1.state.radioObj[j].imageArray === undefined) {

                        }
                        else {

                          flag = true;
                          var reader = new FileReader();
                          reader.readAsDataURL(files[k]);
                          reader.onload = function () {
                            Resizer.imageFileResizer(
                              files[k],
                              500,
                              500,
                              'JPEG',
                              100,
                              0,
                              uri => {
                                that1.state.fileuploaded.push(param + "" + uri)
                                newans.imageArray.push(uri)
                              },
                              'base64'
                            );


                          };
                          reader.onerror = function (error) {
                          };

                        }
                        that1.state.imgquesflag = param;
                        that1.state.fileval = param + "File Upload successful";
                        document.getElementById("proof" + param).value = "File Upload successful"
                      }
                    }
                  }
                }
              }
              if (!flag) {
                if (files.length > 0) {

                  that1.state.quesid.push(param)
                  if (files.length > 0) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = function () {
                      Resizer.imageFileResizer(
                        files[k],
                        500,
                        500,
                        'JPEG',
                        100,
                        0,
                        uri => {
                          that1.state.fileuploaded.push(param + "" + uri)
                          newans.imageArray.push(uri)
                        },
                        'base64'
                      );

                    };

                  }


                  that1.state.imgquesflag = param;
                  that1.state.fileval = param + "File Upload successful";
                  document.getElementById("proof" + param).value = "File Upload successful";

                }
              }
            }
            else {
              //
              var files = document.getElementById(param).files;
              var temp;
              var that1 = this;

              if (files.length > 0) {

                var reader = new FileReader();
                reader.readAsDataURL(files[k]);
                reader.onload = function () {
                  newans.imageArray.push(reader.result);
                  that1.state.fileuploaded.push(param + "" + reader.result)

                };



              }





              that1.state.radioObj.push(newans);
              that1.state.imgquesflag = param;
              that1.state.fileval = param + "File Upload successful";
              that1.state.quesid.push(param);
              document.getElementById("proof" + param).value = "File Upload successful"
            }

          }
          else {
            var that1 = this;
            var files = document.getElementById(param).files;
            let _ = require('underscore')
            var cleanedqid = [];
            this.state.quesid.forEach(function (itm) {
              var unique = true;
              cleanedqid.forEach(function (itm2) {
                if (_.isEqual(itm, itm2)) unique = false;
              });
              if (unique) cleanedqid.push(itm);
            });
            var flag = false;
            if (cleanedqid.length > 0) {
              for (let i = 0; i < cleanedqid.length; i++) {
                const element = cleanedqid[i];
                if (element === param) {
                  for (let j = 0; j < that1.state.radioObj.length; j++) {
                    const element1 = that1.state.radioObj[j];
                    if (element1.questionId === param) {
                      if (files.length > 0) {
                        if (that1.state.radioObj[j].imageArray === undefined) {

                        }
                        else {

                          flag = true;
                          var reader = new FileReader();
                          console.log(`file`, files)
                          reader.readAsDataURL(files[k]);
                          reader.onload = function (evt) {
                            newans.imageArray.push(evt.target.result)
                            that1.state.fileuploaded.push(param + "" + reader.result)
                          };
                          reader.onerror = function (error) {
                          };

                        }
                        that1.state.imgquesflag = param;
                        that1.state.fileval = param + "File Upload successful";
                        document.getElementById("proof" + param).value = "File Upload successful"
                      }
                    }
                  }
                }
              }
              if (!flag) {
                if (files.length > 0) {

                  that1.state.quesid.push(param)
                  if (files.length > 0) {
                    var reader = new FileReader();
                    reader.readAsDataURL(files[k]);
                    reader.onload = function () {
                      newans.imageArray.push(reader.result);
                      that1.state.fileuploaded.push(param + "" + reader.result)

                    };
                    reader.onerror = function (error) {
                    };
                  }

                  that1.state.radioObj.push(newans);
                  that1.state.imgquesflag = param;
                  that1.state.fileval = param + "File Upload successful";
                  document.getElementById("proof" + param).value = "File Upload successful";
                }
              }
            }
            else {

              var files = document.getElementById(param).files;
              var temp;
              var that1 = this;

              if (files.length > 0) {

                var reader = new FileReader();
                reader.readAsDataURL(files[k]);
                reader.onload = function () {
                  newans.imageArray.push(reader.result);
                  that1.state.fileuploaded.push(param + "" + reader.result)

                };
                reader.onerror = function (error) {
                };


              }

              that1.state.radioObj.push(newans);
              that1.state.imgquesflag = param;
              that1.state.fileval = param + "File Upload successful";
              that1.state.quesid.push(param);
              document.getElementById("proof" + param).value = "File Upload successful"
            }


          }
        }
        else {
          this.setState({ instructionUpload: '' });
          confirmAlert({
            title: 'Alert !',
            message: "Unsupported File Format.",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.setState({ instructionUpload: "" }); }
              },

            ]
          })
        }
      }
      console.log(`newans`, newans)
      console.log(`handle`, this.state.radioObj)
      if (this.state.radioObj.length == 0) {
        this.state.radioObj = [newans]
      }
      else {
        for (let z = 0; z < this.state.radioObj.length; z++) {

          if (param == this.state.radioObj[z].questionId) {
            this.state.radioObj[z] = newans

          }
        }

      }

    }
  }


  componentWillMount() {

    this.fetchQuestionPaper()
    this.browserCheck()
    if (this.props.location.state.categoryId !== "6" && this.props.location.state.categoryId !== "7" && this.props.location.state.categoryId !== "8") // role_Id!=="504"
    {
      this.fetchFullPaper("preview")
    }


    // if (this.props.location.state.categoryId !== "4" ) // role_Id!=="504"
    // {

    //   this.fetchFullPaper("preview")
    // }
    // else{
    //   if(this.props.location.state.equipFlag==="0"){
    //   this.fetchFullPaper("preview")  
    // }
    // }

  }


  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  logout = () => {
    if (this.props.location.state.Isroffice == true) {

      this.props.history.push({
        pathname: '/QuestionPreviewLevel1',
        state: {
          usrnm: ''
        }


      });
      localStorage.clear();
      document.getElementById("rofficelogin").click();


    }
    else {
      var Request1 = {
        "guId": this.props.location.state.guId,
        "userId": this.props.location.state.userid
      }
      var EncryptedRequest1 = Encrypt(Request1);

      fetch('/LogOut', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          console.log(`logout`, DecryptedResponse1)


          if (DecryptedResponse1.responseCode === '200 OK') {

            localStorage.clear();
            this.props.history.replace('/');
            disableBrowserBackButton();

          }
          else {
            this.setState({ loading: false })
            showSuccess(DecryptedResponse1.responseMsg)


          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });



        });




    }
  }



  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }


  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  handleOnChange(e) {
    this.setState({ selectedOption: e.target.value });
  }





  handleNotapplicableCheck(listQuestions, e) {
    console.log(` this.state.chkquespush`, e.currentTarget.checked)
    this.setState({ checkbox: !this.state.checkbox })
    console.log(`check`, listQuestions);
    if (e.currentTarget.checked == true) {
      for (let y = 0; y < listQuestions.length; y++) {

        this.state.questionidpush.push(listQuestions[y].questionId)
        this.state.equipmentrequest.push(listQuestions[y].equipCategId)

      }
    }
    else {
      for (let n = 0; n < this.state.questionidpush.length; n++) {
        for (let t = 0; t < listQuestions.length; t++) {
          if (this.state.questionidpush[n] == listQuestions[t].questionId) {
            this.state.questionidpush.splice(n, 1)


          }

        }
      }


      for (let g = 0; g < this.state.equipmentrequest.length; g++) {
        for (let b = 0; b < listQuestions.length; b++) {
          if (this.state.equipmentrequest[g] == listQuestions[b].equipCategId) {
            this.state.equipmentrequest.splice(g, 1)
          }
        }
      }


    }
    this.state.equipmentrequest = [...new Set(this.state.equipmentrequest)]
    // this.state.questionidpush=[...new Set(this.state.questionidpush)]
    console.log(`this.state`, this.state.questionidpush)
    console.log(`equipment`, this.state.equipmentrequest)

  }


  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }


  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId

      }
    }
    );
  }



  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  openReview = () => {
    this.setState((prevState) => ({
      reviewAFM: !prevState.reviewAFM,

    }));
  }


  closeReview = () => {
    this.setState({ reviewAFM: false });
  }


  onChangeEquipmentNo = (e) => {
    this.setState({ data: '' })
    this.setState({ categoryName: [] })
    this.setState({
      arrrefs: [],
      arreqnum: [],
      arrqtypenum: [],
      arrchk: [],
      quesidmail: [],
      quesidcs9: [],
      equipidcs9: [],
      arrrefscs9: [],
      qforsub: []
    })
    this.setState({ getbuttondisable: false })
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ getBarcodeData: e.target.value })
    }
  }

  onScanBarcode = () => {

    this.setState((prevState) => ({ showscanner: !prevState.showscanner }), () => {
      if (this.state.showscanner) {
        this.remtime = setTimeout(() => {
          this.setState((prevState) => ({ showscanner: !prevState.showscanner }))
        }, 20000)
      }
      else if (this.remtime) {
        window.clearTimeout(this.remtime)
      }
    })
    this.setState({ data: '' })
    this.setState({ categoryName: [] })
    this.setState({
      arrrefs: [],
      arreqnum: [],
      arrqtypenum: [],
      arrchk: [],
      quesidmail: [],
      quesidcs9: [],
      equipidcs9: [],
      arrrefscs9: [],
      qforsub: [], getBarcodeData: ""
    })
    this.setState({ getbuttondisable: false })

  }

  fetchFullPapersubcategory = () => {

    this.props.history.push({
      pathname: '/Preview',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.props.location.state.data,
        tskId: this.props.location.state.tskId,
        tskName: this.props.location.state.tskName,
        guId: this.props.location.state.guId,
        storeNo: this.props.location.state.storeNo,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        tskDescr: this.props.location.state.tskDescr,

      }
    }
    );
  }

  render = () => {

    var that = this;
    var imagemodal = [];
    var imageClick = []

    imageClick.push(
      <div className="imgalign"><a class="mr-50" onClick={this.openReview.bind(this)}><img class='mr-10' style={{ height: '40px' }} src={require("./img/comment.png")}></img><b>Feedback</b></a></div>
    )

    imagemodal.push(

      <div className="modal" role="dialog" style={this.state.reviewAFM ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content" >
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.closeReview}>X</a>
              <h4 className="modal-title">FeedBack</h4>
            </div>
            <div className="modal-body">
              <div className="form-horizontal" style={{ "margin-right": "5px", height: "400px", }}>
                <div className="form-group">

                  <div className="col-sm-10">
                    <div className="tbl-holder mt-20">
                      <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide ">

                        <thead>

                          <tr>
                            <th style={{ width: "3%" }}>Sr No</th>
                            <th>Question</th>
                            <th style={{ width: "8%" }}>Feedback</th>
                          </tr>
                        </thead>
                      </table>

                      <Scrollbars style={{ height: 296 }}>

                        <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl ">
                          <tbody>


                            {this.state.Question.map(function (item, key) {


                              return (
                                <tr key={key}>
                                  <td data-th="Sr No" style={{ width: "3%" }}>{key + 1}</td>
                                  <td data-th="Question">  <a href="javascript:void(0)" className="black-text">  {item.surveyQuest}</a></td>
                                  <td data-th="Feedback" style={{ width: "8%" }}>  <a href="javascript:void(0)" className="black-text">  {item.surveyanswer}</a></td>

                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Scrollbars>
                    </div>



                  </div>

                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    )


    //Added by chandrani for custom error page
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <div>
          {imagemodal}
          <a href={config.get('RofficeLoginURL')} id="rofficelogin" hidden></a>
          <div className="spin">
            <Spinner visible={this.state.loading}
              spinnerColor={"rgba(0, 0, 0, 0.3)"} />
          </div>
          <section id="container">
            <div>
              <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                onAction={this.onAction}
                debounce={250}
                timeout={1000 * 60 * 10} />
            </div>
            <header class="header black-bg">

              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo">
                <img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">
                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" /> <b>{this.state.usrnm}</b> ({this.state.storeNo})<b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section class="wrapper">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-20">
                      <h3><i className="fa fa-angle-right"></i>{this.state.tskName}</h3>
                      <p>Description : {this.state.tskDescr}</p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" >Start Date : {this.state.strtDate} </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">End Date : {this.state.edDate}</div>
                    {this.state.file ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" style={{ float: "right", marginTop: "10px" }}>
                        <a onClick={this.openIns.bind(this)}>View Instruction</a>/
                        <a download="instructions" onClick={this.DownloadIns.bind(this)}>Download Instructions</a>
                        <a download="instructions" href={"/Images/" + this.state.file} id="FileDnld" hidden />


                      </div>
                      :
                      null}
                    {this.state.comment ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 mt-20" style={{ "font-weight": "bold" }}>Store Manager :<span style={{ "color": "red" }}>  {this.state.comment}</span></div>
                      : null}

                    {this.state.afmcomment ?
                      <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 mt-20" style={{ "font-weight": "bold" }}>AFM :<span style={{ "color": "red" }}>  {this.state.afmcomment}</span></div>
                      : null
                    }
                    <div class="clearfix"></div>

                    <hr />
                    {this.state.categoryId == "6" || this.state.categoryId == "7" || this.state.categoryId == "8" ?  //role_Id==="504"
                      <div>
                        <button className="btn btn-primary min-wid-90 user-img" onClick={this.fetchFullPapersubcategory.bind(this, "preview")} style={{ float: "right" }}>Preview</button>
                      </div> : ''}
                    <br />
                    <br />
                    {this.state.categoryId == "6" || this.state.categoryId == "7" || this.state.categoryId == "8" ?  //role_Id==="504"
                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Equipment  No</b><br />
                        <input type="text" placeholder="Enter Equipment No" className="form-control" id="equipNum" onChange={this.onChangeEquipmentNo} autoComplete="off" value={this.state.getBarcodeData} />
                      </div> : ''}
                    {this.state.categoryId == "6" || this.state.categoryId == "7" || this.state.categoryId == "8" ?   //role_Id==="504"
                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                        <img src={require("./img/scan.png")} className="user-img mt-20" style={{ width: "40px", height: "40px" }} onClick={this.onScanBarcode.bind(this)} />
                        <button className="btn btn-primary min-wid-90 user-img mt-20 ml-20" onClick={this.fetchFullPaper.bind(this, "here")} disabled={this.state.getbuttondisable}>Click here </button>

                      </div> : ''}



                    {this.state.showscanner ? <Scanning onClose={(getvalue) => { this.setState({ showscanner: false, getBarcodeData: getvalue }) }}

                    /> : ''}


                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <div class="clearfix"></div> */}
                    {(this.state.role_id == "505" || this.state.role_id == "501") && this.state.status == "Submitted" ?
                      <div>{imageClick}</div>
                      : ''}
                    {this.state.loading = false}

                    <ul class="ques">
                      {that.state.data ?
                        (that.state.data.map(function (xitem, xkey) {

                          return (
                            <Accordion allowZeroExpanded >
                              <AccordionItem >
                                <div key={xkey}>

                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <b id="accordionopen" style={{ textAlign: "center" }}>Category Name :{xitem.categoryName}({xitem.listQuestions.length})</b>
                                      {that.state.categoryId !== "6" || that.state.categoryId !== "7" || that.state.categoryId !== "8" ?
                                        <div style={{ "float": "right" }}>
                                          <input type="checkbox"
                                            {...that.state.status === "Submitted" ?
                                              that.state.disabled = "disabled"
                                              :
                                              that.state.disabled = ""
                                            }
                                            defaultChecked={that.state.euipcatidentity.includes(xitem.equipCategId) ? true : false}
                                            onChange={that.handleNotapplicableCheck.bind(that, xitem.listQuestions)}
                                            disabled={that.state.disabled} /><span style={{ "color": "red" }}><b>Not Applicable</b></span>
                                        </div> : ''}


                                      {/* {that.state.categoryId == "4" &&  that.props.location.state.equipFlag==="0"  ?
                                      <div style={{ "float": "right" }}>
                                        <input type="checkbox"
                                          {...that.state.status === "Submitted" ?
                                            that.state.disabled = "disabled"
                                            :
                                            that.state.disabled = ""
                                          }
                                          defaultChecked={that.state.euipcatidentity.includes(xitem.equipCategId) ? true : false}
                                          onChange={that.handleNotapplicableCheck.bind(that, xitem.listQuestions)}
                                          disabled={that.state.disabled} /><span style={{ "color": "red" }}><b>Not Applicable</b></span>
                                      </div> :''} */}



                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    {that.state.data[xkey].listQuestions.map(function (item, key) {
                                      switch (item.questionType) {

                                        case "1":
                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                {item.options.map((optele, index) => (<span class="nameloc">
                                                  <input type="radio"
                                                    class="custom-control-input" id={item.questionId + index} name={item.questionId}
                                                    value={optele}
                                                    {...that.state.arrid1.push(item.questionId + index)}
                                                    {...item.answer === optele ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}
                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + index}>{optele}</label>
                                                </span>
                                                )
                                                )}
                                              </div>
                                            </li>
                                          );
                                          break;
                                        case "2":

                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                {item.options.map((optele, index) => (
                                                  <span class="nameloc">
                                                    <input type="checkbox" class="custom-control-input" id={item.questionId + index} name={item.questionId + index}
                                                      value={optele}
                                                      {...that.state.arrid2.push(item.questionId + index)}
                                                      {...item.answer === undefined ?
                                                        that.state.checked = false :
                                                        {
                                                          ...item.answer.includes(optele) ?
                                                            that.state.checked = true
                                                            :
                                                            that.state.checked = false
                                                        }
                                                      }
                                                      defaultChecked={that.state.checked}
                                                      {...that.state.status === "Submitted" ?
                                                        that.state.disabled = "disabled"
                                                        :
                                                        that.state.disabled = ""
                                                      }
                                                      disabled={that.state.disabled}

                                                      onChange={that.handleChangeCheck.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                    />
                                                    <label style={{ fontWeight: "normal" }} for={item.questionId + index}>{optele}</label>
                                                  </span>
                                                ))}

                                              </div>
                                            </li>
                                          );
                                          break;
                                        case "3":
                                          that.state.arrrefs.push("qtype3" + item.questionId)
                                          that.state.arreqnum.push("qtype3" + item.equipmentNo)
                                          that.state.arrqtypenum.push("qtype3" + item.questionType)
                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0" >
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} /></p>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                  <textarea type="text" class="form-control" placeholder="Some Text"
                                                    value={that.state.value}
                                                    defaultValue={item.answer}
                                                    {...that.state.arrid3.push("qtype3" + item.questionId)}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    ref={"qtype3" + item.questionId}
                                                    id={"qtype3" + item.questionId}
                                                  />
                                                </div>
                                                <div class="clearfix">
                                                </div>
                                              </div>
                                            </li>
                                          );
                                          break;
                                        case "4":
                                          that.state.arrrefs.push("qtype3" + item.questionId)
                                          that.state.numeleid.push("qtype3" + item.questionId)
                                          that.state.arreqnum.push("qtype3" + item.equipmentNo)
                                          that.state.arrqtypenum.push("qtype3" + item.questionType)

                                          that.state.numminval.push(item.minValue)
                                          if (that.state.value1 === "") {
                                            if (!that.state.numchange) {
                                              //that.state.value1 = item.answer
                                            }

                                          }
                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0" >
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                  <that.Astrik isMandatory={item.isMandatory} /></p>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                  <input type="tel" class="form-control" placeholder="Number Only"
                                                    //value={that.state.value1}
                                                    defaultValue={item.answer}
                                                    {...that.state.arrid4.push("qtype3" + item.questionId)}

                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    pattern="^-?[0-9]\d*\.?\d*$" onInput={that.handleChangeNum.bind(that, "qtype3" + item.questionId, item.maxValue)}

                                                    ref={"qtype3" + item.questionId}
                                                    id={"qtype3" + item.questionId}
                                                  />

                                                </div>
                                                <div class="clearfix">
                                                </div>
                                              </div>
                                            </li>
                                          );
                                          break;
                                        case "5":
                                          that.state.arrrefs.push("qtype3" + item.questionId);
                                          that.state.arreqnum.push("qtype3" + item.equipmentNo)
                                          that.state.arrqtypenum.push("qtype3" + item.questionType)

                                          that.state.quesidmail.push("qtype3" + item.questionId)

                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0" >
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                  <input type="email" class="form-control" placeholder="Email-ID"
                                                    value={that.state.value}
                                                    defaultValue={item.answer}
                                                    ref={"qtype3" + item.questionId}
                                                    id={"qtype3" + item.questionId}
                                                    {...that.state.arrid5.push("qtype3" + item.questionId)}

                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                  />
                                                </div>
                                                <div class="clearfix">
                                                </div>
                                              </div>
                                            </li>
                                          );
                                          break;
                                        case "6":
                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                <span class="ml-10">
                                                  <input type="radio" class="custom-control-input" id={item.questionId + 'A'} name={item.questionId}
                                                    value="Yes"
                                                    {...that.state.arrid6.push(item.questionId + 1)}

                                                    {...item.answer === "Yes" ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + 'A'}>Yes</label>
                                                </span>
                                                <span>
                                                  <input type="radio" class="custom-control-input" id={item.questionId + 'B'} name={item.questionId}
                                                    value="No"
                                                    {...that.state.arrid6.push(item.questionId + 2)}

                                                    {...item.answer === "No" ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + 'B'}>No</label>
                                                </span> </div>
                                            </li>
                                          );
                                          break;
                                        case "7":
                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}

                                                  <that.Astrik isMandatory={item.isMandatory} /></p>
                                                <span class="ml-10">
                                                  <input type="radio" class="custom-control-input" id={item.questionId + 'D'} name={item.questionId}
                                                    value="Yes"
                                                    {...that.state.arrid7.push(item.questionId + 1)}

                                                    {...item.answer === "Yes" ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + 'D'}>Yes</label>
                                                </span> <span>
                                                  <input type="radio" class="custom-control-input" id={item.questionId + 'E'} name={item.questionId}
                                                    value="No"
                                                    {...that.state.arrid7.push(item.questionId + 2)}

                                                    {...item.answer === "No" ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + 'E'}>No</label>
                                                </span> <span>
                                                  <input type="radio" class="custom-control-input" id={item.questionId + 'F'} name={item.questionId}
                                                    value="NA"
                                                    {...that.state.arrid7.push(item.questionId + 3)}

                                                    {...item.answer === "NA" ?
                                                      that.state.checked = true
                                                      :
                                                      that.state.checked = false
                                                    }
                                                    defaultChecked={that.state.checked}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    onChange={that.handleChangeRadioNew.bind(that, item.questionId, item.equipmentNo, item.questionType)}
                                                  />
                                                  <label style={{ fontWeight: "normal" }} for={item.questionId + 'F'}>NA</label>
                                                </span> </div>
                                            </li>
                                          );
                                          break;
                                        case "8":
                                          var valup = "Upload File"
                                          var bool = false;
                                          if (that.state.fileval.includes(item.questionId, 0)) {
                                            var elelen = that.state.fileval.length;
                                            valup = that.state.fileval.substring(item.questionId.length, elelen);
                                            bool = true
                                          }

                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                <div class="clearfix">
                                                  <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                    <input id={"proof" + item.questionId} type="text" class="form-control input-upload"
                                                      // value="Upload file"
                                                      {...bool === true ?
                                                        valup = valup
                                                        :
                                                        { ...item.imageArray.length > 0 ? valup = "one file uploaded" : valup = "upload file" }

                                                      }
                                                      value={valup}
                                                      {...that.state.arrid8.push("proof" + item.questionId)}
                                                      {...that.state.status === "Submitted" ?
                                                        that.state.disabled = "disabled"
                                                        :
                                                        that.state.disabled = ""
                                                      }
                                                      disabled={that.state.disabled}
                                                      accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf"
                                                    />
                                                    <span class="btn-upload" style={{ padding: "7px 15px" }}
                                                      {...that.state.status === "Submitted" ?
                                                        that.state.disabled = "disabled"
                                                        :
                                                        that.state.disabled = ""
                                                      }
                                                      disabled={that.state.disabled}

                                                    >Upload</span>
                                                    <input id={item.questionId} type="file" name="proof-data" class="input-hidden"
                                                      {...that.state.status === "Submitted" ?
                                                        that.state.disabled = "disabled"
                                                        :
                                                        that.state.disabled = ""
                                                      }
                                                      disabled={that.state.disabled}
                                                      {...that.state.arrid8.push(item.questionId)}

                                                      onChange={that.handleFileSelect.bind(that, item.questionId, item.questionType, item.equipmentNo)}
                                                      accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf" multiple="multiple" />

                                                    <div class="clearfix"></div>

                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0" >
                                                      <a onClick={that.openPrf.bind(that, item.questionId)}>View file</a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                  </div>
                                                </div>
                                                <div class="clearfix">
                                                </div>
                                              </div>
                                            </li>

                                          );
                                          break;
                                        case "9":
                                          that.state.quesidcs9.push(item.questionId);
                                          that.state.equipidcs9.push(item.equipmentNo)
                                          that.state.qforsub.push(item.questionType)
                                          var ansarr = ""
                                          if (item.answer === undefined || item.answer === null || item.answer === "") {

                                          }
                                          else if (item.answer != null || item.answer != "" || item.answer != undefined) {
                                            ansarr = item.answer.split(",")
                                          }

                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                {item.options.map((optele, index) => (
                                                  <span class="ml-0 pl-0">
                                                    <p for={item.questionId + index}><b>{optele}</b></p>

                                                    <input type="text" class="form-control"
                                                      value={that.state.value}
                                                      onKeyPress={that.fullstop}
                                                      {...ansarr.length > 0 ?
                                                        (that.state.defval = ansarr[index])
                                                        :
                                                        (that.state.defval = "")
                                                      }

                                                      defaultValue={that.state.defval}
                                                      {...that.state.status === "Submitted" ?
                                                        (that.state.disabled = "disabled")
                                                        :
                                                        (that.state.disabled = "")
                                                      }
                                                      disabled={that.state.disabled}

                                                      ref={item.questionId + index}
                                                      id={item.questionId + index}
                                                      {...that.state.arrid9.push(item.questionId + index)}

                                                      {...that.state.arrrefscs9.push(item.questionId + index)}
                                                    />

                                                    <div class="clearfix">
                                                    </div>
                                                  </span>
                                                )
                                                )}
                                              </div>
                                            </li>
                                          );
                                          break;

                                        case "10":

                                          that.state.arrrefs.push("qtype3" + item.questionId)
                                          that.state.arreqnum.push("qtype3" + item.equipmentNo)
                                          that.state.arrqtypenum.push("qtype3" + item.questionType)

                                          return (


                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0" >
                                                <p style={{ fontWeight: "bold" }}>{item.questionText}
                                                  <that.Astrik isMandatory={item.isMandatory} />
                                                </p>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0" >
                                                  <input type="date" className="form-control form-control-inline input-medium default-date-picker"
                                                    size="16" dateFormat="dd-MMM-YYYY" data-date-inline-picker="true"  //selected={that.state.startDate}
                                                    // onChange={that.handleChangeStart.bind(that,item.questionId)} onChangeRaw={that.handleDateChangeRaw.bind(that)}

                                                    value={that.state.value}
                                                    defaultValue={item.answer}
                                                    {...that.state.arrid10.push("qtype3" + item.questionId)}
                                                    {...that.state.status === "Submitted" ?
                                                      that.state.disabled = "disabled"
                                                      :
                                                      that.state.disabled = ""
                                                    }
                                                    disabled={that.state.disabled}

                                                    ref={"qtype3" + item.questionId}
                                                    id={"qtype3" + item.questionId}
                                                  />
                                                </div>
                                                <div class="clearfix">
                                                </div>
                                              </div>
                                            </li>

                                          );

                                          break;
                                        case "11":
                                          that.state.quesid.push(item.questionId);
                                          var valup = "Upload File";
                                          var bool = false;
                                          if (
                                            that.state.fileval.includes(
                                              item.questionId,
                                              0
                                            )
                                          ) {
                                            var elelen = that.state.fileval.length;
                                            valup = that.state.fileval.substring(
                                              item.questionId.length,
                                              elelen
                                            );
                                            bool = true;
                                          }

                                          return (
                                            <li key={key}>
                                              <div class="custom-selection ml-0 pl-0">
                                                <p style={{ fontWeight: "bold" }}>
                                                  {item.questionText}
                                                  <that.Astrik
                                                    isMandatory={item.isMandatory}
                                                  />
                                                </p>
                                                <div class="clearfix">
                                                  {that.state.browser === true ? (
                                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 pl-0">
                                                      <input
                                                        id={"proof" + item.questionId}
                                                        type="text"
                                                        class="form-control input-upload"
                                                        // value="Upload file"
                                                        {...(bool === true
                                                          ? (valup = valup)
                                                          : {
                                                            ...(item.imageArray.length >
                                                              0
                                                              ? (valup =
                                                                "one image uploaded")
                                                              : (valup =
                                                                "upload live image"))
                                                          })}
                                                        value={valup}
                                                        {...that.state.arrid8.push(
                                                          "proof" + item.questionId
                                                        )}
                                                        {...(that.state.status === "Submitted"
                                                          ? (that.state.disabled =
                                                            "disabled")
                                                          : (that.state.disabled = ""))}
                                                        disabled={that.state.disabled}
                                                        accept="image/*"
                                                      />

                                                      <span
                                                        class="btn-upload"
                                                        style={{ padding: "7px 15px" }}
                                                        {...(that.state.status === "Submitted"
                                                          ? (that.state.disabled =
                                                            "disabled")
                                                          : (that.state.disabled = ""))}
                                                        disabled={that.state.disabled}
                                                      >
                                                        Upload
                                                      </span>
                                                      <input
                                                        id={item.questionId}
                                                        type="file"
                                                        name="proof-data"
                                                        class="input-hidden"
                                                        {...(that.state.status === "Submitted"
                                                          ? (that.state.disabled =
                                                            "disabled")
                                                          : (that.state.disabled = ""))}
                                                        disabled={that.state.disabled}
                                                        {...that.state.arrid8.push(
                                                          item.questionId
                                                        )}
                                                        onChange={that.handleFileSelect.bind(
                                                          that,
                                                          item.questionId,
                                                          item.questionType, item.equipmentNo
                                                        )}
                                                        capture="camera"
                                                        accept="image/*"
                                                        multiple="multiple"
                                                      />

                                                      <div class="clearfix"></div>

                                                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
                                                        <a onClick={that.openPrf.bind(that, item.questionId)}>View file</a>
                                                      </div>

                                                      <div class="clearfix"></div>
                                                    </div>
                                                  ) : (
                                                    <h5>Use Mobile Browser to Upload</h5>
                                                  )}
                                                </div>
                                                <div class="clearfix"></div>
                                              </div>
                                            </li>
                                          );
                                          break;

                                        default:
                                          showAlert("Error Displaying Preview.")


                                          break;
                                      }
                                    })}</AccordionItemPanel>
                                </div></AccordionItem>
                            </Accordion>
                          )
                        })
                        ) : (
                          null)

                      }</ul>
                    {this.state.data.length > 0 ?
                      <div class="text-center mt">

                        <button className="btn btn-primary min-wid-90 mt-10"
                          {...that.state.status === "Submitted" ?
                            that.state.disabled = "disabled"
                            :
                            that.state.disabled = ""
                          }
                          disabled={(that.state.disabled)}

                          onClick={that.draftquestion.bind(that)}>
                          Draft
                        </button>
                        {this.state.categoryId !== "6" || this.state.categoryId !== "7" || this.state.categoryId !== "8" ?
                          <button className="btn btn-primary min-wid-90 ml-10"
                            {...that.state.status === "Submitted" ?
                              that.state.disabled = "disabled"
                              :
                              that.state.disabled = ""
                            }
                            disabled={that.state.disabled}

                            onClick={that.savequestion.bind(that)} id="savebtn" >
                            Submit
                          </button> : ''}

                        {/* {this.state.categoryId == "4" && this.props.location.state.equipFlag==="0" ?
                        <button className="btn btn-primary min-wid-90 ml-10"
                          {...that.state.status === "Submitted" ?
                            that.state.disabled = "disabled"
                            :
                            that.state.disabled = ""
                          }
                          disabled={that.state.disabled}

                          onClick={that.savequestion.bind(that)} id="savebtn" >
                          Submit
                    </button> : ''} */}

                      </div> : ''}
                    <div class="clearfix"></div>
                  </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      );
    }
  }



  fullstop = (e) => {
    var theEvent = e || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /[^,;]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.props.location.state.data,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  fetchQuestionPaper = () => {
    var that = this

    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== "") {
      this.setState({
        loading: true,
        taskId: this.props.location.state.tskId,
        userId: this.props.location.state.userid,
        storeNo: this.props.location.state.storeNo,
        usrnm: this.props.location.state.usrnm,
        tskName: this.props.location.state.tskName,
        tskDescr: this.props.location.state.tskDescr,
        strtDate: this.props.location.state.strtDate,
        edDate: this.props.location.state.edDate,
        guId: this.props.location.state.guId,
        role_id: this.props.location.state.role_id,
        categoryId: this.props.location.state.categoryId,
        equipFlag: this.props.location.state.equipFlag

      })
      this.state.ansobj.taskId = this.props.location.state.tskId
      this.state.ansobj.userId = this.props.location.state.userid

    }
    else {

      this.props.history.push({
        pathname: '/',
      });

    }
  }



  fetchFullPaper = (params) => {
    if (params == "preview") {
      var Request = {
        "taskId": this.props.location.state.tskId,
        "storeNo": this.props.location.state.storeNo,
        "userId": this.props.location.state.userid,
        "guId": this.props.location.state.guId,
      }
      this.getTechDetail(Request)

    }
    else {
      if (!document.getElementById("equipNum").value) {
        showSuccess("Please enter Equipment Number.")
      }
      else {
        var Request = {
          "taskId": this.props.location.state.tskId,
          "storeNo": this.props.location.state.storeNo,
          "userId": this.props.location.state.userid,
          "guId": this.props.location.state.guId,
          "equipmentNo": document.getElementById("equipNum").value
        }
        this.getTechDetail(Request)

      }

    }



  }


  getTechDetail = (Request) => {
    var EncryptedRequest = Encrypt(Request);
    console.log(`tech Details `, EncryptedRequest)
    fetch('/GetTechDetails', {
      method: 'POST',
      body: EncryptedRequest,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),



      }

    })
      .then((response) => response.text())
      .then((response) => {

        var DecryptedResponse = decryptData(response)
        if (DecryptedResponse.responseCode == '200 OK') {
          console.log(`GetTssechDetails`, DecryptedResponse)
          for (let i = 0; i < DecryptedResponse.taskQuestionsList.length; i++) {
            if (DecryptedResponse.taskQuestionsList[i].isMandatory === "1") {
              this.state.mandid.push(DecryptedResponse.taskQuestionsList[i].questionId);
            }
          }

          console.log(`response of fetchQuestionPaper `, DecryptedResponse)

          var category = []
          for (let k = 0; k < DecryptedResponse.taskQuestionsList.length; k++) {
            category.push(DecryptedResponse.taskQuestionsList[k].equipCategoryName)
          }

          var item = [...new Set(category)]
          for (let s = 0; s < item.length; s++) {
            var query = {
              categoryName: "",
              equipCategId: "",
              listQuestions: []
            }
            query.categoryName = item[s]
            for (let g = 0; g < DecryptedResponse.taskQuestionsList.length; g++) {
              if (DecryptedResponse.taskQuestionsList[g].equipCategoryName === item[s]) {
                query.listQuestions.push(DecryptedResponse.taskQuestionsList[g])
                query.equipCategId = DecryptedResponse.taskQuestionsList[g].equipCategId

              }

            }
            this.state.categoryName.push(query)

          }

          this.setState({ getbuttondisable: true })


          if (DecryptedResponse.taskDetails.feedback !== "" && DecryptedResponse.taskDetails.feedback != undefined) {
            var questionFeedbackList = ["Quality & Effectiveness of Soft Services (HK and PC)", "Quality of Repair and Maintenance of FM equipment's at the Stores / Sites (MEPC and CAMC)",
              "FM team displays a sense of urgency in addressing issues and faults", "Accessibility & availability of FM team members in emergencies",
              "Effectiveness of Store Connect /FM Self Audit Programs in Store/Supplying Sites", "How would you rate the overall performance of FM services?", "Comment"]

            var answerfeedback = []

            answerfeedback = DecryptedResponse.taskDetails.feedback.split(',')
            answerfeedback.push(DecryptedResponse.taskDetails.managerComment)

            for (let y = 0; y < questionFeedbackList.length; y++) {
              var feedbacksurvey = {
                surveyQuest: "",
                surveyanswer: ""
              }
              feedbacksurvey.surveyQuest = questionFeedbackList[y]
              feedbacksurvey.surveyanswer = answerfeedback[y]
              this.state.Question.push(feedbacksurvey)
            }
          }
          this.setState({ euipcatidentity: DecryptedResponse.equipcategoryID })
          this.setState({ data: this.state.categoryName });
          this.setState({ status: DecryptedResponse.taskDetails.status });
          this.setState({ instReadFlag: DecryptedResponse.taskDetails.instReadFlag })
          console.log(`euipcatidentity`, this.state.euipcatidentity)
          if (DecryptedResponse.equipcategoryID[0] != "") {
            this.setState({ equipmentrequest: DecryptedResponse.equipcategoryID })
          }

          for (let y = 0; y < DecryptedResponse.equipcategoryID.length; y++) {
            for (let j = 0; j < DecryptedResponse.taskQuestionsList.length; j++) {
              if (DecryptedResponse.equipcategoryID[y] == DecryptedResponse.taskQuestionsList[j].equipCategId) {
                this.state.questionidpush.push(DecryptedResponse.taskQuestionsList[j].questionId)
              }
            }
          }
          this.setState({ smapprove: DecryptedResponse.taskDetails.managerApproval })
          // if (this.state.smapprove !== "Approved" && this.state.smapprove === "Pending") {
          //   this.setState({ status: "Draft" })
          // }
          // else {
          //   this.setState({ status: DecryptedResponse.taskDetails.status })
          // }

          if (DecryptedResponse.taskDetails.imageInstructionName != " ") {
            this.setState({ file: DecryptedResponse.taskDetails.imageInstructionName });
          }
          this.setState({ comment: DecryptedResponse.taskDetails.managerComment })
          this.setState({ afmcomment: DecryptedResponse.taskDetails.afmComment })
          this.state.radioObj = [];

          var newans = "";
          var isimgq = "false";
          if (DecryptedResponse.taskQuestionsList.length > 0) {
            for (let index = 0; index < DecryptedResponse.taskQuestionsList.length; index++) {
              var one = DecryptedResponse.taskQuestionsList[index];
              this.state.quesid.push(one.questionId);
              if (one.questionType === "8" || one.questionType === "11") {
                console.log(`one`, one)
                if (one.imageArray === "") {
                  this.state.fileuploaded = [];
                  this.setState({ fileval: "Upload file" })

                }
                else {
                  this.state.fileuploaded.push(one.questionId + one.imageArray);
                  this.setState({ fileval: "One file attached" });
                  isimgq = "true";
                }

                if (isimgq === "false") {
                  this.state.fileuploaded = [];
                  this.setState({ fileval: "Upload file" })
                }
                if (one.imageArray === "") {
                  this.setState({ fileval: "Upload file" })
                } else {
                  if (one.imageArray === "" || one.imageArray === null || one.imageArray === undefined) {
                    newans = {
                      taskId: this.props.location.state.tskId,
                      userId: this.props.location.state.userid,
                      questionId: one.questionId,
                      imageArray: "",
                      equipmentNo: one.equipmentNo,
                      qType: one.questionType
                    };
                  } else {
                    newans = {
                      taskId: this.props.location.state.tskId,
                      userId: this.props.location.state.userid,
                      questionId: one.questionId,
                      imageArray: one.imageArray,
                      equipmentNo: one.equipmentNo,
                      qType: one.questionType

                    };
                  }
                  this.state.radioObj.push(newans);
                  console.log(`ans1`, this.state.radioObj)
                }
              }
              else {
                if (one.answer === "" || one.answer === null || one.answer === undefined) {


                  newans = {
                    taskId: this.props.location.state.tskId,
                    userId: this.props.location.state.userid,
                    questionId: one.questionId,
                    answer: '',
                    equipmentNo: one.equipmentNo,
                    qType: one.questionType

                  };
                }
                else {
                  newans = {
                    taskId: this.props.location.state.tskId,
                    userId: this.props.location.state.userid,
                    questionId: one.questionId,
                    answer: one.answer,
                    equipmentNo: one.equipmentNo,
                    qType: one.questionType

                  };
                }
                this.state.radioObj.push(newans);
                console.log(`ans1`, this.state.radioObj)


              }
            }
            document.getElementById("accordionopen").click();
          }
          else {

          }
        }

        else {
          showAlert(DecryptedResponse.responseMsg)

          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        console.log(`sds`, error)
        this.setState({ visiblity: false })

        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });
      });
  }


  savequestion = (e) => {
    console.log(`print`, JSON.stringify(this.state.radioObj))

    console.log(`lat`, this.state.latitude)

    var validmail = "";
    var validmin = true;
    const uquesidmail = Array.from(new Set(this.state.quesidmail));

    const uniqueNames = Array.from(new Set(this.state.arrrefs));
    const uniqueEqId = Array.from(new Set(this.state.arreqnum))
    var uniqueQtypeId = Array.from(new Set(this.state.arrqtypenum))

    const uniqueChk = Array.from(new Set(this.state.arrchk));
    var newans = "";
    var ans = "";
    var ansc = "";


    if (uniqueNames.length > 0) {
      for (let index = 0; index < uniqueNames.length; index++) {
        var element = uniqueNames[index];
        var qtypenum = this.state.arrqtypenum[index]
        ans = document.getElementById(element).value;
        newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: element.replace("qtype3", ""),
          answer: ans,
          qType: qtypenum.replace("qtype3", "")
        };
        for (let q = 0; q < this.state.radioObj.length; q++) {
          if (this.state.radioObj[q].questionId == newans.questionId) {
            this.state.radioObj[q] = newans;

          }
        }


      }
    }

    const uquesidcs9 = Array.from(new Set(this.state.quesidcs9));
    const valueofid = Array.from(new Set(this.state.equipidcs9))
    const valueofqtpe = Array.from(new Set(this.state.qforsub))
    const uarrrefscs9 = Array.from(new Set(this.state.arrrefscs9));
    if (uquesidcs9.length > 0) {
      for (let j = 0; j < uquesidcs9.length; j++) {
        var elementq = uquesidcs9[j];
        var drfg = valueofqtpe[0]
        if (uarrrefscs9.length > 0) {
          for (let index1 = 0; index1 < uarrrefscs9.length; index1++) {
            var element1 = uarrrefscs9[index1];
            if (element1.substr(0, element1.length - 1).includes(elementq)) {
              ansc = ansc + document.getElementById(element1).value + ",";

            }
          }
          if (ansc.replace(/,/g, "").length > 0) {
            newans = {
              taskId: this.props.location.state.tskId,
              userId: this.props.location.state.userid,
              questionId: elementq,
              answer: ansc.substr(0, ansc.length - 1),
              qType: drfg
            };
            for (let y = 0; y < this.state.radioObj.length; y++) {
              if (this.state.radioObj[y].questionId == newans.questionId) {
                this.state.radioObj[y] = newans;

              }
            }

          }
          else {
            newans = {
              taskId: this.props.location.state.tskId,
              userId: this.props.location.state.userid,
              questionId: elementq,
              answer: "",
              qType: drfg

            };
            for (let o = 0; o < this.state.radioObj.length; o++) {
              if (this.state.radioObj[o].questionId == newans.questionId) {
                this.state.radioObj[o] = newans;

              }
            }

          }
          ansc = "";
        }


      }
    }

    var objwithans = [];
    var myflag = true;
    if (this.state.radioObj.length > 0) {
      var mythis = this;
      {
        this.state.radioObj.map(function (item, key) {
          if (item.answer === "" || (item.imageArray != undefined && item.imageArray.length === 0)) {
            for (let index = 0; index < mythis.state.mandid.length; index++) {
              const element = mythis.state.mandid[index];
              if (item.questionId === element) {
                myflag = false;
              }
            }
          }
          else {
            myflag = true;
            console.log(`objwithans`, objwithans.length)
            if (objwithans.length > 0) {
              {
                let k = 0;
                for (let r = 0; r < objwithans.length; r++) {
                  if (objwithans[r].questionId != item.questionId) {
                    k = k + 1;
                  }
                  else {
                    k = 0
                  }
                }

                if (k > 0) {
                  objwithans.push(item);
                }
                else {
                  console.log(`error`)
                }

              }
            }
            else {
              objwithans.push(item);
            }
          }
        })
      }
    }

    console.log(`object`, objwithans)
    var cleaned = [];
    objwithans.forEach(function (itm) {
      var unique = true;
      console.log(`cleaned`, cleaned)
      cleaned.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) cleaned.push(itm);
    });


    if (this.state.fileval === "One file attached" || this.state.fileval === "File Upload successful") {
      this.state.nooffileques = this.state.nooffileques + 1;
    }

    var unmandid = [];
    this.state.mandid.forEach(function (itm) {
      var unique = true;
      unmandid.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) unmandid.push(itm);
    });
    console.timeEnd('5')

    var abc = 0;
    var flag1 = true


    if (this.state.questionidpush.length > 0) {
      for (let m = 0; m < this.state.questionidpush.length; m++) {
        for (let u = 0; u < unmandid.length; u++) {
          if (this.state.questionidpush[m] == unmandid[u]) {
            unmandid.splice(u, 1)
          }
        }
      }
    }

    console.log(`unmandid`, unmandid)
    for (let i = 0; i < unmandid.length; i++) {
      for (let k = 0; k < cleaned.length; k++) {
        if (unmandid[i] === cleaned[k].questionId) {

          abc = abc + 1
        }

      }

    }

    console.log(`cleaned`, cleaned)
    var clradioobj = []
    this.state.radioObj.forEach(function (itm) {
      var unique = true;
      clradioobj.forEach(function (itm2) {
        if (_.isEqual(itm, itm2)) unique = false;
      });
      if (unique) clradioobj.push(itm);
    });

    for (let index = 0; index < clradioobj.length; index++) {
      const element1 = clradioobj[index];
      var qid = element1.questionId;
      var an
      for (let index1 = 0; index1 < clradioobj.length; index1++) {
        const element2 = clradioobj[index1];
        if (element2.questionId === qid) {
          an = element2.answer
        }
      }
      clradioobj[index].answer = an;
    }

    this.state.radioObj = clradioobj
    //
    var fclrradioobj = [];
    for (let l = 0; l < clradioobj.length; l++) {

      let boxarray = clradioobj[l];
      if (boxarray.answer === "" || (boxarray.imageArray != undefined && boxarray.imageArray.length === 0)) {

      } else {
        fclrradioobj.push(boxarray);
      }

    }

    var ftemp = false;
    var arrftemp = [];
    if (unmandid.length == 0) {
      ftemp = true;

    }
    else {
      for (let i = 0; i < unmandid.length; i++) {
        ftemp = false;
        var indivmand = unmandid[i];
        console.log(`fclrradioobj`, fclrradioobj)
        for (let x = 0; x < fclrradioobj.length; x++) {
          if (fclrradioobj[x].questionId === indivmand) {
            ftemp = true;
            break;
          }
        }
        if (ftemp === false)
          break;
      }
    }

    var str;

    if (unmandid.length <= abc && ftemp === true) {

      if (uquesidmail.length > 0) {
        for (let z = 0; z < uquesidmail.length; z++) {
          var elementk = uquesidmail[z];
          if (document.getElementById(elementk).value != "") {
            var ansk = document.getElementById(elementk).value;

            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            validmail = re.test(ansk);
          }
          else {
            validmail = true
          }
        }
      }
      else {
        validmail = true;
      }

      for (let i = 0; i < this.state.numeleid.length; i++) {
        var numelementv = this.state.numeleid[i];
        var numminvalv = this.state.numminval[i];

        if (numminvalv === undefined) {
          numminvalv = 0;
        }

        if (document.getElementById(numelementv).value.length < numminvalv) {
          if (unmandid.includes(numelementv.replace("qtype3", ""))) {
            validmin = false;
            break;
          }
        }
        else {
          validmin = true;
        }

      }

      if (validmail) {
        if (validmin) {
          var value = this.state.latitude
          if (this.state.latitude != "" && this.state.latitude != '' && this.state.latitude != undefined && this.state.latitude != null && value.includes(".")) {
            confirmAlert({
              message: 'Are you sure you want to submit?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {

                    var Request2 = {
                      "status": "Submitted",
                      "storeId": this.props.location.state.storeNo,
                      "userAnswerList": this.state.radioObj,
                      "userFormatId": this.props.location.state.role_id,
                      "equipcategoryID": this.state.equipmentrequest,
                      "guId": this.props.location.state.guId,
                      "latLong": this.state.latitude,
                      "taskId": this.props.location.state.tskId,
                      "userId": this.props.location.state.userid,
                      "equipmentNo": this.state.radioObj[0].equipmentNo

                    }

                    var EncryptedRequest2 = Encrypt(Request2);
                    console.log(`save`, EncryptedRequest2)
                    fetch('/InsertTechnicianAnswer', {
                      method: 'POST',
                      body: EncryptedRequest2,
                      headers: {
                        guId: this.props.location.state.guId,
                        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


                      }

                    })
                      .then((response) => response.text())
                      .then((response) => {
                        var DecryptedResponse = decryptData(response)
                        if (DecryptedResponse.responseCode === '200 OK') {

                          confirmAlert({

                            message: "Answers has been submitted successfully.",
                            buttons: [
                              {
                                label: 'OK',
                              },

                            ]
                          })

                          this.props.history.push({
                            pathname: '/Taskactive',
                            state: {
                              userid: this.props.location.state.userid,
                              usrnm: this.props.location.state.usrnm,
                              role_id: this.props.location.state.role_id,
                              data: this.props.location.state.data,
                              storeNo: this.props.location.state.storeNo,
                              guId: this.props.location.state.guId

                            }
                          }
                          );
                        }

                        else {
                          showAlert(DecryptedResponse.responseMsg)

                        }
                      })
                      .catch((error) => {

                        confirmAlert({
                          title: 'Alert !',
                          message: "Session expired",
                          buttons: [
                            {
                              label: 'Ok',
                              onClick: () => { this.logoutnormal() }
                            },

                          ]


                        });

                      });
                  }
                },
                {
                  label: 'No',
                  onClick: () => {
                    cleaned = null;
                    this.state.nooffileques = 0;
                    return false;
                  }
                }
              ]
            });
          }
          else {
            showAlert("Unable to access your location.")

          }
        }
        else {
          showAlert("Error saving answer.Please check the minimum value of the numeric answer")

        }
      }

      else {
        this.setState({ objwithans: [] });
        cleaned = null;
        showAlert("Please enter a valid email id")


      }
    }

    else {
      this.state.nooffileques = 0;
      this.setState({ objwithans: [] });
      cleaned = [];
      showAlert("Please answer all the questions")

    }

  }

  draftquestion = (e) => {
    var validmin = true;
    console.log(`sameer.s.pa`, this.state.radioObj)
    const uniqueNames = Array.from(new Set(this.state.arrrefs));
    const uniqueEqId = Array.from(new Set(this.state.arreqnum))
    // var uniqueQtypeId = Array.from(new Set(this.state.arrqtypenum))
    const uniqueChk = Array.from(new Set(this.state.arrchk));
    const uquesidmail = Array.from(new Set(this.state.quesidmail));
    var validmail = "";
    var newans = "";
    var ans = "";
    var ansc = "";
    // this.state.radioObj=[];

    if (uniqueNames.length > 0) {

      for (let index = 0; index < uniqueNames.length; index++) {
        console.log(`error`, index)
        var element = uniqueNames[index];
        if (uniqueEqId[0].includes(undefined)) {
          var idofequip = ''
        }
        else {
          var idofequip = uniqueEqId[0]
        }

        var qtypenum = this.state.arrqtypenum[index]
        ans = document.getElementById(element).value;

        newans = {
          taskId: this.props.location.state.tskId,
          userId: this.props.location.state.userid,
          questionId: element.replace("qtype3", ""),
          answer: ans,
          equipmentNo: idofequip.replace("qtype3", ""),
          qType: qtypenum.replace("qtype3", "")
        };


        for (let a = 0; a < this.state.radioObj.length; a++) {
          if (this.state.radioObj[a].questionId == newans.questionId) {
            this.state.radioObj[a] = newans;

          }
        }




      }



    }
    const uquesidcs9 = Array.from(new Set(this.state.quesidcs9));
    const valueofid = Array.from(new Set(this.state.equipidcs9))
    const uarrrefscs9 = Array.from(new Set(this.state.arrrefscs9));
    const valueofqtpes = Array.from(new Set(this.state.qforsub))
    if (uquesidcs9.length > 0) {
      for (let j = 0; j < uquesidcs9.length; j++) {
        var elementq = uquesidcs9[j];//2220
        if (valueofid[0] === undefined) {
          var equcs9 = ''
        }
        else {
          var equcs9 = valueofid[0]
        }
        var drfg = valueofqtpes[0]
        if (uarrrefscs9.length > 0) {
          for (let index1 = 0; index1 < uarrrefscs9.length; index1++) {
            var element1 = uarrrefscs9[index1];//22201,
            if (element1.substr(0, element1.length - 1).includes(elementq)) { //22220
              ansc = ansc + document.getElementById(element1).value + ",";
            }
          }
          if (ansc.replace(/,/g, "").length > 0) {
            newans = {
              taskId: this.props.location.state.tskId,
              userId: this.props.location.state.userid,
              questionId: elementq,
              answer: ansc.substr(0, ansc.length - 1),
              equipmentNo: equcs9.replace("qtype3", ""),
              qType: drfg
            };

            for (let l = 0; l < this.state.radioObj.length; l++) {
              if (this.state.radioObj[l].questionId == newans.questionId) {
                this.state.radioObj[l] = newans;

              }
            }
            // this.state.radioObj.push(newans);
          }
          else {
            newans = {
              taskId: this.props.location.state.tskId,
              userId: this.props.location.state.userid,
              questionId: elementq,
              answer: "",
              equipmentNo: equcs9,
              qType: drfg
            };

            for (let m = 0; m < this.state.radioObj.length; m++) {
              if (this.state.radioObj[m].questionId == newans.questionId) {
                this.state.radioObj[m] = newans;

              }
            }
            // this.state.radioObj.push(newans);
          }
          ansc = "";
        }

        // this.state.radioObj.push(newans);

      }
    }
    if (uquesidmail.length > 0) {
      for (let k = 0; k < uquesidmail.length; k++) {
        var elementk = uquesidmail[k];
        var ansk = document.getElementById(elementk).value;
        if (ansk.length === 0) {
          validmail = true;
        }
        else {
          var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          validmail = re.test(ansk);
        }
      }
    }
    else {
      validmail = true;
    }

    var numericarray = [...new Set(this.state.numeleid)]
    for (let i = 0; i < numericarray.length; i++) {
      var numelementv = numericarray[i];
      var numminvalv = numericarray[i];

      if (numminvalv === undefined || numelementv === undefined) {
        numminvalv = 0;
      }
      else {
        if (document.getElementById(numelementv).value === "") {
          validmin = true;
        }
        else {
          if (document.getElementById(numelementv).value.length < numminvalv) {
            if (!(this.state.questionidpush.includes(numelementv.replace("qtype3", "")))) {
              validmin = false
              break;
            }

          }
          else {
            validmin = true;
          }
        }
      }
    }





    if (validmail) {
      if (validmin) {

        var Request3 = {
          "status": "Draft",
          "storeId": this.props.location.state.storeNo,
          "userAnswerList": this.state.radioObj,
          "userFormatId": this.props.location.state.role_id,
          "equipcategoryID": this.state.equipmentrequest,
          "guId": this.props.location.state.guId,
          "latLong": this.state.latitude,
          "taskId": this.props.location.state.tskId,
          "userId": this.props.location.state.userid,
          "equipmentNo": this.state.radioObj[0].equipmentNo
        }


        var EncryptedRequest3 = Encrypt(Request3);
        console.log(`draft request`, EncryptedRequest3)


        fetch('/InsertTechnicianAnswer', {
          method: 'POST',
          body: EncryptedRequest3,
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),


          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)
            if (DecryptedResponse.responseCode === '200 OK') {
              console.log(`response of draftanswer `, DecryptedResponse)
              showSuccess("Answers has been saved successfully in Draft.")
              if (this.state.categoryId == "6" || this.state.categoryId == "7" || this.state.categoryId == "8") //role_id =="504"
              {
                this.setState({ data: '' })
                this.setState({ getBarcodeData: '' })
                this.setState({
                  arrrefs: [],
                  arreqnum: [],
                  arrqtypenum: [],
                  arrchk: [],
                  quesidmail: [],
                  quesidcs9: [],
                  equipidcs9: [],
                  arrrefscs9: [],
                  qforsub: []
                })


              }
              else {
                this.props.history.push({
                  pathname: '/Taskactive',
                  state: {
                    userid: this.props.location.state.userid,
                    usrnm: this.props.location.state.usrnm,
                    role_id: this.props.location.state.role_id,
                    data: this.props.location.state.data,
                    storeNo: this.props.location.state.storeNo,
                    guId: this.props.location.state.guId

                  }
                }
                );
              }

            }

            else {

              confirmAlert({
                title: 'Alert !',
                message: DecryptedResponse.responseMsg,
                buttons: [
                  {
                    label: 'OK',
                  },

                ]
              })

            }
          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });
          });
      }
      else {
        confirmAlert({
          title: 'Alert !',
          message: "Please check the minimum value of the numeric answer",
          buttons: [
            {
              label: 'OK',
            },

          ]
        })
      }
    }
    else {
      confirmAlert({
        title: 'Alert !',
        message: "Please enter a valid email id",
        buttons: [
          {
            label: 'OK',
            //onClick: () => alert('Click Yes')
          },

        ]
      })
    }
  }
  Astrik = (props) => {
    const isMandatory = props.isMandatory;
    if (isMandatory == 1) {
      return <font color="red" > *</font>;
    }
    return null;
  }


  EquipmentList = () => {
    if (this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }

  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,

      }
    }
    );
  }
}
export default QuestionPreviewLevel1;
