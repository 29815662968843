/* eslint-disable jsx-a11y/anchor-is-valid */
import addDays from "date-fns/addDays";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IdleTimer from 'react-idle-timer'; // For Idle
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import readXlsxFile from 'read-excel-file';
import './css/react-confirm-alert.css'; // Import css
import './css/style-responsive.css';
import './css/style.css';
import { decryptData, Encrypt } from './Encryption-Decrypytion';
import FormDataService from './services';
import { basicAuth } from './basicAuth';
import { showSuccess, showAlert } from "./services/ConfirmAlert";


class Createtasks extends Component {
  state = {
    TaskDropdwn: false,
    roleList: [],
    rlistopt: [],
    rlistid: [],
    value: "",
    usrnm: "",
    valueid: [],
    roleId: "",
    userid: "",
    previewData: "",
    hasError: false,   //Added by chandrani for custom error page
    hammenu: true,
    taskAssign: '',
    role1: [],
    catID: [],
    catTypeID: [],
    tcatopt: [],
    tcatid: [],
    taskTypeOpt: [],
    taskTypeId: [],
    Idfor: "",
    IdforCatType: "",
    roleidpush: []


  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  constructor(props) {
    super(props);

    this.state = {
      displayMenu: false,
      hasError: false   //Added by chandrani for custom error page

    };
    this.state = {
      selectedFile: null,
        previewImage: null,
      startDate: new Date(),
      endDate: new Date(),
      StoreList: [],
      rlistopt: [],
      rlistid: [],
      FileName: "",
      value: "",
      valueid: [],
      instructionUpload: "",
      previewState: false,
      instructiondata: [],
      hasError: false,   //Added by chandrani for custom error page
      hammenu: true,
      taskAssign: '',
      catID: [],
      catTypeID: [],
      tcatopt: [],
      tcatid: [],
      taskTypeOpt: [],
      taskTypeId: [],
      Idfor: "",
      IdforCatType: "",
      roleidpush: [],
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)


  }

  change = (e) => {
    this.setState({ value: e.target.value });

    let flag = []

    this.setState({ valueid: flag })

  }

  taskcategoryId = (e) => {
    let flagsym = []
    for (let y = 0; y < this.state.catID.length; y++) {
      this.state.catID[y].categoryName.trim() == e.target.value.trim() ? flagsym.push(this.state.catID[y].categoryId) : this.setState({ Idfor: '' })

    }
    this.setState({ Idfor: flagsym })

    console.log(`tst6`, this.state.Idfor)
  }


  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date
    });
  }

  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }







  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)


        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });



      });


  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }


  _onAction(e) {
    //   console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }




  handleSetRole([]) {
    this.setState({
      roleList: []
    });
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  enter = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  }


  fileHandler = (event) => {

    const input = document.getElementById('upload-storedata')
    var stores = []
    readXlsxFile(input.files[0]).then((rows, files) => {

      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        stores.push(element[0])
      }
      this.setState({
        StoreList: stores,
        FileName: 'File Uploaded Successfully'
      });
    })


  }

  Taskactive() {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.roleId,
        guId: this.props.location.state.guId,

      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  templates = () => {
    this.props.history.push({
      pathname: '/Templates',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );

  }

  InstructionHandler = async (e) => {
  
    try {
      let files = e.target.files;
      if (files[0].type == "image/gif"
        || files[0].type == "image/jpg"
        || files[0].type == "image/jpeg"
        || files[0].type == "image/png"
        || files[0].type == "application/msword"
        || files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        || files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        || files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        || files[0].type == "application/vnd.ms-excel"
        || files[0].type == "application/pdf"
        || files[0].type == "application/vnd.ms-powerpoint"
        || files[0].type == "text/plain"
      ) {
        var Request1 = {
          "startDate": document.getElementById("startDate").value,
          "endDate": document.getElementById("endDate").value,
          "createdBy": this.state.userid,
          "taskDesc": document.getElementById("taskDesc").value,
          "taskName": document.getElementById("taskName").value,
          "storeId": this.state.StoreList,
          "action": "Create",
          "roleId": [this.props.location.state.role_id],
          // "taskFile": this.state.previewData

        }

        var EncryptedRequest1 = Encrypt(Request1);
        console.log("ïnst", EncryptedRequest1)
        await FormDataService.uploadFormData(files[0], EncryptedRequest1);

        // this.setState({ previewData: e.target.result });
        this.setState({ instructionUpload: 'File Uploaded Successfully' });

        this.setState({ instructiondata: files });
      }
      else {
        this.setState({ instructionUpload: '' });
        confirmAlert({
          title: 'Alert !',
          message: "Unsupported File Format.",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.setState({ instructionUpload: "" }); }
            },

          ]
        })
      }
    } catch (error) {
      console.error('Need to handle error', error);
    }


  }


  





  // InstructionHandler = (e) => {

  //   let files = e.target.files;
  //   if( files[0].type=="image/gif"
  //     || files[0].type=="image/jpg"
  //     || files[0].type=="image/jpeg"
  //     || files[0].type=="image/png"
  //     || files[0].type=="application/msword"
  //     || files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //     || files[0].type=="application/vnd.openxmlformats-officedocument.presentationml.presentation"
  //     || files[0].type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //     || files[0].type=="application/vnd.ms-excel"
  //     || files[0].type=="application/pdf"
  //     || files[0].type=="application/vnd.ms-powerpoint"
  //     || files[0].type=="text/plain"
  //     ){

  //       console.log(`out`,files[0].type)
  //         if(files[0].type=="image/jpeg"||files[0].type=="image/png")

  //         {

  //           Resizer.imageFileResizer(
  //             files[0],
  //             500,
  //             500,
  //             'JPEG',
  //             100,
  //             0,
  //             uri => {
  //               //  console.log(`sdk`,uri)
  //                 this.setState({ previewData: uri });
  //                 this.setState({ instructionUpload: 'File Uploaded Successfully' });
  //                 this.setState({ instructiondata: files });
  //             },
  //             'base64'
  //         );


  //         }

  //   else{
  //     if (files[0].size < 1572864) {
  //         console.warn("Data File", files[0].size < 1572864)

  //         let reader = new FileReader();

  //         reader.readAsDataURL(files[0]);
  //         reader.onload = (e) => {
  //           console.warn("Data", e.target.result)
  //           this.setState({ previewData: e.target.result });
  //           this.setState({ instructionUpload: 'File Uploaded Successfully' });
  //           this.setState({ instructiondata: files });
  //           console.warn("ByteArray", this.state.previewData)
  //         }
  //       }
  //       else{
  //         confirmAlert({
  //           title: 'Alert !',
  //           message: "File size cannot be greater than 1.5 MB",
  //           buttons: [
  //             {
  //               label: 'Ok',
  //               onClick: () => { this.setState({ instructionUpload: "" }); }
  //             },

  //           ]
  //         })
  //       }
  //     }}
  //     else{
  //       this.setState({ instructionUpload: '' });
  //       confirmAlert({
  //         title: 'Alert !',
  //         message: "Unsupported File Format.",
  //         buttons: [
  //           {
  //             label: 'Ok',
  //             onClick: () => { this.setState({ instructionUpload: "" }); }
  //           },

  //         ]
  //       })
  //     }
  // }
 

  // InstructionHandler = (e) => {

  //      let files = e.target.files;
       
  //      if( files[0].type=="image/gif"
  //        || files[0].type=="image/jpg"
  //        || files[0].type=="image/jpeg"
  //        || files[0].type=="image/png"
  //        || files[0].type=="application/msword"
  //        || files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //        || files[0].type=="application/vnd.openxmlformats-officedocument.presentationml.presentation"
  //        || files[0].type=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //        || files[0].type=="application/vnd.ms-excel"
  //        || files[0].type=="application/pdf"
  //        || files[0].type=="application/vnd.ms-powerpoint"
  //        || files[0].type=="text/plain"
  //        ){
  
  //          console.log(`out`,files[0].type)
  //            if(files[0].size < 1572864) {
  //             console.warn("Data File", files[0].size < 1572864)
              
  //             let reader = new FileReader();
            
              
  //             reader.readAsDataURL(files[0]);
  //             reader.onload = (e) => {
  //               console.warn("Data", e.target.result)
              
  //               this.setState({ previewData: e.target.result });
  //               this.setState({ instructionUpload: 'File Uploaded Successfully' });
  //              this.setState({ instructiondata: files });
  //               console.warn("ByteArray", this.state.previewData)

  //               this.setState({previewImage : reader.result})
  //               this.setState({selectedImage : files})

  //             }
  //           }
  //           else{
  //             confirmAlert({
  //               title: 'Alert !',
  //               message: "File size cannot be greater than 1.5 MB",
  //               buttons: [
  //                 {
  //                   label: 'Ok',
  //                   onClick: () => { this.setState({ instructionUpload: "" }); }
  //                 },
   
  //               ]
  //             })
  //           }
  //         }}
          
          
    
      



  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  componentWillMount() {
    this.getroles();
  }

  showPrewievHandler = () => {
    this.setState({ previewState: !(this.state.previewState) });
  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }
  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }



  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId

      }
    }
    );
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }
  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  render = () => {
    //Added by chandrani for custom error page
   const {previewImage} = this.state;
    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (
        <Router>
          <div>
            <section id="container">
              <div>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10} />
              </div>
              <header class="header black-bg">

                <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
                <a onClick={this.Taskactive.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
                <ul className="nav pull-right pos-rel">
                  <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" /><b>{this.state.usrnm}</b><b className="caret" /></a>
                  </li>

                  {this.state.displayMenu ?
                    (

                      <ul className="dropdown-menuuser-dd">
                        <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                        <li className="divider"></li>
                        <li><a onClick={this.logout} >Log Out</a></li>
                      </ul>
                    ) :
                    (
                      null
                    )
                  }


                </ul>
              </header>
              <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
              <section id="main-content">
                <section class="wrapper">
                  <div class="mt">
                    <h3><i class="fa fa-angle-right"></i>Create Task</h3>
                    <hr />
                    <div class="row mt">
                      <form method="post">



                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Task Name</label>
                          <br />
                          <input type="text" class="form-control" placeholder="Enter Task Name" id="taskName" maxlength="150" autoComplete="off" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Task Description</label>
                          <br />
                          <textarea type="text" class="form-control" placeholder="Enter Task Description" id="taskDesc" maxlength="250"  ></textarea>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Assign to</label>
                          <br />
                          <select class="form-control" onChange={this.change.bind(this)} id='assignTo'>
                            {this.state.rlistopt.map((element) => (<option>{element}</option>))}
                          </select>
                        </div>
                        <div class="clearfix"></div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Start Date</label>
                          <br />
                          <DatePicker className="form-control form-control-inline input-medium default-date-picker"
                            size="16" type="text" placeholder="Select Start Date " dateFormat="dd-MMM-yyyy HH:mm" id="startDate"
                            selected={this.state.startDate} showTimeSelect
                            timeIntervals={30}
                            minDate={new Date()}
                            onChange={this.handleChangeStart} onChangeRaw={this.handleDateChangeRaw}
                          />
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>End Date</label>
                          <br />
                          {<DatePicker className="form-control form-control-inline input-medium default-date-picker"
                            size="16" type="text" placeholderText="Select End Date" dateFormat="dd-MMM-yyyy HH:mm" id="endDate"
                            selected={this.state.endDate} showTimeSelect
                            timeIntervals={30}
                            onChange={this.handleChangeEnd} minDate={this.state.startDate}
                            maxDate={addDays(new Date(), 365)} onChangeRaw={this.handleDateChangeRaw}

                          />
                          }

                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Category ID</label>
                          <br />
                          <select class="form-control"
                            onChange={this.taskcategoryId.bind(this)}
                            id='taskCat'>
                            <option >Select Task Category</option>
                            {this.state.tcatopt.map((element) => (<option>{element}</option>))}
                          </select>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <label>Upload Data</label>
                            <br />
                            <div class="upload-holder">
                              <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                              <span class="btn-upload">Upload</span>
                              <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />
                            </div>
                            <span class="help-block">Sample File <a href={require("./doc/sample.xlsx")}>Sample.xlsx</a></span> </div>
                          <div>
                            <label hidden >Selected Stores</label>
                            <br />
                            <input type="hidden" className="form-control" value={this.state.StoreList} />
                          </div>
                        </div>

                         
                        {/* <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Instructions (Optional)
                        
                          </label>
                          <br />
                          <div class="upload-holder">
                            <input id="instructions" type="text" class="form-control input-upload" placeholder="Upload Instructions" value={this.state.instructionUpload} />
                            <span class="btn-upload"> Upload</span> 
                            {previewImage && (
                <img src ={previewImage} alt="Preview" style={{width:'200px'}}/>
            )}
                            <input id="upload-instructions" type="file" name="Instruction-data" class="input-hidden" onChange={(e) => this.InstructionHandler(e)}  value='' accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.txt,.pdf" />
                           
                          </div>
                          <button class="btn btn-primary min-wid-90 " onClick={this.InstructionHandler} >Submit</button>
                        </div>   */}


 
                       


















                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <div class="text-center mt ">
                            <button class="btn btn-primary min-wid-90 " onClick={this.CreateTask.bind(this)} onKeyPress={this.enter} >Save</button>
                            <button class="btn btn-primary min-wid-90 ml-10" onClick={this.TaskactiveBind.bind(this)}>Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </Router>
      );
    }
  }
  getroles(e) {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      if (this.props.location.state) {
        this.setState({ roleId: this.props.location.state.role_id });
        this.setState({ role_id: this.props.location.state.role_id });
        this.setState({ userid: this.props.location.state.userid });
        this.setState({ usrnm: this.props.location.state.usrnm });



        fetch('/GetTaskCategory', {
          method: 'GET',
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)




            if (DecryptedResponse.responseCode === '200 OK') {
              this.setState({ catID: DecryptedResponse.taskCategoryList });
              let tcat = [];
              let tcat1 = [];
              for (var p = 0; p < DecryptedResponse.taskCategoryList.length; p++) {
                tcat.push(DecryptedResponse.taskCategoryList[p].categoryName)
                tcat1.push(DecryptedResponse.taskCategoryList[p].categoryId)
              }
              this.setState({ tcatopt: tcat });
              this.setState({ tcatid: tcat1 });

            }
            else {
              showSuccess(DecryptedResponse.errorMsg)

            }


          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });

          });

        var Request1 = {
          "guid": this.props.location.state.guId,
          "userId": this.props.location.state.userid
        }
        var EncryptedRequest1 = Encrypt(Request1);

        fetch('/GetTaskTypeCategory', {
          method: 'POST',
          body: EncryptedRequest1,
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)




            if (DecryptedResponse.responseCode === '200 OK') {
              this.setState({ catTypeID: DecryptedResponse.TaskCategoryDetails });
              let tcat = [];
              let tcat1 = [];
              for (var p = 0; p < DecryptedResponse.TaskCategoryDetails.length; p++) {
                tcat.push(DecryptedResponse.TaskCategoryDetails[p].categoryName)
                tcat1.push(DecryptedResponse.TaskCategoryDetails[p].taskCatagoryId)
              }
              this.setState({ taskTypeOpt: tcat });
              this.setState({ taskTypeId: tcat1 });

            }
            else {
              showSuccess(DecryptedResponse.errorMsg)

            }


          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });

          });
        fetch('/GetRoles', {
          method: 'GET',
          headers: {
            guId: this.props.location.state.guId,
            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

          }

        })
          .then((response) => response.text())
          .then((response) => {
            var DecryptedResponse = decryptData(response)



            if (DecryptedResponse.responseCode === '200 OK') {
              this.setState({ roleList: DecryptedResponse.roleList });
              let temp = [];
              let temp1 = [];
              for (var i = 0; i < DecryptedResponse.roleList.length; i++) {
                temp.push(DecryptedResponse.roleList[i].roleDescription)
                temp1.push(DecryptedResponse.roleList[i].roleId)
              }
              this.setState({ rlistopt: temp });
              this.setState({ rlistid: temp1 });

            }
            else {
              showSuccess(DecryptedResponse.errorMsg)

            }


          })
          .catch((error) => {
            confirmAlert({
              title: 'Alert !',
              message: "Session expired",
              buttons: [
                {
                  label: 'Ok',
                  onClick: () => { this.logoutnormal() }
                },

              ]


            });
          });
      } else {
        this.props.history.push({
          pathname: '/',
        });

      }
    }
    else {
      this.props.history.push({
        pathname: '/',
      });

    }
  }

  gotohome = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        role_id: this.props.location.state.role_id,
        usrnm: this.props.location.state.usrnm,
        userName: this.props.location.state.userName,
        userid: this.state.userid,
        guId: this.props.location.state.guId
      }
    });
  }

  CreateTask(e) {
    e.preventDefault();

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];


    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    var systemtime = date + ' ' + new Date().getHours() + ':' + new Date().getMinutes();
    var windowstarttime = document.getElementById("startDate").value;
    var windowendtime = document.getElementById("endDate").value;

    this.state.roleidpush.push(this.props.location.state.role_id)
    this.state.valueid = [...new Set(this.state.roleidpush)]


    if (!document.getElementById("taskName").value || !document.getElementById("taskDesc").value) {
      showAlert("Please enter required fields")

    }

    else {
      var vstartdate = new Date(document.getElementById("startDate").value);
      var venddate = new Date(document.getElementById("endDate").value);
      if (venddate < vstartdate) {
        showSuccess("End date/time cannot be earlier than start date/time.")

      } else if (new Date(windowendtime) <= new Date(systemtime)) {
        showSuccess("End or Start date/time cannot be same or earlier than system date/time.")


      }
      else if (new Date(windowstarttime) < new Date(systemtime)) {
        showSuccess("Start date/time cannot be earlier than system date/time.")

      } else
        if (document.getElementById('assignTo').value == '--Select--') {
          showAlert("Please select Assign to")


        }

        else
          if (document.getElementById("taskCat").value == "Select Task Category") {
            showAlert("Please select task category")

          } 
            else {
              var Request1 = {
                "startDate": document.getElementById("startDate").value,
                "endDate": document.getElementById("endDate").value,
                "createdBy": this.state.userid,
                "taskDesc": document.getElementById("taskDesc").value,
                "taskName": document.getElementById("taskName").value,
                "storeId": this.state.StoreList,
                "action": "Create",
                "roleId": this.state.valueid,
                "taskFile": this.state.previewData,
                "guId": this.props.location.state.guId,
                "taskCategoryId": this.state.Idfor.toString(),
              }

              var EncryptedRequest1 = Encrypt(Request1);
              console.log(EncryptedRequest1)

              fetch('/CreateTask', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                }
              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  console.log(`response of CreateTask: `, DecryptedResponse)
                  if (DecryptedResponse.responseCode === '200 OK') {
                    if (DecryptedResponse.unmatchedStore.length > 0) {
                      confirmAlert({
                        title: 'Success',
                        message: "Task created successfully.  Store" + " " + DecryptedResponse.unmatchedStore + " " + "does not exist.",
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                              this.props.history.push({
                                pathname: '/Taskactive',
                                state: {
                                  userid: this.state.userid,
                                  usrnm: this.state.usrnm,
                                  role_id: this.state.roleId,
                                  guId: this.props.location.state.guId

                                }
                              }
                              );
                            }
                          },

                        ]
                      })
                    }
                    else {
                      confirmAlert({
                        title: 'Success',
                        message: "Task created successfully.  ",
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                              this.props.history.push({
                                pathname: '/Taskactive',
                                state: {
                                  userid: this.state.userid,
                                  usrnm: this.state.usrnm,
                                  role_id: this.state.roleId,
                                  guId: this.props.location.state.guId

                                }
                              }
                              );
                            }
                          },

                        ]
                      })
                    }
                  }


                  else {
                    showAlert(DecryptedResponse.responseMsg)

                  }

                })
                .catch((error) => {
                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });
                });
            }
    }
  }
  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.state.roleId,
        guId: this.props.location.state.guId
      }
    }
    );
  }
  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  EquipmentList = () => {
    console.log(`sdbsjd`, this.state.role_id)
    if (this.props.location.state.role_id == "505" || this.props.location.state.role_id == "501" || this.props.location.state.role_id == "300") {
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }
    else {
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo: this.props.location.state.storeNo,
          guId: this.props.location.state.guId
        }
      }
      );
    }

  }


  TaskactiveBind = (e) => {
    e.preventDefault();
    confirmAlert({
      message: 'Are you sure, you want to cancel? ',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.history.push({
              pathname: '/Taskactive',
              state: {
                userid: this.state.userid,
                usrnm: this.state.usrnm,
                role_id: this.state.roleId,
                guId: this.props.location.state.guId

              }
            }
            );
          }
        },
        {
          label: 'No',
          onClick: () => { return false }
        }
      ]
    });

  }
}

export default withRouter(Createtasks);