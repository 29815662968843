import React, { Component } from 'react';
import './css/style.css';
import './css/style-responsive.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import readXlsxFile from 'read-excel-file';
import { Encrypt, decryptData } from './Encryption-Decrypytion'

import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import config from 'react-global-configuration';

import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import IdleTimer from 'react-idle-timer'// For Idle
import { showAlert, showSuccess } from './services/ConfirmAlert';




class CreateHo extends Component {
  state = {
    value: "",
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    formatId: "",
    formerMasterList: [],
    roleMasterList: [],
    rformatlist: [],
    rformatlisted: [],
    rformatId: [],
    rrole: [],
    lstformat: [],
    formatDesc: [],
    formarMasterList: [],
    roleMasterList1: [],
    storeList: [],
    values: '',
    hasError: false, //Added by chandrani for custom error page
    userid: "",
    guId: ""
  }

  constructor(props) {
    super(props);


    this.state = {
      value: "",
      username: "",
      valueid: [],
      role_id: [],
      firstName: "",
      FileName: "",
      middleName: "",
      lastName: "",
      StoreList: "StoreList",
      email: "",
      formatId: null,
      userId: "",
      formerMasterList: [],
      roleMasterList: [],
      rformatlist: [],
      rformatlisted: [],
      rformatId: [],
      rrole: [],
      rlistid: [],
      lstformat: [],
      storeList: [],
      displayMenu: false,
      values: '',
      hasError: false,  
      userid: "",
      hammenu: true,
      guId: "",

    };

    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }




  _onIdle(e) {
    localStorage.clear();
    window.location.href = '/';
  }

  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    //console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }



  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);

    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {

          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });


      });


  }
 


  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  UserchangeId = (e) => {

    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ values: e.target.value })
    }
  }

  formatSelection = (e) => {
    this.setState({ value: e.target.value });

    let flag = []

    for (var i = 0; i < this.state.rformatlist.length; i++) {

      this.state.rformatlist[i] === e.target.value ?
        flag.push(this.state.rformatId[i]) : this.setState({ valueid: "" })
    }
    this.setState({ valueid: flag })

  }





  Storecreation = () => {
    this.props.history.push({
      pathname: '/Storecreation',
      state: {
        userid: this.props.location.state.userid,
        username: this.props.location.state.username,
        role_id: this.state.roleId,
        formerMasterList: this.state.formerMasterList,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  componentWillMount() {
    this.GetFormatMaster()
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }
  render = () => {

    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {
      return (

        <Router>
          <div >
            <div >

              <section id="container">
                <header class="header black-bg">
                  <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

                  <a className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>

                  <ul className="nav pull-right pos-rel">
                    <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" /><b>{this.state.username}</b> <b className="caret" /></a>
                    </li>
                    <IdleTimer
                      ref={ref => { this.idleTimer = ref }}
                      element={document}
                      onActive={this.onActive}
                      onIdle={this.onIdle}
                      onAction={this.onAction}
                      debounce={250}
                      timeout={1000 * 60 * 10} />
                    {this.state.displayMenu ?
                      (

                        <ul className="dropdown-menuuser-dd">
                          <li><a onClick={this.logout} >Log Out</a></li>
                        </ul>
                      ) :
                      (
                        null
                      )
                    }
                  </ul>


                </header>
                <aside>
                  <div id="sidebar" class="nav-collapse ">
                    {this.state.hammenu ?
                      <ul class="sidebar-menu" id="nav-accordion">
                        <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Ho Creation</span> </a>

                        </li>
                        <li> <a onClick={this.Storecreation}> <i class="fa fa-tasks"></i> <span>Store Creation</span> </a> </li>
                      </ul> : null}
                  </div>
                </aside>
                <section id="main-content">
                  <section class="wrapper">
                    <div class="mt">

                      <h3><i class="fa fa-angle-right"></i>HO Creation</h3>
                      <hr />
                      <div class="row mt">

                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <label>User Id</label><span class="required-mark">*</span>
                          <input type="text" class="form-control" placeholder="Enter UserId" input value={this.state.values} id="UserId" onChange={this.UserchangeId} maxlength="8" autoComplete="off"  ></input>
                          <br />

                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                          <label>Email ID</label><span class="required-mark">*</span>
                          <input type="email" class="form-control" placeholder="Enter EmailId" id="Admin_EmailId" maxlength="40" autoComplete="off" ></input>
                          <br />

                        </div>
                        <div class="clearfix"></div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>First Name</label><span class="required-mark">*</span>
                          <input type="FirstName" class="form-control" placeholder="Enter First Name" id="FirstName" maxlength="30" autoComplete="off" ></input>
                          <br />

                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <label>Middle Name</label>
                          <input type="MiddleName" class="form-control" placeholder="Enter Middle Name" id="MiddleName" maxlength="30" autoComplete="off" ></input>
                          <br />


                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <label>Last Name</label><span class="required-mark">*</span>
                            <br />
                            <input type="LastName" class="form-control" placeholder="Enter Last Name" id="LastName" maxlength="30" autoComplete="off" ></input>
                          </div>
                          <div>

                            <br />

                          </div>
                        </div>

                        <div class="form-group col-lg-4 col-md-4 col-sm-4 col-xs-12">

                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                          <div class="text-center mt ">
                            <button class="btn btn-primary min-wid-90 " onClick={this.CreateHOUser.bind(this)} >Submit</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
              </section>
            </div>
          </div>
        </Router>
      );
    }
  }




  fileHandler = (event) => {
    const input = document.getElementById('upload-storedata')

    readXlsxFile(input.files[0]).then((rows, files) => {
      var storedata = [];

      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i])
        for (let j = 0; j < storedata.length; j++) {
          var store =
          {
            storeNo: "",
            city: "",
            clusterManager: "",
            rrState: "",
            storeName: "",
            zbm: "",
            zbmState: "",
            zbmEmail: ""
          }
          store.storeNo = storedata[j][0]
          store.city = storedata[j][1]
          store.clusterManager = storedata[j][2]
          store.rrState = storedata[j][3]
          store.storeName = storedata[j][4]
          store.zbm = storedata[j][5]
          store.zbmState = storedata[j][6]
          store.zbmEmail = storedata[j][7]

        }
        this.state.storeList.push(store)

      }

      this.setState({

        FileName: 'File Uploaded Successfully'
      });
    })


  }
  CreateHOUser = (e) => {



    e.preventDefault();

    var pattern = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    var re = /^[a-zA-Z]+$/;

    var Admin_EmailId = document.getElementById("Admin_EmailId").value;
    var FirstName = document.getElementById("FirstName").value;
    var LastName = document.getElementById("LastName").value;

    if (!pattern.test(Admin_EmailId)) {
      showSuccess("Please enter valid email.")
      return false;
    }



    else if (!document.getElementById("FirstName").value || !document.getElementById("LastName").value || !document.getElementById("Admin_EmailId").value || !document.getElementById("UserId").value) 
    {
          showSuccess("Please enter required fields")
     
    }


    else if (!re.test(FirstName) || !re.test(LastName)) 
    {
      showSuccess("Special characters & numbers are not allowed.")
    
    }



    else {
      confirmAlert({
        message: "Are you sure, you want to submit?.",
        buttons: [
          {
            label: 'Yes',
            onClick: () => {


              var Request1 = {
                "firstName": document.getElementById("FirstName").value,
                "middleName": document.getElementById("MiddleName").value,
                "lastName": document.getElementById("LastName").value,
                "emailId": document.getElementById("Admin_EmailId").value,
                "userId": document.getElementById("UserId").value,
                "guId": this.props.location.state.guId,

              }
              var EncryptedRequest1 = Encrypt(Request1);
              fetch('/CreateHoUser ', {
                method: 'POST',
                body: EncryptedRequest1,
                headers: {
                  guId: this.props.location.state.guId,
                  'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                }

              })
                .then((response) => response.text())
                .then((response) => {
                  var DecryptedResponse = decryptData(response)
                  if (DecryptedResponse.responseCode === '200 OK') {
                    confirmAlert({
                      message: DecryptedResponse.responseMsg,
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => window.location.reload(true)
                        },

                      ]
                    })



                    this.setState({ userId: DecryptedResponse.userId });


                  }
                  else {
                    showAlert(DecryptedResponse.responseMsg)

                  }


                })
                .catch((error) => {
                  confirmAlert({
                    title: 'Alert !',
                    message: "Session expired",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.logoutnormal() }
                      },

                    ]


                  });
                });


            }
          },
          {
            label: 'No',
            onClick: () => { return (false); }
          }

        ]
      })




    }
  }
  GetFormatMaster = (e) => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({
        username: this.props.location.state.username,
        userid: this.props.location.state.userid,
        guId: this.props.location.state.guId
      })
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }


  }

  email = (e) => {
    e.preventDefault();

  }


}

export default withRouter(CreateHo);

