import React, { Component } from 'react';
import './css/style.css';
import './css/style-responsive.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import readXlsxFile from 'read-excel-file';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import { withRouter } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import IdleTimer from 'react-idle-timer'// For Idle
import { Scrollbars } from 'react-custom-scrollbars';
import ReactExport from "react-data-export";
import { showAlert, showSuccess } from './services/ConfirmAlert';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Storecreation extends Component {
  state = {
    value: "",
    userId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    formatId: "",
    formerMasterList: [],
    roleMasterList: [],
    rformatlist: [],
    rformatlisted: [],
    rformatId: [],
    rrole: [],
    lstformat: [],
    formatDesc: [],
    formarMasterList: [],
    roleMasterList1: [],
    storeList: [],
    valueid: [],
    userid: "",
    guId: "",
    data: [],
    Searchnum: "",
    searchedDate: "",
  }

  constructor(props) {
    super(props);


    this.state = {
      value: "",
      username: "",
      valueid: [],
      role_id: [],
      firstName: "",
      FileName: "",
      middleName: "",
      lastName: "",
      email: "",
      formatId: null,
      userId: "",
      formerMasterList: [],
      roleMasterList: [],
      rformatlist: [],
      rformatlisted: [],
      rformatId: [],
      rrole: [],
      rlistid: [],
      lstformat: [],
      storeList: [],
      displayMenu: false,
      showAction: false,
      showSubmit: false,
      storeNo: "",
      former: false,
      userid: "",
      hammenu: true,
      guId: "",
      data: [],
      Searchnum: "",
      searchedDate: "",



    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.onChangeUserId = this.onChangeUserId.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);


  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }




  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }



  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {
          this.setState({ loading: false })
          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }



  handleSetList([]) {
    this.setState({
      formarMasterList: []
    });
  }

  componentWillMount() {
    this.GetFormatMaster()
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }


  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  onChangeUserId(e) {

    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ value: e.target.value })
    }

  }

  formatSelection = (e) => {

    if (document.getElementById("SelQType1").value == "Select") {
      showAlert("Please select action")
    }

    else {
      if (document.getElementById("SelQType1").value == "Add/Update") {
        if (document.getElementById("SelQType2").value == "Select") {
          showAlert("Please select format type.")
        }
        else {
          var that = this;
          let excel = 0
          this.state.storeList.map((item, key) => {
            if (item.storeNo == undefined ||
              item.storeName == undefined ||
              item.zone == undefined ||
              item.rrState == undefined ||
              item.city == undefined ||
              item.storeEmail == undefined ||
              item.technicianId == undefined ||
              item.storeManager == undefined ||
              item.afmEcNo == undefined ||
              item.zonalEcNo == undefined) {
              excel = 0

            }
            else {
              excel = excel + 1
            }
          })
          if (excel == 0) {
            showAlert("Please upload the correct excel")

          }

          else {

            var request = {
              "storeList": that.state.storeList,
              "formatId": that.state.valueid[0],
              "userId": that.state.userid,
              "action": "ADD",
              "guId": that.props.location.state.guId,
            }
            var EncryptedRequest = Encrypt(request);
            console.log(`Store Creation`, EncryptedRequest)

            fetch('/CreateStore', {
              method: 'POST',
              body: EncryptedRequest,
              headers: {
                guId: that.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(that.props.location.state.userid),
              }
            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse = decryptData(response)
                if (DecryptedResponse.responseCode === "200 OK") {
                  confirmAlert({
                    title: 'Success',
                    message: "Stores added successfully",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { window.location.reload(true) }
                      },

                    ]
                  })
                }
                else {
                  confirmAlert({
                    title: 'Success',
                    message: "Stores added successfully, " + " below uploaded stores are already present " + DecryptedResponse.existingStore,
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { window.location.reload(true) }
                      },

                    ]
                  })
                }


              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });

              });
          }

        }

      }
      else {
        confirmAlert({
          message: 'Are you sure, you want to delete this store?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var Request2 = {
                  "storeList": this.state.storeList,
                  "userId": this.state.userid,
                  "action": "DELETE",
                  "guId": this.props.location.state.guId,
                }
                var EncryptedRequest2 = Encrypt(Request2);
                console.log('storedeleteresponse', EncryptedRequest2)
                fetch('/CreateStore', {
                  method: 'POST',
                  body: EncryptedRequest2,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })
                  .then((response) => response.text())
                  .then((response) => {

                    var DecryptedResponse2 = decryptData(response)
                    if (DecryptedResponse2.responseCode === "200 OK") {
                      confirmAlert({
                        message: 'Store deleted Successfully.',
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => { window.location.reload(true) }
                          },

                        ]
                      })

                      this.forceUpdate();
                    }
                    else {
                      showAlert(DecryptedResponse2.responseMsg)

                    }
                  })
                  .catch((error) => {
                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });

                  });
              }
            },
            {
              label: 'No',
              onClick: () => {
                return false;
              }
            }
          ]
        })




      }
    }

  }


  addformat = () => {
    if (document.getElementById("SelQType1").value == "Add/Update") {
      this.setState({ former: true })
    }
    else {
      this.setState({ former: false })
    }
  }

  CreateHo = () => {
    this.props.history.push({
      pathname: '/CreateHo',
      state: {
        userid: this.props.location.state.userid,
        username: this.props.location.state.username,
        role_id: this.state.roleId,
        guId: this.props.location.state.guId


      }
    }
    );
  }



  onChangenum = e => {
    this.setState({
      Searchnum: e.target.value
    });
  }

  onChangeTech = e => {
    this.setState({
      searchedDate: e.target.value
    });
  }






  render = () => {
    const { Searchnum } = this.state;


    return (

      <Router>
        <div >
          <div>

            <section id="container">
              <header class="header black-bg">
                <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>
                <a className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>

                <ul className="nav pull-right pos-rel">
                  <li className="dropdown"><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}><img src={require("./img/user.png")} className="user-img" /><b>{this.state.username}</b> <b className="caret" /></a>
                  </li>
                  <div>
                    <IdleTimer
                      ref={ref => { this.idleTimer = ref }}
                      element={document}
                      onActive={this.onActive}
                      onIdle={this.onIdle}
                      onAction={this.onAction}
                      debounce={250}
                      timeout={1000 * 60 * 10} />

                  </div>
                  {this.state.displayMenu ?
                    (

                      <ul className="dropdown-menuuser-dd">
                        <li><a onClick={this.logout} >Log Out</a></li>
                      </ul>
                    ) :
                    (
                      null
                    )
                  }
                </ul>


              </header>
              <aside>
                <div id="sidebar" class="nav-collapse ">
                  {this.state.hammenu ?
                    <ul class="sidebar-menu" id="nav-accordion">
                      <li className="sub-menu mt" onClick={this.CreateHo}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Ho Creation</span> </a>
                      </li>
                      <li> <a> <i class="fa fa-tasks"></i> <span>Store Creation</span> </a> </li>

                    </ul> : null}
                </div>
              </aside>
              <section id="main-content">
                <section class="wrapper">
                  <div class="mt">
                    <h3><i class="fa fa-angle-right"></i>Store Creation</h3>
                    <hr />
                    <div class="row mt">
                      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                        <label>Upload File</label>
                        <div class="upload-holder">
                          <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                          <span class="btn-upload" style={{ padding: "7px 25px" }}>Upload</span>
                          <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />
                        </div>
                        <span class="help-block">Sample File <a href={require("./doc/StoreCreation.xlsx")}>StoreCreation.xls</a></span>






                      </div>
                      {this.state.showAction ?
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <label>Action </label>
                          <select class="form-control" id="SelQType1" onChange={this.addformat}  >
                            <option id="select" >Select</option>
                            <option id="add" >Add/Update</option>
                            <option id="delete">Delete</option>
                          </select>
                        </div>

                        : null}

                      {this.state.former ? (
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <label>Select Format Type</label><span class="required-mark"  >*</span>
                          <br />

                          <select class="form-control" id="SelQType2"
                            onChange={this.roleType.bind(this)}

                          >
                            <option  >Select</option>
                            {this.state.formerMasterList.map(function (item, key) {
                              return (
                                <option  >{item.formatName}</option>
                              )

                            })}
                          </select>
                        </div>
                      ) : (null)}

                      {this.state.showSubmit ?
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                          <button class="btn btn-primary min-wid-90 mt-25" onClick={this.formatSelection.bind(this)
                          } >Submit</button>
                        </div>
                        : null}

                      <div className="clearfix"></div>
                      <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Store  ID</b><br />
                        <input type="text" placeholder="Search  Store ID" className="form-control" id="storeId" onChange={this.onChangenum} autoComplete="off" />
                      </div>


                      <ExcelFile element={<button className="btn btn-theme" style={{ "margin-top": "15px", float: "right", "margin-right": "50px" }} disabled={this.state.loading}> Download List</button>}>
                        <ExcelSheet data={this.state.data} name="Store Details">
                          <ExcelColumn label="Store_No" value="storeNo" />
                          <ExcelColumn label="Store_Name" value="storeName" />
                          <ExcelColumn label="Zone" value="zone" />
                          <ExcelColumn label="State" value="state" />
                          <ExcelColumn label="City" value="city" />
                          <ExcelColumn label="Store_Email" value="storeEmail" />
                          <ExcelColumn label="Technician_Ecno" value="technicianId" />
                          <ExcelColumn label="Store Manager_Ecno" value="storeManager" />
                          <ExcelColumn label="AFM_Ecno" value="afmEcNo" />
                          <ExcelColumn label="Zonal_Ecno" value="zonalEcNo" />
                        </ExcelSheet>
                      </ExcelFile>






                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12 tbwdth">
                        <div className="mt">
                          <div className="tbl-holder">
                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>
                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Store No</th>
                                  <th>Name</th>
                                  <th>State</th>
                                  <th>City</th>
                                  <th>Tech Id</th>
                                  <th>SM Id</th>
                                  <th>AFM Id</th>
                                  <th>Zonal Id</th>
                                </tr>
                              </thead>
                            </table>
                            <Scrollbars style={{ height: 296 }}>

                              <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                <tbody>
                                  {this.state.data.map(function (item, key) {
                                    if ((Searchnum !== "") && (item.storeNo.toLowerCase().indexOf(Searchnum.toLowerCase())) === -1) {
                                      return null
                                    }
                                    return (
                                      <tr key={key}>
                                        <td data-th="StoreNo">{item.storeNo}</td>
                                        <td data-th="Name"> {item.storeName}</td>
                                        <td data-th="State">{item.state}</td>
                                        <td data-th="City">{item.city}</td>
                                        <td data-th="Tech ID">{item.technicianId}</td>
                                        <td data-th="SM ID">{item.storeManager}</td>
                                        <td data-th="AFM ID">{item.afmEcNo}</td>
                                        <td data-th="Zonal ID">{item.zonalEcNo}</td>
                                      </tr>
                                    )
                                  })}</tbody>

                              </table>
                            </Scrollbars>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </section>
              </section>
            </section>
          </div>
        </div>
      </Router>
    );
  }

  roleType = (e) => {
    this.setState({ value: e.target.value });

    let flag = []
    for (var i = 0; i < this.state.rformatlist.length; i++) {

      this.state.rformatlist[i] === e.target.value ?
        flag.push(this.state.rformatId[i]) : this.setState({ valueid: "" })
    }
    if (flag.length > 0) { this.setState({ showSubmit: true }) } else { this.setState({ showSubmit: false }) }
    this.setState({ valueid: flag })
  }



  fileHandler = (event) => {
    this.setState({ storeList: [] });
    const input = document.getElementById('upload-storedata')
    readXlsxFile(input.files[0]).then((rows, files) => {
      var storedata = [];
      for (let i = 1; i < rows.length; i++) {
        storedata.push(rows[i])
        for (let j = 0; j < storedata.length; j++) {
          var store =
          {

            storeNo: "",
            storeName: "",
            zone: "",
            rrState: "",
            city: "",
            storeEmail: "",
            technicianId: "",
            storeManager: "",
            afmEcNo: "",
            clusterEcNo: "",
            regionalEcNo: "",
            zonalEcNo: "",
            energyManageEcNo: "",
            stateLeadEcNo: "",
            pestTechENo: "",
            hkSupervisorEcNo: ""

          }
          store.storeNo = storedata[j][0].toString()
          store.storeName = storedata[j][1]
          store.zone = storedata[j][2]
          store.rrState = storedata[j][3]
          store.city = storedata[j][4]
          store.storeEmail = storedata[j][5]
          store.technicianId = storedata[j][6].toString()
          store.storeManager = storedata[j][7].toString()
          store.afmEcNo = storedata[j][8].toString()
          store.clusterEcNo = storedata[j][9].toString()
          store.regionalEcNo = storedata[j][10].toString()
          store.zonalEcNo = storedata[j][11].toString()
          store.energyManageEcNo = storedata[j][12].toString()
          store.stateLeadEcNo = storedata[j][13].toString()
          store.pestTechENo = storedata[j][14].toString()
          store.hkSupervisorEcNo = storedata[j][15].toString()
        }
        {
          this.state.storeList.push(store)
        }
      }

      this.setState({

        FileName: 'File Uploaded Successfully',
        showAction: true
      });
    })

  }





  GetFormatMaster = (e) => {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.setState({ username: this.props.location.state.username })
      this.setState({ formerMasterList: this.props.location.state.formerMasterList })
      this.setState({ userid: this.props.location.state.userid })
      this.setState({ guId: this.props.location.state.guId })
      this.setState({ loading: true })
      fetch('/GetFormatMaster', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode == '200 OK') {
            this.setState({ formerMasterList: DecryptedResponse.formatMasterList });
            this.setState({ loading: false })

            let list = [];
            let list1 = [];
            let list2 = [];
            let list3 = [];
            for (var i = 0; i < DecryptedResponse.formatMasterList.length; i++) {
              list.push(DecryptedResponse.formatMasterList[i].formatName);
              list1.push(DecryptedResponse.formatMasterList[i].formatId);

            }
            this.setState({ rformatlist: list });
            this.setState({ rformatId: list1 });
          }
          else {
            this.setState({ loading: false })
            showAlert(DecryptedResponse.errorMsg)
          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
      var Request1 = {
        "userId": this.props.location.state.userid,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);

      fetch('/GetStoreList', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode == '200 OK') {
            this.setState({ loading: false })
            this.setState({ data: DecryptedResponse.storeList })
          }
          else {
            this.setState({ loading: false })
            showAlert(DecryptedResponse.errorMsg)
          }


        })
        .catch((error) => {
          this.setState({ loading: false })
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });





    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }

  }



}

export default withRouter(Storecreation);

