import React, { Component } from 'react';

class AddQuestionChild extends React.Component {

    constructor(props) {
        super(props);
        this.state = { category: "" };

    }

    state = {
        category: ""
    }

    getCategory() {
        return this.state.category;
    }

}

export default AddQuestionChild;