/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import 'jquery/dist/jquery';
import { withRouter } from "react-router-dom";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './css/react-confirm-alert.css' // Import css
import Spinner from 'react-spinner-material';//Import Spinner
import "react-datepicker/dist/react-datepicker.css";
import { Scrollbars } from 'react-custom-scrollbars';
import readXlsxFile from 'read-excel-file';
import Login from './Login';
import ReactDOM from 'react-dom';
import Resizer from 'react-image-file-resizer';
import config from 'react-global-configuration';
import IdleTimer from 'react-idle-timer'// For Idle
import ProgressBar from 'react-bootstrap/ProgressBar'
import App from './App' // For Idel
import './css/style.css';
import './css/style-responsive.css';
import { Encrypt, decryptData } from './Encryption-Decrypytion'
import ReactExport from "react-data-export";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { basicAuth } from './basicAuth';
import { showSuccess, showAlert } from './services/ConfirmAlert';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const display = {
  display: 'block'
};
const hide = {
  display: 'none'
};

class Templates extends Component {
  state = {
    TaskDropdwn: false,
    hasError: false   //Added by chandrani for custom error page
  }
  showTaskDropdwnHandler = () => {
    this.setState({
      TaskDropdwn: !(this.state.TaskDropdwn)
    })
  }
  componentWillMount() {
    this.GetTasksOnload()
  }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      displayMenu: false,
      hasError: false,  //Added by chandrani for custom error page
      toggle: false,
      disappear: false,
      appear: false
    };
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      resptaskname: [],
      respstartdate: [],
      respenddate: [],
      resptaskdesc: [],
      data: [],
      usrnm: "",
      userid: "",
      Search: "",
      searchSDate: "",
      searchedDate: "",
      searcheddesc: "",
      tskId: "",
      tskName: "",
      loading: false,
      hasError: false,  //Added by chandrani for custom error page
      toggle: false,
      disappear: false,
      appear: false,
      rlistid: [],
      rlistopt: [],
      FileName: "",
      previewData: "",
      instructionUpload: "",
      instructiondata: [],
      StoreList: [],
      valueid: [],
      templetId: '',
      hammenu: true,
      scheduleTemplate: false,
      isMonthly: false,
      isDaily: false,
      scheduleDate: new Date(),
      scheduleend: new Date(),
      responsetempId: "",
      responsetemptaskName: "",
      responsetaskDesc: "",
      responserecurrence: "",
      responsestart: new Date(),
      responsend: new Date(),
      responsetaskStores: [],
      showrecurrance: false,
      guid: "",
      catID: [],
      tcatopt: [],
      tcatid: [],
      Idfor: "",
      taskCategoryId: "",
      taskcategoryname: "",
      taskCategoryIdname: "",
      settingFlag: [],
      roleidpush: [],
      catTypeID: [],
      taskTypeOpt: [],
      taskTypeId: [],
    };
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handlescheduledate = this.handlescheduledate.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleScheduleEnd = this.handleScheduleEnd.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleChangeStartdatefor = this.handleChangeStartdatefor.bind(this);
    this.handleChangeEnddatefor = this.handleChangeEnddatefor.bind(this);
  }


  handleChangeStart(date) {
    this.setState({
      startDate: date
    });
  }
  handleChangeEnd(date) {
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    this.setState({
      searchedDate: (date.getDate() + '-' + monthNames[date.getMonth()] + "-" + date.getFullYear()),
      endDate: date
    });
  }
  logoutnormal = () => {

    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }




  taskcategoryId = (e) => {
    let flagsym = []

    for (let y = 0; y < this.state.catID.length; y++) {
      this.state.catID[y].categoryName.trim() == e.target.value.trim() ? flagsym.push(this.state.catID[y].categoryId) : this.setState({ Idfor: '' })
    }
    this.setState({ Idfor: flagsym })
  }


  taskcreatecategoryId = (e) => {
    let flagId = []
    for (let y = 0; y < this.state.catID.length; y++) {
      this.state.catID[y].categoryName.trim() == e.target.value.trim() ? flagId.push(this.state.catID[y].categoryId) : this.setState({ Idfor: '' })
    }
    this.setState({ settingFlag: flagId })

  }

  logout = () => {
    var Request1 = {
      "guId": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);
    fetch('/LogOut', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse1 = decryptData(response)
        if (DecryptedResponse1.responseCode === '200 OK') {
          localStorage.clear();
          this.props.history.replace('/');
          disableBrowserBackButton();

        }
        else {
          this.setState({ loading: false })
          showSuccess(DecryptedResponse1.responseMsg)
        }
      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });

      });


  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  onChange = e => {
    this.setState({
      Search: e.target.value
    });
    //this.setState({searcheddesc:e.target.value});
  }


  onChange1 = e => {
    this.setState({ searcheddesc: e.target.value });
  }


  _onAction(e) {
    //console.log('user did something', e)
  }

  _onActive(e) {
    // console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    localStorage.clear();
    this.props.history.replace('/');
    disableBrowserBackButton();
  }

  //Added by chandrani for custom error page
  componentDidCatch() {
    this.setState({ hasError: true })
  }

  componentDidMount() {
    if (window.innerWidth <= 768) { this.setState({ hammenu: false }) }
  }

  modalclose = () => {
    this.setState({
      toggle: false,
    })

    window.location.reload(true)
  }


  toggle(taskId, taskName, taskDesc, recurrence) {
    var that = this
    this.setState({ responsetaskStores: [] })


    if (recurrence != null) {
      this.state.showrecurrance = true
      var Request1 = {
        "taskId": taskId,
        "guId": this.props.location.state.guId,
      }
      var EncryptedRequest1 = Encrypt(Request1);
      fetch('/GetTemplateDetails', {
        method: 'POST',
        body: EncryptedRequest1,
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          if (DecryptedResponse1.responseCode === '200 OK') {

            this.setState({
              taskId: taskId,
              taskName: taskName,
              taskDesc: taskDesc,
              responserecurrence: recurrence,
              responsestart: new Date(DecryptedResponse1.templteList[0].startDate),
              responsend: new Date(DecryptedResponse1.templteList[0].endDate),
              toggle: !this.state.toggle
            })
            this.state.StoreList = []
            for (var i = 0; i < DecryptedResponse1.templteList[0].storeId.length; i++) {
              var storedata = { storeId: "" }
              storedata.storeId = DecryptedResponse1.templteList[0].storeId[i]
              this.state.StoreList.push(DecryptedResponse1.templteList[0].storeId[i]
              )
              that.state.responsetaskStores.push(storedata)

            }
          }
          else {
            this.setState({ loading: false })
            showSuccess( DecryptedResponse1.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
    else {
      this.setState({ toggle: !this.state.toggle })
      this.state.showrecurrance = false
      this.setState({
        taskId: taskId,
        taskName: taskName,
        taskDesc: taskDesc
      })
    }
  }






  fileHandler = (event) => {
    const input = document.getElementById('upload-storedata')
    var stores = []
    readXlsxFile(input.files[0]).then((rows, files) => {

      for (let index = 0; index < rows.length; index++) {
        const element = rows[index];
        stores.push(element[0])
      }
      this.setState({
        StoreList: stores,
        FileName: 'File Uploaded Successfully'
      });
    })


  }


  createtclose = () => {
    this.setState({
      disappear: false,

    })
  }

  createmodal(taskName, taskDesc) {
    this.setState((prevState) => ({
      disappear: !prevState.disappear
    }));
    this.setState({
      taskName: taskName,
      taskDesc: taskDesc
    })

  }


  duplicate(taskName, taskDesc, taskId, taskCategoryId) {
    this.setState((prevState) => ({
      appear: !prevState.appear
    }))


    let flagsymed = []
    let flafiesd = []

    for (let y = 0; y < this.state.catID.length; y++) {
      this.state.catID[y].categoryId.trim() == taskCategoryId.trim() ? flagsymed.push(this.state.catID[y].categoryName) : this.setState({ taskcategoryname: '' })
      this.state.catID[y].categoryId.trim() == taskCategoryId.trim() ? flafiesd.push(this.state.catID[y].categoryId) : this.setState({ taskCategoryIdname: '' })
    }
    this.setState({ taskcategoryname: flagsymed })
    this.setState({ taskCategoryIdname: flafiesd })
    this.setState({
      taskName: taskName,
      taskDesc: taskDesc,
      templetId: taskId,
      taskCategoryId: taskCategoryId
    })



  }



  boxclose = () => {
    this.setState({
      appear: false,
    })
  }

  changerole = (e) => {
    this.setState({ value: e.target.value });
    let flag = []
    for (var i = 0; i < this.state.rlistopt.length; i++) {
      this.state.rlistopt[i] === e.target.value ? flag.push(this.state.rlistid[i]) : this.setState({ valueid: "" })
    }
    this.setState({ valueid: flag })

  }





  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  tiker = () => {

    this.props.history.push({
      pathname: '/Tiker',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );

  }
  InstructionHandler = (e) => {


    let files = e.target.files;

    if (files[0].type == "image/gif"
      || files[0].type == "image/jpg"
      || files[0].type == "image/jpeg"
      || files[0].type == "image/png"
      || files[0].type == "application/msword"
      || files[0].type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      || files[0].type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      || files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      || files[0].type == "application/vnd.ms-excel"
      || files[0].type == "application/pdf"
      || files[0].type == "application/vnd.ms-powerpoint"
      || files[0].type == "text/plain"
    ) {
      if (files[0].type == "image/jpeg" || files[0].type == "image/png") {

        Resizer.imageFileResizer(
          files[0],
          500,
          500,
          'JPEG',
          100,
          0,
          uri => {
            this.setState({ previewData: uri });
            this.setState({ instructionUpload: 'File Uploaded Successfully' });
            this.setState({ instructiondata: files });
          },
          'base64'
        );


      }

      else {
        if (files[0].size < 1572864) {
          let reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = (e) => {
            this.setState({ previewData: e.target.result });
            this.setState({ instructionUpload: 'File Uploaded Successfully' });
            this.setState({ instructiondata: files });
          }
        }
        else {
          confirmAlert({
            title: 'Alert !',
            message: "File size cannot be greater than 1.5 MB",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.setState({ instructionUpload: "" }); }
              },

            ]
          })
        }
      }
    }
    else {
      this.setState({ instructionUpload: '' });
      confirmAlert({
        title: 'Alert !',
        message: "Unsupported File Format.",
        buttons: [
          {
            label: 'Ok',
            onClick: () => { this.setState({ instructionUpload: "" }); }
          },

        ]
      })
    }
  }
  checkInterval = () => {
    if (document.getElementById('recurrence').value == 'Monthly') {
      this.setState({
        isMonthly: true,
        isDaily: true
      })
    }
    else
      if (document.getElementById('recurrence').value == 'Daily') {
        this.setState({
          isDaily: true,
          isMonthly: false
        })
      }
      else {
        this.setState({ isDaily: false, isMonthly: false })
      }

  }

  handlescheduledate = (date) => {
    this.setState({
      scheduleDate: date
    });
  }

  handleScheduleEnd = (date) => {
    this.setState({
      scheduleend: date
    });
  }


  handleschedulechange = (e) => {
    e.preventDefault();
  }

  handleDateraw = (e) => {
    e.preventDefault();
  }

  showTaskdwnHandler = () => {
    this.setState({
      Taskdwn: !(this.state.Taskdwn)
    })
  }


  usercreation = () => {
    this.props.history.push({
      pathname: '/CategoryCreation',
      state: {
        userid: this.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  handleChangeStartdatefor(date) {
    this.setState({
      responsestart: date
    });
  }

  handleChangeEnddatefor(date) {
    this.setState({
      responsend: date
    });
  }

  userlist = () => {
    this.props.history.push({
      pathname: '/UserList',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  render = () => {
    var that = this
    var renderMonths = [];
    var NoDays = [];
    var NoDays = [];
    for (let i = 1; i <= 30; i++) {
      NoDays.push(<option>{i}</option>)
    }



    var modal = [];
    modal.push(
      <div className="modal" role="dialog" style={this.state.toggle ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.modalclose}>X</a>
              <h4 className="modal-title">Update Template</h4>
            </div>

            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">

                  <h4 className="control-label col-sm-4">Template ID</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" value={this.state.taskId} onChange={(f) => this.setState({ taskId: f.target.value })} id="taskupID" disabled></input><br />
                  </div>

                  <h4 className="control-label col-sm-4">Template Name</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" value={this.state.taskName} onChange={(f) => this.setState({ taskName: f.target.value })} id="taskupName" autoComplete="off" maxLength="250"></input><br />
                  </div>


                  <h4 className="control-label col-sm-4"> Description</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" value={this.state.taskDesc} onChange={(f) => this.setState({ taskDesc: f.target.value })} id="taskupDesc" autoComplete="off" maxLength="250"></input>
                  </div>

                  <div className="clearfix"></div>
                  {this.state.showrecurrance ?
                    <div>
                      <h4 className="control-label col-sm-4 mt-20">Template Interval</h4>


                      <div className="col-sm-8 mt-20">
                        <input type="text" class="form-control" value={this.state.responserecurrence} id="interval" autoComplete="off" maxLength="250" disabled></input>
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">Start Date</h4>

                      <div className="col-sm-8 mt-20">
                        <DatePicker className="form-control form-control-inline input-medium default-date-picker"
                          dateFormat="dd-MMM-yyyy HH:mm" id="startdatefortemp" size="16" type="text"
                          showTimeSelect
                          timeIntervals={30}
                          value={this.state.responsestart}
                          selected={this.state.responsestart}
                          minDate={new Date()}
                          autoComplete="off"
                          onChange={this.handleChangeStartdatefor}
                          maxLength="250" />
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">End Date</h4>

                      <div className="col-sm-8 mt-20">
                        <DatePicker className="form-control form-control-inline input-medium default-date-picker"
                          dateFormat="dd-MMM-yyyy HH:mm"
                          showTimeSelect size="16" type="text"
                          timeIntervals={60}
                          value={this.state.responsend}
                          selected={this.state.responsend}
                          minDate={this.state.responsestart}
                          maxDate={addDays(new Date(), 365)} onChange={this.handleChangeEnddatefor}
                          id="enddatefortemp" autoComplete="off" maxLength="250" />
                      </div>

                      <div className="clearfix"></div>
                      <h4 class="control-label col-sm-4 ">&nbsp;</h4>
                      <div className="col-sm-8 mt-20">
                        {this.state.responserecurrence == 'Monthly' ?
                          <select class="form-control " style={{
                            width: '150px'
                          }}
                            id="updateday">
                            <option>No of Days</option>
                            {NoDays}
                          </select>
                          : null}
                      </div>

                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">Role</h4>

                      <div className="col-sm-8 mt-20">
                        <select id="multi-select" className="form-control" multiple="multiple" onChange={this.changerole.bind(this)} autoComplete="off">
                          {this.state.rlistopt.map((element) => (<option>{element}</option>))}
                        </select>
                      </div>


                      <div className="clearfix"></div>
                      <h4 className="control-label col-sm-4 mt-20">Stores(Optional)</h4>
                      <br />
                      <div className="col-sm-8 ">
                        <div class="upload-holder">
                          <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                          <span class="btn-upload">Upload</span>
                          <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />
                        </div>

                        <span class="help-block">Sample File <a href={require("./doc/sample.xlsx")}>Sample.xls</a></span>
                        <span >Existing store data </span>
                        <ExcelFile element={<a> Download</a>}>
                          <ExcelSheet data={this.state.responsetaskStores} name="Store Details">
                            <ExcelColumn label="Store No" value="storeId" />
                          </ExcelSheet>
                        </ExcelFile>
                      </div>
                    </div>
                    : null}

                </div>
                <div className="col-sm-8" style={{ "margin-left": "180px" }}>
                  <button class="btn btn-primary min-wid-90 mt-17"
                    onClick={this.UpdateTemplate.bind(this)}
                  >Update</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
    var createt = [];
    createt.push(
      <div className="modal" role="dialog" style={this.state.disappear ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff" }} onClick={this.createtclose}>X</a>
              <h4 className="modal-title">Create Template</h4>
            </div>
            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">

                  <h4 className="control-label col-sm-4">Category Name</h4>
                  <div className="col-sm-8">
                    <select class="form-control" onChange={this.taskcategoryId.bind(this)} id='temptaskCat'>
                      <option >Select Task Category</option>
                      {this.state.tcatopt.map((element) => (<option>{element}</option>))}
                    </select>                  </div>
                </div>



                <div className="form-group">

                  <h4 className="control-label col-sm-4">Template Name</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" id="tempName" autoComplete="off" maxLength="250"></input><br />
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Template Description</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" id="tempDesc" autoComplete="off" maxLength="250"></input>
                  </div>
                </div>
                <div className="form-group">
                  <h4 className="control-label col-sm-4"> Scheduled Template</h4>
                  <div className="col-sm-8">
                    <input type="checkbox" style={{ 'margin-top': '18px' }} class="custom-control-input" id="schedulecheck" onChange={() => { this.setState({ scheduleTemplate: !this.state.scheduleTemplate, isDaily: false, isMonthly: false }) }} /><br />
                    {this.state.scheduleTemplate ?
                      <div>
                        <select class="form-control col-lg-3 col-md-4 col-sm-4 col-xs-12 mb"
                          id="recurrence" onChange={this.checkInterval}>
                          <option>Select Interval</option>
                          <option>Daily</option>
                          <option>Monthly</option>
                        </select>


                        <div className="Clearfix"></div>
                        {this.state.isDaily == true ?
                          <div>
                            <label><b>Start Date</b></label>
                            <br />
                            <DatePicker className="form-control form-control-inline input-medium default-date-picker"
                              size="16" type="text" placeholderText="Select Start Date " dateFormat="dd-MMM-yyyy HH:mm" id="Dailydate"
                              selected={this.state.scheduleDate} showTimeSelect
                              timeIntervals={30}
                              minDate={new Date()}
                              onChange={this.handlescheduledate} onChangeRaw={this.handleschedulechange}
                            />
                          </div> : (null)}



                        {this.state.isDaily == true ?

                          <div className="mt-20">
                            <label><b>End Date</b></label>
                            <br />
                            {<DatePicker className="form-control form-control-inline input-medium default-date-picker"
                              size="16" type="text" placeholderText="Select End Date" dateFormat="dd-MMM-yyyy HH:mm" id="Dailyedate"
                              selected={this.state.scheduleend} showTimeSelect
                              timeIntervals={60}
                              minTime={setHours(setMinutes(new Date(), this.state.scheduleDate.getMinutes()), this.state.scheduleDate.getHours())}
                              maxTime={setHours(setMinutes(new Date(), 59), 23)}
                              onChange={this.handleScheduleEnd} minDate={this.state.scheduleDate}
                              maxDate={addDays(new Date(), 365)} onChangeRaw={this.handleDateraw}

                            />
                            }

                          </div>
                          : (null)}
                        {this.state.isMonthly == true ?

                          <select class="form-control mt-20" style={{ width: '150px' }}
                            id="recurrenceday">
                            <option>No of Days</option>
                            {NoDays}
                          </select>
                          : (null)}
                        <div className="clerfix"></div>
                        <div className="form-group mt-20">
                          <label className="control-label col-sm-4 "><b>Role</b></label>
                          <div className="col-sm-12 ">
                            <select id="multi-select" className="form-control" multiple="multiple" onChange={this.changerole.bind(this)} autoComplete="off">
                              {this.state.rlistopt.map((element) => (<option>{element}</option>))}
                            </select></div>
                        </div>


                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <label><b>Stores</b></label>
                            <br />
                            <div class="upload-holder">
                              <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                              <span class="btn-upload">Upload</span>
                              <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />
                            </div>
                            <span class="help-block">Sample File <a href={require("./doc/sample.xlsx")}>Sample.xls</a></span> </div>
                          <div>
                            <label hidden >Selected Stores</label>
                            <br />
                            <input type="hidden" className="form-control" value={this.state.StoreList} />
                          </div>
                        </div>

                      </div>
                      : null}
                  </div>


                </div>

                <div className="form-group">
                  <div className="control-label col-sm-4">&nbsp;</div>
                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-17"
                      onClick={this.CreateTemplate.bind(this)}
                    >Create Template</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );


    var box = [];

    box.push(

      <div className="modal" role="dialog" style={this.state.appear ? display : hide}>
        <div className="modal-dialog animate">
          <div className="modal-content">
            <div className="modal-header">
              <a className="close" style={{ color: "#fff", }} onClick={this.boxclose}>X</a>
              <h4 className="modal-title">Create Task (using this template)</h4>
            </div>
            <div className="modal-body box-scroll">
              <div className="form-horizontal">
                <div className="form-group">
                  <h4 className="control-label col-sm-4">Task Name</h4>
                  <div className="col-sm-8">
                    <input type="text" class="form-control" placeholder="Enter Task Name" value={this.state.taskName} onChange={(f) => this.setState({ taskName: f.target.value })} id="taskNameCT" maxlength="150" autoComplete="off" /><br />
                  </div>
                  <h4 className="control-label col-sm-4">Task Description</h4>
                  <div className="col-sm-8">
                    <textarea type="text" class="form-control" placeholder="Enter Task Description" value={this.state.taskDesc} onChange={(f) => this.setState({ taskDesc: f.target.value })} id="taskDescCT" maxlength="250" autoComplete="off" /><br />
                  </div>

                  <h4 className="control-label col-sm-4">Role</h4>
                  <div className="col-sm-8">
                    <select id="multi-select" className="form-control" multiple="multiple" onChange={this.changerole.bind(this)} autoComplete="off">
                      {this.state.rlistopt.map((element) => (<option>{element}</option>))}
                    </select>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Start Date</h4>
                  <div className="col-sm-8">
                    <DatePicker ref="startdate" className="form-control" placeholderText="Select Start Date"
                      size="16" type="text" dateFormat="dd-MMM-yyyy HH:mm" id="startDateCT"
                      selected={this.state.startDate}
                      minDate={new Date()}
                      showTimeSelect
                      timeIntervals={30}
                      onChange={this.handleChangeStart} autoComplete="off" onChangeRaw={this.handleDateChangeRaw}

                    /><br /><br />
                  </div>


                  <h4 className="control-label col-sm-4">End Date</h4>
                  <div className="col-sm-8">

                    {<DatePicker className="form-control"
                      size="16" type="text" placeholderText="Select End Date" dateFormat="dd-MMM-yyyy HH:mm" id="endDateCT"
                      selected={this.state.endDate}
                      onChange={this.handleChangeEnd}
                      minDate={this.state.startDate}
                      showTimeSelect
                      timeIntervals={30}
                      maxDate={addDays(new Date(), 365)}
                      onChangeRaw={this.handleDateChangeRaw}

                      autoComplete="off" />}

                    <br /><br />
                  </div>

                  <h4 className="control-label col-sm-4">Category ID</h4>
                  <div className="col-sm-8">
                    <select class="form-control"
                      onChange={this.taskcategoryId.bind(this)}
                      id='taskCat'>
                      <option>Select Task Catgory</option>
                      {this.state.tcatopt.map((element) => (<option>{element}</option>))}
                    </select>
                    <br />
                  </div>

                  <h4 className="control-label col-sm-4">Store</h4>
                  <div className="col-sm-8">
                    <input id="store-list" type="text" class="form-control input-upload" value={this.state.FileName} />
                    <span class="btn-upload">Upload</span>
                    <input id="upload-storedata" type="file" name="Store-list-data" class="input-hidden" onChange={this.fileHandler.bind(this)} accept=".xlsx,.xls" />
                  </div>
                  <div className="col-sm-8">
                    <p class="text-right samp-mg">Sample File <a href={require("./doc/sample.xlsx")}>Sample.xls</a></p>
                  </div>
                  <h4 className="control-label col-sm-4">&nbsp;</h4>
                  <div className="col-sm-8">
                    <button class="btn btn-primary min-wid-90 mt-20"
                      onClick={this.CreateTask.bind(this)}
                    >Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );


    var that = this;
    const { Search } = this.state;
    const { searchSDate } = this.state;
    const { searchedDate } = this.state;
    const { searcheddesc } = this.state;




    if (this.state.hasError) {
      return (
        <div><h2>Error occurred!! please contact administrator</h2></div>
      )
    } else {


      return (

        <div>
          {modal}
          {createt}
          {box}
          <section id="container">
            <header className="header black-bg" >
              <div>
                <IdleTimer
                  ref={ref => { this.idleTimer = ref }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 10} />
              </div>
              <a className="mob-show" onClick={() => this.setState({ hammenu: !this.state.hammenu })}><i className="fa fa-tasks hammenu" style={{ "margin-top": "30px" }}></i></a>

              <a onClick={this.TaskactiveBind.bind(this)} className="logo"><img src={require("./img/retail-logo.png")} alt="logo" /></a>
              <ul className="nav pull-right pos-rel">

                <li className="dropdown" ><a className="dropdown-toggle" data-toggle="dropdown" onClick={this.showDropdownMenu}> <img src={require("./img/user.png")} className="user-img" />
                  <b>{this.state.usrnm}</b>
                  <b className="caret" /></a>
                </li>

                {this.state.displayMenu ?
                  (

                    <ul className="dropdown-menuuser-dd">
                      <li><a onClick={this.Changepasswordbind} >Change Password</a></li>
                      <li className="divider"></li>
                      <li><a onClick={this.logout} >Log Out</a></li>
                    </ul>
                  ) :
                  (
                    null
                  )
                }

              </ul>
            </header>
            <aside>

              <div id="sidebar" className="nav-collapse">
                {this.state.hammenu ?
                  <ul className="sidebar-menu" id="nav-accordion">
                    <li className="sub-menu mt" onClick={this.showTaskDropdwnHandler.bind(this)}> <a href="javascript:;"> <i className="fa fa-tasks"></i> <span>Task</span> </a>
                      {this.state.TaskDropdwn ?
                        <div>
                          <ul class="sub" >
                            {this.state.role_id == "300" ?
                              <>
                                <li><a onClick={this.getroles.bind(this)}>Create Task</a></li>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>Active Task(s)</a></li>
                              </>
                              :
                              <>
                                <li><a onClick={this.TaskactiveBind.bind(this)}>My Task(s)</a></li>
                                <li><a onClick={this.TaskApprovalOneBind.bind(this)}>L-2 Approval</a></li>
                                <li><a onClick={this.TaskApprovalTwoBind.bind(this)}>L-3 Approval</a></li>
                              </>
                            }
                            {this.state.role_id == "300" ? <li><a onClick={this.CompletedTaskListbind.bind(this)}  >Task Answers</a></li> : null}
                            {this.state.role_id == "300" || this.state.role_id == "508" ? <li><a onClick={this.templates.bind(this)}>Templates</a></li> : null}

                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.archive.bind(this)}><span>Archive List</span> </a> </li>
                              : null}
                            {this.state.role_id == "506" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "300" ?
                              <li> <a onClick={this.EquipmentList.bind(this)}><span>Equipment List</span> </a> </li> : ''}
                          </ul>
                        </div>

                        : null}
                    </li>
                    {this.state.role_id == "300" || this.state.role_id == "505" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskReportHandler.bind(this)}><a href="javascript:;" ><i className="fa fa-tasks"></i>
                        <span>Reports</span> </a>
                        {this.state.Taskreport ?
                          <div>
                            <ul class="sub" >
                              <li> <a onClick={this.Reportsbind.bind(this)}><span>General Reports</span> </a> </li>
                              {this.state.role_id == "300" || this.state.role_id == "501" ? <li> <a onClick={this.summaryReports.bind(this)}><span>Summary Reports</span> </a> </li> : ''}
                            </ul>
                          </div> : null}
                      </li>
                      : null}
                    {this.state.role_id == "300" || this.state.role_id == "501" || this.state.role_id == "508" ?
                      <li className="sub-menu" onClick={this.showTaskdwnHandler.bind(this)}> <a href="javascript:;" > <i className="fa fa-tasks"></i> <span>Utilities</span> </a>
                        {this.state.Taskdwn ?
                          <div>
                            <ul class="sub" >
                              {this.state.role_id == "300" ? <li> <a onClick={this.tiker.bind(this)}><span>Tiker Creation</span> </a> </li> : ''}
                              {this.state.role_id == "300" ? <li> <a onClick={this.usercreation.bind(this)}><span>Category Creation</span> </a> </li> : ''}
                              <li> <a onClick={this.userlist.bind(this)}><span>User List</span> </a> </li>

                            </ul>
                          </div> : null}
                      </li> : null}
                  </ul> : null}
              </div>
            </aside>
            <section id="main-content">
              <section className="wrapper">
                <div className="mt">
                  <h3><i className="fa fa-angle-right"></i>Template(s)
             <a className="pull-right btn btn-theme" onClick={this.createmodal.bind(this)}>+ Create Template</a> </h3>
                  <hr />
                  <div className="row mt">
                    <div className="col-md-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3"> <b>Template Name</b><br />
                          <input type="text" placeholder="Search Template Name" className="form-control" id="taskname" onChange={this.onChange} autoComplete="off" />
                        </div>

                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4"> <b>Template Description</b><br />
                          <input type="text" placeholder="Search Template Description" className="form-control" id="taskdesc" onChange={this.onChange1} autoComplete="off" />
                        </div>
                        <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 mt-xs-10">
                          <img src={require("./img/searchicon.png")} className="user-img mt-20" style={{ width: "25px", height: "25px" }} />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                        <div className="mt">

                          <div className="tbl-holder">

                            <div className="spin">
                              <Spinner visible={this.state.loading}
                                spinnerColor={"rgba(0, 0, 0, 0.3)"} />
                            </div>

                            <table className="table table-striped table-advance table-hover table-bordered tbl-task tbl-hhide">
                              <thead>
                                <tr>
                                  <th>Template Name</th>
                                  <th>Description</th>
                                  <th>Recurrence</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>

                            <Scrollbars style={{ height: 296 }}>
                              <table className="table table-striped table-advance table-hover table-bordered tbl-task mob-tbl">
                                <tbody>
                                  {this.state.data.map(function (item, key) {

                                    if ((Search !== "" || searcheddesc !== "") &&
                                      (item.taskName.toLowerCase().indexOf(Search.toLowerCase()) || item.taskDesc.toLowerCase().indexOf(searcheddesc.toLowerCase())) === -1) {
                                      return null
                                    }

                                    return (
                                      <tr key={key}>
                                        <td data-th="Task Name"><a href="javascript:void(0)" onClick={that.calladdtemplatepage.bind(that, item.taskId, item.taskName, item.taskDesc)} className="black-text" > {item.taskName}</a></td>
                                        <td data-th="Description">{item.taskDesc}</td>
                                        <td data-th="Description">{item.recurrence}</td>
                                        <td data-th="Action">
                                          <a className="edit-icon"
                                            onClick={that.toggle.bind(that, item.taskId, item.taskName, item.taskDesc, item.recurrence)}> <i className="fa fa-pencil "></i> </a>
                                          <a className="delete-icon" onClick={that.deletetask.bind(that, item.taskId)}> <i className="fa fa-trash-o "></i> </a>
                                          <a onClick={that.duplicate.bind(that, item.taskName, item.taskDesc, item.taskId, item.taskCategoryId)}>
                                            <img src={require("./img/add-template.png")} className="ml-20" /></a>
                                          <span className="d-none">{item.taskId}</span>
                                        </td>


                                      </tr>
                                    )
                                  })}</tbody>

                              </table>
                            </Scrollbars>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </section>
            </section>
          </section>

        </div>
      );
    }
  }


  GetTasksOnload(e) {
    if (this.props.location.state !== undefined && this.props.location.state.usrnm !== '') {
      this.fetchrole();
      this.setState({
        loading: true,
        usrnm: this.props.location.state.usrnm,
        userid: this.props.location.state.userid,
        guId: this.props.location.state.guId

      })
      fetch('/GetAllTemplate', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse1 = decryptData(response)
          if (DecryptedResponse1.responseCode === '200 OK') {
            this.setState({ data: DecryptedResponse1.templteList });
            this.setState({ usrnm: this.props.location.state.usrnm });
            this.setState({ userid: this.props.location.state.userid });
            this.setState({ tskId: this.props.location.state.tskId });
            this.setState({ tskName: this.props.location.state.tskName });
            this.setState({ loading: false   })
          }
          else {
            this.setState({ loading: false })
            showSuccess(DecryptedResponse1.responseMsg)
          }
        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    }
    else {
      this.props.history.push({
        pathname: '/',
      });
    }


    var Request1 = {
      "guid": this.props.location.state.guId,
      "userId": this.props.location.state.userid
    }
    var EncryptedRequest1 = Encrypt(Request1);

    fetch('/GetTaskTypeCategory', {
      method: 'POST',
      body: EncryptedRequest1,
      headers: {
        guId: this.props.location.state.guId,
        'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

      }

    })
      .then((response) => response.text())
      .then((response) => {
        var DecryptedResponse = decryptData(response)




        if (DecryptedResponse.responseCode === '200 OK') {
          this.setState({ catTypeID: DecryptedResponse.TaskCategoryDetails });
          let tcat = [];
          let tcat1 = [];
          for (var p = 0; p < DecryptedResponse.TaskCategoryDetails.length; p++) {
            tcat.push(DecryptedResponse.TaskCategoryDetails[p].categoryName)
            tcat1.push(DecryptedResponse.TaskCategoryDetails[p].taskCatagoryId)
          }
          this.setState({ taskTypeOpt: tcat });
          this.setState({ taskTypeId: tcat1 });

        }
        else {
          showSuccess(DecryptedResponse.errorMsg)

        }


      })
      .catch((error) => {
        confirmAlert({
          title: 'Alert !',
          message: "Session expired",
          buttons: [
            {
              label: 'Ok',
              onClick: () => { this.logoutnormal() }
            },

          ]


        });
      });

  }

  CreateTemplate = () => {

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];


    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    var systemtime = date + ' ' + new Date().getHours() + ':' + new Date().getMinutes();

    if (this.state.isDaily == true) {
      var windowstarttime = document.getElementById("Dailydate").value;
      var windowendtime = document.getElementById("Dailyedate").value;
    }

    this.state.roleidpush.push(this.props.location.state.role_id)
    this.state.valueid = [...new Set(this.state.roleidpush)]
    if (!document.getElementById("tempDesc").value || !document.getElementById("tempName").value) {
      showSuccess('Please enter the required fields.')

    }
    else
      if (this.state.scheduleTemplate == true && document.getElementById('recurrence').value == 'Select Interval') {
        showSuccess('Please select an interval.')
      }
      else
        if (new Date(windowendtime) <= new Date(windowstarttime)) {
          showSuccess("End date/time cannot be earlier than start date/time.")

        }
        else if (new Date(windowendtime).getHours() <= new Date(windowstarttime).getHours()) {
          showSuccess("End time cannot be earlier than start time.")

        } else if (new Date(windowendtime) <= new Date(systemtime)) {

          showSuccess("End or Start date/time cannot be same or earlier than system date/time.")

        }

        else if (new Date(windowstarttime) < new Date(systemtime)) {
          showSuccess("Start date/time cannot be earlier than system date/time.")

        }



        else if (this.state.scheduleTemplate == true && this.state.isMonthly == true && document.getElementById("recurrenceday").value == "No of Days") {
          showSuccess('Please select no of days .')

        }
        else if (this.state.isDaily == true && this.state.valueid.length == 0) {
          showSuccess('Please select role.')

        }

        else if (this.state.scheduleTemplate == true && this.state.StoreList.length == 0) {
          showSuccess('Please upload stores.')
        }
        else {
          if (document.getElementById("temptaskCat").value == "Select Task Category") {
            showAlert("Please select task category")

          }
          
          else {
            var Request1 = {}
            if (this.state.scheduleTemplate != true) {
              Request1 = {
                "createdBy": this.state.userid,
                "taskDesc": document.getElementById("tempDesc").value,
                "taskName": document.getElementById("tempName").value,
                "taskCategoryId": this.state.Idfor.toString()
              }
            }
            else if (this.state.isMonthly == true) {
              Request1 = {
                "createdBy": this.state.userid,
                "taskDesc": document.getElementById("tempDesc").value,
                "taskName": document.getElementById("tempName").value,
                "recurrence": document.getElementById("recurrence").value,
                "startDate": document.getElementById("Dailydate").value,
                "endDate": document.getElementById("Dailyedate").value,
                "noOfDay": document.getElementById("recurrenceday").value,
                "roleId": this.state.valueid,
                "storeId": this.state.StoreList,
                "taskFile": this.state.previewData,
                "taskCategoryId": this.state.Idfor.toString()
              }
            }
            else {
              Request1 = {
                "createdBy": this.state.userid,
                "taskDesc": document.getElementById("tempDesc").value,
                "taskName": document.getElementById("tempName").value,
                "recurrence": document.getElementById("recurrence").value,
                "startDate": document.getElementById("Dailydate").value,
                "endDate": document.getElementById("Dailyedate").value,
                "roleId": this.state.valueid,
                "storeId": this.state.StoreList,
                "taskFile": this.state.previewData,
                "guId": this.props.location.state.guId,
                "taskCategoryId": this.state.Idfor.toString()
              }
            }
            var EncryptedRequest1 = Encrypt(Request1);
            
            fetch('/CreateTemplate', {
              method: 'POST',
              body: EncryptedRequest1,
              headers: {
                guId: this.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
              }
            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse = decryptData(response)
                if (DecryptedResponse.responseCode === '200 OK') {
                  confirmAlert({
                    title: 'Success',
                    message: "Template created successfully.  ",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { this.createtclose(); window.location.reload(true) }
                      },

                    ]
                  })


                }
                else {
                  showAlert(DecryptedResponse.responseMsg)

                }

              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });

              });

          }
        }
  }


  deletetask = (tskid) => {

    confirmAlert({
      message: 'Are you sure, you want to delete this template?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            var Request2 = {
              "taskId": tskid,
              "deletedBy": this.state.userid,
              "guId": this.props.location.state.guId,

            }
            var EncryptedRequest2 = Encrypt(Request2);
            fetch('/DeleteTemplate', {
              method: 'POST',
              body: EncryptedRequest2,
              headers: {
                guId: this.props.location.state.guId,
                'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
              }

            })
              .then((response) => response.text())
              .then((response) => {
                var DecryptedResponse2 = decryptData(response)
                if (DecryptedResponse2.responseCode === '200 OK') {
                  confirmAlert({
                    message: 'Template  Deleted Successfully.',
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => { window.location.reload(true) }
                      },

                    ]
                  })

                  this.forceUpdate();
                }
                else {
                  showAlert(DecryptedResponse2.responseMsg)
                }
              })
              .catch((error) => {
                confirmAlert({
                  title: 'Alert !',
                  message: "Session expired",
                  buttons: [
                    {
                      label: 'Ok',
                      onClick: () => { this.logoutnormal() }
                    },

                  ]


                });

              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          }
        }
      ]
    })

  }

  UpdateTemplate = () => {

    if (!document.getElementById("taskupDesc").value || !document.getElementById("taskupName").value) {
      showSuccess('Please enter the required fields')
    }
    else
      if (this.state.showrecurrance == true) {
        var vstartdate = new Date(document.getElementById('startdatefortemp').value);
        var venddate = new Date(document.getElementById("enddatefortemp").value);
        if (venddate < vstartdate) {
          showSuccess("End date cannot be earlier than start date")
        }
        else {

          if (this.state.valueid.length == 0) {
            showSuccess('Please select role.')
          }
          else {

            if (this.state.responserecurrence == 'Monthly') {
              if (document.getElementById('updateday').value == 'No of Days') {
                showSuccess("Select no of days.")

              }
              else {
                confirmAlert({
                  message: 'Are you sure, you want to update this template?',
                  buttons: [
                    {
                      label: 'Yes',
                      onClick: () => {
                        var Request2 = {
                          "taskId": document.getElementById("taskupID").value,
                          "taskDesc": document.getElementById("taskupDesc").value,
                          "taskName": document.getElementById("taskupName").value,
                          "updatedBy": this.state.userid,
                          "startDate": document.getElementById('startdatefortemp').value,
                          "endDate": document.getElementById("enddatefortemp").value,
                          "storeId": this.state.StoreList,
                          "recurrence": document.getElementById("interval").value,
                          "roleId": this.state.valueid,
                          "noOfDay": document.getElementById("updateday").value,
                          "guId": this.props.location.state.guId,
                        }
                        var EncryptedRequest2 = Encrypt(Request2);
                        fetch('/UpdateTemplate', {
                          method: 'POST',
                          body: EncryptedRequest2,
                          headers: {
                            guId: this.props.location.state.guId,
                            'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                          }

                        })
                          .then((response) => response.text())
                          .then((response) => {

                            var DecryptedResponse2 = decryptData(response)
                            if (DecryptedResponse2.responseCode === '200 OK') {
                              confirmAlert({
                                message: 'Template updated successfully.',
                                buttons: [
                                  {
                                    label: 'Ok',
                                    onClick: () => {
                                      this.modalclose();
                                      window.location.reload(true)
                                    }
                                  },

                                ]
                              })

                              this.forceUpdate();
                            }
                            else {
                              showAlert(DecryptedResponse2.responseMsg)
                            }
                          })
                          .catch((error) => {
                            confirmAlert({
                              title: 'Alert !',
                              message: "Session expired",
                              buttons: [
                                {
                                  label: 'Ok',
                                  onClick: () => { this.logoutnormal() }
                                },

                              ]


                            });

                          });
                      }
                    },
                    {
                      label: 'No',
                      onClick: () => {
                        return false;
                      }
                    }
                  ]
                })

              }
            }



            else {
              confirmAlert({
                message: 'Are you sure, you want to update this template?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      var Request2 = {
                        "taskId": document.getElementById("taskupID").value,
                        "taskDesc": document.getElementById("taskupDesc").value,
                        "taskName": document.getElementById("taskupName").value,
                        "updatedBy": this.state.userid,
                        "startDate": document.getElementById('startdatefortemp').value,
                        "endDate": document.getElementById("enddatefortemp").value,
                        "storeId": this.state.StoreList,
                        "recurrence": document.getElementById("interval").value,
                        "roleId": this.state.valueid,
                        "guId": this.props.location.state.guId,
                      }
                      var EncryptedRequest2 = Encrypt(Request2);
                      fetch('/UpdateTemplate', {
                        method: 'POST',
                        body: EncryptedRequest2,
                        headers: {
                          guId: this.props.location.state.guId,
                          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

                        }
                      })
                        .then((response) => response.text())
                        .then((response) => {

                          var DecryptedResponse2 = decryptData(response)
                          if (DecryptedResponse2.responseCode === '200 OK') {
                            confirmAlert({
                              message: 'Template updated successfully.',
                              buttons: [
                                {
                                  label: 'Ok',
                                  onClick: () => {
                                    this.modalclose();
                                    window.location.reload(true)
                                  }
                                },

                              ]
                            })

                            this.forceUpdate();
                          }
                          else {
                            showAlert(DecryptedResponse2.responseMsg)
                          }
                        })
                        .catch((error) => {
                          confirmAlert({
                            title: 'Alert !',
                            message: "Session expired",
                            buttons: [
                              {
                                label: 'Ok',
                                onClick: () => { this.logoutnormal() }
                              },

                            ]


                          });

                        });
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                      return false;
                    }
                  }
                ]
              })
            }
          }
        }
      }
      else {
        confirmAlert({
          message: 'Are you sure, you want to update this template?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                var Request2 = {
                  "taskId": document.getElementById("taskupID").value,
                  "taskDesc": document.getElementById("taskupDesc").value,
                  "taskName": document.getElementById("taskupName").value,
                  "updatedBy": this.state.userid,
                  "guId": this.props.location.state.guId,
                }
                var EncryptedRequest2 = Encrypt(Request2);
                fetch('/UpdateTemplate', {
                  method: 'POST',
                  body: EncryptedRequest2,
                  headers: {
                    guId: this.props.location.state.guId,
                    'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
                  }

                })
                  .then((response) => response.text())
                  .then((response) => {
                    var DecryptedResponse2 = decryptData(response)
                    if (DecryptedResponse2.responseCode === '200 OK') {
                      confirmAlert({
                        message: 'Template updated successfully.',
                        buttons: [
                          {
                            label: 'Ok',
                            onClick: () => {
                              this.modalclose();
                              window.location.reload(true)
                            }
                          },

                        ]
                      })

                      this.forceUpdate();
                    }
                    else {
                      showAlert(DecryptedResponse2.responseMsg)

                    }
                  })
                  .catch((error) => {
                    confirmAlert({
                      title: 'Alert !',
                      message: "Session expired",
                      buttons: [
                        {
                          label: 'Ok',
                          onClick: () => { this.logoutnormal() }
                        },

                      ]


                    });

                  });
              }
            },
            {
              label: 'No',
              onClick: () => {
                return false;
              }
            }
          ]
        })
      }
  }
  CreateTask(e) {
    e.preventDefault();

    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];


    var date = new Date().getDate() + '-' + monthNames[new Date().getMonth()] + "-" + new Date().getFullYear()

    var systemtime = date + ' ' + new Date().getHours() + ':' + new Date().getMinutes();
    var windowstarttime = document.getElementById("startDateCT").value;
    var windowendtime = document.getElementById("endDateCT").value;

    this.state.roleidpush.push(this.props.location.state.role_id)
    this.state.valueid = [...new Set(this.state.roleidpush)]
    if (!document.getElementById("taskNameCT").value || !document.getElementById("taskDescCT").value) {
      showAlert("Please enter required fields")

    }

    else {
      var vstartdate = new Date(document.getElementById("startDateCT").value);
      var venddate = new Date(document.getElementById("endDateCT").value);
      if (venddate < vstartdate) {
        showSuccess("End date/time cannot be earlier than start date/time.")

      }
      else if (new Date(windowendtime) <= new Date(systemtime)) {

        showSuccess("End or Start date/time cannot be same or earlier than system date/time.")

      }
      else if (new Date(windowstarttime) < new Date(systemtime)) {
        showSuccess("Start date/time cannot be earlier than system date/time.")
      }


      else {


        if (document.getElementById("taskCat").value == "Select Task Catgory") {
          showAlert("Please select task category.")
        }
        else {




          var Request1 = {
            "startDate": document.getElementById("startDateCT").value,
            "endDate": document.getElementById("endDateCT").value,
            "createdBy": this.state.userid,
            "taskDesc": document.getElementById("taskDescCT").value,
            "taskName": document.getElementById("taskNameCT").value,
            "storeId": this.state.StoreList,
            "action": "Create",
            "roleId": this.state.valueid,
            "taskFile": this.state.previewData,
            "templateId": this.state.templetId,
            "guId": this.props.location.state.guId,
            "taskCategoryId": this.state.Idfor.toString()

          }
          var EncryptedRequest1 = Encrypt(Request1);
          fetch('/CreateTask', {
            method: 'POST',
            body: EncryptedRequest1,
            headers: {
              guId: this.props.location.state.guId,
              'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),
            }
          })
            .then((response) => response.text())
            .then((response) => {
              var DecryptedResponse = decryptData(response)
              if (DecryptedResponse.responseCode === '200 OK') {
                if (DecryptedResponse.unmatchedStore.length > 0) {
                  confirmAlert({
                    title: 'Success',
                    message: "Task created successfully." + DecryptedResponse.unmatchedStore + " " + "does not exist.",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {
                          this.props.history.push({
                            pathname: '/Taskactive',
                            state: {
                              userid: this.state.userid,
                              usrnm: this.state.usrnm,
                              role_id: this.state.roleId,
                              guId: this.props.location.state.guId

                            }
                          }
                          );
                        }
                      },

                    ]
                  })
                }
                else {
                  confirmAlert({
                    title: 'Success',
                    message: "Task created successfully.  ",
                    buttons: [
                      {
                        label: 'Ok',
                        onClick: () => {
                          this.props.history.push({
                            pathname: '/Taskactive',
                            state: {
                              userid: this.state.userid,
                              usrnm: this.state.usrnm,
                              role_id: this.state.roleId,
                              guId: this.props.location.state.guId

                            }
                          }
                          );
                        }
                      },

                    ]
                  })
                }

              }
              else {
                showAlert(DecryptedResponse.responseMsg)

              }

            })
            .catch((error) => {
              confirmAlert({
                title: 'Alert !',
                message: "Session expired",
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => { this.logoutnormal() }
                  },

                ]


              });

            });
        }
      }
    }
  }


  callupdatetaskpage(tskId, tskName, tskDescr, strtDate, edDate) {
    this.props.history.push({
      pathname: '/Updatetasks',
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: new Date(strtDate),
        edDate: new Date(edDate),
        userid: this.props.location.state.userid,
        role_id: this.props.location.state.role_id,
        usrnm: this.state.usrnm,
        guId: this.props.location.state.guId
      }
    }
    );

  }
  calladdquestionpage(tskId, tskName, tskDescr, strtDate, edDate, publishStatus) {
    this.props.history.push({
      pathname: '/Addquestion',
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        strtDate: strtDate,
        edDate: edDate,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        publishStatus: publishStatus,
        guId: this.props.location.state.guId
      }
    }
    );

  }

  calladdtemplatepage(tskId, tskName, tskDescr) {
    this.props.history.push({
      pathname: '/Addtemplate',
      state: {
        tskId: tskId,
        tskName: tskName,
        tskDescr: tskDescr,
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId


      }
    }
    );

  }


  EquipmentList =()=>{
    if(this.props.location.state.role_id=="505"||  this.props.location.state.role_id=="501" || this.props.location.state.role_id=="300"){
      this.props.history.push({
        pathname: '/Newstore',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
    }
    else{
      this.props.history.push({
        pathname: '/EquipmentList',
        state: {
          userid: this.props.location.state.userid,
          usrnm: this.props.location.state.usrnm,
          role_id: this.props.location.state.role_id,
          storeNo:this.props.location.state.storeNo,
          guId:this.props.location.state.guId
        }
      }
      );
   }
    
  }


  getroles(e) {
    this.props.history.push({
      pathname: '/Createtasks',
      state: {
        role_id: this.props.location.state.role_id,
        userid: this.props.location.state.userid,
        usrnm: this.props.location.state.usrnm,
        guId: this.props.location.state.guId,


      }
    }
    );
  }

  TaskactiveBind = () => {
    this.props.history.push({
      pathname: '/Taskactive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId


      }
    }
    );
  }

  TaskApprovalOneBind = () => {
    this.props.history.push({
      pathname: '/LevelTwoApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  TaskApprovalTwoBind = () => {
    this.props.history.push({
      pathname: '/LevelThreeApproval',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Changepasswordbind = () => {
    this.props.history.push({
      pathname: '/Changepassword',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId

      }
    }
    );
  }

  Reportsbind = () => {
    this.props.history.push({
      pathname: '/Reports',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        guId: this.props.location.state.guId
      }
    }
    );
  }

  showTaskReportHandler = () => {
    this.setState({
      Taskreport: !(this.state.Taskreport)
    })
  }



  summaryReports = () => {
    this.props.history.push({
      pathname: '/TaskSummaryReport',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        guId: this.props.location.state.guId,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
      }
    }
    );
  }

  CompletedTaskListbind = () => {
    this.props.history.push({
      pathname: '/CompletedTaskList',
      state: {
        data: this.state.data,
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId
      }
    }
    );
  }



  archive = () => {
    this.props.history.push({
      pathname: '/Archive',
      state: {
        userid: this.props.location.state.userid,
        usrnm: this.state.usrnm,
        role_id: this.props.location.state.role_id,
        data: this.state.data,
        tskId: this.state.tskId,
        tskName: this.state.tskName,
        storeNo: this.props.location.state.storeNo,
        guId: this.props.location.state.guId,


      }
    }
    );
  }




  fetchrole(e) {
    if (this.props.location.state) {
      this.setState({ roleId: this.props.location.state.role_id });
      this.setState({ userid: this.props.location.state.userid });
      this.setState({ usrnm: this.props.location.state.usrnm });
      this.setState({ guId: this.props.location.state.guId })
      fetch('/GetTaskCategory', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }

      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ catID: DecryptedResponse.taskCategoryList });
            let tcat = [];
            let tcat1 = [];
            for (var p = 0; p < DecryptedResponse.taskCategoryList.length; p++) {
              tcat.push(DecryptedResponse.taskCategoryList[p].categoryName)
              tcat1.push(DecryptedResponse.taskCategoryList[p].categoryId)
            }
            this.setState({ tcatopt: tcat });
            this.setState({ tcatid: tcat1 });
          }
          else {
            showSuccess(DecryptedResponse.errorMsg)
          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });

      fetch('/GetRoles', {
        method: 'GET',
        headers: {
          guId: this.props.location.state.guId,
          'Authorization': 'Basic ' + basicAuth(this.props.location.state.userid),

        }
      })
        .then((response) => response.text())
        .then((response) => {
          var DecryptedResponse = decryptData(response)
          if (DecryptedResponse.responseCode === '200 OK') {
            this.setState({ roleList: DecryptedResponse.roleList });
            let temp = [];
            let temp1 = [];
            for (var i = 0; i < DecryptedResponse.roleList.length; i++) {
              temp.push(DecryptedResponse.roleList[i].roleDescription)
              temp1.push(DecryptedResponse.roleList[i].role_id)
            }
            this.setState({ rlistopt: temp });
            this.setState({ rlistid: temp1 });

          }
          else {
            showSuccess(DecryptedResponse.errorMsg)
          }


        })
        .catch((error) => {
          confirmAlert({
            title: 'Alert !',
            message: "Session expired",
            buttons: [
              {
                label: 'Ok',
                onClick: () => { this.logoutnormal() }
              },

            ]


          });

        });
    } else {
      this.props.history.push({
        pathname: '/',
      });

    }
  }







}
export default withRouter(Templates);

